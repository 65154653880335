import './DateFilter.scss';
import { Col, Form, Row } from 'react-bootstrap';
import { StartEndDatePicker } from '../..';

const DateFilters = (props) => {
  return (
    <Col className="p-0 col-date-filter">
      <Form.Group controlId="time-date">
        <StartEndDatePicker
          startDate={props.startDate}
          endDate={props.endDate}
          onChangeStartDate={props.onChangeStartDate}
          onChangeEndDate={props.onChangeEndDate}
        />
      </Form.Group>
    </Col>
  );
};

export default DateFilters;
