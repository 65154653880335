import '../../scss/comps/Swal.scss';
import './EquipmentInfo.scss';
import { isAdmin, isReadOnly, isSystemAdmin, isUser } from '@utils';
import { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Icon } from '..';

function EquipmentInfo({
  initData,
  updateIdentifiers,
  setEquipDirty,
  isAddingIdentifier,
  setIsAddingIdentifier,
}) {
  const [isEditing, setIsEditing] = useState<number>(-1);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [tempKey, setTempKey] = useState<string>(null);
  const [tempValue, setTempValue] = useState<string>(null);
  const [invalidKey, setInvalidKey] = useState<boolean>(false);
  const MySwal = withReactContent(Swal);

  const authorized = isAdmin() || isSystemAdmin();

  // Closes new identifier if edit is clicked
  useEffect(() => {
    if (isEditing === -1) {
      setEquipDirty(false);
    } else if (isEditing !== -1) {
      setEquipDirty(true);
      setIsAddingIdentifier(false);
    }
  }, [isEditing]);

  // Closes edit if new identifier is clicked
  useEffect(() => {
    if (isAddingIdentifier === false) {
      setEquipDirty(false);
    } else if (isAddingIdentifier === true) {
      setIsEditing(-1);
      setInvalidKey(false);
      setTempKey(null);
      setTempValue(null);
      setIsModified(false);
      setEquipDirty(true);
    }
  }, [isAddingIdentifier]);

  // Confirmation dialog and submit
  const submitData = (
    newData: Array<{ key: any; value: any }>,
    type: string,
    indeX: number = 0,
  ) => {
    let textContent = document.createElement('div');

    textContent.innerHTML = `
      <b>
        <div class="swal2-submitData" >${newData[indeX].key}-${newData[indeX].value}?</div>
        Are you sure you want to ${type} this key-value pair?
      </b>`;

    MySwal.fire({
      allowOutsideClick: false,
      title: `${type.charAt(0).toUpperCase() + type.slice(1)} key-value pair`,
      html: textContent,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      customClass: {
        htmlContainer: 'swal2HtmlContainer',
      },
    })
      .then((submit) => {
        if (submit.isConfirmed && type === 'delete') {
          const rmData = newData.filter((equipObj: any, equipIndex) => {
            return equipIndex !== indeX;
          });
          updateIdentifiers(rmData);
        } else if (submit.isConfirmed) {
          updateIdentifiers(newData);
        }
        // Reset state
        setIsAddingIdentifier(false);
        setIsModified(false);
        setTempKey(null);
        setTempValue(null);
        setIsEditing(-1);
      })
      .catch((err: any) => {
        console.log('What is the submitData error', err);
      });
  };

  // Renders existing identifiers
  const renderIdentifiers = () => {
    return initData.map((item, index) => {
      return (
        <div className="h-100 d-flex align-items-center" key={item.key}>
          <Badge
            bg={isEditing === index && invalidKey ? 'danger' : 'primary'}
            style={{ margin: '8px', padding: '10px' }}
            pill
          >
            <input
              className="fs-lg"
              style={
                isEditing === index
                  ? {
                      border: '1px solid var(--secondary)',
                      borderRadius: '2em',
                      height: '25px',
                      paddingLeft: '10px',
                    }
                  : {
                      background: 'transparent',
                      border: '1px solid transparent',
                      color: 'var(--light)',
                      borderRadius: '2em',
                      height: '25px',
                      paddingLeft: '10px',
                    }
              }
              disabled={isEditing === index ? false : true}
              type="text"
              value={isModified && isEditing === index && tempKey !== null ? tempKey : item.key}
              placeholder="Enter key"
              onChange={(e) => {
                setIsModified(true);
                setInvalidKey(
                  initData.find((elem) => elem.key === e.target.value && item.key !== elem.key)
                    ? true
                    : false,
                );
                setTempKey(e.target.value);
              }}
            />
          </Badge>
          <Badge style={{ margin: '8px', padding: '10px' }} pill>
            <input
              className="fs-lg"
              style={
                isEditing === index
                  ? {
                      border: '1px solid var(--secondary)',
                      borderRadius: '2em',
                      height: '25px',
                      paddingLeft: '10px',
                    }
                  : {
                      background: 'transparent',
                      border: '1px solid transparent',
                      color: 'var(--light)',
                      borderRadius: '2em',
                      height: '25px',
                      paddingLeft: '10px',
                    }
              }
              disabled={isEditing === index ? false : true}
              type="text"
              value={
                isModified && isEditing === index && tempValue !== null ? tempValue : item.value
              }
              placeholder="Enter value"
              onChange={(e) => {
                setIsModified(true);
                setTempValue(e.target.value);
              }}
            />
          </Badge>

          {/* Edit icons */}

          <>
            <Icon
              name={isEditing === index ? 'check' : authorized || isUser() ? 'edit' : ''}
              size="md"
              variant="light"
              disabled={
                // Check for empty values OR invalid key
                (isEditing === index &&
                  isModified &&
                  (tempKey?.trim() === '' || tempValue?.trim() === '')) ||
                (isEditing === index && isModified && invalidKey) ||
                isReadOnly()
              }
              style={{ color: 'var(--primary)' }}
              className={
                isEditing === index
                  ? 'sonarIcon-circle'
                  : authorized || isUser()
                  ? 'sonarIcon-circle'
                  : 'sonarIcon-circle-invisible'
              }
              onClick={() => {
                if (isEditing === index) {
                  const newData = JSON.parse(JSON.stringify(initData));
                  if (tempKey) {
                    newData[isEditing].key = tempKey;
                  }
                  if (tempValue) {
                    newData[isEditing].value = tempValue;
                  }
                  if (isModified) {
                    submitData(newData, 'modify', index);
                  } else {
                    setIsEditing(-1);
                  }
                } else {
                  setIsEditing(index);
                }
              }}
            />
            {!isReadOnly() && (
              <Icon
                name="add"
                size="md"
                variant="light"
                disabled={isEditing === index ? false : true}
                style={{ color: isEditing === index ? 'var(--danger)' : 'transparent' }}
                className={
                  isEditing === index
                    ? 'sonarIcon-rotate-45 sonarIcon-circle'
                    : 'sonarIcon-rotate-45 sonarIcon-circle-invisible'
                }
                onClick={() => {
                  const newData = JSON.parse(JSON.stringify(initData));
                  submitData(newData, 'delete', index);
                  setIsEditing(-1);
                  setInvalidKey(false);
                }}
              />
            )}
          </>
        </div>
      );
    });
  };

  return (
    <div className="d-flex flex-column justify-content-middle align-items-center">
      <div
        className="d-flex flex-column justify-content-left align-items-left"
        style={{ whiteSpace: 'nowrap' }}
      >
        {/* Header row */}
        <div>
          <Badge pill bg="light" style={{ margin: '8px', padding: '10px' }}>
            <input
              className="fs-lg"
              style={{
                background: 'transparent',
                border: '1px solid transparent',
                color: 'var(--dark)',
                borderRadius: '2em',
                height: '25px',
                paddingLeft: '10px',
              }}
              disabled={true}
              type="text"
              value="Key:"
              placeholder="Enter key"
            />
          </Badge>
          <Badge pill bg="light" style={{ margin: '8px', padding: '10px' }}>
            <input
              className="fs-lg"
              style={{
                background: 'transparent',
                border: '1px solid transparent',
                color: 'var(--dark)',
                borderRadius: '2em',
                height: '25px',
                paddingLeft: '10px',
              }}
              disabled={true}
              type="text"
              value="Value:"
              placeholder="Enter value"
            />
          </Badge>
        </div>

        {/* Existing identifiers */}
        {renderIdentifiers()}

        {/* New identifier */}
        {isAddingIdentifier && (
          <div className="k-v-pair">
            <Badge
              bg={invalidKey ? 'danger' : 'primary'}
              style={{ margin: '8px', padding: '10px' }}
              pill
            >
              <input
                className="fs-lg"
                style={{
                  border: '1px solid var(--secondary)',
                  borderRadius: '2em',
                  height: '25px',
                  paddingLeft: '10px',
                }}
                type="text"
                placeholder="Enter key"
                onChange={(e) => {
                  setInvalidKey(
                    initData.find((item: any) => item.key === e.target.value) ? true : false,
                  );
                  setTempKey(e.target.value);
                }}
              />
            </Badge>
            <Badge style={{ margin: '8px', padding: '10px' }} pill>
              <input
                className="fs-lg"
                style={{
                  border: '1px solid var(--secondary)',
                  borderRadius: '2em',
                  height: '25px',
                  paddingLeft: '10px',
                }}
                type="text"
                placeholder="Enter value"
                onChange={(e) => setTempValue(e.target.value)}
              />
            </Badge>

            {/* New identifier icons */}
            {!isReadOnly() && (
              <>
                <Icon
                  name="check"
                  size="md"
                  variant="light"
                  disabled={invalidKey || !tempKey || !tempValue ? true : false}
                  style={{ color: 'var(--primary)' }}
                  className="sonarIcon-circle"
                  onClick={() => {
                    const newData = JSON.parse(JSON.stringify(initData));
                    newData.push({ key: tempKey, value: tempValue });
                    submitData(newData, 'add', newData.length - 1);
                  }}
                />
                <Icon
                  name="add"
                  size="md"
                  variant="light"
                  disabled={false}
                  style={{ color: 'var(--danger)' }}
                  className="sonarIcon-rotate-45 sonarIcon-circle"
                  onClick={() => {
                    setIsAddingIdentifier(false);
                    setInvalidKey(false);
                  }}
                />
              </>
            )}
          </div>
        )}

        {/* Invalid key help text */}
        {invalidKey && (
          <div
            className="d-flex justify-content-left fs-md"
            style={{ color: 'var(--danger)', margin: '8px' }}
          >
            * Cannot use duplicate keys
          </div>
        )}
      </div>
    </div>
  );
}

export default EquipmentInfo;
