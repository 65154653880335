import './BeaconNameAssignedDateRenderer.scss';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../../Icon/Icon';

const BeaconNameAssignedDateRenderer = ({ data }) => {
  const assignedDate = moment(data.assigned).format('lll');
  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  return (
    <div className="d-flex flex-column associated-beacons-name-column">
      {data.isLocationBeacon && (
        <span className="align-self-center">
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip('This icon indicates the Primary Beacon on this Asset.')}
          >
            <span className="d-inline-block">
              <div
                variant="link"
                style={{
                  pointerEvents: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <Icon
                  name={'crown'}
                  variant="light"
                  size="1x"
                  style={{ color: '#E1A409' }}
                  disabled={false}
                />
              </div>
            </span>
          </OverlayTrigger>
        </span>
      )}
      <span className="fs-lg">{data.name}</span>

      <span className="fs-xsm">Assigned On: {assignedDate}</span>
    </div>
  );
};

export default BeaconNameAssignedDateRenderer;
