// import mapService from '../../../services/mapService';
import 'react-rangeslider/lib/index.css';
import './breadcrumbs.css';
import './breadcrumbsContainer.scss';
import { toast } from '@components';
import { Api } from '@services';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
// import thingService from '../../../services/thingService';
import { Button } from 'react-bootstrap';
import Slider from 'react-rangeslider';
import BreadcrumbsTable from '../components/breadcrumbsTable';
import LocationMap from '../components/locationMap';
import PlaybackControls from '../components/playbackControls';
import timeSeriesFunctions from '../services/timeSeriesFunctions';
import GeoLocationMap from './../components/geoLocationBreadcrumbMap';
import GeoLocationMap2 from './../components/geoLocationMap';

async function getGeoTimeSeries(thingId, startDate, endDate, pageNumber, pageSize, sortOrder) {
  return Api.get(
    `/assets/${thingId}/breadcrumbs?start=${moment(startDate).toISOString()}&end=${moment(
      endDate,
    ).toISOString()}&limit=${pageSize}&page=${pageNumber}&order=${sortOrder}`,
  );
}

let playTimeout;

const MAP_TYPE = {
  STATIC: 0,
  GPS: 1,
};

class BreadcrumbsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      maps: [],
      locations: [],
      totalItems: '0',
      totalPages: '0',
      thing: {},
      query: {
        limit: '0',
        page: '1',
        sortOrder: 1,
        sortField: 'timestamp',
        filter: '',
        startDate: undefined,
        endDate: undefined,
      },
      mapDisplayType: 'lines',
      playback: false,
      showPlaybackControls: false,
      playbackSpeed: 'Normal',
      visibility: {
        gateways: false,
        zones: false,
        beacons: false,
        outliers: false,
      },
      currentlySelected: null,
      seeAllBreadcrumbs: false,
      barVal: 0,
      pace: 750,
      paused: false,
      ready: false,
      fallbackMap: null,
      units: null,
    };

    this.handlePlayback = this.handlePlayback.bind(this);
    this.changeMapDisplayType = this.changeMapDisplayType.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.queryChange = this.queryChange.bind(this);
    this.changeSelection = this.changeSelection.bind(this);
    this.frameChange = this.frameChange.bind(this);
    this.showPlaybackControls = this.showPlaybackControls.bind(this);
    this.handlePlaybackSpeed = this.handlePlaybackSpeed.bind(this);
    this.play = this.play.bind(this);
    this.resetToDefault = this.resetToDefault.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
  }

  // loop through primary array start to finish
  //

  play(startIndex) {
    let i = 1;
    if (startIndex) {
      i = startIndex;
    }
    let drawMarkerAtPace = () => {
      if (!this.state.playback) {
        return;
      }

      playTimeout = setTimeout(() => {
        if (!this.state.locations[i]) {
          return;
        }
        this.changeSelection(i);
        this.setState({ currentlySelected: i }, () => {
          i++;
          if (i < this.state.locations.length) {
            drawMarkerAtPace();
          }
        });
      }, this.state.pace);
    };

    drawMarkerAtPace();
  }

  handlePlayback() {
    let condition = !this.state.playback;
    if (this.state.paused && this.state.currentlySelected) {
      // CONTINUING THE PLAY
      this.setState({ playback: true, paused: false }, () => {
        this.play(this.state.currentlySelected);
      });
    } else {
      // NEW PLAY
      this.setState(
        { playback: !this.state.playback, paused: false, currentlySelected: null },
        () => {
          if (condition) {
            this.play();
          }
        },
      );
    }
  }

  handlePause(pausedNum) {
    clearTimeout(playTimeout);
    this.setState({ playback: true, paused: true, currentlySelected: pausedNum });
    this.changeSelection(pausedNum);
  }

  resetToDefault() {
    clearTimeout(playTimeout);
    this.changeSelection(0);
    this.setState({ playback: false, paused: false, currentlySelected: 0 });
  }

  frameChange(direction) {
    this.handlePause();
    let index;
    // Setting starting Index
    if (!this.state.currentlySelected) {
      this.changeSelection(0);
      index = 0;
    } else {
      index = this.state.currentlySelected;
    }

    if (direction === 'up') {
      index = index + 1;
    } else {
      index = index - 1;
    }

    if (index < 0) {
      index = this.state.locations.length - 1;
    } else if (index >= this.state.locations.length) {
      index = 0;
    }
    this.changeSelection(index);
  }

  showPlaybackControls() {
    this.setState({ showPlaybackControls: !this.state.showPlaybackControls });
  }

  handlePlaybackSpeed(selection) {
    this.setState({ playbackSpeed: selection });
    const rate = selection === 'Normal' ? 1 : selection;
    this.setState({ pace: 750 / rate });
  }

  componentDidMount() {
    let thingId = this.props.match.params.thingId;
    this.getThingDetails(thingId);
    this.getUnitDetails();
  }

  getUnitDetails = async () => {
    try {
      const { data } = await Api.get(`/users`);
      const unit = data.distanceUnit === 'Imperial' ? 'ft' : 'm';
      this.setState({ units: unit });
    } catch (error) {
      setIsError(true);
    }
  };

  getTimeSeries() {
    const { thingId } = this.props.match.params;
    const { startDate, endDate, page, limit, sortOrder } = this.state.query;

    this.setState({ isLoading: true });
    getGeoTimeSeries(thingId, startDate, endDate, page, limit, sortOrder)
      .then((x) => {
        const { results, total, pageCount } = x.data.locations;
        let locations = timeSeriesFunctions.assignPropsToTimeSeries(results);
        let sortedLocations = this.sortLocationData('full', locations);

        x.data.maps.forEach((map, i) => {
          map.gateways.forEach(async (gw, ii) => {
            let gwData = await Api.get(`/gateways/${gw.gatewayId}`);
            x.data.maps[i].gateways[ii].isOnline = gwData.data.isOnline;
          });
        });

        this.setState(
          {
            locations,
            sortedLocations,
            totalItems: total + '',
            pageCount: pageCount + '',
            maps: x.data.maps,
            currentlySelected: 0,
            ready: true,
          },
          () => {
            this.changeSelection(0);
          },
        );
      })
      .catch((error) => {
        console.error('Error fetching time series data:', error);
        // Handle error and possibly set isLoading to false if needed
      })
      .finally(() => {
        this.setState({ isLoading: false }); // Set isLoading to false after the call is completed
      });
  }

  sortLocationData(type = 'full' || 'slider', locations) {
    locations = locations !== undefined ? locations : this.state.locations;
    if (locations.length === 0) {
      return;
    }

    const datasets = [];
    const sliderLabelData = {};

    let index = 0;
    let prevMapId = locations[0].mapId;

    locations.map((value, i) => {
      if (value.mapId === prevMapId) {
        datasets[i] !== undefined ? datasets[i].push(value) : (datasets[i] = [value]);
        sliderLabelData[index] = '|';
      } else {
        index = value.index;
        datasets[index] = [value];
        sliderLabelData[index] = '|';
      }
      prevMapId = value.mapId;

      return datasets ?? sliderLabelData;
    });

    return type === 'full' ? datasets : sliderLabelData;
  }

  renderSliderLabels() {
    if (this.state.maps.length > 1) {
      return this.sortLocationData('slider');
    } else {
      return {};
    }
  }

  getThingDetails = async (thingId) => {
    const { data: asset } = await Api.get(`/assets/${thingId}`);
    asset.lastLocationCoordinates = { ...asset.location.coordinates, type: MAP_TYPE.STATIC };
    this.setState({ thing: asset }, async () => {
      let { data: fallbackMap } = await Api.get(`/legacy/maps/${asset.location.mapId}`);

      fallbackMap.gateways.forEach(async (gw, i) => {
        let gwData = await Api.get(`/gateways/${gw.gatewayId}`);
        fallbackMap.gateways[i].isOnline = gwData.data.isOnline;
      });

      this.setState({ fallbackMap });
    });
  };

  changeMapDisplayType(value) {
    this.setState({ mapDisplayType: value });
  }

  toggleVisibility(name) {
    let { maps, visibility, fallbackMap } = this.state;

    const map =
      (this.isReadyWithLocations() &&
        this.getCurrentLocation().location.type === MAP_TYPE.STATIC &&
        this.getCurrentMap()) ||
      fallbackMap;

    map.outliers = []; // REMOVE THIS!

    if (!map) return toast.error('Failed to find a map.');

    map[name].map((i) => {
      i.isVisible = !visibility[name];
      return i;
    });

    let position = maps
      .map((val) => {
        return val.id;
      })
      .indexOf(map.id);

    // eslint-disable-next-line
    this.state.maps[position] = map;

    visibility[name] = !visibility[name];

    this.setState({ maps, visibility });
  }

  queryChange(page, limit, sortOrder, sortField, startDate, endDate) {
    // Check if both startDate and endDate are selected
    if (startDate !== undefined && endDate !== undefined) {
      this.setState(
        {
          query: {
            page: page,
            limit: limit,
            sortOrder: sortOrder,
            sortField: sortField,
            startDate: startDate,
            endDate: endDate,
          },
        },
        () => {
          this.getTimeSeries();
        },
      );
    }
  }

  changeSelection(index) {
    let { locations } = this.state;
    locations = locations.map((l) => {
      if (l.index === index) {
        if (!l.isHighlighted) {
          l.isHighlighted = true;
          l.isVisible = true;
        } else {
          l.isHighlighted = false;
        }
      } else {
        l.isHighlighted = false;
      }

      return l;
    });

    this.setState({ currentlySelected: index, locations: locations });
  }

  handleSliderChange(value) {
    this.handlePause(value);
  }

  mapGateways = (gateways) => {
    return gateways.map((gateway) => ({
      ...gateway,
      location: { ...gateway.location, type: MAP_TYPE.STATIC },
      gateway: { ...gateway },
    }));
  };

  mapBeacons = (beacons) => {
    return beacons.map((beacon) => ({
      ...beacon,
      location: { ...beacon.location, type: MAP_TYPE.STATIC },
      beacon: { ...beacon },
    }));
  };

  renderFallback() {
    const { thing, fallbackMap } = this.state;

    const noDataElement = (
      <h1 className={'cg-text'}>
        No Data is currently available for the selected time range or current location.
      </h1>
    );

    if (!thing || !thing.location?.mapId) return noDataElement;

    // NOTE: lastLocationCoordinates.type is always MAP_TYPE.STATIC. the previous backend mustve returned type but current doesnt so its hardcoded to static...
    if (thing.lastLocationCoordinates.type === MAP_TYPE.GPS) {
      return (
        <GeoLocationMap2
          map={undefined}
          marker={this.state.thing.lastLocationCoordinates}
          height="800px"
        />
      );
    }
    if (thing.lastLocationCoordinates.type === MAP_TYPE.STATIC && fallbackMap) {
      return (
        <LocationMap
          map={fallbackMap}
          gateways={this.mapGateways(fallbackMap.gateways)}
          beacons={this.mapBeacons(fallbackMap.beacons)}
          zones={fallbackMap.zones}
          marker={thing.lastLocationCoordinates}
          height="800px"
        />
      );
    }

    return noDataElement;
  }

  /**
   * @desc determines if component is ready and has locations data to show
   */
  isReadyWithLocations = () => {
    return (
      this.state.ready && this.state.currentlySelected !== null && this.state.locations?.length > 0
    );
  };

  getCurrentMap = () => {
    return this.state.maps.find(
      (map) => map.id === this.state.locations[this.state.currentlySelected]?.mapId,
    );
  };

  getCurrentLocation = () => {
    return this.state.locations[this.state.currentlySelected];
  };

  renderLocationMap = () => {
    const map =
      this.isReadyWithLocations() &&
      this.getCurrentLocation().location.type === MAP_TYPE.STATIC &&
      this.getCurrentMap();

    if (!map) {
      return (
        <div>
          <h1>No map was found.</h1>
        </div>
      );
    }

    return (
      <div className="card">
        <LocationMap
          pace={this.state.pace}
          map={map}
          locations={this.state.locations}
          sortedLocations={this.state.sortedLocations}
          gateways={this.mapGateways(map.gateways)}
          zones={map.zones}
          beacons={this.mapBeacons(map.beacons)}
          marker={this.getCurrentLocation().location}
          height="800px"
          playback={this.state.playback}
          displayType={this.state.mapDisplayType}
          selectedIndex={this.state.currentlySelected}
          paused={this.state.paused}
        />
      </div>
    );
  };

  renderGeoLocationMap = () => {
    return (
      <div className="card">
        <GeoLocationMap
          pace={this.state.pace}
          locations={this.state.locations}
          marker={this.state.thing.lastLocationCoordinates}
          height="800px"
          playback={this.state.playback}
          displayType={this.state.mapDisplayType}
          selectedIndex={this.state.currentlySelected}
          paused={this.state.paused}
        />
      </div>
    );
  };

  renderMap() {
    if (!this.isReadyWithLocations()) return this.renderFallback();

    if (this.getCurrentLocation()?.location.type === MAP_TYPE.STATIC)
      return this.renderLocationMap();

    return this.renderGeoLocationMap();
  }

  renderControls = () => {
    return (
      <div className="d-flex app-toolbar align-items-center justify-content-between mb-2 mt-3">
        <div className="btn-group restart-sequence-btn">
          <Button
            type="button"
            variant="secondary"
            onClick={this.resetToDefault}
            title="Restart sequence"
          >
            <i className="fas fa-undo-alt"></i>
          </Button>
          <Button
            type="button"
            variant={this.state.playbackBackward ? 'primary' : 'secondary'}
            onClick={(e) => {
              e.preventDefault();
              this.frameChange('down');
            }}
            title="Step backward"
          >
            <i className="fas fa-step-backward" />
          </Button>
          {(this.state.playback === false || this.state.paused === true) && (
            <Button
              type="button"
              variant={this.state.playback && !this.state.paused ? 'primary' : 'secondary'}
              onClick={this.handlePlayback}
              title="Play sequence"
            >
              <i className="fa fa-play" />
            </Button>
          )}
          {this.state.paused === false && this.state.playback === true && (
            <Button
              type="button"
              variant={this.state.paused ? 'primary' : 'secondary'}
              onClick={(e) => {
                e.preventDefault();
                this.handlePause(this.state.currentlySelected);
              }}
              title="Pause senquence"
            >
              <i className="fa fa-pause" />
            </Button>
          )}
          <Button
            type="button"
            variant={this.state.playbackForward ? 'primary' : 'secondary'}
            onClick={(e) => {
              e.preventDefault();
              this.frameChange('up');
            }}
            title="Step forward"
          >
            <i className="fas fa-step-forward" />
          </Button>
          <Button
            type="button"
            variant={this.state.playbackForward ? 'primary' : 'secondary'}
            onClick={(e) => {
              e.preventDefault();
              this.showPlaybackControls();
            }}
            title="Playback speed"
            style={{ zIndex: 500 }}
            // unfortunately had to do inline to override bootstrap styles...
          >
            <i className="fas fa-tachometer-alt" />
            <PlaybackControls
              hidden={!this.state.showPlaybackControls}
              playbackSpeed={this.state.playbackSpeed}
              handlePlaybackSpeed={this.handlePlaybackSpeed}
              items={['0.25', '0.5', '0.75', 'Normal', '1.25', '1.5', '1.75', '2']}
            />
          </Button>
        </div>
        <div className="slider-container">
          <Slider
            min={0}
            max={this.state.locations.length ? this.state.locations.length - 1 : 0}
            value={this.state.currentlySelected ? this.state.currentlySelected : 0}
            orientation="horizontal"
            onChange={this.handleSliderChange}
            labels={this.renderSliderLabels()}
          />
        </div>
        <div className="btn-group" style={{ marginBottom: '16px' }}>
          <Button
            variant={this.state.mapDisplayType === 'points' ? 'primary' : 'secondary'}
            onClick={() => this.changeMapDisplayType('points')}
            title="Show Points"
          >
            <i className="fa fa-chart-scatter" />
          </Button>
          <Button
            variant={this.state.mapDisplayType === 'lines' ? 'primary' : 'secondary'}
            onClick={() => this.changeMapDisplayType('lines')}
            title="Show Path"
          >
            <i className="fa fa-chart-line-down" />
          </Button>
          <Button
            variant={this.state.visibility.gateways ? 'primary' : 'secondary'}
            title="Toggle Gateways"
            onClick={() => this.toggleVisibility('gateways')}
          >
            <i className="sonarIcon sonarIcon-gateway" />
          </Button>
          <Button
            variant={this.state.visibility.zones ? 'primary' : 'secondary'}
            onClick={() => this.toggleVisibility('zones')}
            title="Toggle Zones"
          >
            <i className="sonarIcon sonarIcon-place" />
          </Button>
          <Button
            variant={this.state.visibility.beacons ? 'primary' : 'secondary'}
            onClick={() => this.toggleVisibility('beacons')}
            title="Toggle Beacons"
          >
            <i className="fa fa-tags" />
          </Button>
          <Button
            variant={this.state.visibility.outliers ? 'primary' : 'secondary'}
            onClick={() => this.toggleVisibility('outliers')}
            title="Toggle Outliers"
          >
            <i className="fas fa-bullseye"></i>
          </Button>
        </div>
      </div>
    );
  };

  // TODO: SETUP A DEFAULT RENDER FOR THE EVENT THERE ARE 0 MAPS && 0 LOCATIONS
  render() {
    const locations = !this.state.locations
      ? [
          {
            timestamp: this.state.thing.lastLocationCheckin,
            distanceMoved: 0,
          },
        ]
      : this.state.locations;

    return (
      <div className="bread-crumbs-table-container">
        <div className="ps-5 pe-5">
          <div className="d-flex app-toolbar align-items-center justify-content-between mb-3 mt-3">
            <h4 className=" mb-0 cg-text">
              <i className="fa fa-map" /> &nbsp;
              {this.state.fallbackMap?.name ? this.state.fallbackMap.name : 'Earth'}
            </h4>

            <h4 className="ms-4  mb-0 cg-text">
              <i className="fa fa-tags" /> {this.state.thing.name ? this.state.thing.name : ''}
            </h4>
          </div>

          <div className="card">
            <BreadcrumbsTable
              allowFilter={true}
              locations={locations}
              totalItems={this.state.totalItems}
              pageCount={this.state.pageCount}
              sortField={this.state.query.sortField}
              sortOrder={this.state.query.sortOrder}
              limit={this.state.query.limit}
              page={String(this.state.query.page)}
              startDate={this.state.query.startDate}
              endDate={this.state.query.endDate}
              onQueryChanged={this.queryChange}
              onRowSelect={this.changeSelection}
              current={this.state.currentlySelected}
              units={this.state.units}
              isLoading={this.state.isLoading}
            />
          </div>

          {this.renderControls()}

          {this.renderMap()}
        </div>
      </div>
    );
  }
}
BreadcrumbsContainer.propTypes = {
  map: PropTypes.object,
  locations: PropTypes.array,
  lastLocation: PropTypes.object,
  match: PropTypes.object,
};
export default BreadcrumbsContainer;
