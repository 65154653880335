import { dockCloseAction, dockOpenAction, dockRenderAction } from '@redux';
import { useDispatch } from 'react-redux';

function useDock() {
  const dispatch = useDispatch();

  const openDock = ({ width, minWidth }) => dispatch(dockOpenAction({ width, minWidth }));
  const closeDock = ({ persistRenderOnClose = false } = {}) =>
    dispatch(dockCloseAction({ persistRenderOnClose }));
  const renderDock = ({ render, width = undefined, minWidth = undefined }) =>
    dispatch(dockRenderAction({ render, width, minWidth }));

  return {
    openDock,
    closeDock,
    renderDock,
  };
}

export default useDock;
