import PropTypes from 'prop-types';
import { Component } from 'react';
import { Form, InputGroup,Row } from 'react-bootstrap';

const styles = {
  btnEnabled: {
    cursor: 'pointer',
  },
  btnDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  setBoundary: {
    borderColor: 'transparent!important',
    fontSize: '14px',
  },
  boundarySet: {
    borderColor: 'transparent!important',
    fontSize: '14px',
    textDecoration: 'none',
  },
};
class ZoneEditRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.zone.name,
      translation: this.props.zone.translation,
      isInvalid: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  handleChange(event) {
    this.setState({
      name: event.target.value,
    });
  }

  onConfirm() {
    if (this.state.name !== '') {
      this.setState({
        isInvalid: false,
      });
      this.props.onEditCompleted('zones', {
        index: this.props.zone.index,
        id: this.props.zone.id,
        zone: this.props.zone,
        name: this.state.name,
        translation: this.state.translation,
        isVisible: true,
        boundary: this.props.zone.boundary,
        isValid: this.props.zone.isValid,
      });
    } else {
      this.setState({
        isInvalid: true,
      });
    }
  }

  onCancel() {
    if (!this.props.zone.zone) {
      this.props.onAddCanceled('zones', this.props.zone.index);
      this.props.zone.boundary = null;
    } else {
      this.props.onEditCompleted('zones', this.props.zone);
    }
  }

  render() {
    return (
      <div className="align-items-center w-100 ps-4 pe-4 mt-4 edit-maps-group">
        <div className="form-group">
          <label className="form-label">Place Name *</label>
          <input
            isInvalid={this.state.isInvalid}
            aria-label="Place Name"
            aria-describedby="Name"
            type="text"
            className="form-control"
            value={this.state.name ?? ''}
            onFocus={(e) => {
              this.setState({ name: '' });
            }}
            onChange={(e) => {
              this.handleChange(e);
            }}
          />
        </div>

        <div className="d-flex justify-content-center mt-4">
          <span
            className="btn"
            onClick={() => this.props.onEditMap(this.props.zone)}
            title="Draw Place Boundary"
          >
            <button
              className="btn btn-primary"
              type="button"
              style={this.props.zone.boundary ? styles.boundarySet : styles.setBoundary}
            >
              {this.props.zone.boundary ? 'Boundary Set' : 'Draw Place Boundary'}
            </button>
          </span>
        </div>

        <div className="d-flex justify-content-center mt-5 edit-map-submit-cancel-container">
          <button
            onClick={this.state.name && this.props.zone.boundary ? this.onConfirm : undefined}
            style={
              this.state.name && this.props.zone.boundary ? styles.btnEnabled : styles.btnDisabled
            }
            type="button"
            className="btn btn-primary pe-4 ps-4"
            title="Confirm"
          >
            Confirm
          </button>
          <button
            onClick={this.onCancel}
            type="button"
            className="btn btn-primary pe-4 ps-4 btn-danger"
            title="Cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

ZoneEditRow.propTypes = {
  zone: PropTypes.object,
  onSearchFilterChanged: PropTypes.func,
  searchItems: PropTypes.array,
  excludedSearchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
  onEditMap: PropTypes.func,
};

export default ZoneEditRow;
