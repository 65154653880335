import { TOGGLE_NAVIGATION, TOGGLE_APPLICATION } from '../types';
import {
  OPEN_NAV_WIDTH,
  CLOSED_NAV_WIDTH,
  OPEN_LOGO_HEIGHT,
  CLOSED_LOGO_HEIGHT,
} from '../../components/layout/layoutUtils';

export type NavigationReducerState = {
  isOpen: boolean;
  navWidth: string;
  logoHeight: string;
};

const initState = {
  isOpen: true,
  navWidth: OPEN_NAV_WIDTH,
  logoHeight: OPEN_LOGO_HEIGHT,
};

function handleToggleNavigation(state) {
  const isOpen = !state.isOpen;
  const navWidth = isOpen ? OPEN_NAV_WIDTH : CLOSED_NAV_WIDTH;
  const logoHeight = isOpen ? OPEN_LOGO_HEIGHT : CLOSED_LOGO_HEIGHT;

  return {
    ...state,
    isOpen,
    navWidth,
    logoHeight,
  };
}

function handleToggleApplication(state, action) {
  return {
    ...state,
    currentApplication: action.payload,
  };
}

const actionHandler = {
  [TOGGLE_NAVIGATION]: handleToggleNavigation,
  [TOGGLE_APPLICATION]: handleToggleApplication,
};

const reducer = (state = initState, action) => {
  const handlerFn = actionHandler[action.type];

  if (!handlerFn) return state;

  return handlerFn(state, action);
};

export default reducer;
