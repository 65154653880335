import { IconValueCellRenderer, LinkCellRenderer } from "@components";

export const placeListColumnDefs = [
  {
    headerName: 'Place',
    field: 'name',
    colId: 'name',
    cellRenderer: LinkCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `/places/${data.id}`,
      text: data.name,
    }),
  },
  {
    headerName: 'Map',
    field: 'mapName',
  },
  {
    headerName: 'Assets',
    field: 'assets',
    cellRenderer: IconValueCellRenderer,
    cellRendererParams: { iconName: 'asset' },
  },
  {
    headerName: 'Stationary Beacons',
    field: 'stationaryBeacons',
    cellRenderer: IconValueCellRenderer,
    cellRendererParams: { iconName: 'stationary-beacon' },
  },
];
