import { useDock } from '@react';
import { Api } from '@services';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DockContainer, Favorites, Icon } from '../../components';
import { useApp } from '../../react';

async function fetchRecents({ appId }) {
  if (!appId) return [];
  const { data } = await Api.get(`/users/recentlyviewed/${appId}`);
  return data;
}

function RecentlyViewed({ edit, index, remove }) {
  const app = useApp();
  const history = useHistory();
  const { renderDock, closeDock } = useDock();
  const recentsRes = useQuery<any>(['home-recents'], () => fetchRecents({ appId: app?.id }));
  let truncatedName;

  const onClickItem = (item) => {
    const route = `/${item.itemType.toLowerCase()}s/${item.itemId}`;
    history.push(route);
  };

  const renderFavorites = () => {
    renderDock({
      render: () => (
        <DockContainer>
          <Favorites defaultActiveKey={'3'} onDone={closeDock} />
        </DockContainer>
      ),
    });
  };

  if (!recentsRes.isLoading && recentsRes.isSuccess && recentsRes.data[0] !== undefined) {
    truncatedName = recentsRes.data[0].name;
    if (truncatedName.length > 36) {
      truncatedName = truncatedName.substring(0, 33) + '...'; // Truncate and add ellipsis
    }
  }

  return (
    <div>
      <Card.Header className="d-flex align-items-center justify-content-between">
        <Button
          className="fs-md fw-bold"
          variant="light"
          style={{
            pointerEvents: edit ? 'none' : 'auto',
            color: 'var(--nav-text)',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            renderFavorites();
          }}
        >
          <div
            className="icon-wrap"
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'var(--nav-text)',
              borderRadius: '60px',
              padding: '0.5em 0.5em',
              textAlign: 'right',
            }}
          >
            <i
              className={`sonarIcon sonarIcon-history`}
              style={{ color: 'var(--white)', cursor: 'pointer', fontWeight: 'normal' }}
            />
          </div>
          &nbsp; Recently Viewed
        </Button>
        {edit && (
          <Button
            className="delete-icon ignore-click"
            variant="light"
            onClick={() => {
              remove(index);
            }}
          >
            <i
              className="ignore-click sonarIcon sonarIcon-add sonarIcon-rotate-45 align-sub fs-lg"
              style={{ color: 'var(--danger)' }}
            />
          </Button>
        )}
      </Card.Header>
      <Card.Body
        className="d-flex flex-row justify-content-around align-items-center"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {!recentsRes.isLoading && recentsRes.isSuccess && recentsRes.data[0] !== undefined && (
          <div
            className="d-flex flex-row justify-content-around align-items-center"
            style={{ height: '50px' }}
          >
            {recentsRes.data[0].name.length > 36 ? (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{recentsRes.data[0].name}</Tooltip>}
              >
                <Button
                  className="fs-md fw-bold d-flex flex-row justify-content-center align-items-center"
                  variant="light"
                  style={{
                    color: 'var(--dark)',
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    pointerEvents: edit ? 'none' : 'auto',
                  }}
                  onClick={() => onClickItem(recentsRes.data[0])}
                >
                  <i
                    className={`sonarIcon sonarIcon-${recentsRes.data[0].itemType.toLowerCase()}`}
                    style={{
                      color: 'var(--dark)',
                      display: 'flex',
                      alignItems: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  />
                  &nbsp; {truncatedName}
                </Button>
              </OverlayTrigger>
            ) : (
              <Button
                className="fs-md fw-bold d-flex flex-row justify-content-center align-items-center"
                variant="light"
                style={{
                  color: 'var(--dark)',
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  pointerEvents: edit ? 'none' : 'auto',
                }}
                onClick={() => onClickItem(recentsRes.data[0])}
              >
                <i
                  className={`sonarIcon sonarIcon-${recentsRes.data[0].itemType.toLowerCase()}`}
                  style={{
                    color: 'var(--dark)',
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                />
                &nbsp; {truncatedName}
              </Button>
            )}
          </div>
        )}
        {!recentsRes.isLoading && recentsRes.data[0] === undefined && (
          <>
            <div
              className="fs-md fw-bold d-flex flex-row justify-content-center align-items-center"
              style={{ height: '50px' }}
            >
              There are no recently viewed Assets.
            </div>
          </>
        )}
      </Card.Body>
    </div>
  );
}

export default RecentlyViewed;
