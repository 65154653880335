import '../../scss/comps/Swal.scss';
import '../../scss/icons/icons.scss';
import './DeleteModal.scss';
import { AssetSearch, CreateAsset, Text } from '@components';
import { useApp } from '@react';
import { Api } from '@services';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import toast from '../../components/utils/toast';

const MySwal = withReactContent(Swal);

export const PAGES = {
  BEACON_REASSIGN_QUESTION: 1,
  CREATE_ASSET: 2,
  ASSET_SEARCH: 3,
  SUCCESS: 4,
};

let newAsset = null;

const DeleteModal = (props, { initPage = PAGES.BEACON_REASSIGN_QUESTION }) => {
  //useState Hooks
  const app = useApp();
  const { isOpen, toggle, asset, beacons } = props;
  const [pages, setPages] = useState([initPage]);
  // const [newAsset, setNewAsset] = useState(null);
  const [completed, setCompleted] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setPages([PAGES.BEACON_REASSIGN_QUESTION]);
    newAsset = null;
  }, [isOpen]);

  const innerToggle = () => {
    if (completed) {
      history.push('/assets');
    } else {
      toggle();
    }
  };

  const reassignAllBeacons = async () => {
    await Promise.all(
      beacons.map(async (beacon) => {
        await Api.post(`assets/${newAsset.id}/beacons/${beacon.id}`, {});
      }),
    );
  };

  async function pCreate() {
    let userResponse;
    try {
      newAsset.applicationId = app.id;
      newAsset.movementThreshold = 0;

      userResponse = await MySwal.fire({
        allowOutsideClick: false,
        title: 'Are you sure?',
        text: `You are deleting this asset and reassigning its beacons to ${newAsset?.name}?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
      })
        .then((submit) => {
          if (submit.isConfirmed) {
            return submit;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (userResponse) {
        Api.post('assets', newAsset)
          .then((results) => {
            newAsset.id = results.data;
            // setNewAsset(newAsset);
            reassignAllBeacons().then(() => {
              deleteAsset();
            });
          })
          .catch((error) => {
            console.log(error);
            toast.error(JSON.stringify(error));
          });
      }
    } catch (err) {
      userResponse = false;
      console.log(err);
    }
  }

  async function pExist() {
    let userResponse;
    try {
      userResponse = await MySwal.fire({
        allowOutsideClick: false,
        title: 'Are you sure?',
        text: `You are deleting this asset and reassigning its beacons to ${newAsset?.name}?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
      })
        .then((submit) => {
          if (submit.isConfirmed) {
            return submit;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (userResponse) {
        reassignAllBeacons().then(() => {
          deleteAsset();
        });
      }
    } catch (err) {
      userResponse = false;
      console.log(err);
    }
  }

  //Func will call swal alert modal to comfirm action.
  const deleteAndReassign = async (type) => {
    if (type === 'create') {
      pCreate();
    } else if (type === 'existing') {
      pExist();
    }
  };

  const deleteAsset = () => {
    Api.delete('/assets/' + asset.id)
      .then(() => {
        setCompleted(true);
        setPages([PAGES.SUCCESS]);
      })
      .catch(() => {
        toast.error(`${asset.name} was not deleted.`);
      });
  };

  const goBack = () => {
    setPages([PAGES.BEACON_REASSIGN_QUESTION]);
    newAsset = null;
  };

  let DeletePopUp = null;

  const unassignBeacons = async () => {
    // Create an array of promises to delete each beacon
    const deletePromises = beacons.map((beacon) =>
      Api.delete(`/assets/${asset.id}/beacons/${beacon.id}`),
    );

    try {
      // Wait for all beacon deletions to complete
      await Promise.all(deletePromises);

      // After all beacons are deleted, you can call deleteAsset
      deleteAsset();
    } catch (error) {
      // Handle any errors here
      console.error('Error deleting beacons:', error);
    }
  };

  switch (
    pages[pages.length - 1] // render off last page in array
  ) {
    case PAGES.BEACON_REASSIGN_QUESTION:
      DeletePopUp = (
        <div>
          <Row>
            <Col className="text-center pt-3">
              <div className="sa mb-3">
                <div className="sa-warning mx-auto">
                  <div className="sa-warning-body"></div>
                  <div className="sa-warning-dot"></div>
                </div>
              </div>
              <h6>
                <strong>
                  Asset {asset.name} has {beacons.length} assigned beacon
                  {beacons.length === 1 ? '' : 's'}. <br />
                  would you like to re-assign {beacons.length === 1 ? 'this' : 'these'} beacon
                  {beacons.length === 1 ? '' : 's'}?{' '}
                </strong>
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="default" className="my-3 w-100 back-btn" onClick={unassignBeacons}>
                No, Keep Unassigned
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="default"
                className="my-3 w-100 next-btn"
                onClick={() => {
                  setPages([PAGES.CREATE_ASSET]);
                }}
              >
                Yes, to New Asset
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="default"
                className="my-3 w-100 next-btn"
                onClick={() => {
                  setPages([PAGES.ASSET_SEARCH]);
                }}
              >
                Yes, to Existing Asset
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                variant="transparent"
                onClick={() => {
                  innerToggle();
                }}
              >
                <u>Cancel</u>
              </Button>
            </Col>
          </Row>
        </div>
      );
      break;
    case PAGES.CREATE_ASSET:
      DeletePopUp = (
        <CreateAsset
          includeBackBtn={true}
          goBack={goBack}
          isModified={() => {}}
          onClickNext={() => deleteAndReassign('create')}
          onCreateAsset={(asset) => {
            newAsset = asset;
            deleteAndReassign('create');
          }}
        />
      );
      break;
    case PAGES.ASSET_SEARCH:
      DeletePopUp = (
        <AssetSearch
          onSelectAsset={(asset) => {
            newAsset = asset;
          }}
          onClickNext={() => deleteAndReassign('existing')}
          goBack={goBack}
        />
      );
      break;
    case PAGES.SUCCESS:
      DeletePopUp = (
        <div className="text-center">
          <h5>Success</h5>
          {newAsset && (
            <div className="alert alert-success">
              Beacons have been assigned to <strong>{newAsset.name}</strong>
            </div>
          )}
          <Row>
            <Col>
              <Button variant="primary" className="w-50" onClick={innerToggle}>
                OK
              </Button>
            </Col>
          </Row>
        </div>
      );
      break;
    default:
      DeletePopUp = <Text>Uh Oh! Failed to find a page :(</Text>;
  }

  return (
    <Modal contentClassName="delete-modal" show={isOpen} onHide={innerToggle}>
      <Modal.Body>
        <div className="p-5 h-100">{DeletePopUp}</div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
