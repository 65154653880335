const initState = {
  isOpen: false,
  render: () => null,
  width: 0,
  minWidth: 0,
};

const DEFAULT_WIDTH = '50vw';
const DEFAULT_MIN_WIDTH = '600px';

export const DOCK_OPEN = 'DOCK_OPEN';
export const DOCK_CLOSE = 'DOCK_CLOSE';
export const DOCK_RENDER = 'DOCK_RENDER';

export function dockOpenAction({ width = DEFAULT_WIDTH, minWidth = DEFAULT_MIN_WIDTH }) {
  return { type: DOCK_OPEN, width, minWidth };
}

export function dockCloseAction({ persistRenderOnClose = false } = {}) {
  return { type: DOCK_CLOSE, persistRenderOnClose };
}

export function dockRenderAction({ render, width = DEFAULT_WIDTH, minWidth = DEFAULT_MIN_WIDTH }) {
  return { type: DOCK_RENDER, render, width, minWidth };
}

function handleDockOpen(state, { width, minWidth }) {
  return {
    ...state,
    isOpen: true,
    width,
    minWidth,
  };
}

function handleDockClose(state, { persistRenderOnClose }) {
  if (persistRenderOnClose) {
    return {
      ...state,
      isOpen: false,
      width: 0,
      minWidth: 0,
    };
  }

  return initState;
}

function handleDockRender(state, { render, width, minWidth }) {
  return {
    ...state,
    render,
    isOpen: true,
    width,
    minWidth,
  };
}

const actionHandler = {
  [DOCK_CLOSE]: handleDockClose,
  [DOCK_OPEN]: handleDockOpen,
  [DOCK_RENDER]: handleDockRender,
};

const reducer = (state = initState, action) => {
  const handlerFn = actionHandler[action.type];

  if (!handlerFn) return state;

  return handlerFn(state, action);
};

export default reducer;
