import { store } from '@redux';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenStatic } from 'axios';
import authService from './AuthService';

// es6 class wrapper for axios
class Axios {
  instance: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.instance = axios.create(config);
  }
}

interface IApi {
  CancelToken: CancelTokenStatic;
  get<R>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>>;
  post<D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<R>>;
  put<D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<R>>;
  delete<R>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>>;
  patch<D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<R>>;
}

/**
 * @desc Simple class wrapper over Axios.
 * Adds better typing then Axios provides.
 */
class BaseApi extends Axios implements IApi {
  constructor(config: AxiosRequestConfig) {
    super(config);

    this.instance.interceptors.request.use(mainRequestInterceptor);
    this.instance.interceptors.response.use(
      mainResponseInterceptorSuccess,
      mainResponseInterceptorError,
    );
  }

  CancelToken = axios.CancelToken;

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const res = await this.instance.get<T>(url, config);
    return res;
  }

  async post<D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    const res = await this.instance.post<R>(url, data, config);
    return res;
  }

  async put<D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    const res = await this.instance.put<R>(url, data, config);
    return res;
  }

  async delete<R>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    const res: AxiosResponse<R> = await this.instance.delete(url, config);
    return res;
  }

  async patch<D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    const res = await this.instance.patch<R>(url, data, config);
    return res;
  }
}

// #region TRANSFORMS
function transformResponse(data) {
  if (!data) return data; // handle NoContent

  let parsedData;
  try {
    parsedData = JSON.parse(data);
  } catch (error) {
    // failed to parse, could be string, pass as  is
    parsedData = data;
  }

  // transform api list response
  if (parsedData.value) {
    const listResponse = {
      count: parsedData['count'],
      next: parsedData['next'],
      items: parsedData['value'],
    };

    return listResponse;
  }

  return parsedData;
}
// #endregion TRANSFORMS

// #region INTERCEPTORS

function mainRequestInterceptor(config: AxiosRequestConfig) {
  const { app, auth } = store.getState();

  // set default url parameters for every req
  config.params = config.params || {};
  config.params.applicationId = app.app?.id;

  // set default headers for every req
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${auth.token}`;

  return config;
}

function mainResponseInterceptorSuccess(res: AxiosResponse) {
  return res;
}

function mainResponseInterceptorError(err: any) {
  if (axios.isCancel(err) && err.message === undefined) return err;
  // assume a 401 means token expired
  if (err.response?.status === 401) authService.logout();
  return Promise.reject(err);
}

// #endregion INTERCEPTORS

const Api = new BaseApi({
  baseURL: `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api`,
  transformResponse: [transformResponse],
});

export default Api;
export { Api, BaseApi };
