import * as L from 'leaflet';
import {} from 'leaflet.awesome-markers/dist/leaflet.awesome-markers';
import { noop, onlineStatusFunctions } from '../../../../utils';

// Create gateway icons for Add/Edit Gateways on Edit Map Page

const createGatewayMarker = (gateway, color) => {
  let isOnline = gateway.gateway?.isOnline;
  if (gateway.location) {
    const markerColor = isOnline == null ? 'cadetblue' : isOnline ? 'green' : 'red ';
    let marker = L.marker([gateway.location.y, gateway.location.x], {
      icon: L.AwesomeMarkers.icon({
        prefix: 'fa',
        icon: 'router',
        markerColor: markerColor,
      }),
      title: gateway.gateway?.name,
    });

    marker.gatewayIndex = gateway.index;
    gateway.gateway?.name && marker.bindPopup(gateway.gateway?.name);

    if (gateway.gateway?.name) {
      marker.on('click', (e) => {
        e.data = gateway;
        e.marker = marker;
        const name = gateway.gateway.name.replace("'", '&#8217;');
        e.marker
          .bindPopup(`<br/><div class='d-flex flex-row align-items-center'><strong class='col-10'>${gateway.gateway.name}</strong> 
          <a
            class='btn-light d-flex justify-content-left align-items-center' href='#'
            onclick="CBCopy('${name}')"
          > 
            <i class="col-1 far fa-lg fa-copy" ></i> 
          <a>
          </div>`);
        if (gateway.onmouseover) gateway.onmouseover(e);
        if (gateway.autoPopup) marker.openPopup();
      });
    }
    return marker;
  }
  return null;
};

const createGatewayLayers = (gateway) => {
  const layers = [];
  if (gateway.isVisible) {
    let layer = createGatewayMarker(
      gateway,
      onlineStatusFunctions.getHexColorByStatus(gateway.onlineStatus),
    );

    if (layer != null) {
      layer.data = gateway;
      layers.push(layer);
    }
  }

  return layers;
};

const createGatewaysLayerGroup = (gateways, { onSetLayers = noop } = {}) => {
  return L.featureGroup(createGatewaysLayerArray(gateways, { onSetLayers }));
};

const createGatewaysLayerArray = (gateways, { onSetLayers }) => {
  if (!gateways) {
    return [];
  }

  const layers = [];
  for (let gateway of gateways) {
    const gatewayLayers = createGatewayLayers(gateway);
    for (let layer of gatewayLayers) {
      layers.push(layer);
    }
  }

  onSetLayers(layers);
  return layers;
};

export { createGatewaysLayerGroup };
