import './StartEndDatePicker.scss';
import MomentUtils from '@date-io/moment';
import { createTheme } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#0048FF',
    },
  },
  overrides: {
    MuiDialogActions: {
      root: {
        boxShadow: '0 0 0 1px var(--c-primary)',
        '& button': {
          textTransform: 'unset',
        },
        '& button:last-child': {
          color: '#fff',
          backgroundColor: '#0048FF',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'rgb(206, 212, 218)',
          top: '-3px !important',
          bottom: '-3px !important',
        },
        '&:hover $notchedOutline': {
          borderColor: 'rgb(206, 212, 218)',
        },
        '&$focused $notchedOutline': {
          borderColor: 'var(--c-primary)',
        },
      },
      adornedEnd: {
        paddingRight: '5px',
      },
    },
    MuiFormControl: {
      root: {
        minWidth: 'auto',
      },
    },
    MuiInputBase: {
      root: {
        color: '(var--dark)',
      },
    },
    MuiFormLabel: {
      root: {
        color: '(var--dark)',
        top: '-10px!important',
        margin: '0!important',
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(14px, -2px) scale(0.75) !important',
        zIndex: '1!important',
        backgroundColor: 'var(--light) !important',
        padding: '3px !important',
      },
      outlined: {
        zIndex: '0',
      },
    },
    MuiIconButton: {
      root: {
        color: '(var--dark)',
        padding: '0',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: 'var(--c-primary)',
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: 'white!important',
      },
    },
    MuiFormHelperText: {
      root: {
        color: 'var(--danger)!important',
      },
    },
  },
});

const DateTimeWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: baseline;
  justify-items: center;
  margin-top: 3px;
`;

const SingleDateTimeWrapper = styled.div`
  width: 100%;
  justify-items: center;
  margin-top: 3px;
`;

const StartEndDatePicker = (props) => {
  const [hasDateRangeError, setHasDateRangeError] = useState(false);

  const clearDate = null;
  let startDiff;
  let endDiff;
  let dateDifference;
  const dayLimitError = 'Date Range: 1 Week or Less';

  if (props.startDate) startDiff = moment(props.startDate, 'YYYY-MM-DD hh:mm A');
  if (props.endDate) endDiff = moment(props.endDate, 'YYYY-MM-DD hh:mm A');
  if (props.startDate && props.endDate)
    dateDifference = Math.abs(moment.duration(startDiff.diff(endDiff)).asDays());

  // Check date range user selected to set error message
  useEffect(() => {
    // Prevent errors on breadcrumb dates that applies to analytic report dates
    if (props.isBreadCrumbDates) {
      return;
    } else {
      if (dateDifference === NaN || dateDifference === undefined) {
        setHasDateRangeError(false);
      } else if (dateDifference <= 7) {
        setHasDateRangeError(false);
      } else if (dateDifference >= 7) {
        setHasDateRangeError(true);
      }
    }
  }, [props]);

  return (
    <div className="flex-row align-items-center flex-wrap">
      <Form>
        <DateTimeWrapper>
          <ThemeProvider theme={defaultMaterialTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                inputVariant="outlined"
                label="Start Date"
                maxDate={props.endDate}
                maxDateMessage="Date should not be after today's date"
                value={
                  props.startDate === undefined || props.startDate === ''
                    ? clearDate
                    : props.startDate
                }
                format="MM/DD/YYYY hh:mm A"
                placeholder="12/12/2020 8:00 AM"
                onChange={(date) => {
                  if (date == null) date = undefined;
                  props.onChangeStartDate(date);
                }}
                disableFuture
                className="me-3"
                error={false}
                {...(hasDateRangeError ? { error: true } : {})}
                {...(hasDateRangeError ? { helperText: dayLimitError } : {})}
              />
              <KeyboardDateTimePicker
                variant="inline"
                label="End Date"
                minDate={props.startDate}
                minDateMessage="Date should not be before start date"
                maxDateMessage="Date should not be after today's date"
                inputVariant="outlined"
                value={
                  props.endDate == undefined || props.endDate == '' ? clearDate : props.endDate
                }
                format="MM/DD/YYYY hh:mm A"
                placeholder="12/12/2020 8:00 AM"
                onChange={(date) => {
                  if (date == null) date = undefined;
                  props.onChangeEndDate(date);
                }}
                disableFuture
                error={false}
                {...(hasDateRangeError ? { error: true } : {})}
                {...(hasDateRangeError ? { helperText: dayLimitError } : {})}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </DateTimeWrapper>
      </Form>
    </div>
  );
};

export default StartEndDatePicker;
