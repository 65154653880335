export function getQueryParams() {
  return new URLSearchParams(window.location.search);
}

export function getTableQueryParams() {
  const params = new URLSearchParams(window.location.search);

  return {
    search: params.get('search') || '',
    limit: Number(params.get('limit')) || 20,
    page: Number(params.get('page')) || 1,
  };
}

export function createTableQs({ search = '', limit = 20, page = 1 }) {
  const params = new URLSearchParams();

  params.append('search', search);
  params.append('limit', limit);
  params.append('page', page);

  return params.toString();
}
