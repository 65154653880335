/* Utilities for User Management */
import '../../scss/comps/Swal.scss';
import EventEmitter from 'eventemitter3';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from '../../components';
import { Api } from '../../services';

const MySwal = withReactContent(Swal);

export const emitter = new EventEmitter();

export const EVENTS = {
  INVITE_USERS_CLOSED: 'INVITE_USERS_CLOSED',
  REFETCH_USERS: 'REFETCH_USERS',
  EDIT_USER: 'EDIT_USER',
};

// User Management Roles
export const ROLES = ['ReadOnly', 'User', 'Admin'];

// Admin Portal User Roles
export const ADVANCEDROLES = ['ReadOnly', 'User', 'Admin', 'SuperAdmin'];

export function validateRoles(users) {
  const invalidRoles = users.filter((user) => !ROLES.includes(user.role));
  if (!invalidRoles.length) return true; // we are good

  // alert user to the invalid roles
  let invalidRolesAlert = '';
  invalidRoles.forEach((invitee, index) => {
    invalidRolesAlert += `${index + 1}. Please fix the user role for ${
      invitee.userName
    } to one of (ReadOnly | User | Admin). It is currently "${invitee.role}"\n\n`;
  });
  toast.warn(invalidRolesAlert);
  return false;
}

export async function inviteUsers({ users, appName, close }) {
  // error handling
  if (!validateRoles(users)) return;
  if (!users.length) return toast.error('Please add a user to invite.');

  const prefix = `Inviting Users to Application ${appName}:\n`;
  const confirmString =
    prefix +
    users.map((invitee, index) => `${index + 1}. ${invitee.userName} - ${invitee.role}`).join('\n');

  let userResponsePromise = new Promise((resolve, reject) => {
    MySwal.fire({
      allowOutsideClick: false,
      title: 'Confirm Invitation?',
      text: confirmString,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    })
      .then((submit) => {
        resolve(submit.isConfirmed);
      })
      .catch((err) => {
        reject(err);
      });
  });

  let userResponse;

  try {
    userResponse = await userResponsePromise;
  } catch (error) {
    userResponse = false;
    console.log(error);
  }

  if (userResponse) {
    try {
      await Api.post(`/users/invite`, users);
      toast.success('Successfully invited users!');
      emitter.emit(EVENTS.REFETCH_USERS);
      MySwal.close();
    } catch (err) {
      if (Boolean(Object.keys(err).length)) {
        toast.error('Failed to invite users.');
      }
    }
  }
}

/**
 * @desc Creates a new blank invitee for User to fill out
 * @returns {Object} Black invitee with email, role that defaults to READ_ONLY, hasFocused for UI logic
 */
export function getNewInvitee() {
  return { userName: '', role: ROLES[0], hasFocused: false };
}
