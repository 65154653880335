import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Text } from '../../components';
import { appService } from '../../services';

function AppSelector({ appVal, appName, setAppVal, setAppName }) {
  const { data: apps, isLoading } = useQuery(['appselector-applications'], () =>
    appService.getApps(2000),
  );

  if (isLoading) return <Text>Loading apps...</Text>;

  return (
    <Form.Group>
      <Form.Label>Select Application</Form.Label>
      <Form.Select
        defaultValue={appVal}
        onChange={(e) => {
          const appPicked = apps.find((obj) => {
            return obj.id === Number(e.target.value);
          });

          setAppVal(appPicked?.id === undefined ? 0 : appPicked?.id);
          setAppName(appPicked?.name === undefined ? 'Select Application' : appPicked?.name);
        }}
      >
        <option value={0}>Select Application</option>
        {apps.map((app) => {
          return (
            <option key={app.id} value={app.id}>
              {app.name}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
}

export default AppSelector;
