import { useState } from 'react';
import { Button, Form, Row, Col, Container, Spinner } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Keywords, Dropzone, toast } from '../../components';
import { Api } from '../../services';

async function createMap(data) {
  return Api.post('/maps', data);
}

async function uploadMapImage({ id, files }) {
  return Api.post(`/maps/${id}/images`, files);
}

function CreateMap({ app, history }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mapId, setMapId] = useState('');

  const createMapMutation = useMutation(createMap);
  const uploadImageMutation = useMutation(uploadMapImage);

  const callUploadImageMutation = async (id) => {
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    await uploadImageMutation.mutateAsync(
      { id, files: formData },
      {
        onSuccess: () => {
          toast.success('Successfully created map!');
          history.push('/maps');
        },
        onError: () =>
          toast.error(
            'Successfully created map, however the image upload failed. Please try to submit again.',
          ),
      },
    );
  };

  const onSubmit = async () => {
    setLoading(true);

    const mapData = {
      applicationId: app.id,
      name,
      description,
      keywords,
    };

    try {
      // create map was success but image upload failed
      if (mapId && uploadImageMutation.isError) {
        return callUploadImageMutation(mapId);
      }

      // create map and upload images
      await createMapMutation.mutateAsync(mapData, {
        onSuccess: async ({ data: id }) => {
          setMapId(id);
          await callUploadImageMutation(id);
          setLoading(false);
        },
        onError: () => toast.error('Failed to create map. Please try again.'),
      });
    } catch (error) {
      toast.error('Failed to create map. Please try again.');
      setLoading(false);
    }
  };

  const renderSubmitBtn = () => {
    if (loading) return <Spinner animation="border" variant="primary" />;

    return (
      <Button variant="primary" disabled={!name || !files.length} onClick={onSubmit}>
        Submit
      </Button>
    );
  };

  const onDrop = (files) => {
    setFiles(files);
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                placeholder="Enter map name"
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={description}
                placeholder="Enter map description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Form>

          <div>
            <Form.Label>Keywords</Form.Label>
            <Keywords isAddingKeyword onChange={(keywords) => setKeywords(keywords)} />
          </div>

          <div className="my-5">
            <Dropzone accept="image/*" multiple onDrop={onDrop} />
          </div>

          {renderSubmitBtn()}
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default connect(mapStateToProps)(withRouter(CreateMap));
