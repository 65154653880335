import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ABILITY_MAP, IGNORED_ABILITIES } from '../../../../constants';
import BeaconReadingsPager from '../../../BeaconReadingsPager/BeaconReadingsPager';
import Icon from '../../../Icon/Icon';
import Text from '../../../Text/Text';

const MySwal = withReactContent(Swal);

function BeaconReadingsCellRenderer({ value: beacons, data, history }) {
  if (!beacons?.length) return <Text>--</Text>;

  if (beacons.length === 1) {
    const beacon = beacons[0];

    const beaconReadings = beacon.readings.filter(
      (reading) => !IGNORED_ABILITIES.includes(reading.ability),
    );

    if (!beaconReadings.length) return <Text>--</Text>;

    return (
      <div className="d-flex flex-row">
        {beaconReadings.map((reading) => {
          let abilityFormatter = ABILITY_MAP[reading.ability] || ABILITY_MAP['Generic'];
          return (
            <div key={reading.abilityId} className="me-3">
              <Text>{abilityFormatter.value(reading)}</Text>
              <Icon
                name={reading.ability.toLowerCase()}
                variant="dark"
                size="1x"
                className="ms-1 align-sub"
                style={{ color: 'var(--primary)' }}
                disabled={false}
                showPointer={false}
                containerClassName="p-0"
              />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <Button
      variant="link"
      onClick={() =>
        MySwal.fire({
          html: <ShowReadings beacons={beacons} asset={data} history={history} />,
          showConfirmButton: false,
        })
      }
    >
      Show Multiple Readings
    </Button>
  );
}

function ShowReadings({ beacons, asset, history }) {
  return (
    <div>
      <BeaconReadingsPager
        beacons={beacons}
        assetId={asset.id.toString()}
        mapName={asset.location ? asset.location.mapName : '--'}
        placeName={asset.location && asset.location.places ? asset.location.places[0].name : '--'}
      />
      <Button
        className="mt-3"
        onClick={() => {
          history.push(`/assets/${asset.id}`);
          MySwal.close();
        }}
      >
        View Asset
      </Button>
    </div>
  );
}

export default withRouter(BeaconReadingsCellRenderer);
