import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { EditDeleteCellRenderer, toast, UserProfileCellRenderer } from '../../../components';
import { store } from '../../../redux';
import { CustomApi } from '../../../services';
import { formatPhoneNumber, getFormattedBool, getFormattedDateTime } from '../../../utils';
import { ADVANCEDROLES } from '../../userMgmt/utils';

const MySwal = withReactContent(Swal);

export const adminUsersColumnDefs = (appIdSelected) => [
  {
    headerName: 'Email',
    field: 'userName',
    suppressMovable: true,
  },
  {
    headerName: 'Email Confirmed',
    field: 'emailConfirmed',
    cellRenderer: (params) => {
      return params.value === true ? 'Yes' : 'No';
    },
  },
  {
    headerName: 'Phone',
    field: 'phoneNumber',
    valueFormatter: ({ value }) => formatPhoneNumber(value),
  },
  {
    headerName: 'Lock Out Expiration',
    field: 'lockoutEnd',
    valueFormatter: ({ value }) => getFormattedDateTime(value),
  },
  {
    headerName: 'Role',
    field: 'userRole',
    colId: 'role',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ADVANCEDROLES,
    },
    editable(params) {
      const { node } = params;
      const { editDeleteCR } = store.getState()?.grids || {};
      return node.data.userId === editDeleteCR.id;
    },
  },
  {
    headerName: 'Edit/Delete',
    colId: 'editDelete',
    suppressMovable: true,
    sortable: false,
    width: 100,
    cellRenderer: EditDeleteCellRenderer,
    cellRendererParams(params) {
      const { data, api, node } = params;
      const { userId: id } = data;
      return {
        id,
        onClickEdit() {
          let rowIndex = 0;
          api.forEachNodeAfterFilterAndSort((node, nodeIndex) => {
            if (node.data.userId === id) {
              rowIndex = nodeIndex;
            }
          });
          api.startEditingCell({ colKey: 'role', rowIndex: rowIndex });
        },
        onClickCancelEdit() {
          api.stopEditing(true);
        },
        async onClickDelete() {
          let userResponsePromise = new Promise((resolve, reject) => {
            MySwal.fire({
              allowOutsideClick: false,
              title: 'Are you sure?',
              text: 'This user will be deleted.',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'No',
              confirmButtonText: 'Yes',
            })
              .then((submit) => {
                resolve(submit.isConfirmed);
              })
              .catch((err) => {
                reject(err);
              });
          });

          let userResponse;

          try {
            userResponse = await userResponsePromise;
          } catch (error) {
            userResponse = false;
            console.log(error);
          }

          if (userResponse) {
            try {
              await CustomApi.delete(`api/users/${data.userId}?applicationId=${appIdSelected}`);
              api.gridBodyCtrl.gridOptionsService.gridOptions.rowData.splice(node.rowIndex, 1);
              api.setRowData(api.gridBodyCtrl.gridOptionsService.gridOptions.rowData);
              toast.success(`Successfully deleted user ${data.userName}!`);
            } catch (error) {
              toast.error(`Failed to delete user ${data.userName}.`);
            }
          }
        },
        async onClickConfirmEdit() {
          try {
            const model = {
              sonarUserId: data.userId,
              role: data.userRole,
            };
            await CustomApi.post(`api/users/assign-role?applicationId=${appIdSelected}`, model);
            toast.success(`Successfully updated user ${data.userName}!`);
          } catch (error) {
            toast.error(`Failed to update user ${data.userName}.`);
          }
        },
      };
    },
  },
];
