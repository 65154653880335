import { useState } from 'react';
import { useQuery } from 'react-query';
import { useApp } from '../../react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { Text } from '../../components';
import { Api, favoriteService } from '../../services';
import './Favorites.scss';

async function fetchFavorites({ appId }) {
  if (!appId) return [];
  const data = await favoriteService.getFavorites(appId);
  return data;
}

export function RecentlyViewedTab({ eventKey, items, onClickItem, color, refresh, reset }) {
  const app = useApp();
  const [reload, setReload] = useState<boolean>(false);
  const favoritesRes = useQuery<any>(['recents-favorites', reload, reset], () =>
    fetchFavorites({ appId: app?.id }),
  );

  const unfavoriteItem = async (type, id) => {
    switch (type) {
      case 'Asset':
        await favoriteService.unfavoriteAsset(id);
        break;
      case 'Map':
        await Api.delete(`/maps/${id}/favorites`);
        break;
      case 'Place':
        await favoriteService.unfavoritePlace(id);
        break;
    }
    setReload(!reload);
    refresh();
  };

  const favoriteItem = async (type, id) => {
    switch (type) {
      case 'Asset':
        await favoriteService.favoriteAsset(id);
        break;
      case 'Map':
        await Api.post(`/maps/${id}/favorites`);
        break;
      case 'Place':
        await favoriteService.favoritePlace(id);
        break;
    }
    setReload(!reload);
    refresh();
  };

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header
        style={{
          color: 'var(--dark)',
          userSelect: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
        className="fs-lg fw-bold btn-light"
      >
        <div
          className="icon-wrap"
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: color,
            borderRadius: '60px',
            padding: '0.5em 0.5em',
            textAlign: 'right',
          }}
        >
          <i
            className={`sonarIcon sonarIcon-history`}
            style={{ color: 'var(--white)', cursor: 'pointer', fontWeight: 'normal' }}
          />
        </div>
        &emsp; Recently Viewed
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          <Col>
            <Card.Body>
              {!favoritesRes.isLoading && favoritesRes.isSuccess && (
                <div className="d-flex flex-column">
                  {items.map((item, index) => {
                    let color;
                    switch (item.itemType) {
                      case 'Asset':
                        color = 'var(--c-primary)';
                        break;
                      case 'Map':
                        color = 'var(--alt1)';
                        break;
                      case 'Place':
                        color = 'var(--tertiary)';
                        break;
                    }
                    return (
                      <div
                        key={index}
                        className="favorite-tab d-flex flex-row justify-content-left align-items-center mb-1"
                      >
                        {favoritesRes.data.data?.filter((e) => e.itemId === item.itemId).length >
                        0 ? (
                          <i
                            className={`remove-favorite sonarIcon sonarIcon-star-fill fs-1x`}
                            style={{ color: color, cursor: 'pointer' }}
                            onClick={() => unfavoriteItem(item.itemType, item.itemId)}
                          />
                        ) : (
                          <i
                            className={`remove-favorite sonarIcon sonarIcon-star-empty fs-1x`}
                            style={{ color: color, cursor: 'pointer' }}
                            onClick={() => favoriteItem(item.itemType, item.itemId)}
                          />
                        )}
                        &emsp;
                        <Button
                          className="d-flex flex-row justify-content-left align-items-center"
                          variant="light"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          onClick={() => onClickItem(item)}
                        >
                          <i
                            className={`sonarIcon sonarIcon-${item.itemType.toLowerCase()}`}
                            style={{
                              color: color,
                              padding: '5px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          />
                          <Text
                            title={item.name}
                            style={{
                              color: 'var(--dark)',
                            }}
                          >
                            &nbsp; {item.name}
                          </Text>
                        </Button>
                      </div>
                    );
                  })}
                </div>
              )}
            </Card.Body>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}
