import { useSelector } from 'react-redux';

import { ReducerState, App } from '../../redux';

function useApp(): App | undefined {
  const app = useSelector((state: ReducerState) => state.app.app);
  return app;
}

export default useApp;
