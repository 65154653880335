import { useSelector, useDispatch } from 'react-redux';

import { NavigationReducerState, TOGGLE_NAVIGATION, ReducerState } from '@redux';

interface UseNavigationResponse extends NavigationReducerState {
  toggleNavigation: () => void;
}

function useNavigation(): UseNavigationResponse {
  const dispatch = useDispatch();
  const navigation = useSelector((state: ReducerState) => state.navigation);
  const toggleNavigation = () => {
    {
      dispatch({ type: TOGGLE_NAVIGATION });
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 200);
    }
  };

  return {
    ...navigation,
    toggleNavigation,
  };
}

export default useNavigation;
