export class PagedModel<T> {
  items: T[];
  count: number;
  next: string;

  constructor() {
    this.items = [];
    this.count = 0;
    this.next = '';
  }
}
