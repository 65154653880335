import { navigateToMaps } from './sonarTourUtils';

// Steps for Sonar New User Tour
// Steps are unique to each User Role

// Admin/Super Admin
export const AdminSteps = [
  {
    title: 'Dashboard Page ',
    content: (
      <ul>
        <li>View and export data on your favorite Assets,</li>
        <li>Quick access to Assets, Maps, Places & More,</li>
        <li>Find Online & Offline data for Beacons & Gateways</li>
      </ul>
    ),
    target: '#main-content',
    placement: 'center',
    disableBeacon: true,
    hideFooter: true,
  },
  {
    title: 'Navigation Menu',
    content: <p>Navigate through each tab to access data within your application.</p>,
    target: '.sidenav',
    placement: 'right-start',
    isFixed: true,
  },
  {
    title: 'Application Switcher',
    content: <p>View and switch to your assigned applications.</p>,
    target: '#application-switcher-btn',
    placement: 'auto',
  },
  {
    title: 'Actions',
    content: (
      <>
        Actions allow you to:
        <ul>
          <li>Assign Beacons to Assets,</li>
          <li>Create Assets, Maps, & Triggers,</li>
          <li>Bulk Upload/Delete Assets</li>
        </ul>
      </>
    ),
    target: '#actions-btn',
    placement: 'auto',
    spotlightClicks: true,
  },
  {
    title: 'Favorites',
    content: (
      <p>
        Quick access to your favorite: <br />
        Assets, Maps, & Places and most Recently Viewed.
      </p>
    ),
    target: '#favorites-btn',
    placement: 'auto',
  },
  {
    title: 'Settings',
    content: <p>Update User Preferences, Change Password, & Logout.</p>,
    target: '#settings-btn',
    placement: 'bottom-end',
  },
  {
    title: 'Edit Dashboard',
    content: <p>Customize your dashboard with relevant data.</p>,
    target: '#edit-dash-btn',
    placement: 'bottom-end',
  },
  // {
  //   title: 'Navigate to Maps',
  //   content: 'Click on the button to navigate to the Maps page.',
  //   target: '#navigate-to-maps-btn', // Replace with the actual ID or selector of the button
  //   placement: 'auto',
  //   disableBeacon: true,
  //   // Add a callback to navigate when the step is completed
  //   onComplete: () => {
  //     navigateToMaps(); // Call the function to navigate to the Maps page
  //   },
  // },
];

export const UserSteps = [
  {
    title: 'Dashboard Page ',
    content: (
      <ul>
        <li>View and export data on your favorite Assets,</li>
        <li>Quick access to Assets, Maps, Places & More,</li>
        <li>Find Online & Offline data for Beacons & Gateways</li>
      </ul>
    ),
    target: '#main-content',
    placement: 'center',
    disableBeacon: true,
    hideFooter: true,
  },
  {
    title: 'Navigation Menu',
    content: <p>Navigate through each tab to access data within your application.</p>,
    target: '.sidenav',
    placement: 'right-start',
    isFixed: true,
  },
  {
    title: 'Application Switcher',
    content: <p>View and switch to your assigned applications.</p>,
    target: '#application-switcher-btn',
    placement: 'auto',
  },
  {
    title: 'Actions',
    content: (
      <>
        Actions allow you to:
        <ul>
          <li>Assign Beacons to Assets,</li>
          <li>Create Assets, Maps, & Triggers,</li>
          <li>Bulk Upload/Delete Assets</li>
        </ul>
      </>
    ),
    target: '#actions-btn',
    placement: 'auto',
    spotlightClicks: true,
  },
  {
    title: 'Favorites',
    content: (
      <p>
        Quick access to your favorite: <br />
        Assets, Maps, & Places and most Recently Viewed.
      </p>
    ),
    target: '#favorites-btn',
    placement: 'auto',
  },
  {
    title: 'Settings',
    content: <p>Update User Preferences, Change Password, & Logout.</p>,
    target: '#settings-btn',
    placement: 'bottom-end',
  },
  {
    title: 'Edit Dashboard',
    content: <p>Customize your dashboard with relevant data.</p>,
    target: '#edit-dash-btn',
    placement: 'bottom-end',
  },
];

export const ReadOnlySteps = [
  {
    title: 'Dashboard Page ',
    content: (
      <ul>
        <li>View and export data on your favorite Assets,</li>
        <li>Quick access to Assets, Maps, Places & More,</li>
        <li>Find Online & Offline data for Beacons & Gateways</li>
      </ul>
    ),
    target: '#main-content',
    placement: 'center',
    disableBeacon: true,
    hideFooter: true,
  },
  {
    title: 'Navigation Menu',
    content: <p>Navigate through each tab to access data within your application.</p>,
    target: '.sidenav',
    placement: 'right-start',
    isFixed: true,
  },
  {
    title: 'Application Switcher',
    content: <p>View and switch to your assigned applications.</p>,
    target: '#application-switcher-btn',
    placement: 'auto',
  },
  {
    title: 'Favorites',
    content: (
      <p>
        Quick access to your favorite: <br />
        Assets, Maps, & Places and most Recently Viewed.
      </p>
    ),
    target: '#favorites-btn',
    placement: 'auto',
  },
  {
    title: 'Settings',
    content: <p>Update User Preferences, Change Password, & Logout.</p>,
    target: '#settings-btn',
    placement: 'bottom-end',
  },
  {
    title: 'Edit Dashboard',
    content: <p>Customize your dashboard with relevant data.</p>,
    target: '#edit-dash-btn',
    placement: 'bottom-end',
  },
];
