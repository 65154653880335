import { useCallback,useEffect } from 'react';
import { gridsResetEditDeleteCRAction,gridsSetEditDeleteCRAction, store } from '../../redux';

/**
 * @desc Hook to control EditDeleteCellRenderer in grid.
 * editDeleteCR = { id: number, isEditing: boolean }
 */
function useGridEditDelete() {
  const setEditDeleteCR = useCallback((editDeleteCR) => {
    store.dispatch(gridsSetEditDeleteCRAction(editDeleteCR));
  }, []);

  const resetEditDeleteCR = useCallback(() => {
    store.dispatch(gridsResetEditDeleteCRAction());
  }, []);

  // on unmount, reset state for other grids
  useEffect(() => {
    return () => resetEditDeleteCR();

    // eslint-disable-next-line
  }, []);

  return {
    setEditDeleteCR,
    resetEditDeleteCR,
  };
}

export default useGridEditDelete;
