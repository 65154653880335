import './AddBeaconToAsset.scss';
import '../../../../scss/comps/Swal.scss';
import { CreateAsset, Icon, Text } from '@components';
import { withDock } from '@react';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AssetSearch from './AssetSearch/AssetSearch';
import BeaconConfig from './BeaconConfig/BeaconConfig';
import BeaconReassignQuestion from './BeaconReassignQuestion';
import BeaconSearch, { ASSET_TYPE } from './BeaconSearch/BeaconSearch';
import BeaconSuccess from './BeaconSuccess/BeaconSuccess';

const MySwal = withReactContent(Swal);

export const PAGES = {
  BEACON_SEARCH: 1,
  CREATE_ASSET: 2,
  ASSET_SEARCH: 3,
  BEACON_CONFIG: 4,
  BEACON_SUCCESS: 5,
  BEACON_REASSIGN_QUESTION: 6,
};

export const PAGES_ASSETS_VERSION = {
  BEACON_SEARCH: 1,
  BEACON_CONFIG: 2,
  BEACON_SUCCESS: 3,
  BEACON_REASSIGN_QUESTION: 4,
};

function useOutsideAlerter(ref, isModified, callBack) {
  useEffect(() => {
    function handleClickOutside(event) {
      const selectedNav = event.target.closest('a');
      if (ref.current && !ref.current.contains(event.target) && !isModified) {
        callBack();
      } else if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.className.includes('swal2')
      ) {
        MySwal.fire({
          allowOutsideClick: false,
          title: 'Are you sure you want to leave?',
          text: 'Your changes will not be saved.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((submit) => {
          if (submit.isConfirmed) {
            callBack();
            if (selectedNav) {
              window.location = selectedNav.attributes.href.value;
            }
          } else {
            MySwal.close();
          }
        });
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModified]);
}

function AddBeaconToAsset({
  closeDock,
  renderDock,
  history,
  initPage = PAGES.BEACON_SEARCH,
  initAssetType = '',
  initAsset = null,
  onAssetDetailsPage,
  selectedBeacon = null,
  onSuccess,
  existingBeacons,
}) {
  const wrapperRef = useRef(null);
  const [pages, setPages] = useState([initPage]);
  const [beacon, setBeacon] = useState(selectedBeacon);
  const [asset, setAsset] = useState(initAsset);
  const [assetConfig, setAssetConfig] = useState();
  const [initPathname] = useState(history.location.pathname);
  const [isModified, setIsModified] = useState(false);

  let onSuccessPage;
  let Comp = null;

  useOutsideAlerter(wrapperRef, isModified, closeDock);

  useEffect(() => {
    if (beacon !== null || asset !== null || assetConfig !== null) {
      setIsModified(true);
    }
  }, [beacon, asset, assetConfig]);

  const resetData = () => {
    setBeacon(null);
    setAsset(null);
    setAssetConfig(null);
    setIsModified(false);
  };

  const onCloseDock = () => {
    renderDock({ render: () => null });
    closeDock();
    setPages([PAGES.BEACON_SEARCH]);
    resetData();
  };

  const goBack = () => {
    const copy = [...pages];
    copy.splice(pages.length - 1); // remove last page
    setPages(copy);
  };

  // listen for route changes and close dock automatically
  useEffect(() => {
    history.listen((e) => {
      if (e.pathname !== initPathname) onCloseDock();
    });
  }, []);

  //  Switch case to determine page/process of adding beacons
  if (!initAsset) {
    // When user adds beacon by using top right menu
    switch (
      pages[pages.length - 1] // Grab last page in array & determine page to show from PAGES
    ) {
      case PAGES.BEACON_SEARCH:
        Comp = (
          <BeaconSearch
            handleNewAsset={() => setPages(pages.concat(PAGES.CREATE_ASSET))}
            handleExistingAsset={() => setPages(pages.concat(PAGES.ASSET_SEARCH))}
            onSelectBeacon={(theBeacon) => setBeacon(theBeacon)}
            selectedBeacon={beacon ? beacon : null}
            initAssetType={initAssetType}
            onAssetDetailsPage={onAssetDetailsPage}
          />
        );
        break;
      case PAGES.CREATE_ASSET:
        Comp = (
          <CreateAsset
            onClickNext={() => setPages(pages.concat(PAGES.BEACON_CONFIG))}
            onCreateAsset={(theAsset) => setAsset({ ...theAsset, isCreated: true })}
            isModified={() => setIsModified(true)}
            includeBackBtn={true}
            goBack={goBack}
          />
        );
        break;
      case PAGES.ASSET_SEARCH:
        Comp = (
          <AssetSearch
            onSelectAsset={(theAsset) => setAsset(theAsset)}
            onClickNext={() => setPages(pages.concat(PAGES.BEACON_CONFIG))}
            initAsset={!initAsset ? asset : initAsset}
            goBack={goBack}
          />
        );
        break;
      case PAGES.BEACON_CONFIG:
        Comp = (
          <BeaconConfig
            beacon={beacon}
            asset={asset}
            onSuccess={() => setPages(pages.concat(PAGES.BEACON_SUCCESS))}
            onCreateConfig={(config) => setAssetConfig(config)}
            goBack={goBack}
            onReasignQuestion={() => setPages(pages.concat(PAGES.BEACON_REASSIGN_QUESTION))}
          />
        );
        break;
      case PAGES.BEACON_SUCCESS:
        onSuccessPage = true;
        Comp = (
          <BeaconSuccess
            asset={asset}
            assetConfig={assetConfig}
            beacon={beacon}
            onClickDone={() => {
              onCloseDock();
              if (onSuccess) onSuccess();
            }}
          />
        );
        break;
      case PAGES.BEACON_REASSIGN_QUESTION:
        Comp = (
          <BeaconReassignQuestion
            beacon={beacon}
            asset={asset}
            goBack={goBack}
            onNoTriggers={() => setPages(pages.concat(PAGES.BEACON_SUCCESS))}
          />
        );
        break;
      default:
        Comp = <Text>Uh Oh! Failed to find a page :(</Text>;
    }
  } else if (initAsset) {
    // When user adds beacon by using button within Asset Details
    switch (
      pages[pages.length - 1] // Grab last page in array & determine page to show from PAGES_ASSET_VERSION
    ) {
      case PAGES_ASSETS_VERSION.BEACON_SEARCH:
        Comp = (
          <BeaconSearch
            handleExistingAsset={() => setPages(pages.concat(PAGES_ASSETS_VERSION.BEACON_CONFIG))}
            onSelectBeacon={(theBeacon) => setBeacon(theBeacon)}
            selectedBeacon={beacon ? beacon : null}
            initAssetType={initAssetType}
            onAssetDetailsPage={onAssetDetailsPage}
          />
        );
        break;
      case PAGES_ASSETS_VERSION.BEACON_CONFIG:
        Comp = (
          <BeaconConfig
            beacon={beacon}
            existingBeacons={existingBeacons}
            asset={asset}
            onSuccess={() => setPages(pages.concat(PAGES_ASSETS_VERSION.BEACON_SUCCESS))}
            onCreateConfig={(config) => setAssetConfig(config)}
            goBack={goBack}
            onReasignQuestion={() =>
              setPages(pages.concat(PAGES_ASSETS_VERSION.BEACON_REASSIGN_QUESTION))
            }
          />
        );
        break;
      case PAGES_ASSETS_VERSION.BEACON_SUCCESS:
        onSuccessPage = true;
        Comp = (
          <BeaconSuccess
            asset={asset}
            assetConfig={assetConfig}
            beacon={beacon}
            existingBeacons={existingBeacons}
            onClickDone={() => {
              onCloseDock();
              if (onSuccess) onSuccess();
            }}
          />
        );
        break;
      case PAGES_ASSETS_VERSION.BEACON_REASSIGN_QUESTION:
        Comp = (
          <BeaconReassignQuestion
            beacon={beacon}
            asset={asset}
            goBack={goBack}
            onNoTriggers={() => setPages(pages.concat(PAGES_ASSETS_VERSION.BEACON_SUCCESS))}
          />
        );
        break;
      default:
        Comp = <Text>Uh Oh! Failed to find a page.</Text>;
    }
  }

  // Add Beacons Title
  const renderTitle = () => {
    const beaconName = beacon?.name ? <Text variant="primary">{beacon.name}</Text> : '';
    const assetName = asset?.name ? <Text variant="primary">{asset.name}</Text> : '';
    return (
      <h4 className="text-center">
        Add Beacon {beaconName ? beaconName : ''} to Asset {assetName ? assetName : ''}
      </h4>
    );
  };

  return (
    <div ref={wrapperRef} className="d-flex flex-column p-3 h-100">
      {/* X Close Icon */}
      <div className="d-flex flex-row justify-content-end">
        <Icon
          name="add"
          variant="light"
          size="lg"
          disabled={false}
          onClick={closeDock}
          className="sonarIcon-rotate-45 align-sub"
          style={{ color: 'var(--dark)' }}
        />
      </div>

      {/* Show/Hide Add Beacons Title */}
      <div className="add-beacon-asset-header d-flex flex-row justify-content-center position-relative">
        {onSuccessPage ? '' : renderTitle()}
      </div>

      {/* Add Beacons Content */}
      <div className="p-5 h-100">{Comp}</div>
    </div>
  );
}

AddBeaconToAsset.propTypes = {
  closeDock: PropTypes.func.isRequired,

  // defaults
  onSuccess: PropTypes.func,
  initPage: PropTypes.number,
  initAssetType: PropTypes.oneOf([ASSET_TYPE.NEW, ASSET_TYPE.EXISTING]),
  initAsset: PropTypes.object,
};

export default withDock(withRouter(AddBeaconToAsset));
export { ASSET_TYPE };
