import PropTypes from 'prop-types';
import { Component } from 'react';
import { Button } from 'react-bootstrap';
import ZoneEditRow from './zoneEditRow';

class ZoneList extends Component {
  render() {
    return (
      <div>
        <div className="btn-group btn-group-sm w-100">
          <button
            className="btn btn-primary btn-sm ms-4 me-4 mt-2 mb-2"
            type="button"
            onClick={() => {
              this.props.onAdd(), this.setState({ isNewZone: true });
            }}
            disabled={this.props.editing}
          >
            Add Place
          </button>
        </div>

        <ul className="list-group list-group-flush edit-map-device-list">
          {/* Adding Places Process */}
          {this.props.zones.map((z, i) => {
            return (
              <>
                {this.props.editing &&
                  z.index === this.props.editing.index &&
                  !z.zone &&
                  this.state?.isNewZone && (
                    <ZoneEditRow
                      zone={z}
                      excludedSearchItems={[]}
                      onSearchFilterChanged={this.props.onSearchFilterChanged}
                      searchItems={this.props.searchItems}
                      onEditCompleted={this.props.onEditCompleted}
                      onAddCanceled={this.props.onAddCanceled}
                      onEditMap={this.props.onEditMap}
                    />
                  )}
                {this.props.editing &&
                  z.index === this.props.editing.index &&
                  z.zone &&
                  !this.state?.isNewZone && (
                    <ZoneEditRow
                      zone={z}
                      excludedSearchItems={[]}
                      onSearchFilterChanged={this.props.onSearchFilterChanged}
                      searchItems={this.props.searchItems}
                      onEditCompleted={this.props.onEditCompleted}
                      onAddCanceled={this.props.onAddCanceled}
                      onEditMap={this.props.onEditMap}
                    />
                  )}
              </>
            );
          })}

          {/* List of Places / Hides when Adding New Places or Editing Places */}
          {!this.props.editing && (
            <>
              {Boolean(this.props.zones.length) && (
                <>
                  <li className="d-flex list-group-item p-1 justify-content-between align-items-center ps-4 pe-4">
                    <div className="w-50">Name</div>
                    <div className="w-25"></div>
                  </li>
                </>
              )}

              {this.props.zones.map((z, i) => {
                return (
                  <>
                    {z.boundary && (
                      <li
                        className="list-group-item list-group-item-action ps-4 pe-4 pb-1 pt-1"
                        key={i}
                      >
                        <div className="w-100 d-flex align-items-center justify-content-between p-0">
                          <span
                            className="w-50"
                            onClick={() => this.props.onSelect('zones', 'index', z.index)}
                          >
                            {z.isVisible ? <strong>{z.name}</strong> : <span>{z.name}</span>}
                          </span>
                          <div className="w-25">
                            <div className="btn-group float-right edit-places-btns">
                              <span
                                className="btn flat-btn btn-default p-2 edit-properties-btn"
                                onClick={() => {
                                  this.props.onEdit(z), this.setState({ isNewZone: false });
                                }}
                                title="Edit Properties"
                              >
                                <i className="fa fa-edit" />
                              </span>

                              <span
                                className="btn flat-btn btn-default p-2"
                                onClick={() => this.props.onEditMap(z)}
                                title="Change Zone Boundaries"
                              >
                                <i className="fa fa-map-pin" />
                              </span>
                              <span
                                onClick={() => this.props.onRemove('zones', i)}
                                className="btn flat-btn btn-default p-2"
                                title="Remove from Map"
                              >
                                <i className="fas fa-times text-danger" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </>
                );
              })}
            </>
          )}
        </ul>
      </div>
    );
  }
}

ZoneList.propTypes = {
  editing: PropTypes.object,
  zones: PropTypes.array,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onEditMap: PropTypes.func,
  onSearchFilterChanged: PropTypes.func,
  searchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
};

export default ZoneList;
