import { Link } from 'react-router-dom';
import { sortArrayObjsByKey } from '../../../../utils';
import Text from '../../../Text/Text';

function PlacesCellRenderer({ data }) {
  const map = data.location?.mapName;
  const mapId = data.location?.mapId;
  const places = data.location?.places;
  let sortedPlaces;

  // If no map or places logic to display '--'

  if (map) {
    if (places) {
      sortedPlaces = sortArrayObjsByKey(places, 'area');
    }
    return (
      <>
        <span key={mapId}>
          <Link to={`/maps/${mapId}`} className="btn-link font-weight-light">
            <Text>{`${map}`}</Text>
          </Link>
        </span>
        {places && <Text>, </Text>}

        {places &&
          sortedPlaces.map((place, index) => {
            const postfix = index === sortedPlaces.length - 1 ? '' : ', ';
            const placeId = place.placeId || place.id;
            return (
              <span key={placeId}>
                <Link to={`/places/${placeId}`} className="btn-link font-weight-light">
                  <Text>{`${place.name}`}</Text>
                </Link>
                <Text>{postfix}</Text>
              </span>
            );
          })}
      </>
    );
  } else {
    return <Text>&nbsp;&nbsp;--</Text>;
  }
}

export default PlacesCellRenderer;
