import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import { Api } from '../../services';
import UploadScreen from './UploadScreen';

async function postBulkBeaconUpload(data) {
  return Api.post('/beacons/upload', data);
}

const GENERIC_BEACON_ID = 10;

export function BeaconUpload({ app, gridRefresh }) {
  const csvFile = {
    headers: [
      { label: 'Name', key: 'name' },
      { label: 'Device ID', key: 'deviceId' },
      { label: 'Beacon Type ID', key: 'typeId' },
    ],
    exampleData: [{ name: 'Beacon 1', deviceId: 'AA:AA:AA:AA:AA:AA', typeId: 1 }],
    filename: 'BeaconsUpload.csv',
  };

  const csvFileSuccess = {
    headers: [
      { label: 'Name', key: 'name' },
      { label: 'Device ID', key: 'deviceId' },
    ],
    filename: 'BeaconsUploadSuccess.csv',
  };

  const csvFileError = {
    headers: [
      { label: 'Name', key: 'name' },
      { label: 'Device ID', key: 'deviceId' },
      { label: 'Error Message', key: 'errorMessages' },
    ],
    filename: 'GatewaysUploadError.csv',
  };

  const beaconUploadMutation = useMutation(postBulkBeaconUpload);

  const itemMapper = useCallback(
    (row) => {
      const item = {
        name: row[0],
        deviceId: row[1],
        beaconTypeId: Number(row[2]) || GENERIC_BEACON_ID,
        applicationId: app.id,
        uuid: '',
        power: 100,
      };

      return item;
    },
    [app],
  );

  return (
    <UploadScreen
      csvFile={csvFile}
      csvFileSuccess={csvFileSuccess}
      csvFileError={csvFileError}
      mutation={beaconUploadMutation.mutateAsync}
      itemName="Beacons"
      itemMapper={itemMapper}
      refresh={gridRefresh}
    />
  );
}

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default connect(mapStateToProps)(BeaconUpload);
