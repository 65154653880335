import jwtDecode from 'jwt-decode';
import { store } from '../redux';
import { authService } from '../services';

export function getDecodedToken() {
  const { token } = store.getState()?.auth || {};

  if (!token) {
    if (!window.location.href.includes('/login')) {
      sessionStorage.setItem(
        'redirectAfterLogin',
        window.location.href.replace(window.location.origin, ''),
      );
    }
    authService.logout();
    return {};
  }

  const decoded = jwtDecode(token);
  return decoded;
}

export function isReadOnly() {
  return getDecodedToken().role === 'ReadOnly';
}

// Admin/SuperAdmin have same permissions
export function isAdmin() {
  return getDecodedToken().role === 'Admin' || getDecodedToken().role === 'SuperAdmin';
}

export function isSuperAdmin() {
  return getDecodedToken().role === 'SuperAdmin';
}

export function isSystemAdmin() {
  return getDecodedToken().role === 'SystemAdmin';
}

export function isUser() {
  return getDecodedToken().role === 'User';
}

export function isSSO() {
  return Boolean(getDecodedToken().sso);
}
