import './Pager.scss';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Icon from '../Icon/Icon';

function Pager({ pageCount, page, onChange }) {
  const handleChange = useCallback(
    (e) => {
      const newPage = parseInt(e.target.value, 10);

      if (isNaN(newPage) || newPage < 1 || newPage > pageCount) return;

      if (page !== newPage) {
        onChange(newPage);
      }
    },
    [onChange, page, pageCount],
  );

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*$/.test(inputValue)) {
      const parsedValue = parseInt(inputValue, 10);
      const clampedValue = Math.min(Math.max(parsedValue, 1), pageCount);

      if (!isNaN(clampedValue)) {
        onChange(clampedValue);
      } else {
        onChange(''); // Set an empty string if the input is not a number
      }
    } else {
      onChange(''); // Set an empty string for non-numeric input
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e) => {
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData; // For cross-browser compatibility
    const pastedData = clipboardData.getData('text');
    const numericValue = pastedData.replace(/[^\d]/g, ''); // Remove non-numeric characters

    const inputValue = parseInt(numericValue, 10);
    const clampedValue = Math.min(Math.max(inputValue, 1), pageCount);

    if (!isNaN(clampedValue)) {
      onChange(clampedValue);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        variant="light"
        disabled={page === 1}
        onClick={() => handleChange({ target: { value: page - 1 } })}
      >
        <i
          className={`sonarIcon sonarIcon-left-arrow fs-2x align-sub me-1`}
          style={{
            color: page === 1 ? 'var(--nav-text)' : 'var(--primary)',
            cursor: page === 1 ? 'auto' : 'pointer',
            fontWeight: 'normal',
          }}
        />
      </Button>

      <InputGroup size="sm">
        <Form.Control
          size="sm"
          value={page}
          type="text"
          min={1}
          max={pageCount}
          style={{ borderRadius: '3px', width: '60px' }}
          onChange={handleInputChange}
          onPaste={preventPasteNegative}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      </InputGroup>

      <Button
        variant="light"
        disabled={page === pageCount}
        onClick={() => handleChange({ target: { value: page + 1 } })}
      >
        <i
          className={`sonarIcon sonarIcon-right-arrow fs-2x align-sub ms-1`}
          style={{
            color: page === pageCount ? 'var(--nav-text)' : 'var(--primary)',
            cursor: page === pageCount ? 'auto' : 'pointer',
            fontWeight: 'normal',
          }}
        />
      </Button>

      <span style={{ whiteSpace: 'nowrap' }}>of {pageCount}</span>
    </div>
  );
}

Pager.propTypes = {
  pageCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pager;
