import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { isReadOnly } from '../../utils';
import Text from '../Text/Text';

function PrivateRoute({
  token,
  component: Component,
  checkReadOnly = false,
  exact = false,
  disabled = false,
  ...props
}) {
  if (!token) return <Redirect to="/login" />;

  if (disabled)
    return <Route {...props} render={() => <Text as="h1">This screen is disabled.</Text>} />;

  if (checkReadOnly && isReadOnly())
    return <Route {...props} render={() => <Text as="h1">Your account is read only!</Text>} />;

  return <Route {...props} exact={exact} render={(props) => <Component {...props} />} />;
}

const mapStateToProps = ({ auth }) => ({
  token: auth?.token,
});

export default connect(mapStateToProps)(PrivateRoute);
