import { DateFilter, Icon, Loading, OnlineStatusCellRenderer, Pager, Text } from '@components';
import { Popover } from '@material-ui/core';
import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import { debounce } from 'lodash';
import moment from 'moment';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { ContactList } from '../../../../src/screens/reports/components/ContactList';
import { FilterContainer } from '../../../containers/FilterContainer';
import { PagedModel } from '../../../models/PagedModel';
import ReportTypeInput from '../../../screens/Analytics/tabDefs/analyticsReports/ReportTypeInput';
import { isAdmin, isSystemAdmin } from '../../../utils';
import { V2FilterPayload } from '../../AssetFilter/functions/V2FilterPayload';
import { SearchInput } from '../../inputs';

const RowCounter = styled.input`
  min-width: 4rem;
  width: ${(props) => props.value.toString().length + 0.5 + 'rem'} !important;
  text-align: center;
`;

export interface IPagedTableProps<T> {
  id: string;
  initQueryParams: PagedQueryParams;
  assetFilters?: string[];
  columnDefs: (ColDef | ColGroupDef)[];
  gridProps?: any;
  isLoading: Boolean;
  ignoreFetch?: Boolean;
  disabledFilters: string[];
  rowData?: PagedModel<T>;
  csvData?: any;
  reload?: Boolean;
  handleFetchData?: (filter?: any, isCSV?: boolean, queryState?: any, pageType?: string) => void;
  triggerUpdate?: Boolean;
  onCellClick?(e: T, field: string): void;
  enumTranslation?: any;
  csvHeaders?: string[];
  searchControl?: Boolean;
  dateControl?: Boolean;
  filterControl?: Boolean;
  viewingControl?: Boolean;
  pagerControl?: Boolean;
  exportControl?: Boolean;
  tabKey?: string[];
  filterReportType?: string[];
  reportType?: string;
  reportLabel?: string;
  ChangeReportType?: () => void;
  anchorEl?: string;
  setAnchorEl?: string;
  deleteRow?: any;
}

export class PagedQueryParams {
  constructor(id?: string) {
    let searchHistory = JSON.parse(sessionStorage.getItem('searchHistory'));
    let searchNotEmpty = Boolean(searchHistory) && Object.keys(searchHistory).length !== 0;

    this.filter = searchNotEmpty && Boolean(searchHistory[id]) ? searchHistory[id] : '';
    this.sort = { colId: 'name', sort: 'asc' };
    this.pageNumber = 1;
    this.pageSize = 20;
    this.dateFilters = {
      start: undefined,
      end: undefined,
    };
  }

  public filter?: string;
  public sort?: { colId: string; sort: string };
  public pageNumber?: number;
  public pageSize?: number;
  public dateFilters?: { start: any; end: any };

  orderBy = function (): string {
    return this.sort.colId.trim() + ' ' + this.sort.sort.trim();
  };

  skip = function (): number {
    if (this.pageNumber === 0) {
      return 0;
    } else return this.pageSize * (this.pageNumber - 1);
  };

  setSort = function (): any[] {
    let sorts = [
      {
        field: this.sort.colId,
        ascending: this.sort.sort === 'asc' ? true : false,
      },
    ];
    return sorts;
  };

  setInitialSort(field: string, direction: string): PagedQueryParams {
    this.sort = { colId: field, sort: direction };
    return this;
  }
}

export const SSGContext = createContext({});
export const PageTypeContext = createContext(null);

export function ServerSideGrid<T>(props: IPagedTableProps<T>) {
  //useState Hooks
  const [andOr, setAndOr] = useState<string>('or');
  const [queryState, setQueryState] = useState<PagedQueryParams>(props.initQueryParams);
  const [assetFilters, setAssetFilters] = useState<string[]>(
    props.disabledFilters
      ? (props.assetFilters || []).filter(
          (item) => !props.disabledFilters.some((value) => item && item.includes(value)),
        )
      : props.assetFilters || [],
  );

  const [csvReady, setCsvReady] = useState(false);
  const [submitShowContent, setSubmitShowContent] = useState(false);
  const [trendReport, setTrendReport] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [disableBtn, setDisableBtn] = useState(true);
  const [dateKey, setDateKey] = useState(0);
  const [filterKey, setFilterKey] = useState(0);

  //useRefs Hooks
  const agGridRef = useRef<AgGridReactType>();
  const csvLinkRef = useRef(null);
  const gridApiRef = useRef<any>(null);

  //Vars
  const open = Boolean(anchorEl);
  const pageType = props.tabKey;
  const params: any = {};
  const authorized = isAdmin() || isSystemAdmin();
  const isAdminPortal = props.id.includes('AdminPortal');

  const components = useMemo(() => {
    return {
      onlineStatusRenderer: OnlineStatusCellRenderer,
    };
  }, []);

  //useEffect to delete rows
  useEffect(() => {
    if (props.deleteRow) {
      const { api } = agGridRef.current;
      //Array of all rows on current page.]
      const { rowData } = agGridRef.current.props;

      //Returns everything but the row that matches the row that the user confirmed to delete.
      let Data = rowData.filter((obj, i) => {
        return obj.id !== props.deleteRow.id;
      });

      //Checks if array is not empty.
      Data.length !== undefined && Data.length !== 0 && api.setRowData(Data);
    }
  }, [props.deleteRow]);

  // This resets assetFilters when switching thru tabs (ex. Places Details, Device Mgmt)
  useEffect(() => {
    // Incrementing the filterKey to force re-rendering when props.id changes
    setFilterKey((prevKey) => prevKey + 1);
  }, [props.id]);

  // Analytics Reports - Trend Filters for Temp & Humidity
  // When reportType switches pageNumber sets back to 1
  // When reportType switches pageSize sets back to 20
  useEffect(() => {
    if (props.reportType) {
      setQueryState(props.initQueryParams);
      props.reportType === 'humidity' || props.reportType === 'temperature'
        ? setTrendReport(true)
        : setTrendReport(false);
      queryState.pageNumber = 1;
      queryState.pageSize = 20;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportType]);

  //Create CSV link
  useEffect(() => {
    if (csvReady && csvLinkRef) {
      setTimeout(() => csvLinkRef.current.link.click(), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.csvData]);

  // Get New Data on Parameter Update for Normal SSG
  useEffect(() => {
    //prevents refetch when pager is empty input when backspacing
    const isPageNumberValid = Number.isInteger(queryState.pageNumber) && queryState.pageNumber > 0;

    if (!props.ignoreFetch && isPageNumberValid) {
      debounceFetchData(queryState, assetFilters, andOr, props, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.triggerUpdate, andOr, queryState, assetFilters, props.reload]);

  // Get New Data on Parameter Update for Analytic Reports SSG
  useEffect(() => {
    if (props.ignoreFetch && props.reportType && submitShowContent) {
      debounceFetchData(queryState, assetFilters, andOr, props, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.sort, queryState?.filter, queryState?.pageNumber, queryState?.pageSize]);

  // Empty search string when switching pages
  useEffect(() => {
    if (queryState?.filter) {
      queryState.filter = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  // Loading Gear Overlay
  useEffect(() => {
    if (props.isLoading) {
      setTimeout(() => {
        agGridRef.current?.api.showLoadingOverlay();
      }, 100);
    } else {
      setTimeout(() => {
        agGridRef.current?.api.hideOverlay();
      }, 100);
    }
  }, [props.isLoading]);

  const fetchCSV = () => {
    setCsvReady(true);
    debounceFetchData(queryState, assetFilters, andOr, props, true);
  };
  const fetchData = (
    queryState: PagedQueryParams,
    assetFilters: any[],
    andOr: string,
    props: IPagedTableProps<T>,
    isCSV?: boolean,
  ) => {
    if (props.filterControl === true) {
      props.handleFetchData(
        V2FilterPayload(
          assetFilters,
          andOr,
          isCSV ? 0 : queryState.skip(),
          queryState.setSort(),
          isCSV ? props.rowData?.count : queryState.pageSize,
          undefined,
          queryState.filter,
          queryState.dateFilters.start,
          queryState.dateFilters.end,
        ),
        isCSV,
        queryState,
        props.id,
      );
    } else {
      props.handleFetchData(undefined, isCSV, queryState, props.id);
    }
  };

  const debounceFetchData = useCallback(
    debounce((queryState, assetFilters, andOr, props?, isCSV?) => {
      fetchData(queryState, assetFilters, andOr, props, isCSV);
    }, 500),
    [],
  );

  const handleFilterChange = (e: Array<string>, type: string) => {
    if (JSON.stringify(e) !== JSON.stringify(assetFilters)) {
      setAssetFilters(e);
    }
    if (type !== andOr) {
      setAndOr(type);
    }
    queryState.pageNumber = 1;
  };

  const onChangeLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);
    setQueryState({
      ...queryState,
      pageSize: pageSize,
      pageNumber: pageSize === 0 ? 0 : 1,
    } as PagedQueryParams);
  };

  // Date Filters Start-End Dates
  // If Start Date is Selected First, Set End Date to 24 Hours After
  const onChangeStartDate = (e) => {
    if (e === undefined) {
      setQueryState({
        ...queryState,
        dateFilters: {
          start: e,
          end: queryState.dateFilters.end,
        },
      } as PagedQueryParams);
    } else {
      let endDate;
      queryState.dateFilters.end === undefined
        ? (endDate = moment(e).add(24, 'hours').format('YYYY-MM-DD hh:mm A'))
        : (endDate = queryState.dateFilters.end);
      setQueryState({
        ...queryState,
        dateFilters: {
          start: e.toISOString(),
          end: endDate,
        },
      } as PagedQueryParams);
    }
  };

  // If End Date is Selected First, Set Start Date to 24 Hours Before
  const onChangeEndDate = (e: any) => {
    if (e === undefined) {
      setQueryState({
        ...queryState,
        dateFilters: {
          start: queryState.dateFilters.start,
          end: e,
        },
      } as PagedQueryParams);
    } else {
      let startDate;
      queryState.dateFilters.start === undefined
        ? (startDate = moment(e).subtract(24, 'hours').format('YYYY-MM-DD hh:mm A'))
        : (startDate = queryState.dateFilters.start);
      setQueryState({
        ...queryState,
        dateFilters: {
          start: startDate,
          end: e.toISOString(),
        },
      } as PagedQueryParams);
    }
  };

  // Disables Submit if invalid date, empty date, and if muiErrors or muiHelperText is present
  const checkIfErrorExist = () => {
    let muiErrors = document?.getElementsByClassName('Mui-error');
    let muiHelperText = document?.getElementsByClassName('MuiFormHelperText-contained');
    if (queryState.dateFilters.start == null || queryState.dateFilters.end == null) {
      setDisableBtn(true);
    } else if (
      queryState.dateFilters.start === undefined ||
      queryState.dateFilters.end === undefined
    ) {
      setDisableBtn(true);
    } else if (queryState.dateFilters.start !== null && queryState.dateFilters.end !== null) {
      muiErrors?.length > 0 ? setDisableBtn(true) : setDisableBtn(false);
      muiHelperText?.length > 0 ? setDisableBtn(true) : setDisableBtn(false);
    }
  };

  useEffect(() => {
    if (props.reportType && props.reportType !== 'battery') {
      setTimeout(() => {
        checkIfErrorExist();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.dateFilters]);

  // Date Filter Component
  let dateFilterRender = (queryState, dateKey) => {
    return (
      <DateFilter
        key={dateKey}
        startDate={queryState.dateFilters.start}
        endDate={queryState.dateFilters.end}
        onChangeStartDate={(e) => onChangeStartDate(e)}
        onChangeEndDate={(e) => onChangeEndDate(e)}
      />
    );
  };

  // Analytic Reports - Fetch Data Handler for Submit Button
  const handleSubmitReport = () => {
    debounceFetchData(queryState, assetFilters, andOr, props, false);
    setSubmitShowContent(true);
  };

  // Analytics Reports - Show Instructions on Report Type Change
  useEffect(() => {
    setSubmitShowContent(false);
    setDateKey(dateKey + 1);
    props.reportType === 'battery' ? setDisableBtn(false) : setDisableBtn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportType]);

  function getDefaultColDef(): ColDef {
    return {
      sortable: true,
      filter: false,
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        return 0;
      },
      flex: 1,
      resizable: true,
      singleClickEdit: true,
      filterParams: {
        resetButton: true,
      },
    } as ColDef;
  }

  // Prevents users from copy/pasting a negative number into inputs
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  function getPageCount(pageSize?: number): number {
    if (props.rowData?.count > 0 && pageSize > 0) {
      return Math.ceil(props.rowData?.count / (pageSize ?? queryState.pageSize));
    } else {
      return 0;
    }
  }

  // Compares items to filter size so that filter size is never greater than number of items.
  function adjustPageSize() {
    //queryState.pageSize is set on page load and sets the Viewing number of assets
    return queryState.pageSize > props.rowData?.count
      ? props.rowData?.count + ''
      : queryState.pageSize + '';
  }

  //Sizes grid columns to fit and shows loading overlay if needed
  function onGridReady(e: GridReadyEvent) {
    gridApiRef.current = e.api;
    if (props.isLoading) e.api.showLoadingOverlay();
  }

  function onCellClick(e: CellClickedEvent) {
    if (props.onCellClick) {
      props.onCellClick(e.data as T, e.colDef.field);
    }
  }

  useEffect(() => {
    setQueryState({ ...queryState, pageNumber: 1 } as PagedQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabKey]);

  // Formatting CSV File Name Functions
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const capitalizeWords = (str) => {
    return str.split('_').map(capitalizeFirstLetter).join('_');
  };

  const adjustReportName = (str) => {
    let reportType = props.reportType;

    // Check if reportType includes a hyphen ("-")
    if (reportType.includes('-')) {
      // Replace hyphens with underscores
      reportType = reportType.replace(/-/g, '_');
    }

    // Split the reportType into words
    const words = reportType.split('_');

    // Capitalize each word
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the capitalized words with underscores
    reportType = capitalizedWords.join('_');

    return reportType;
  };

  // Default Header for SSG
  function renderHeader(pageType: any) {
    return (
      <>
        <div className="d-flex flex-row align-items-center justify-content-between">
          {/* Grid Search Bar */}
          {props.searchControl && (
            <div className="d-flex flex-row align-items-center justify-content-between">
              <SearchInput
                id={props.id}
                initSearch={queryState?.filter}
                placeholder="Quick Search"
                debounceTimeSec={1}
                onChange={(e: string) => {
                  setQueryState({
                    ...queryState,
                    filter: e,
                    pageNumber: 1,
                  } as PagedQueryParams);
                }}
              />
              {/* Asset Filters */}
              {props.filterControl && !props.reportType && (
                <PageTypeContext.Provider value={{ pageType }}>
                  <FilterContainer
                    key={filterKey}
                    onChange={handleFilterChange}
                    disabledFilters={props.disabledFilters}
                    filterReportType={'asset-filter'}
                  />
                </PageTypeContext.Provider>
              )}
            </div>
          )}
          {/* Grid Viewing Number Control */}
          {props.viewingControl && (
            <div
              className="d-flex flex-row align-items-center justify-content-between"
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              <RowCounter
                type="number"
                className="form-control form-control-sm m-2"
                min={0}
                max={props.rowData?.count ?? props.rowData?.length ?? ''}
                pattern="[0-9]"
                value={adjustPageSize()}
                onChange={(e) => onChangeLimit(e)}
                onPaste={preventPasteNegative}
                onKeyPress={(event) => {
                  switch (event.charCode) {
                    case 45:
                    case 46:
                      event.preventDefault();
                      break;
                    default:
                      return null;
                  }
                }}
              />
              <span> of {props.rowData?.count ?? props.rowData?.length}</span>
            </div>
          )}
          {/* Grid Paging Control */}
          {props.pagerControl && (
            <Pager
              page={Number(queryState.pageNumber) ?? 1}
              pageCount={getPageCount(queryState.pageSize)}
              onChange={(e: number) => {
                setQueryState({ ...queryState, pageNumber: e } as PagedQueryParams);
              }}
            />
          )}

          {/* Grid CSV Export Button */}
          {props.exportControl && (
            <Button
              size="sm"
              variant="secondary"
              onClick={() => fetchCSV()}
              disabled={Boolean(props.isLoading) || queryState.pageSize === 0 || csvReady}
            >
              Export
            </Button>
          )}

          {props.csvData && props.exportControl && csvReady && (
            <CSVLink
              ref={csvLinkRef}
              headers={props.csvHeaders}
              filename={
                props.reportType
                  ? `${capitalizeWords(props.id.replace(/-/g, '_'))}_${adjustReportName(
                      props.reportType,
                    )}_Export.csv`
                  : isAdminPortal
                  ? `${capitalizeWords(props.id.replace(/-/g, '_'))}_Export.csv`
                  : `${capitalizeWords(props.id.replace(/-/g, '_'))}_Export.csv`
              }
              variant="secondary"
              className="d-none"
              onClick={() => setCsvReady(false)}
              data={
                props.csvData?.items
                  ? props.csvData?.items.map((row: any) => {
                      return Object.keys(row).map((key) => {
                        return typeof row[key] === 'object'
                          ? JSON.stringify(row[key]).replace(/"/g, '""')
                          : row[key].toString();
                      });
                    })
                  : props.csvData?.map((row: any) => {
                      return Object.keys(row).map((key) => {
                        return typeof row[key] === 'object'
                          ? JSON.stringify(row[key]).replace(/"/g, '""')
                          : row[key].toString();
                      });
                    })
              }
              enclosingCharacter={`"`}
            />
          )}
        </div>
      </>
    );
  }

  // Analytic Reports - Header and Filters
  function renderAnalyticReportHeader() {
    return (
      <Card className="mt-5 analytic-report-selector">
        <Card.Body>
          <div className="d-flex gap-3 analytic-report-selector-wrapper">
            <div className="header-input">
              <ReportTypeInput ChangeReportType={props.ChangeReportType} />
            </div>
            {/* Date Filters For All Reports Except Battery  */}
            {props.reportType && props.reportType !== 'battery' && (
              <div className="date-filter">{dateFilterRender(queryState, dateKey)}</div>
            )}
            {/* Asset Filters For Reports BATTERY  */}
            {props.filterControl && props.reportType && props.reportType === 'battery' && (
              <div className="asset-filter-battery">
                <PageTypeContext.Provider value={{ pageType }}>
                  <FilterContainer
                    onChange={handleFilterChange}
                    disabledFilters={props.disabledFilters}
                    filterReportType={'asset-filter'}
                  />
                </PageTypeContext.Provider>
              </div>
            )}
            {/* Asset Filters For Reports ENV  */}
            {/* {props.filterControl && props.reportType && props.reportType === 'environmental' && (
              <div className="asset-filter">
                <PageTypeContext.Provider value={{ pageType }}>
                  <FilterContainer
                    onChange={handleFilterChange}
                    disabledFilters={props.disabledFilters}
                    filterReportType={'asset-filter'}
                  />
                </PageTypeContext.Provider>
              </div>
            )} */}
            {/* Asset Filters For Reports TRIGGER  */}
            {props.filterControl &&
              props.reportType &&
              props.reportType === 'trigger-resolution' && (
                <div className="asset-filter">
                  <PageTypeContext.Provider value={{ pageType }}>
                    <FilterContainer
                      onChange={handleFilterChange}
                      disabledFilters={[
                        'all',
                        'identifier',
                        'keyword',
                        'map',
                        'place',
                        'device id',
                        'battery value',
                      ]}
                      filterReportType={'asset-filter'}
                    />
                  </PageTypeContext.Provider>
                </div>
              )}
            {/* Show Trend Filter For Temp & Humidity Report ONLY */}
            {/* {trendReport && (
              <div >
                <FilterContainer
                  onChange={handleFilterChange}
                  disabledFilters={props.disabledFilters}
                  filterReportType={'trend-filter'}
                  trendFilterType={props.id}
                />
              </div>
            )} */}
            {/* Bell Notification for Battery Report Admins Only */}
            {props.reportType === 'battery' && authorized && (
              <div className="battery-bell">
                <Button variant="transparent" onClick={(event) => setAnchorEl(event.currentTarget)}>
                  <i className="fa fa-bell fa-lg icon-primary ms-1 mx-auto" />
                </Button>
                <Popover
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <ContactList />
                </Popover>
              </div>
            )}
            {/* Submit Button For Reports ENV & TRIGGER */}
            {props.reportType && props.reportType !== 'battery' && (
              <div className="ms-auto submit-btn">
                <Button
                  variant="primary"
                  className="px-5 float-right submit-report-btn"
                  onClick={handleSubmitReport}
                  title="Submit Report"
                  disabled={disableBtn}
                >
                  Submit
                </Button>
              </div>
            )}
            {/* Submit Button For Reports Battery */}
            {props.reportType && props.reportType === 'battery' && (
              <div className="ms-auto submit-btn-battery">
                <div>
                  <Button
                    variant="primary"
                    className="px-5 float-right submit-report-btn"
                    onClick={handleSubmitReport}
                    title="Submit Report"
                    disabled={disableBtn}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    );
  }

  // ServerSideGrid for Analytics Report and etc
  const SSG = (props: IPagedTableProps<T>) => {
    return (
      <SSGContext.Provider value={props.rowData}>
        <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
          {renderHeader(pageType)}
          <div className="ag-theme-material h-100 w-100">
            <AgGridReact
              ref={agGridRef}
              rowData={props.rowData?.items || props.rowData}
              columnDefs={props.columnDefs}
              components={components}
              onSortChanged={(e: SortChangedEvent) => {
                if (e.columnApi.getColumnState().find((col) => col.sort !== null) === undefined)
                  return;
                const col = e.columnApi.getColumnState().find((col) => col.sort !== null).colId;
                const order = e.columnApi.getColumnState().find((col) => col.sort !== null).sort;
                setQueryState({
                  ...queryState,
                  sort: { colId: col, sort: order },
                } as PagedQueryParams);
              }}
              onGridReady={(e) => onGridReady(e)}
              scrollbarWidth={10}
              onCellClicked={(e) => onCellClick(e)}
              animateRows
              defaultColDef={getDefaultColDef()}
              suppressDragLeaveHidesColumns
              suppressNoRowsOverlay
              loadingOverlayComponent={Loading}
              {...props.gridProps}
            />
          </div>
        </div>
      </SSGContext.Provider>
    );
  };

  //Renders Normal SSG or Analytic Reports SSG version
  return (
    <>
      {props.id !== 'analytic-reports' ? (
        <>{SSG(props)}</>
      ) : (
        <>
          {renderAnalyticReportHeader()}
          <Card className="h-100 mt-3 analytic-report-data">
            <Card.Body>
              <Row className="h-100">
                <Col className="h-100">
                  {/* If Submit Button is Clicked show SSG else show Instruction Text */}
                  {submitShowContent ? (
                    <>{SSG(props)}</>
                  ) : (
                    <>
                      <div className="report-msg">
                        <Icon
                          name="analytics"
                          size="6x"
                          className="mb-3"
                          variant="dark"
                          disabled={false}
                          showPointer={false}
                        />
                        <Text className="mb-3" as="h5">
                          To Generate Reports:
                        </Text>
                        <Text className="mb-4" as="h5">
                          Select a Report, Adjust Filters & Click Submit.
                        </Text>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
}
