import Icon from '../Icon/Icon';

function ModalContainer({ close, children }) {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-end">
        <Icon
          name="add"
          size="lg"
          disabled={false}
          className="sonarIcon-rotate-45"
          variant="dark"
          onClick={close}
        />
      </div>

      {children}
    </div>
  );
}

export default ModalContainer;
