import classnames from 'classnames';
import { Link } from 'react-router-dom';

function LinkCellRenderer({ to, text, isBlock = true }) {
  const className = classnames('btn-link', { 'w-100 d-block': isBlock });

  return (
    <Link to={to} className={className}>
      {text}
    </Link>
  );
}

export default LinkCellRenderer;
