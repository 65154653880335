import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import Swal from 'sweetalert2';
import Dropzone from '../../dropzones/dropzone';
import Text from '../../Text/Text';
import { toast } from '../../utils';
import CloseModal from '../CloseModal';

function CsvModal({ title, text, csvFile, onUpload, setModified, itemName, isDock }) {
  const [data, setData] = useState([]);

  const onDrop = (file) => {
    const reader = new FileReader();
    setModified(true);

    reader.onload = (e) => {
      const csvData = e.target.result
        .replace(/(\r\n|\r)/g, '\n')
        .split('\n')
        .filter(Boolean) // check string isnt empty
        .slice(1) // rm headers
        .map((csvString) => {
          const rowArray = csvString.split(',').map((str) => str.replace(/"/g, ''));
          return rowArray;
        });

      setData(csvData);
    };

    reader.onerror = (e) => {
      toast.error('Failed to read the CSV file.');
    };

    reader.readAsText(file);
  };

  const openBeaconTypesTab = () => {
    window.open('/beacon-types', '_blank');
  };

  return (
    <Container>
      <Row>
        <Col>{!isDock && <CloseModal close={() => Swal.close()} />}</Col>
      </Row>

      <Row>
        <Col>
          <h1>{title}</h1>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <Text>{text}</Text>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <CSVLink
              variant="secondary"
              className="btn btn-secondary"
              data={csvFile.exampleData}
              headers={csvFile.headers}
              filename={csvFile.filename}
            >
              Download Template
            </CSVLink>
          </div>
        </Col>
      </Row>
      {itemName === 'Beacons' && (
        <Row className="my-2">
          <Col>
            <Button
              variant="link"
              style={{
                fontSize: '.8rem',
              }}
              onClick={openBeaconTypesTab}
            >
              View Beacon Types
            </Button>
          </Col>
        </Row>
      )}

      <Row className="my-4">
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <Dropzone
              onDrop={onDrop}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <Button
              variant="primary"
              disabled={!data.length}
              onClick={() => {
                if (!isDock) {
                  Swal.close();
                }
                onUpload(data);
              }}
            >
              Upload
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CsvModal;
