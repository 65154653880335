import PropTypes from 'prop-types';
import { useState } from 'react';
import Icon from '../Icon/Icon';

const styles = {
  disabled: {
    color: 'grey',
  },
};

/**
 * @desc Renders a row with paging arrows on left and right and
 * dynamic content in middle.
 */
function DynamicPager({ children, initPage, onChangePage, pageCount }) {
  const [page, setPage] = useState(initPage);

  function changePage(newPage) {
    onChangePage(newPage);
    setPage(newPage);
  }

  return (
    <div className="d-flex flex-row w-100 h-100 justify-content-between align-items-center">
      <Icon
        name="double-arrow-left"
        className="align-sub"
        variant="light"
        size="lg"
        disabled={page === 1}
        onClick={() => changePage(page - 1)}
        style={page === 1 ? styles.disabled : {}}
      />

      {children}

      <Icon
        name="double-arrow-right"
        className="align-sub"
        variant="light"
        size="lg"
        disabled={page === pageCount}
        onClick={() => changePage(page + 1)}
        style={page === pageCount ? styles.disabled : {}}
      />
    </div>
  );
}

DynamicPager.defaultProps = {
  initPage: 1,
};

DynamicPager.propTypes = {
  children: PropTypes.any.isRequired,
  onChangePage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,

  // defaults
  initPage: PropTypes.number,
};

export default DynamicPager;
