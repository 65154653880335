import './HomeCharts.scss';
import { update } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ABILITY_MAP } from '../../constants';
import { ThemeContext } from '../../context/ThemeContext';
import { useApp } from '../../react';
import { Api } from '../../services';
import { DateTabs, Icon, Loading, Text } from '..';
import { Export } from '../utils';
import AssetChartEdit from './AssetChartEdit';
import Graph from './Graph';

//Gets
async function fetchAsset(asset) {
  if (!asset) return;
  const data = Api.get(`assets/${asset}`);
  return data;
}

async function getReadings(beacon, reading, startDate, endDate, params?) {
  const startDateStr = startDate.toISOString();
  const endDateStr = endDate.toISOString();
  const data = Api.get(
    `/beacons/${beacon.id}/readings/${reading.abilityId}?start=${startDateStr}&end=${endDateStr}`,
    params,
  );
  return data;
}

function AssetChart({ type, info, edit, index, isValid, remove, updateWidget }) {
  const app = useApp();
  const { theme } = useContext(ThemeContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [assetId, setAssetId] = useState<any>(info ? info.asset : null);
  const [beaconId, setBeaconId] = useState<any>(info ? info.beacon : null);
  const [readingId, setReadingId] = useState<any>(info ? info.reading : null);
  const [curBeacon, setCurBeacon] = useState<any>(null);
  const [curReading, setCurReading] = useState<any>(null);
  const [dataOption, setDataOption] = useState('mean');
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [chartData, setChartData] = useState<any>(null);
  const BEACON_READING_OPTIONS = ['Mean', 'Max', 'Value', 'N', 'Min'];
  const { data: curAsset, refetch: curAssetRefetch } = useQuery<any>([`asset-chart-${index}`], () =>
    fetchAsset(assetId),
  );

  //forces update of plotly chart when theme is changed. otherwise it wont update until the page is refreshed
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, [theme]);

  useEffect(() => {
    if (assetId && beaconId && readingId) {
      updateWidget({
        index: index,
        asset: assetId,
        beacon: beaconId,
        reading: readingId,
        isValid: true,
      });
    } else {
      updateWidget({ index: index, isValid: false });
    }
  }, [index, assetId, beaconId, readingId, updateWidget]);

  useEffect(() => {
    curAssetRefetch();
  }, [assetId, curAssetRefetch]);

  useEffect(() => {
    if (curAsset && beaconId) {
      let newBeacon = curAsset.data.beacons.filter((beacon) => {
        return beacon.id === beaconId;
      });
      setCurBeacon(newBeacon[0]);
    }
  }, [curAsset, beaconId]);

  useEffect(() => {
    if (curBeacon && readingId) {
      let newReading = curBeacon.readings.filter((reading) => {
        return reading.abilityId === readingId;
      });
      setCurReading(newReading[0]);
    }
  }, [curBeacon, readingId]);

  useEffect(() => {
    if (curReading && startDate && endDate && !edit) {
      getReadings(curBeacon, curReading, startDate, endDate).then((res) => {
        setChartData(res);
      });
    }
  }, [curBeacon, curReading, startDate, endDate, edit]);

  const getExport = async (exportType) => {
    setCsvLoading(true);
    const params = {
      responseType: 'blob',
      headers: { Accept: exportType },
    };
    const res = await getReadings(curBeacon, curReading, startDate, endDate, params);

    setTimeout(() => {
      setCsvLoading(false);
    }, 1000);
    return res;
  };

  const onChangeReadingsDate = useCallback(async (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  // Select chart data type
  const onChangeDataOption = (val) => {
    switch (val) {
      case 0:
        setDataOption('mean');
        break;
      case 1:
        setDataOption('max');
        break;
      case 2:
        setDataOption('value');
        break;
      case 3:
        setDataOption('n');
        break;
      case 4:
        setDataOption('min');
        break;
      default:
        setDataOption('mean');
        break;
    }
  };

  const renderReadingsCharts = () => {
    if (!chartData.data.length)
      return (
        <div className="d-flex flex-column align-items-center justify-content-center mt-5 mb-5 w-100 no-reading-container">
          <Icon
            name="analytics"
            variant="dark"
            size="6x"
            className="mb-3"
            disabled={false}
            showPointer={false}
          />
          <Text className="fs-md">
            There are currently no readings for the selected Beacon/Ability combination.
          </Text>
        </div>
      );

    const readings = chartData.data.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
    let abilityFormatter = ABILITY_MAP[curReading.ability] || ABILITY_MAP['Generic'];

    return (
      <Graph
        title={`${curBeacon.name} - ${curReading.ability}`}
        x={readings.map((reading) => new Date(reading.timestamp))}
        y={readings.map((reading) => abilityFormatter.numValue(reading, dataOption))}
      />
    );
  };

  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  if (edit) {
    return (
      <AssetChartEdit
        index={index}
        curAsset={curAsset}
        assetId={assetId}
        beaconId={beaconId}
        readingId={readingId}
        isValid={isValid}
        setAssetId={setAssetId}
        setBeaconId={setBeaconId}
        setReadingId={setReadingId}
        remove={remove}
      />
    );
  }

  if (curAsset && curBeacon && curReading && !isLoading) {
    return (
      <div className={isLoading ? 'card-load' : 'card-display'}>
        <Card.Header className="d-flex flex-row justify-content-between align-items-left reading-container-header">
          <div className="d-flex flex-row justify-content-left align-items-left">
            <Button
              className="fs-md fw-bold ignore-click"
              variant="light"
              onClick={() => history.push(`/assets/${curAsset.data.id}`)}
            >
              {curAsset.data.name}
            </Button>
          </div>
          {edit && (
            <Button
              className="delete-icon ignore-click"
              variant="light"
              onClick={() => {
                remove(index);
              }}
            >
              <i
                className="ignore-click sonarIcon sonarIcon-add sonarIcon-rotate-45 align-sub fs-lg"
                style={{ color: 'var(--danger)' }}
              />
            </Button>
          )}
        </Card.Header>

        {curBeacon && (
          <Card.Body className="d-flex flex-row flex-wrap justify-content-between align-items-center">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('All Health Check graphs are locked to N values.')}
              >
                <span className="d-inline-block info-icon-span me-2">
                  <div className="info-icon-div">
                    <Icon
                      className="align-sub ignore-click"
                      name="info"
                      variant="dark"
                      disabled={false}
                      size="md"
                    />
                  </div>
                </span>
              </OverlayTrigger>
              <DropdownButton
                className="me-3 ignore-click"
                variant="secondary"
                title="Beacons"
                id="Beacons"
              >
                {curAsset.data.beacons
                  .filter(function (item) {
                    if (item.readings.length !== 0) return true;
                    return false;
                  })
                  .map((beacon) => (
                    <Dropdown.Item
                      as="button"
                      key={beacon.id}
                      active={curBeacon.id === beacon.id}
                      onClick={() => {
                        setCurBeacon(beacon);
                      }}
                    >
                      {beacon.name}
                    </Dropdown.Item>
                  ))}
              </DropdownButton>

              <DropdownButton
                className="me-3 ignore-click"
                variant="secondary"
                title="Abilites"
                id="Abilites"
              >
                {curBeacon.readings.map((reading) => (
                  <Dropdown.Item
                    as="button"
                    key={reading.abilityId}
                    active={readingId === reading.abilityId}
                    onClick={() => {
                      setCurReading(reading);
                    }}
                  >
                    {reading.ability}
                  </Dropdown.Item>
                ))}
              </DropdownButton>

              <DropdownButton
                className="me-3 ignore-click"
                variant="secondary"
                title="Data Options"
                id="Data Options"
              >
                {BEACON_READING_OPTIONS.map((option, index) => (
                  <Dropdown.Item
                    as="button"
                    key={index}
                    active={option.toLowerCase() === dataOption}
                    onClick={() => {
                      onChangeDataOption(index);
                    }}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Export
                filename={`${curBeacon.name}_${curReading.ability}_Export`}
                options={['CSV', 'JSON']}
                csvLoading={csvLoading}
                getExport={async (option) => {
                  return await getExport(option);
                }}
                advancedAsset={undefined}
                addRun={false}
              />
            </div>
            {curBeacon && (
              <div className="d-flex flex-row justify-content-between">
                <DateTabs onChangeDate={onChangeReadingsDate} />
              </div>
            )}
          </Card.Body>
        )}

        <Card.Body className="overflow-hidden d-flex flex-row justify-content-between align-items-center">
          {isLoading && <Loading />}
          {!isLoading && curBeacon && curReading && chartData && renderReadingsCharts()}
          {!curBeacon && !isLoading && (
            <Text className="fs-md ">
              There are currently no active beacons attached to this asset. To show data for this
              asset attach an active beacon.
            </Text>
          )}
        </Card.Body>
      </div>
    );
  }
}

export default AssetChart;
