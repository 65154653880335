function EnvScreen() {
  return (
    <div>
      <pre>
        <code>{JSON.stringify(window._sonar_env, null, 5)}</code>
      </pre>
    </div>
  );
}

export default EnvScreen;
