import { Button,Col, Row } from 'react-bootstrap';

const KeepTriggers = (props) => {
  const { beacon, triggerList } = props;

  return (
    <div className="text-center">
      <Row>
        <Col>
          {Boolean(triggerList.length) && (
            <h5 className="mb-4">
              You have {triggerList.length} Triggers associated with this beacon.
            </h5>
          )}
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h5 className="mb-4">Keep Triggers with {beacon.name}?</h5>
        </Col>
      </Row>
      <Row>
        <Col className="text-left col-8 m-auto">
          <ul>
            {triggerList?.map((trigger) => (
              <li>{trigger}</li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="success">Done</Button>
        </Col>
      </Row>
    </div>
  );
};

export default KeepTriggers;
