// progress bar
export const PROGRESS_SET_PROGRESS = 'PROGRESS_SET_PROGRESS';
export const PROGRESS_START_PROGRESS = 'PROGRESS_START_PROGRESS';
export const PROGRESS_END_PROGRESS = 'PROGRESS_END_PROGRESS';

// thing actions
export const THINGS_REQUEST = 'THINGS_REQUEST';
export const THINGS_RECEIVED = 'THINGS_RECEIVED';
export const THING_REQUEST = 'THING_REQUEST';
export const THING_RECEIVED = 'THING_RECEIVED';
export const THING_REQUEST_FAILURE = 'THING_REQUEST_FAILURE';
export const THING_PATCH_STARTED = 'THING_PATCH_STARTED';
export const THING_PATCH_COMPLETED = 'THING_PATCH_COMPLETED';
export const THING_POST_STARTED = 'THING_POST_STARTED';
export const THING_POST_COMPLETED = 'THING_POST_COMPLETE';
export const THING_DELETE_STARTED = 'THING_DELETE_STARTED';
export const THING_DELETE_COMPLETED = 'THING_DELETE_COMPLETED';
export const THING_SUMMARY_REQUEST = 'THING_SUMMARY_REQUEST';
export const THING_SUMMARY_RECEIVED = 'THING_SUMMARY_RECEIVED';
export const THING_CLEAR = 'THING_CLEAR';

// place actions
export const PLACES_REQUEST = 'PLACES_REQUEST';
export const PLACES_RECEIVED = 'PLACES_RECEIVED';
export const PLACE_POST_STARTED = 'PLACE_POST_STARTED';
export const PLACE_POST_COMPLETED = 'PLACE_POST_COMPLETED';
export const PLACE_PATCH_STARTED = 'PLACE_PATCH_STARTED';
export const PLACE_PATCH_COMPLETED = 'PLACE_PATCH_COMPLETED';
export const PLACE_REQUEST = 'PLACE_REQUEST';
export const PLACE_RECEIVED = 'PLACE_RECEIVED';
export const PLACE_SEES_REQUEST = 'PLACE_SEES_REQUEST';
export const PLACE_SEES_RECEIVED = 'PLACE_SEES_RECEIVED';
export const PLACE_DELETE_STARTED = 'PLACE_DELETE_STARTED';
export const PLACE_DELETE_COMPLETED = 'PLACE_DELETE_COMPLETED';
export const PLACES_REQUEST_FAILURE = 'PLACES_REQUEST_FAILURE';
export const PLACE_SUMMARY_REQUEST = 'PLACE_SUMMARY_REQUEST';
export const PLACE_SUMMARY_RECEIVED = 'PLACE_SUMMARY_RECEIVED';

// people actions
export const PEOPLE_REQUEST = 'PEOPLE_REQUEST';
export const PEOPLE_RECEIVED = 'PEOPLE_RECEIVED';
export const PEOPLE_REQUEST_FAILURE = 'PEOPLE_REQUEST_FAILURE';
export const PERSON_DELETE_STARTED = 'PERSON_DELETE_STARTED';
export const PERSON_DELETE_COMPLETED = 'PERSON_DELETE_COMPLETED';
export const PERSON_POST_STARTED = 'PERSON_POST_STARTED';
export const PERSON_POST_COMPLETED = 'PERSON_POST_COMPLETED';
export const PERSON_PATCH_STARTED = 'PERSON_PATCH_STARTED';
export const PERSON_PATCH_COMPLETED = 'PERSON_PATCH_COMPLETED';
export const PERSON_REQUEST = 'PERSON_REQUEST';
export const PERSON_RECEIVED = 'PERSON_RECEIVED';
export const PERSON_DEVICE_POST_STARTED = 'PERSON_DEVICE_POST_STARTED';
export const PERSON_DEVICE_POST_COMPLETED = 'PERSON_DEVICE_POST_COMPLETED';
export const PERSON_DEVICES_REQUEST = 'PERSON_DEVICES_REQUEST';
export const PERSON_DEVICES_RECEIVED = 'PERSON_DEVICES_RECEIVED';
export const PERSON_DEVICE_REQUEST = 'PERSON_DEVICE_REQUEST';
export const PERSON_DEVICE_RECEIVED = 'PERSON_DEVICE_RECEIVED';
export const PERSON_DEVICE_PATCH_STARTED = 'PERSON_DEVICE_PATCH_STARTED';
export const PERSON_DEVICE_PATCH_COMPLETED = 'PERSON_DEVICE_PATCH_COMPLETED';
export const PERSON_DEVICE_DELETE_STARTED = 'PERSON_DEVICE_DELETE_STARTED';
export const PERSON_DEVICE_DELETE_COMPLETED = 'PERSON_DEVICE_DELETE_COMPLETED';
export const PERSON_DEVICE_LAST_READING_STARTED = 'PERSON_DEVICE_LAST_READING_STARTED';
export const PERSON_DEVICE_LAST_READING_COMPLETED = 'PERSON_DEVICE_LAST_READING_COMPLETED';

// readings actions
export const READINGS_REQUEST = 'READINGS_REQUEST';
export const READINGS_RECEIVED = 'READINGS_RECEIVED';
export const READINGS_REQUEST_FAILURE = 'READINGS_REQUEST_FAILURE';
export const READINGS_CLEAR = 'READINGS_CLEAR';

// place actions
export const PERIPHERALS_REQUEST = 'PERIPHERALS_REQUEST';
export const PERIPHERALS_RECEIVED = 'PERIPHERALS_RECEIVED';
export const PERIPHERALS_REQUEST_FAILURE = 'PERIPHERALS_REQUEST_FAILURE';

// navigation
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION';
export const TOGGLE_APPLICATION = 'TOGGLE_APPLICATION';

// user
export const UPDATE_USER = 'UPDATE_USER';

// reports
export const TEMP_HUMIDITY_REQUEST = 'TEMP_HUMIDITY_REQUEST';
export const TEMP_HUMIDITY_RECEIVED = 'TEMP_HUMIDITY_RECEIVED';
export const REPORT_REQUEST_FAILURE = 'REPORT_REQUEST_FAILURE';
export const LOCATION_REQUEST = 'LOCATION_REQUEST';
export const LOCATION_RECEIVED = 'LOCATION_RECEIVED';
export const ASSET_TYPES_REQUEST = 'ASSET_TYPES_REQUEST';
export const ASSET_TYPES_RECEIVED = 'ASSET_TYPES_RECEIVED';

// search

export const UPDATE_SEARCH_STRING = 'UPDATE_SEARCH_STRING';
export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER';

// maps
export const MAPS_RECEIVED = 'MAPS_RECEIVED';
export const MAPS_REQUEST_FAILURE = 'MAPS_REQUEST_FAILURE';
export const REQUEST_MAPS = 'REQUEST_MAPS';
export const REQUEST_MAP = 'REQUEST_MAP';
export const MAP_RECEIVED = 'MAP_RECEIVED';
export const MAP_DELETED = 'MAP_DELETED';
export const MAP_REQUEST_FAILURE = 'MAP_REQUEST_FAILURE';
export const REQUEST_MAP_DELETE = 'REQUEST_MAP_DELETE';
export const MAP_UPDATED = 'MAP_UPDATED';
export const START_MAP_UPDATE = 'START_MAP_UPDATE';
export const MAP_UPLOADED = 'MAP_UPLOADED';
export const MAP_UPLOAD_FAILURE = 'MAP_UPLOAD_FAILURE';
export const START_MAP_UPLOAD = 'START_MAP_UPLOAD';
export const MAP_CREATED_FAILURE = 'MAP_CREATED_FAILURE';
export const MAP_CREATED = 'MAP_CREATED';
export const START_MAP_CREATION = 'START_MAP_CREATION';
export const MAPS_CLEAR = 'MAPS_CLEAR';

// trigger

export const TRIGGER_THING_GET_STARTED = 'TRIGGER_THING_GET_STARTED';
export const TRIGGER_THING_GET_COMPLETED = 'TRIGGER_THING_GET_COMPLETED';
export const TRIGGER_THING_GET_FAILED = 'TRIGGER_THING_GET_FAILED';
export const TRIGGER_APPLICATION_GET_STARTED = 'TRIGGER_APPLICATION_GET_STARTED';
export const TRIGGER_APPLICATION_GET_COMPLETED = 'TRIGGER_APPLICATION_GET_COMPLETED';
export const TRIGGER_APPLICATION_GET_FAILED = 'TRIGGER_APPLICATION_GET_FAILED';
export const TRIGGER_GET_STARTED = 'TRIGGER_GET_STARTED';
export const TRIGGER_GET_COMPLETED = 'TRIGGER_GET_COMPLETED';
export const TRIGGER_GET_FAILED = 'TRIGGER_GET_FAILED';
export const TRIGGER_NEW_STARTED = 'TRIGGER_NEW_STARTED';
export const TRIGGER_NEW_COMPLETED = 'TRIGGER_NEW_COMPLETED';
export const TRIGGER_NEW_FAILED = 'TRIGGER_NEW_FAILED';
export const TRIGGER_UPDATE_STARTED = 'TRIGGER_UPDATE_STARTED';
export const TRIGGER_UPDATE_COMPLETED = 'TRIGGER_UPDATE_COMPLETED';
export const TRIGGER_UPDATE_FAILED = 'TRIGGER_UPDATE_FAILED';
export const TRIGGER_DELETE_STARTED = 'TRIGGER_DELETE_STARTED';
export const TRIGGER_DELETE_COMPLETED = 'TRIGGER_DELETE_COMPLETED';
export const TRIGGER_DELETE_FAILED = 'TRIGGER_DELETE_FAILED';

// #region CONTACT TRACING
export const CONTACT_TRACING_SET_START_DATE = 'CONTACT_TRACING_SET_START_DATE';
export const CONTACT_TRACING_SET_END_DATE = 'CONTACT_TRACING_SET_END_DATE';
export const CONTACT_TRACING_SET_SEARCH = 'CONTACT_TRACING_SET_SEARCH';
export const CONTACT_TRACING_SET_PAGE = 'CONTACT_TRACING_SET_PAGE';
// #endregion CONTACT TRACING
