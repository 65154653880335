import '../../scss/variables.scss';
import Plot from 'react-plotly.js';

const Graph = ({ x, y, title }) => {
  const noData = !x.length && !y.length;

  var trace1 = {
    x: x,
    y: y,
    type: 'scatter',
    mode: 'lines',
    line: {
      color: getComputedStyle(document.documentElement).getPropertyValue('--primary'),
      width: 4,
    },
  };

  let layout = {
    title: {
      text: title,

      font: {
        family: 'Arial',
        size: 22,
        color: getComputedStyle(document.documentElement).getPropertyValue('--dark'),
      },
      xref: 'paper',
      x: 0.01,
    },
    xaxis: {
      showgrid: false,
      type: 'date',
      tickformat: '%I:%M %p \n %b %d, %Y',
      color: getComputedStyle(document.documentElement).getPropertyValue('--dark'),
    },
    yaxis: {
      color: getComputedStyle(document.documentElement).getPropertyValue('--dark'),
      automargin: true,
    },
    showlegend: false,
    paper_bgcolor: getComputedStyle(document.documentElement).getPropertyValue('--white'),
    plot_bgcolor: getComputedStyle(document.documentElement).getPropertyValue('--white'),
  };
  let config = {
    displaylogo: false,
    modeBarButtonsToRemove: ['select2d', 'lasso2d'],
  };
  if (noData) {
    layout['annotations'] = [
      {
        x: 0.5,
        y: 0.5,
        xref: 'paper',
        yref: 'paper',
        text: 'No Data',
        showarrow: false,
        font: {
          size: 44,
          family: 'Arial',
        },
        opacity: 0.7,
      },
    ];
    layout.xaxis = {
      showticklabels: false,
      showgrid: false,
      zeroline: false,
      linecolor: 'black',
      linewidth: 1,
      mirror: true,
    };
    layout.yaxis = {
      showticklabels: false,
      showgrid: false,
      zeroline: false,
      linecolor: 'black',
      linewidth: 1,
      mirror: true,
    };
  }
  return (
    <Plot
      className="ignore-click"
      data={[trace1]}
      layout={layout}
      config={config}
      useResizeHandler={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
};

export default Graph;
