import { toast } from '@components';
import {
  appToggleApplication,
  store,
  userPrefsSetAssetFiltersAction,
  userPrefsSetTempAction,
} from '@redux';
import { Api } from '@services';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { connect, useDispatch } from 'react-redux';
import { appService } from '../../services';
import { UserSettingsForm } from './UserSettingsForm';

const UserSettingsContainer = ({ onDone }) => {
  const { data: apps } = useQuery(['appselector-applications'], () => appService.getApps(2000));
  const dispatch = useDispatch();
  const [user, setUser] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const resp = await Api.get(`/users`);
        setUser(resp.data);
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const updateUser = (payload: any) => {
    // Check if temperatureUnit is missing in payload
    if (!payload.temperatureUnit) {
      // Assign user's temperatureUnit to payload
      payload.temperatureUnit = user.temperatureUnit;
    }

    // Check if distanceUnit is missing in payload
    if (!payload.distanceUnit) {
      // Assign user's distanceUnit to payload
      payload.distanceUnit = user.distanceUnit;
    }

    Api.put(`/users`, payload)
      .then(() => {
        store.dispatch(userPrefsSetTempAction(payload.temperatureUnit));
        if (payload.assetFilters !== undefined) {
          store.dispatch(userPrefsSetAssetFiltersAction(payload.assetFilters));
          localStorage.setItem('assetFilters', JSON.stringify(payload.assetFilters));
        }
        toast.success('Successfully updated User Preferences!');
        // If user updates default business application, app will switch over and reload page
        if (payload.DefaultApplicationId !== undefined) {
          const defaultApp = apps?.find((app) => app.id === payload.DefaultApplicationId);
          const app = defaultApp ? { id: defaultApp.id, name: defaultApp.name } : '';
          localStorage.setItem('defaultApplication', JSON.stringify(app));
        }
      })
      .catch((error: any) => {
        setIsError(true);
        toast.error('Failed to update User Preferences.');
      });
  };
  return (
    <UserSettingsForm
      userProfile={user}
      updateUser={updateUser}
      isLoading={isLoading}
      isError={isError}
      onDone={onDone}
    />
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    userId: auth.user.id,
    currentApp: auth.user.defaultApplication,
  };
};

export default connect(mapStateToProps)(UserSettingsContainer);
