const selectAllOrNoneButton = (map, source) => {
  let items = map[source];
  for (let i = 0; i < items.length; i++) {
    if (!items[i].isVisible) {
      return (
        <button className="btn btn-sm" onClick={() => this.selectAll(source)}>
          {' '}
          Select All
        </button>
      );
    }
  }
  return (
    <button className="btn btn-sm" onClick={() => this.selectNone(source)}>
      {' '}
      Select None
    </button>
  );
};

const create = {
  gateway: (index) => {
    return {
      index: Number(index) + 1,
      gateway: null,
      gatewayId: null,
      floor: 1,
      location: null,
    };
  },
  zone: (index) => {
    return {
      index: index + 1,
      zoneId: null,
      name: '',
      translation: '',
      boundary: null,
    };
  },
  beacon: (index) => {
    return {
      index: index + 1,
      beaconId: null,
      location: null,
      beacon: null,
    };
  },
};

const replace = (map, source, item) => {
  map[source] = map[source].map((g) => {
    if (g.index === item.index) {
      return item;
    }
    return g;
  });

  return map;
};

const remove = (map, source, index) => {
  if (index >= 0 && index < map[source].length) {
    map[source].splice(index, 1);
  }
  return map;
};

const removeNew = (map, source, index) => {
  map[source].splice(0, 1);
  return map;
};

const setGatewayLocation = (map, index, location) => {
  map.gateways = map.gateways.map((g) => {
    if (g.index === index) {
      g.location = {
        x: Math.round(location.lng),
        y: Math.round(location.lat),
      };
    }
    return g;
  });

  return map;
};

const setZoneBoundary = (map, index, boundary) => {
  map.zones = map.zones.map((z) => {
    if (z.index === index) {
      const roundedBounds = boundary.map(({ lng, lat }) => ({
        lng: Math.round(lng),
        lat: Math.round(lat),
      }));
      roundedBounds.push(roundedBounds[0]); // close zone circle

      z.boundary = roundedBounds.map((val) => {
        return {
          x: val.lng,
          y: val.lat,
        };
      });
    }
    return z;
  });

  return map;
};

const setBeaconLocation = (map, index, location) => {
  map.beacons = map.beacons.map((b) => {
    if (b.index === index) {
      b.location = {
        x: Math.round(location.lng),
        y: Math.round(location.lat),
      };
    }
    return b;
  });

  return map;
};

const All = {
  create,
  replace,
  selectAllOrNoneButton,
  remove,
  removeNew,
  setGatewayLocation,
  setZoneBoundary,
  setBeaconLocation,
};

export default All;
