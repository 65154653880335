import './StartEndDatePicker.scss';
import MomentUtils from '@date-io/moment';
import { createTheme } from '@material-ui/core';
import { KeyboardDateTimePicker,MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#0048FF',
    },
  },
  overrides: {
    MuiDialogActions: {
      root: {
        boxShadow: '0 0 0 1px var(--c-primary)',
        '& button': {
          textTransform: 'unset',
        },
        '& button:last-child': {
          color: '#fff',
          backgroundColor: '#0048FF',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'rgb(206, 212, 218)',
          top: '-3px !important',
          bottom: '-3px !important',
        },
        '&:hover $notchedOutline': {
          borderColor: 'rgb(206, 212, 218)',
        },
        '&$focused $notchedOutline': {
          borderColor: 'var(--c-primary)',
        },
      },
      adornedEnd: {
        paddingRight: '5px',
      },
    },
    MuiFormControl: {
      root: {
        minWidth: 'auto',
        width: '100%',
      },
    },
    MuiInputBase: {
      root: {
        color: '(var--dark)',
      },
    },
    MuiFormLabel: {
      root: {
        color: '(var--dark)',
        top: '-10px!important',
        margin: '0!important',
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(14px, -2px) scale(0.75) !important',
        zIndex: '1!important',
        backgroundColor: 'var(--light) !important',
        padding: '3px !important',
      },
      outlined: { zIndex: '0' },
    },
    MuiIconButton: {
      root: {
        color: '(var--dark)',
        padding: '0',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: 'var(--c-primary)',
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: 'white!important',
      },
    },
    MuiFormHelperText: {
      root: {
        color: 'var(--danger)!important',
      },
    },
  },
});

const DateTimeWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: baseline;
  justify-items: center;
  margin-top: 3px;
`;

const SingleDateTimeWrapper = styled.div`
  width: 100%;
  justify-items: center;
  margin-top: 3px;
`;

// Single Version Start-End Date Picker for Analytics Overview Page

const SingleStartEndDatePicker = (props) => {

  //useState Hooks
  const {charts, input, formik, tabName} = props
  const [clearDate, setClearDate] = useState(null);
  const [isCalOpen, setIsCalOpen] = useState(false);
  const [isManual, setIsManual] = useState(false);

  return (
    <div>
      <SingleDateTimeWrapper>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <>
              {input.label === 'Start Date' && (
                <KeyboardDateTimePicker
                  variant="inline"
                  inputVariant="outlined"
                  label={input.label}
                  maxDate={formik.values.endDate}
                  maxDateMessage="Date should not be after today"
                  value={formik.values.startDate === undefined ? clearDate : formik.values.startDate}
                  format="MM/DD/YYYY hh:mm a"
                  placeholder="12/12/2020 8:00 AM"
                  onChange={(e) => {
                    if (e !== null ) {

                      if (isCalOpen === true) {

                         if (formik.values.startDate === undefined && isManual === false) {

                            //Set 24 hours before/after depending on what date is selected
                            formik.setValues({...formik.values, startDate: e.toISOString(), endDate: moment(e.toISOString()).add(24, 'hours').format('YYYY-MM-DD hh:mm A')});
                          } else {

                            formik.setValues({...formik.values, startDate: e.toISOString()});
                          }

                      } else {

                        if (e.toString() !== "Invalid date") {

                          formik.setValues({...formik.values, startDate: e.toString()});
                          setIsManual(true);
                        }

                      }
                    }
                  }}
                  onClose={(e) => {
                    setIsCalOpen(false);
                  }}
                  onOpen={(e) => {
                    setIsCalOpen(true);
                  }}
                  disableFuture
                  className="me-3"
                  error={false}
                />
              )}
              {input.label === 'End Date' && (
                <KeyboardDateTimePicker
                  variant="inline"
                  label={input.label}
                  minDate={formik.values.startDate}
                  minDateMessage="Date should not be before start date"
                  inputVariant="outlined"
                  value={formik.values.endDate === undefined ? clearDate : formik.values.endDate}
                  format="MM/DD/YYYY hh:mm A"
                  placeholder="12/12/2020 8:00 AM"
                  onChange={(e) => {
                    if (e !== null ) {

                      if (isCalOpen === true) {

                          if (formik.values.endDate === undefined && isManual === false) {

                            //Set 24 hours before/after depending on what date is selected
                            formik.setValues({...formik.values, endDate: e.toISOString(), startDate: moment(e.toISOString()).subtract(24, 'hours').format('YYYY-MM-DD hh:mm A')});
                          } else {
                            formik.setValues({...formik.values, endDate: e.toISOString()});
                          }
                      }
                      } else {

                          if (e.toString() !== "Invalid date") {
                            formik.setValues({...formik.values, startDate: e.toString()});
                            setIsManual(true);
                          }

                      }
                  }}
                  onClose={(e) => {
                    setIsCalOpen(false);
                  }}
                  onOpen={(e) => {
                    setIsCalOpen(true);
                  }}
                  error={false}
                  disableFuture
                />
              )}
            </>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </SingleDateTimeWrapper>
    </div>
  );
};

export default SingleStartEndDatePicker;
