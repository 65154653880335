import { Form, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './TrendFilter.scss';

interface ITrendFilterProps {
  trendFilterType?: string;
  setTrendValue?: number;
}

export const TrendFilter: React.FC<ITrendFilterProps> = (props) => {
  // Grabbing Users Temp Unit Preference to display in temp choices
  // let userPrefTemp = useSelector(() => state.userPrefs.temp);

  const trendChoices = [
    {
      value: 'a change of',
    },
    {
      value: 'an increase of',
    },
    {
      value: 'a decrease of',
    },
  ];

  const temperatureChoices = [
    {
      // value: `°${userPrefTemp}`,
      value: `°`,
      title: 'Fahrenheit',
    },
    {
      value: '%',
      title: 'Percentage',
    },
  ];

  const humidityChoices = [
    {
      value: 'RH',
      title: 'Real Humidity',
    },
    {
      value: '%',
      title: 'Percentage',
    },
  ];

  const [mappedChoices, setMappedChoices] = useState<any>();
  const [errorMsg, setErrorMsg] = useState<string>('');
  // const [value, setValue] = useState<number>();
  const trendFilterType = props.trendFilterType;

  useEffect(() => {
    trendFilterType.includes('Humidity')
      ? setMappedChoices(humidityChoices)
      : setMappedChoices(temperatureChoices);
    setErrorMsg('');
  }, [trendFilterType]);

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  return (
    <>
      <p>Trend is greater than</p>
      <Form className="trend-filter-container">
        <Row>
          <Col xs={6}>
            <Form.Group>
              <Form.Control as={'select'} defaultValue="0">
                {trendChoices.map((choice, index) => {
                  return (
                    <option value={index} key={index} title={choice.value}>
                      {choice.value}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col className="input-col">
            <Form.Group>
              <Form.Control
                id="filter-value"
                as={'input'}
                type="number"
                min="1"
                placeholder="Value"
                onPaste={preventPasteNegative}
                // onKeyUp={(event) => {
                //     props.setTrendValue(event.target.value);
                // }}
                onKeyPress={(event) => {
                  event.charCode == 45
                    ? (setErrorMsg('* Please enter a positive value'), event.preventDefault())
                    : setErrorMsg('');
                  event.charCode == 13 ? event.preventDefault() : null;
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Control as={'select'} defaultValue="0">
                {mappedChoices?.map((choice, index) => {
                  return (
                    <option value={index} key={index} title={choice.title}>
                      {choice.value}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <p id="trend-filter-error">{errorMsg}</p>
          </Col>
        </Row>
      </Form>
    </>
  );
};
