import { PROGRESS_END_PROGRESS, PROGRESS_SET_PROGRESS, PROGRESS_START_PROGRESS } from '../types';

const initState = {
  progress: 0, // 0-100
  inProgress: false, // is bar moving
};

function handleSetProgress(state, action) {
  const progress = (action.data || {}).progress;

  if (!progress) {
    console.error('There is no progress set');
  }

  return {
    ...state,
    progress: progress || state.progress,
  };
}

function handleStartProgress(state) {
  return {
    ...state,
    progress: 0,
    inProgress: true,
  };
}

function handleEndProgress(state) {
  return {
    ...state,
    inProgress: false,
  };
}

const actionHandler = {
  [PROGRESS_SET_PROGRESS]: handleSetProgress,
  [PROGRESS_START_PROGRESS]: handleStartProgress,
  [PROGRESS_END_PROGRESS]: handleEndProgress,
};

const progressBarReducer = (state = initState, action) => {
  const handlerFn = actionHandler[action.type];

  if (!handlerFn) return state;

  return handlerFn(state, action);
};

export default progressBarReducer;
