function Errors({ errors, setErrors }) {
  //Prevent showing the same error over again
  const errorFiltered = errors.filter((val, id, array) => array.indexOf(val) == id);

  return (
    <>
      {errorFiltered.map((error, index) => (
        <div className="sign-in-error-container">
          <p
            key={index}
            onClose={() => {
              const copy = [...errors];
              copy.splice(index, 1);
              setErrors(copy);
            }}
          >
            {error}
          </p>
        </div>
      ))}
    </>
  );
}

export default Errors;
