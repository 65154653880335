import { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDock } from '@react';
import { isReadOnly, isUser, isAdmin, isSystemAdmin } from '@utils';
import {
  Text,
  DockContainer,
  AddBeaconToAsset,
  PAGES,
  CreateAssetWorkflow,
  CreateTriggerWorkflow,
} from '@components';
import { motion } from 'framer-motion';
import BulkAssetUpload from '../../../../screens/bulkUpload/BulkAssetUpload';
import BulkAssetDelete from '../../../../screens/bulkDelete/BulkDelete';
import styled from 'styled-components';
import '../Header.scss';
import '../../../../scss/variables.scss';

const Item = styled(ListGroup.Item)`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  background-color: var(--dropdown);
  color: var(--dark);
`;

function CreateNewDropdown({ closeDropdown }) {
  const history = useHistory();
  const authorized = isAdmin() || isSystemAdmin();
  const { renderDock, closeDock } = useDock();
  const [attachBulkAsset, setAttachBulkAsset] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);

  if (isReadOnly()) return null;

  const renderAddBeaconToAsset = (page) => {
    closeDropdown();

    renderDock({
      render: () => <AddBeaconToAsset initPage={page} />,
    });
  };

  const renderCreateAsset = () => {
    closeDropdown();

    renderDock({
      render: () => (
        <DockContainer>
          <CreateAssetWorkflow onDone={closeDock} />
        </DockContainer>
      ),
    });
  };

  const navToMapsCreate = () => {
    history.push('/maps/create');
    closeDropdown();
  };

  const renderCreateTrigger = () => {
    closeDropdown();

    renderDock({
      render: () => (
        <DockContainer>
          <CreateTriggerWorkflow triggerId={0} onDone={closeDock} setRowSelected={setRowSelected} />
        </DockContainer>
      ),
    });
  };

  const renderBulkAsset = () => {
    closeDropdown();

    renderDock({
      render: () => (
        <DockContainer>
          <BulkAssetUpload onDone={closeDock} />
        </DockContainer>
      ),
    });
  };

  const renderBulkDelete = () => {
    closeDropdown();

    renderDock({
      render: () => (
        <DockContainer>
          <BulkAssetDelete onDone={closeDock} />
        </DockContainer>
      ),
    });
  };

  return (
    <>
      <ListGroup variant="flush" className="create-new-dropdown">
        <Item action onClick={() => renderAddBeaconToAsset(PAGES.BEACON_SEARCH)}>
          <motion.i className="sonarIcon sonarIcon-beacon fs-lg" />
          <Text>&emsp; Beacon</Text>
        </Item>

        <Item action onClick={renderCreateAsset}>
          <motion.i className="sonarIcon sonarIcon-asset fs-lg" />
          <Text>&emsp; Asset</Text>
        </Item>

        {authorized && (
          <Item action onClick={navToMapsCreate}>
            <motion.i className="sonarIcon sonarIcon-map fs-lg" />
            <Text>&emsp; Map</Text>
          </Item>
        )}

        <Item action onClick={renderCreateTrigger}>
          <motion.i className="sonarIcon sonarIcon-trigger fs-lg" />
          <Text>&emsp; Trigger</Text>
        </Item>
        <Item action onClick={renderBulkAsset}>
          <motion.i className="sonarIcon sonarIcon-folder-replace fs-lg" />
          <Text>&emsp; Bulk Upload</Text>
        </Item>
        {authorized && (
          <Item action onClick={renderBulkDelete}>
            <motion.i className="sonarIcon sonarIcon-trash fs-lg" />
            <Text>&emsp; Bulk Delete</Text>
          </Item>
        )}
      </ListGroup>
    </>
  );
}

export default CreateNewDropdown;
