const getHexColorByStatus = (onlineStatus) => {
  switch (onlineStatus) {
    case 0:
      return '#1EA18E';
    case 1:
      return '#C62E49';
    case 2:
      return '#ccc61c';
    default:
      return '#535353';
  }
};

const getStyleByOnlineStatus = (onlineStatus) => {
  return { color: getHexColorByStatus(onlineStatus), fontSize: '0.5em' };
};

/**
 * @desc Get online status int from ping timestamp
 * @param {Date} ping ping
 */
const getOnlineStatusFromPing = (ping) => {
  if (ping) {
    let now = new Date();
    let pingDate = new Date(ping);
    if (now - pingDate > 5 * 60 * 1000) {
      return 1;
    } else {
      return 0;
    }
  } else {
    return 2;
  }
};

const Helpers = {
  getHexColorByStatus,
  getStyleByOnlineStatus,
  getOnlineStatusFromPing,
};

export default Helpers;
