import { Link } from 'react-router-dom';
import Text from '../../../Text/Text';

function LastLocationCellRenderer({ data }) {
  const map = data?.mapName;
  const mapId = data?.mapId;
  const place = data?.placeName;
  const placeId = data?.placeId;

  // If no map or places logic to display '--'

  if (!map && !place) {
    return <Text>--</Text>;
  } else if (map && !place) {
    return (
      <>
        <span key={mapId}>
          <Link to={`/maps/${mapId}`} className="btn-link font-weight-light">
            <Text>{`${map}`}</Text>
          </Link>
          <Text>, </Text>
        </span>
        <Text>--</Text>
      </>
    );
  } else if (!map && place) {
    return (
      <>
        <Text>--, </Text>
        <span key={placeId}>
          <Link to={`/places/${placeId}`} className="btn-link font-weight-light">
            <Text>{`${place}`}</Text>
          </Link>
        </span>
      </>
    );
  } else {
    return (
      <>
        <span key={mapId}>
          <Link to={`/maps/${mapId}`} className="btn-link font-weight-light">
            <Text>{`${map}`}</Text>
          </Link>
          <Text>, </Text>
        </span>
        <span key={placeId}>
          <Link to={`/places/${placeId}`} className="btn-link font-weight-light">
            <Text>{`${place}`}</Text>
          </Link>
        </span>
      </>
    );
  }
}

export default LastLocationCellRenderer;
