import * as types from '../types';

const places = (
  state = {
    isLoading: false,
    items: [],
    pages: 0,
    total: 0,
    currentPage: 1,
    currentOrder: 'asc',
    currentPlace: { name: '' },
    sees: {
      items: [],
      pages: 0,
      total: 0,
      currentPage: 1,
    },
    onlinePlaces: 0,
    offlinePlaces: 0,
    provisionedPlaces: 0,
    totalPlaces: 0,
  },
  action,
) => {
  switch (action.type) {
    case types.PLACE_SUMMARY_REQUEST: // initiating a delete request
      return Object.assign({}, state, {
        isLoading: true,
      });

    case types.PLACE_SUMMARY_RECEIVED: // initiating a delete request
      return Object.assign({}, state, {
        isLoading: false,
        onlinePlaces: action.online,
        offlinePlaces: action.offline,
        provisionedPlaces: action.provisioned,
        totalPlaces: action.total,
      });

    case types.PLACE_DELETE_COMPLETED: // delete request has completed
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.PLACE_DELETE_STARTED: // initiating a delete request
      return Object.assign({}, state, {
        isLoading: true,
      });

    case types.PERSON_PATCH_COMPLETED: // update request has completed
      return Object.assign({}, state, {
        isLoading: false,
        currentPerson: action.person,
      });
    case types.PERSON_PATCH_STARTED: // update request completed
      return Object.assign({}, state, {
        isLoading: true,
      });

    case types.PLACE_REQUEST: // get single thing request
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PLACE_RECEIVED: // single thing request completed
      return {
        ...state,
        isLoading: false,
        currentPlace: action.place,
      };
    case types.PLACE_SEES_REQUEST: // get single thing request
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PLACE_POST_STARTED: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.PLACE_POST_COMPLETED: {
      return {
        ...state,
        currentPlace: {},
        sees: {},
      };
    }
    case types.PLACE_SEES_RECEIVED: // get single thing request
      return {
        ...state,
        isLoading: false,
        sees: {
          items: action.sees,
          pages: action.pages,
          total: action.total,
          currentPage: 1,
        },
      };
    case types.PLACES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        currentPlace: {},
      });
    case types.PLACES_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        items: action.places,
        pages: action.pages,
        total: action.total,
        currentPage: action.currentPage,
        currentOrder: action.currentOrder,
        currentPlace: {},
        sees: {},
      });
    case types.PLACES_REQUEST_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
};

export default places;
