// #region TYPES
export type App = {
  id: number;
  name: string;
};

export type AppReducerState = {
  app?: App;
};

type ToggleAppAction = {
  type: typeof APP_TOGGLE_APPLICATION;
  app: App;
};
// #endregion TYPES

const initState: AppReducerState = {
  app: undefined,
};

// #region TYPES
export const APP_TOGGLE_APPLICATION = 'APP_TOGGLE_APPLICATION';
// #endregion TYPES

// #region ACTIONS
export const appToggleApplication = (app: App): ToggleAppAction => ({
  type: APP_TOGGLE_APPLICATION,
  app,
});
// #endregion ACTIONS

// #region ACTION HANDLERS
const handleToggleApp = (state: AppReducerState, { app }: ToggleAppAction) => ({ ...state, app });
// #endregion ACTION HANDLERS

const actionHandler = {
  [APP_TOGGLE_APPLICATION]: handleToggleApp,
};

const reducer = (state = initState, action) => {
  const handlerFn = actionHandler[action.type];

  if (!handlerFn) return state;

  return handlerFn(state, action);
};

export default reducer;
