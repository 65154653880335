import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { Api } from '@services';
import * as L from 'leaflet';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { noop } from '../../../utils';
import { createBeaconsLayerGroup } from '../services/leafletLayerFactories/leafletBeaconFactory';
import { createGatewaysLayerGroup } from '../services/leafletLayerFactories/leafletGatewayFactory';
import {
  createLocationsLayerArray,
  createLocationsLayerGroup,
} from '../services/leafletLayerFactories/leafletLocationFactory';
import { createOutlierLayerGroup } from '../services/leafletLayerFactories/leafletOutliersFactory';
import { createZonesLayerGroup } from '../services/leafletLayerFactories/leafletZoneFactory';
import { createPath } from '../services/leafletLayerFactories/lineLayerFactory';

let locations;
let image;
let paths;

// TODO: REDO THIS ENTIRE FILE
class LocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      selectedIndex: null,
      imageBlob: null,
    };
  }

  componentDidMount() {
    let x = this.props.map.width || 1000;
    let y = this.props.map.height || 1000;
    let bounds = [
      [0, 0],
      [y, x],
    ];

    let map = L.map(this.props.id, {
      scrollWheelZoom: true,
      zoomControl: true,
      crs: L.CRS.Simple,
      maxBoundsViscosity: 0.8,
      maxBounds: bounds,
      attributionControl: false,
      minZoom: -5,
      zoomDelta: 0.1,
      zoomSnap: 0.1,
    });
    image = L.imageOverlay(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${this.props.map.id}/image`,
      bounds,
    );
    map.addLayer(image);
    map.fitBounds(bounds);
    this.setState({ map: map, loaded: true });
    this.drawCanvas(map);
  }

  componentDidUpdate(prevProps) {
    const { map } = this.state;

    let redrawCanvas = false;

    // check map instance equality
    if (prevProps.map !== this.props.map) redrawCanvas = true;

    // check for map ids
    if (this.props.map.id !== prevProps.map.id) {
      if (map.hasLayer(image)) {
        map.removeLayer(image);
        let x = this.props.map.width;
        let y = this.props.map.height;
        let bounds = [
          [0, 0],
          [y, x],
        ];
        image = L.imageOverlay(
          `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${this.props.map.id}/image`,
          bounds,
        );
        map.addLayer(image);
        map.fitBounds(bounds);
      }
      redrawCanvas = true;
    }

    // check for reading prop change
    if (prevProps.reading !== this.props.reading) redrawCanvas = true;

    // check for marker
    if (this.props.marker !== prevProps.marker) redrawCanvas = true;

    // check for changes
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) redrawCanvas = true;

    if (redrawCanvas) this.drawCanvas(map);
  }

  getImageUrl = () => {
    const { map } = this.props;
    const { imageUrl, preSignedImageUrl } = map;
    return preSignedImageUrl || imageUrl;
  };

  drawPaths(paths, map) {
    for (let i = 0; i < this.props.selectedIndex; i++) {
      if (this.props.map.id !== this.props.locations[i].mapId) {
        paths.forEach((value) => {
          value.removeFrom(map);
        });
      } else {
        paths[i].addTo(map);
      }
    }
  }

  drawCanvas(map) {
    if (!map) {
      return;
    }

    map.eachLayer(function (layer) {
      if (layer._url == null) {
        map.removeLayer(layer);
      }
    });

    const zones = createZonesLayerGroup(this.props.zones);
    zones.addTo(map);
    const gateways = createGatewaysLayerGroup(this.props.gateways, {
      onSetLayers: this.props.onSetLayers,
    });
    gateways.addTo(map);
    const beacons = createBeaconsLayerGroup(this.props.beacons, {
      reading: this.props.reading,
      onSetLayers: this.props.onSetLayers,
    });
    beacons.addTo(map);

    const outliers = createOutlierLayerGroup(this.props.outliers, {
      reading: this.props.reading,
      onSetLayers: this.props.onSetLayers,
    });
    outliers.addTo(map);

    // NOT THIS
    if (this.props.marker && this.props.marker.x) {
      let marker = new L.Marker([this.props.marker.y, this.props.marker.x]);
      marker.addTo(map);
    }

    if (this.props.static === true) {
      locations = createLocationsLayerGroup(this.props.locations);
      locations.addTo(map);
    }

    if (this.props.locations && this.props.locations.length >= 1) {
      if (this.props.playback) {
        if (this.props.selectedIndex) {
          // USER IS CONTROLLING WHAT THEY SEE AND THE PACE
          if (this.props.displayType === 'lines') {
            paths = createPath(this.props.locations);
            this.drawPaths(paths, map);
          } else {
            locations = createLocationsLayerArray(this.props.locations, {
              onSetLayers: this.props.onSetLocationLayers,
            });

            for (let i = 0; i < this.props.selectedIndex; i++) {
              if (this.props.locations[i].location.type === 0) {
                locations[i].addTo(map);
              }
            }
            for (let i = this.props.locations.length - 1; i < locations.length; i++) {
              locations[i].addTo(map);
            }
          }
        } else if (this.props.paused) {
          // USER IS CONTROLLING WHAT THEY SEE AND THE PACE
          if (this.props.displayType === 'lines') {
            this.drawPaths(this.props.sortedLocations, map);
          } else {
            locations = createLocationsLayerArray(this.props.locations, {
              onSetLayers: this.props.onSetLocationLayers,
            });
            for (let i = 0; i < this.props.selectedIndex; i++) {
              if (this.props.locations[i].location.type === 0) {
                locations[i].addTo(map);
              }
            }
            for (let i = this.props.locations.length - 1; i < locations.length; i++) {
              locations[i].addTo(map);
            }
          }
        } else {
          //TODO: What is this code doing??
          // BREADCRUMBS ARE IN AUTO PLAY MODE
          if (this.props.displayType === 'lines') {
            paths = createPath(this.props.locations);
            this.drawPaths(paths, map);
          } else {
            locations = createLocationsLayerArray(this.props.locations, {
              onSetLayers: this.props.onSetLocationLayers,
            });
          }
        }
      } else {
        // INITIAL RUN
        if (this.props.displayType === 'lines') {
          paths = createPath(this.props.locations);
          this.drawPaths(paths, map);
        } else {
          locations = createLocationsLayerGroup(this.props.locations, {
            mapId: this.props.map.id,
            onSetLayers: this.props.onSetLocationLayers,
          });
          locations.addTo(map);
        }
      }
    } else if (this.props.selectedIndex === 0) {
      if (this.props.displayType === 'lines') {
        this.drawPaths(this.props.sortedLocations, map);
      } else {
        locations = createLocationsLayerGroup(this.props.locations);
        locations.addTo(map);
      }
    }
  }

  render() {
    return <div id={this.props.id} style={{ height: this.props.height }} />;
  }
}

LocationMap.defaultProps = {
  id: 'map',
  onSetLayers: noop,
  onSetLocationLayers: noop,
};

LocationMap.propTypes = {
  id: PropTypes.string,
  map: PropTypes.object,
  locations: PropTypes.array,
  sortedLocations: PropTypes.array,
  zones: PropTypes.array,
  gateways: PropTypes.array,
  beacons: PropTypes.array,
  outliers: PropTypes.array,
  height: PropTypes.string,
  playback: PropTypes.bool,
  displayType: PropTypes.string,
  marker: PropTypes.object,
  reading: PropTypes.string,
  selectedIndex: PropTypes.number,
  pace: PropTypes.number,
  paused: PropTypes.bool,
  onSetLayers: PropTypes.func,
  onSetLocationLayers: PropTypes.func,
};

export default LocationMap;
