import { AbilityTriggerTypes } from '../enums/AbilityTriggerTypes';

interface AbilityUnit {
  key: string;
  text: string;
}

export interface Ability {
  id: number;
  name: string;
  icon: string;
  canTrigger: boolean;
  triggerType: AbilityTriggerTypes;
  units: AbilityUnit[];
}

export const Abilities = [
  // CreateAbility(1, 'Battery', 'battery', [CreateUnit("pct", "%")], true, AbilityTriggerTypes.FallsBelow),
  CreateAbility(2, 'Location', 'location'),
  CreateAbility(
    3,
    'Temperature',
    'thermometer',
    [CreateUnit('f', '°F'), CreateUnit('c', '°C')],
    true,
    AbilityTriggerTypes.Both,
  ),
  CreateAbility(4, 'Humidity', '', [CreateUnit('pct', '%')], true, AbilityTriggerTypes.Both),
  CreateAbility(
    5,
    'Pressure',
    '',
    [
      CreateUnit('pa', 'Pa'),
      CreateUnit('kpa', 'kPa'),
      CreateUnit('psi', 'psi'),
      CreateUnit('bar', 'bar'),
    ],
    true,
    AbilityTriggerTypes.Both,
  ),
  CreateAbility(6, 'Vibration', '', [CreateUnit('g', 'g')], true, AbilityTriggerTypes.Both),
  CreateAbility(
    7,
    'Frequency',
    '',
    [CreateUnit('hz', 'Hz'), CreateUnit('khz', 'kHz'), CreateUnit('mhz', 'MHz')],
    true,
    AbilityTriggerTypes.Both,
  ),
  CreateAbility(8, 'Voltage', '', [CreateUnit('v', 'V')], true, AbilityTriggerTypes.Both),
  // CreateAbility(9, 'Spo2', '', [CreateUnit("pct", "%")], true),
  // CreateAbility(10, 'Weight', '', [CreateUnit("lb", "lbs")], true),
  // CreateAbility(11, 'Blood Pressure', '', [CreateUnit("mmhg", "mmHg")], true),
  // CreateAbility(12, 'Ecg/Ekg', ''),
  // CreateAbility(13, 'HeartRate', '', [CreateUnit("bpm", "bpm")], true),
  // CreateAbility(14, 'Systolic', '', [CreateUnit("pct", "%")], true),
  // CreateAbility(15, 'Diastolic', '', [CreateUnit("pct", "%")], true),
  // CreateAbility(16, 'Moisture', '', [CreateUnit("pct", "%")], true, AbilityTriggerTypes.Both),
  CreateAbility(17, 'Light', '', [CreateUnit('pct', '%')], true, AbilityTriggerTypes.Both),
  CreateAbility(18, 'Tamper', '', [CreateUnit('pct', '%')], true, AbilityTriggerTypes.Toggle),
  CreateAbility(19, 'Amperage', '', [CreateUnit('amp', 'A')], true, AbilityTriggerTypes.Both),
  // CreateAbility(20, 'PressureDifferential', '', [CreateUnit("pct", "%")], true),
  CreateAbility(21, 'HealthCheck', '', [CreateUnit('pct', '%')], false),
  CreateAbility(22, 'GatewayHealthCheck', '', [CreateUnit('pct', '%')], false),
  CreateAbility(23, 'Fault', '', [CreateUnit('pct', '%')], true, AbilityTriggerTypes.Toggle),
  CreateAbility(24, 'Orientation', '', [CreateUnit('pct', '%')], true, AbilityTriggerTypes.Both),
  CreateAbility(25, 'Status', '', [CreateUnit('pct', '%')], true, AbilityTriggerTypes.Toggle),
  CreateAbility(42, 'Sound', '', [CreateUnit('dB', 'dB')], true, AbilityTriggerTypes.Both),
  CreateAbility(43, "TVOC", '', [CreateUnit('ppm', 'ppm')], true, AbilityTriggerTypes.Both),
  CreateAbility(44, 'IAQ', '', [CreateUnit('none', 'none')], true, AbilityTriggerTypes.Both)
];

function CreateUnit(key: string, text: string) {
  return {
    key: key,
    text: text,
  } as AbilityUnit;
}
function CreateAbility(
  id: number,
  name: string,
  icon: string,
  units: AbilityUnit[] = [CreateUnit('none', 'none')],
  canTrigger: boolean = false,
  triggerType: AbilityTriggerTypes = AbilityTriggerTypes.Both,
) {
  return {
    id: id,
    name: name,
    icon: icon,
    canTrigger: canTrigger,
    triggerType: triggerType,
    units: units,
  } as Ability;
}
