import './ScanIn.scss';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { noop } from '../../utils';

const schema = Yup.object().shape({
  scanName: Yup.string().min(1).required(),
});

function ScanIn({ onAddScanIn = noop }) {
  //useRef Hooks
  const scanNameRef = useRef(null);

  //useFormik Hook
  const formik = useFormik({
    initialValues: {
      scanName: '',
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      try {
        onAddScanIn({ value: values.scanName, notes: '' });
        resetForm({ values: '' });
        scanNameRef.current.focus();
      } catch (e) {
        console.log('ScanIn Errors - ', e);
      }
    },
  });

  // function ScanNotes() {

  //   return (
  //     <React.Fragment>
  //       <Form.Control
  //         as="textarea"
  //         placeholder="Enter optional notes about this scan."
  //         value={notes}
  //         onChange={(e) => setNotes(e.target.value)}
  //         rows={3}
  //       />

  //       <div className="d-flex flex-row justify-content-start">
  //         <Form.Check
  //           label="Keep Notes"
  //           checked={keepNotes}
  //           onChange={(e) => setKeepNotes(e.target.checked)}
  //         />
  //       </div>
  //     </React.Fragment>
  //   )
  // }

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className="d-flex flex-column justify-content-around"
      style={{ minHeight: 200 }}
    >
      <h1 className="scaninTitle">New Scan</h1>

      <Form.Control
        ref={scanNameRef}
        type="text"
        name="scanName"
        placeholder="Scan or Type Barcode"
        value={formik.values.scanName}
        autoComplete="off"
        isInvalid={formik.errors.scanName && formik.touched.scanName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Form.Control.Feedback type="invalid" style={{ textAlign: 'left' }}>
        Please scan or type a valid barcode.
      </Form.Control.Feedback>

      {/* <ScanNotes /> */}

      <div className="d-flex flex-row justify-content-center">
        <Button
          type="submit"
          variant="primary"
          className="next-btn"
          disabled={!isEmpty(formik.errors) || !formik.dirty}
          style={{ marginTop: 50, width: 200 }}
        >
          Submit
        </Button>
      </div>
    </Form>
  );
}

ScanIn.propTypes = {
  onAddScanIn: PropTypes.func.isRequired,
};

export default ScanIn;
