import './WelcomeBanner.scss';
import { useDock } from '@react';
import { UserSettingsContainer } from '@screens/userProfile';
import { isAdmin, isReadOnly, isSuperAdmin, isSystemAdmin, isUser } from '@utils';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { DockContainer, Text } from '../../components';
import { Api } from '../../services';

async function fetchUserData() {
  const { data } = await Api.get(`/users`);
  return data;
}

function WelcomeBanner() {
  const [showGreet, setShowGreet] = useState(true);
  const userRes = useQuery<any>('home-user', fetchUserData);
  const { renderDock, closeDock } = useDock();

  // Set Time out
  useEffect(() => {
    const timer = setTimeout(() => setShowGreet(false), 3000);
    return () => clearTimeout(timer);
  });

  const renderUserPreferences = () => {
    renderDock({
      render: () => (
        <DockContainer>
          <UserSettingsContainer onDone={closeDock} />
        </DockContainer>
      ),
    });
  };

  const getGreeting = () => {
    let date = new Date();
    let hrs = date.getHours();
    let greeting;
    if (hrs < 12) greeting = 'Good Morning';
    else if (hrs >= 12 && hrs <= 16) greeting = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24) greeting = 'Good Evening';
    return greeting;
  };

  if (userRes.isLoading) {
    return <div />;
  } else {
    return showGreet ? (
      <Card.Body className="greet d-flex flex-column justify-content-center align-items-center">
        <span className="fs-1x fw-bold" style={{ color: 'var(--primary)' }}>
          {getGreeting()}
          {userRes.data?.firstName !== undefined &&
          userRes.data?.firstName !== '' &&
          userRes.data?.firstName !== null &&
          userRes.data?.firstName?.charAt(0).toUpperCase() !== ' '
            ? ', ' + userRes.data?.firstName
            : ''}
        </span>
      </Card.Body>
    ) : (
      <Card.Body className="info d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="profile-pic">
            <span className="user-initials fs-3x">
              {userRes.data?.firstName !== undefined &&
              userRes.data?.firstName !== '' &&
              userRes.data?.firstName?.charAt(0).toUpperCase() !== ' '
                ? userRes.data?.firstName?.charAt(0).toUpperCase()
                : userRes.data?.email?.charAt(0).toUpperCase()}
            </span>
            <div
              className="edit-profile-pic d-flex flex-row align-items-center"
              onClick={() => renderUserPreferences()}
            >
              <i className={`sonarIcon sonarIcon-edit fs-sm`} style={{ color: 'var(--white)' }} />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-left" style={{ float: 'right' }}>
            <span className="fs-lg fw-bold" style={{ color: 'var(--primary)' }}>
              {getGreeting()}
              {userRes.data?.firstName !== undefined &&
              userRes.data?.firstName !== '' &&
              userRes.data?.firstName !== null &&
              userRes.data?.firstName?.charAt(0).toUpperCase() !== ' '
                ? ', ' + userRes.data?.firstName
                : ''}
            </span>
            <span className="fw-bold" style={{ color: 'var(--nav-text)' }}>
              {isSuperAdmin()
                ? 'Super Admin'
                : isUser()
                ? 'User'
                : isReadOnly()
                ? 'Read Only'
                : isSystemAdmin()
                ? 'System Admin'
                : isAdmin()
                ? 'Admin'
                : ''}
            </span>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="fs-md fw-bold" style={{ color: 'var(--dark)' }}>
            {new Date().toLocaleDateString('en-us', {
              weekday: 'long',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </Text>
        </div>
      </Card.Body>
    );
  }
}

export default WelcomeBanner;
