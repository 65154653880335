import { useEffect, useRef, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';

interface PlaceFormProps {
  isSubmitted: boolean;
  selectedMap: any[];
  selectedPlace: any[];
  hasMap: any;
  hasPlace: any;
  mapOptions: any[];
  placesOptions: any[];
  onPlaceFormChange: Function;
  disabled: boolean;
  mapIsNull: boolean;
}

export function PlaceForm({
  isSubmitted,
  selectedMap,
  selectedPlace,
  hasMap,
  hasPlace,
  onPlaceFormChange,
  disabled,
  mapOptions,
  placesOptions,
  mapIsNull,
}: PlaceFormProps) {
  const [mapIsDirty, setMapDirty] = useState<boolean>(false);
  const [placeIsDirty, setPlaceDirty] = useState<boolean>(false);
  const [curMap, setCurMap] = useState<any>([]);
  const [curPlace, setCurPlace] = useState<any>([]);

  const typeaheadPlace = useRef(null);

  useEffect(() => {
    setCurMap(selectedMap);
    setCurPlace(selectedPlace);
  }, [selectedMap, selectedPlace]);

  return (
    <>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="2">
          Map
          <span className="text-danger"> *</span>
        </Form.Label>
        <Col>
          <InputGroup>
            <Typeahead
              filterBy={['id', 'name']}
              {...(!hasMap && isSubmitted ? { className: 'is-invalid' } : null)}
              isInvalid={!hasMap && isSubmitted}
              isValid={hasMap && isSubmitted}
              highlightOnlyResult
              id="map-select"
              disabled={disabled}
              placeholder="Search for a Map..."
              onChange={(selected) => {
                setCurMap(selected);
                let value = selected[0]?.id;
                typeaheadPlace.current.clear();
                onPlaceFormChange(value, 'MapId');
              }}
              onBlur={() => setMapDirty(true)}
              labelKey={'name'}
              selected={curMap}
              options={
                mapOptions &&
                mapOptions.map((option) => {
                  return {
                    id: option.id.toString(),
                    name: option.name,
                  };
                })
              }
              renderMenuItemChildren={(option, { text }) => (
                <div title={option.name}>
                  <Highlighter search={text}>{option.name}</Highlighter>
                  <div>
                    <small style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Map ID: {option.id}</small>
                  </div>
                </div>
              )}
            />
            {mapIsDirty && mapIsNull === true ? (
              <div className="invalid-feedback">* Please select a Map</div>
            ) : null}
          </InputGroup>
        </Col>
      </Form.Group>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="2">
          Place
          <span className="text-danger"> *</span>
        </Form.Label>
        <Col>
          <InputGroup>
            <Typeahead
              ref={typeaheadPlace}
              filterBy={['id', 'name']}
              {...(!hasPlace && isSubmitted ? { className: 'is-invalid' } : null)}
              isInvalid={!hasPlace && isSubmitted}
              isValid={hasPlace && isSubmitted}
              highlightOnlyResult
              id="place-select"
              disabled={mapIsNull}
              placeholder="Search for a Place..."
              onChange={(selected) => {
                setCurPlace(selected);
                let value = selected[0]?.id;
                onPlaceFormChange(value, 'PlaceId');
              }}
              onBlur={() => setPlaceDirty(true)}
              labelKey="name"
              selected={curPlace}
              options={
                placesOptions &&
                placesOptions.map((option) => {
                  return {
                    id: option.id.toString(),
                    name: option.name,
                  };
                })
              }
              renderMenuItemChildren={(option, { text }) => (
                <div title={option.name}>
                  <Highlighter search={text}>{option.name}</Highlighter>
                  <div>
                    <small style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Place ID: {option.id}</small>
                  </div>
                </div>
              )}
            />
            {!hasPlace && isSubmitted ? (
              <div className="invalid-feedback">* Please select a Place</div>
            ) : null}
          </InputGroup>
        </Col>
      </Form.Group>
    </>
  );
}
