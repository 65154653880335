import '../AdvancedAsset/AdvancedAsset.scss';
import { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';

function Export({ filename, options, csvLoading, getExport, advancedAsset, addRun }) {
  const [exportData, setExportData] = useState<any>(null);
  const [createFile, setCreateFile] = useState<boolean>(false);
  const [extension, setExtension] = useState<string>('csv');

  useEffect(() => {
    const generateCsv = async () => {
      if (createFile && exportData) {
        // ADVANCED ASSETS: Generate Two Runs CSV/JSON only if "All Runs" is checked and the extension is CSV or JSON
        if (
          document.getElementById('all-runs')?.checked &&
          (extension === 'csv' || extension === 'json')
        ) {
          // First CSV/JSON FILE
          let href = URL.createObjectURL(exportData.firstRun.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${filename}_first_run.${extension}`); // or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          // Second CSV/JSON FILE
          let secondHref = URL.createObjectURL(exportData.secondRun.data);
          // create "a" HTML element with href to file & click
          const secondLink = document.createElement('a');
          secondLink.href = secondHref;
          secondLink.setAttribute('download', `${filename}_second_run.${extension}`);
          document.body.appendChild(secondLink);
          secondLink.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(secondLink);
          URL.revokeObjectURL(secondHref);
        } else {
          // Normal Export (only 1 file)
          let href = URL.createObjectURL(exportData.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${filename}_first_run.${extension}`); // or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }
      }
    };
    generateCsv();
  }, [exportData]);

  const setHeader = (option) => {
    switch (option) {
      case 'CSV':
        return 'text/csv';
      case 'JSON':
        return 'application/json';
      case 'GeoJSON':
        return 'application/geo+json';
      default:
        return 'text/csv';
    }
  };

  return (
    <DropdownButton
      className="float-end ignore-click"
      variant="secondary"
      title={csvLoading ? 'Loading' : 'Export'}
      disabled={csvLoading}
      id="Export"
    >
      {advancedAsset && !!addRun && (
        <Form.Check
          className="all-run-checkbox"
          defaultChecked={false}
          type="checkbox"
          id="all-runs"
          label="All Runs"
        />
      )}
      {options.map((option, index) => (
        <Dropdown.Item
          as="button"
          key={index}
          onClick={async () => {
            setCreateFile(true);
            setExtension(option.toLowerCase());
            const res = await getExport(setHeader(option));
            setExportData(res);
          }}
        >
          {option}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default Export;
