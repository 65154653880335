import * as L from 'leaflet';
import { noop } from '../../../../utils';
import {} from '../leaflet-icons';

const createOutlierMarker = (outlier, { reading }) => {
  if (!outlier.location) return null;

  // if (!reading) return null;
  // if (!outlier.outlier[reading] && outlier.outlier[reading]?.value !== 0) return null; // we want to display 0 values

  const markerColor = outlier.outlier[reading]?.activeTrigger ? 'red' : 'cadetblue';
  const value = Math.round(outlier.outlier[reading]?.value || Number.NaN);
  let marker = L.marker([outlier.location.y, outlier.location.x], {
    icon: L.AwesomeMarkers.icon({
      prefix: 'fa',
      icon: 'tags',
      markerColor,
      value: value + '' === 'NaN' ? '' : value + '',
    }),
    title: outlier.outlier.name,
  });

  marker.bindPopup(outlier.outlier.name);
  marker.outlierIndex = outlier.index;

  marker.on('mouseover', (e) => {
    e.data = outlier;
    e.marker = marker;
    const name = outlier.outlier.name.replace("'", '&#8217;');
    e.marker
      .bindPopup(`<br/><div class='d-flex flex-row align-items-center'><strong class='col-10'>${outlier.outlier.name}</strong> 
      <a
        class='btn-light d-flex justify-content-left align-items-center' href='#'
        onclick="CBCopy('${name}')"
      > 
        <i class="col-1 far fa-lg fa-copy" ></i> 
      <a>
      </div>`);
    if (outlier.onmouseover) outlier.onmouseover(e);
    if (outlier.autoPopup) marker.openPopup();
  });

  marker.on('mouseout', (e) => {
    e.data = outlier;
    e.marker = marker;
    if (outlier.onmouseout) outlier.onmouseout(e);
    if (outlier.autoPopup) marker.closePopup();
  });

  return marker;
};

const createOutlierLayers = (outlier, { reading }) => {
  const layers = [];
  if (outlier.isVisible) {
    let layer = createOutlierMarker(outlier, { reading });

    if (layer != null) {
      layer.data = outlier;
      layers.push(layer);
    }
  }

  return layers;
};

const createOutlierLayerGroup = (outliers, { reading = '', onSetLayers = noop } = {}) => {
  return new L.FeatureGroup(createOutlierLayerArray(outliers, { reading, onSetLayers }));
};

const createOutlierLayerArray = (outliers, { reading, onSetLayers }) => {
  if (!outliers) {
    return [];
  }

  const layers = [];
  for (let outlier of outliers) {
    const outlierLayers = createOutlierLayers(outlier, { reading, onSetLayers });
    onSetLayers(outlierLayers);

    for (let layer of outlierLayers) {
      layers.push(layer);
    }
  }

  return layers;
};

export { createOutlierLayerGroup };
