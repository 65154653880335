import './AdvancedAsset.scss';
import { AdvancedAssetAddRunPicker, AdvancedAssetStartPicker } from '@components';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { Api } from '../../services';
import { Export } from '../utils';
import CheckboxDropdown from './CheckboxDropdown';

const ChartFilters = ({
  title,
  chartBeacons,
  chartAbilities,
  chartHours,
  addRun,
  addRunEndDate,
  handleDropdownSelect,
  handleNormalizeCheck,
  handleAddRunChange,
  setGraphStartDate,
  setGraphEndDate,
  graphEndDate,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [assetName, setAssetName] = useState(title);
  const [csvLoading, setCsvLoading] = useState(false);
  const [currentBeacon, setCurrentBeacon] = useState(null);
  const [currentReading, setCurrentReading] = useState(null);
  const [advancedStartDate, setAdvancedStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const input = { label: 'Start Date' };

  const handleBeaconSelect = (id, selectAll) => {
    setCurrentBeacon(id);
    const updatedBeacons = chartBeacons.map((beacon) => {
      // if select all button is clicked return beacon
      if (selectAll) {
        return beacon;
      } else if (beacon.id === parseInt(id)) {
        return Object.assign({}, beacon, {
          selected: !beacon.selected,
        });
      } else {
        return beacon;
      }
    });

    handleDropdownSelect('Beacons', updatedBeacons);

    // const numBeacons = updatedBeacons.filter(beacon => beacon.selected).length;
    // if (numBeacons <= 20) {
    //     handleDropdownSelect("Beacons", updatedBeacons);
    // } else {
    //     setShowModal(true);
    // }
  };

  const handleAbilitySelect = (id, selectAll) => {
    setCurrentReading(id);
    const updatedAbilities = chartAbilities.map((ability) => {
      // if select all button is clicked return ability
      if (selectAll) {
        return ability;
      } else if (ability.id === parseInt(id)) {
        return Object.assign({}, ability, {
          selected: !ability.selected,
        });
      } else {
        return ability;
      }
    });

    handleDropdownSelect('Abilities', updatedAbilities);

    // const numAbilities = updatedAbilities.filter(ability => ability.selected).length;
    // if (numAbilities <= 10) {
    //     handleDropdownSelect("Abilities", updatedAbilities);
    // } else {
    //     setShowModal(true);
    // }
  };

  const handleHourSelect = (id) => {
    if (!advancedStartDate) {
      return;
    } else {
      const updatedHours = chartHours.map((hour) => {
        if (hour.id === parseInt(id)) {
          return Object.assign({}, hour, {
            selected: true,
          });
        } else {
          return Object.assign({}, hour, {
            selected: false,
          });
        }
      });
      let date = new Date(advancedStartDate);
      date.setHours(date.getHours() + id);
      setEndDate(date);
      handleDropdownSelect('Hours', updatedHours);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  // update Graph on Advanced Assets when hour gets changed
  useEffect(() => {
    setGraphEndDate(endDate);
  }, [endDate]);

  // update Graph on Advanced Assets when start date gets changed and then update the new end date
  useEffect(() => {
    setGraphStartDate(advancedStartDate);
    if (endDate) {
      const selectedHourId = chartHours.find((hour) => hour.selected)?.id;
      let date = new Date(advancedStartDate);
      date.setHours(date.getHours() + selectedHourId);
      setGraphEndDate(date);
    }
  }, [advancedStartDate]);

  async function getReadings(beacon, reading, advancedStartDate, graphEndDate, params?) {
    const startDateStr = advancedStartDate.toISOString();
    const endDateStr = graphEndDate.toISOString();

    let queryString = [];

    // Filter selected chartBeacons and chartAbilities
    const selectedBeacons = chartBeacons.filter((item) => item.selected);
    const selectedAbilities = chartAbilities.filter((item) => item.selected);

    // Build the query string for selected beacons and abilities
    for (let i = 0; i < selectedBeacons.length; i++) {
      for (let j = 0; j < selectedAbilities.length; j++) {
        let str = `id=${selectedBeacons[i].id}&abilityId=${selectedAbilities[j].id}&`;
        queryString.push(str);
      }
    }
    queryString = queryString.join('');

    if (document.getElementById('all-runs')?.checked) {
      const startAddRunStr = new Date(addRun).toISOString();
      const endAddRunStr = new Date(addRunEndDate).toISOString();
      try {
        const [firstRunData, secondRunData] = await Promise.all([
          Api.get(
            `beacons/readings/export?${queryString}start=${startDateStr}&end=${endDateStr}`,
            params,
          ),
          Api.get(
            `beacons/readings/export?${queryString}start=${startAddRunStr}&end=${endAddRunStr}`,
            params,
          ),
        ]);
        return { firstRun: firstRunData, secondRun: secondRunData };
      } catch (error) {
        console.log(error);
        return null; // or handle the error as needed
      }
    } else {
      try {
        const firstRunData = Api.get(
          `beacons/readings/export?${queryString}start=${startDateStr}&end=${endDateStr}`,
          params,
        );
        return firstRunData;
      } catch (error) {
        console.log(error);
        return null; // or handle the error as needed
      }
    }
  }

  const getExport = async (exportType) => {
    if (!currentBeacon || !currentReading || !advancedStartDate || !endDate) {
      return;
    }
    setCsvLoading(true);
    const params = {
      responseType: 'blob',
      headers: { Accept: exportType },
    };
    const res = await getReadings(
      currentBeacon,
      currentReading,
      advancedStartDate,
      graphEndDate,
      params,
    );
    setTimeout(() => {
      setCsvLoading(false);
    }, 1000);
    return res;
  };

  return (
    <>
      <Col className="d-flex flex-row align-items-end gap-5 advanced-graph-container">
        <div className="d-flex flex-row flex-wrap gap-4">
          <CheckboxDropdown title="Beacon" items={chartBeacons} handleSelect={handleBeaconSelect} />
          <CheckboxDropdown
            title="Ability"
            items={chartAbilities}
            handleSelect={handleAbilitySelect}
          />
          <AdvancedAssetStartPicker {...{ input, advancedStartDate, setAdvancedStartDate }} />
          <CheckboxDropdown title="Hours" items={chartHours} handleSelect={handleHourSelect} />
        </div>

        <Form.Check
          type="switch"
          id="normalize-checkbox"
          label="Normalized"
          onChange={handleNormalizeCheck}
          className="me-4"
        ></Form.Check>
        <Col xs="auto">
          <AdvancedAssetAddRunPicker addRun={addRun} handleAddRunChange={handleAddRunChange} />
        </Col>
        <div className="flex-grow-1">
          <Export
            filename={`${assetName}`}
            options={['CSV', 'JSON']}
            advancedAsset={true}
            addRun={addRun}
            csvLoading={csvLoading}
            getExport={async (option) => {
              return await getExport(option);
            }}
          />
        </div>
      </Col>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Limit Reached</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please unselect an option before adding another.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChartFilters;
