import { Component } from 'react';
import { connect } from 'react-redux';
import { dockCloseAction, dockOpenAction, dockRenderAction } from '../../redux';

function withDock(Comp) {
  class WithDock extends Component {
    render() {
      return (
        <Comp
          {...this.props}
          dock={this.props.dock}
          openDock={this.props.openDock}
          closeDock={this.props.closeDock}
          renderDock={this.props.renderDock}
        />
      );
    }
  }

  const mapStateToProps = ({ dock }) => {
    return {
      dock,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      openDock: ({ width, minWidth }) => dispatch(dockOpenAction({ width, minWidth })),
      closeDock: ({ persistRenderOnClose } = {}) =>
        dispatch(dockCloseAction({ persistRenderOnClose })),
      renderDock: ({ render, width, minWidth }) =>
        dispatch(dockRenderAction({ render, width, minWidth })),
    };
  };

  const ConnectedWithDock = connect(mapStateToProps, mapDispatchToProps)(WithDock);
  return ConnectedWithDock;
}

export default withDock;
