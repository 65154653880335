/* eslint-disable no-unused-vars */
// modules
import '../../../scss/_export.module.scss';
import '../components/editMap/editMap.scss';
import { Loading, Text } from '@components';
import PropTypes from 'prop-types';
import { Component, useContext } from 'react';
import {
  Accordion,
  AccordionContext,
  Button,
  Card,
  Col,
  Container,
  Row,
  useAccordionButton,
} from 'react-bootstrap';
import { Prompt } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Api } from '../../../services';
import { isReadOnly, isUser } from '../../../utils';
import BeaconList from '../components/editMap/beaconList';
import editMapFunctions from '../components/editMap/editMapFunctions';
import GatewayList from '../components/editMap/gatewayList';
import MapEdit from '../components/editMap/mapEdit';
import ZoneList from '../components/editMap/zoneList';
import mapFunctions from '../services/mapFunctions';
import toast from './../../../components/utils/toast';
import DeleteMapSwal from './../components/deleteMapSwal';
import MeasurementRow from './../components/editMap/measurementRow';

const MySwal = withReactContent(Swal);

const styles = {
  cardHeader: {
    cursor: 'pointer',
  },
  cardHeaderDisabled: {
    opacity: 0.6,
  },
  cardBody: {
    height: '62vh',
    overflowY: 'auto',
  },
  editButtons: {
    gap: '25px',
  },
};
let zones;

class MapEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      map: {},
      url: null,
      search: {
        gateways: [],
        beacons: [],
      },
      editingGateway: null,
      editingZone: null,
      editingBeacon: null,
      editingMeasurement: false,
      ready: false,
      maps: {
        editGateway: null,
        addGateway: null,
        editZone: null,
        addZone: null,
        editBeacon: null,
        addBeacon: null,
      },
      staticMeasurements: {
        pixels: 0,
        lengthInMeters: 0,
      },
      initPxPerMeter: null,
      isDirty: false,
    };
    this.handleIsDirty = this.handleIsDirty.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);

    this.getGateways = this.getGateways.bind(this);
    this.onGatewayEdit = this.onGatewayEdit.bind(this);
    this.onClickDeleteMap = this.onClickDeleteMap.bind(this);
    this.onGatewayAdd = this.onGatewayAdd.bind(this);
    this.onGatewayEditMap = this.onGatewayEditMap.bind(this);
    this.onGatewayEditMapCompleted = this.onGatewayEditMapCompleted.bind(this);

    this.onZoneEdit = this.onZoneEdit.bind(this);
    this.onZoneAdd = this.onZoneAdd.bind(this);
    this.onZoneEditMap = this.onZoneEditMap.bind(this);
    this.onZoneEditMapCompleted = this.onZoneEditMapCompleted.bind(this);

    this.getBeacons = this.getBeacons.bind(this);
    this.onBeaconEdit = this.onBeaconEdit.bind(this);
    this.onBeaconAdd = this.onBeaconAdd.bind(this);
    this.onBeaconEditMap = this.onBeaconEditMap.bind(this);
    this.onBeaconEditMapCompleted = this.onBeaconEditMapCompleted.bind(this);

    this.selectButton = this.selectButton.bind(this);
    this.changeVisibility = this.changeVisibility.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.selectNone = this.selectNone.bind(this);

    this.onRemove = this.onRemove.bind(this);
    this.onEditCompleted = this.onEditCompleted.bind(this);
    this.onAddCanceled = this.onAddCanceled.bind(this);
    this.onMeasurementAdd = this.onMeasurementAdd.bind(this);
    this.onMeasurementDistanceChange = this.onMeasurementDistanceChange.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      // Callback function that will be executed after isLoading is set to true
      this.getMap();
    });
  }

  getMap = async () => {
    const mapId = this.props.match.params.mapId;

    try {
      let { data: map } = await Api.get(`/legacy/maps/${mapId}`);
      map = mapFunctions.assignPropsToMap(map);

      const gateways = await Promise.all(
        map.gateways.map(async (gateway) => {
          const gw = await Api.get(`gateways/${Number(gateway.gatewayId)}`);
          return {
            ...gateway,
            ...{ gateway: { ...gateway.gateway, ...{ isOnline: Boolean(gw.data.isOnline) } } },
          };
        }),
      );

      map.gateways = gateways;

      let url = `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${mapId}/image`;
      this.setState({
        map,
        url: url,
        ready: true,
        initPxPerMeter: map.pixelsPerMeter,
      });

      // Set isLoading to false after the loading operation completes
      this.setState({ isLoading: false });
    } catch (error) {
      console.error(error);
      toast.error('Failed to load map data.');
      // Also set isLoading to false in case of an error
      this.setState({ isLoading: false });
    }
  };

  getGateways = async (filter) => {
    const { data } = await Api.get(`/gateways?top=1000000&filter=${filter}`);
    this.setState({
      search: {
        beacons: [],
        gateways: data.items,
      },
    });
  };

  getBeacons = async (filter) => {
    const { data } = await Api.get(`/beacons?top=1000000&filter=${filter}`);
    this.setState({
      search: {
        gateways: [],
        beacons: data.items,
      },
    });
  };

  handleIsDirty = () => {};

  handleUpdate = async () => {
    const { map } = this.state;
    let deleteZones = [];

    this.state.map.zones.filter((obj, i) => {
      if (obj.boundary === null) {
        deleteZones.push(i);
        return obj;
      }
      return null;
    });
    for (let i = deleteZones.length - 1; i >= 0; i--) {
      this.state.map.zones.splice(deleteZones[i], 1);
    }
    if (
      this.state.staticMeasurements.pixels > 0 &&
      this.state.staticMeasurements.lengthInMeters > 0
    ) {
      let x = this.state.map;
      x.pixelsPerMeter = this.getPxPerMeter();
      this.setState({ map: x });
    }

    try {
      await Api.put(`/legacy/maps/${map.id}`, this.state.map);
      toast.success('Map changes saved!');
      this.setState({ ...this.state, isDirty: false });
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit update, please try again.');
    }
  };

  changeVisibility = (source, field, id) => {
    let map = this.state.map;
    map[source] = map[source].map((i) => {
      if (i[field] === id) {
        i.isVisible = !i.isVisible;
      }
      return i;
    });

    this.setState({ map: map });
  };

  selectAll = (source) => {
    let map = this.state.map;
    map[source] = map[source].map((i) => {
      i.isVisible = true;
      return i;
    });
    this.setState({ map: map });
  };

  selectNone = (source) => {
    let map = this.state.map;
    map[source] = map[source].map((i) => {
      i.isVisible = false;
      return i;
    });
    this.setState({ map: map });
  };

  selectButton = (map, source) => {
    return editMapFunctions.selectAllOrNoneButton(map, source);
  };

  onRemove = (source, index) => {
    let map = this.state.map;
    map = editMapFunctions.remove(map, source, index);
    this.setState({ map: map, isDirty: true });
  };

  onGatewayAdd = () => {
    const map = this.state.map;
    const gateway = editMapFunctions.create.gateway(this.state.map.gateways.length + 1);
    map.gateways.unshift(gateway);
    this.setState({
      map: map,
      editingGateway: gateway,
      isDirty: true,
    });
  };

  onGatewayEdit = (mapGateway) => {
    this.setState({
      editingGateway: mapGateway,
      isDirty: true,
    });
  };

  onAddCanceled = (source, index) => {
    let map = this.state.map;
    map = editMapFunctions.removeNew(this.state.map, source, index);
    this.setState({
      map: map,
      editingGateway: null,
      editingBeacon: null,
      editingZone: null,
      addBeacon: null,
      editBeacon: null,
      isDirty: true,
    });
    // Call clearTemporaryMarkers on MapEdit component
    if (this.child) {
      this.child.clearTemporaryMarkers();
    }
  };

  onEditCompleted = async (source, item) => {
    let map = editMapFunctions.replace(this.state.map, source, item);
    this.setState({
      map: map,
      editingGateway: null,
      editingBeacon: null,
      editingZone: null,
      addBeacon: null,
      editBeacon: null,
      isDirty: true,
    });
    // Call clearTemporaryMarkers on MapEdit component
    if (this.child) {
      this.child.clearTemporaryMarkers();
    }
  };

  onGatewayEditMap = (gateway) => {
    if (gateway.location) {
      this.setState({
        maps: {
          addGateway: null,
          editGateway: gateway.index,
          addZone: false,
          editZone: null,
          addBeacon: null,
          editBeacon: null,
        },
        isDirty: true,
      });
      return;
    }

    this.setState({
      maps: {
        addGateway: gateway.index,
        editGateway: null,
        addZone: false,
        editZone: null,
        addBeacon: null,
        editBeacon: null,
      },
      isDirty: true,
    });
  };

  onGatewayEditMapCompleted = (gatewayIndex, location) => {
    let map = this.state.map;
    map = editMapFunctions.setGatewayLocation(map, gatewayIndex, location);

    this.setState({
      map,
      maps: {
        addGateway: null,
        editGateway: null,
        addZone: null,
        editZone: null,
        addBeacon: null,
        editBeacon: null,
      },
      isDirty: true,
    });
  };

  callZoneSaveChild = () => {
    this.child.saveChangesHandleZoneSave();
  };

  onZoneAdd = () => {
    const map = this.state.map;
    const zone = editMapFunctions.create.zone(this.state.map.zones.length);
    map.zones.unshift(zone);

    this.setState({
      map: map,
      editingZone: zone,
      isDirty: true,
    });
  };

  onZoneEdit = (zone) => {
    this.setState({
      editingZone: zone,
      isDirty: true,
    });
  };

  onZoneEditMap = (zone) => {
    if (zone.boundary && zone.boundary.length > 0) {
      this.setState({
        maps: {
          addGateway: null,
          editGateway: null,
          addZone: null,
          editZone: zone.index,
          addBeacon: null,
          editBeacon: null,
        },
        isDirty: true,
      });
      return;
    }

    this.setState({
      maps: {
        addGateway: null,
        editGateway: null,
        addZone: zone.index,
        editZone: null,
        addBeacon: null,
        editBeacon: null,
      },
      isDirty: false,
    });
  };

  onZoneEditMapCompleted = (zoneIndex, boundary) => {
    let map = this.state.map;
    map = editMapFunctions.setZoneBoundary(map, zoneIndex, boundary);
    this.setState({
      map,
      maps: {
        addGateway: null,
        editGateway: null,
        addZone: null,
        editZone: null,
        addBeacon: null,
        editBeacon: null,
      },
      isDirty: true,
    });
  };

  onBeaconAdd = () => {
    const map = this.state.map;
    const beacon = editMapFunctions.create.beacon(
      this.state.map.beacons ? this.state.map.beacons.length : 0,
    );
    map.beacons.unshift(beacon);

    this.setState({
      map: map,
      editingBeacon: beacon,
      isDirty: true,
    });
  };

  onMeasurementAdd = () => {
    this.setState({
      editingMeasurement: !this.state.editingMeasurement,
      isDirty: true,
    });
  };

  onMeasurementAdded = (distance) => {
    let x = this.state.staticMeasurements;
    x.pixels = distance;
    this.setState({
      staticMeasurements: x,
      editingMeasurement: !this.state.editingMeasurement,
      isDirty: true,
    });
  };

  onMeasurementDistanceChange = (distance) => {
    let x = this.state.staticMeasurements;
    x.lengthInMeters = distance;
    this.setState({
      staticMeasurements: x,
      isDirty: true,
    });
  };

  onBeaconEdit = (beacon) => {
    this.setState({
      editingBeacon: beacon,
      isDirty: true,
    });
  };

  onBeaconEditMap = (beacon) => {
    if (beacon.location) {
      this.setState({
        maps: {
          addGateway: null,
          editGateway: null,
          addZone: null,
          editZone: null,
          addBeacon: null,
          editBeacon: beacon.index,
        },
        isDirty: true,
      });
      return;
    }

    this.setState({
      maps: {
        addGateway: null,
        editGateway: null,
        addZone: null,
        editZone: null,
        addBeacon: beacon.index,
        editBeacon: null,
      },
      isDirty: false,
    });
  };

  onBeaconEditMapCompleted = (beaconIndex, location) => {
    let map = this.state.map;
    map = editMapFunctions.setBeaconLocation(map, beaconIndex, location);
    this.setState({
      map,
      maps: {
        addGateway: null,
        editGateway: null,
        addZone: null,
        editZone: null,
        addBeacon: null,
        editBeacon: null,
      },
      isDirty: true,
    });
  };

  onClickDeleteMap = () => {
    //sweetalert
    MySwal.fire({
      allowOutsideClick: false,
      showConfirmButton: false,
      html: <DeleteMapSwal mapName={this.state.map.name} mapId={this.state.map.id} />,
    });
  };

  getPxPerMeter = () => {
    const { staticMeasurements } = this.state;
    if (!staticMeasurements.pixels && staticMeasurements.lengthInMeters) return 0;

    return Math.floor(
      Number(staticMeasurements.pixels) / Number(staticMeasurements.lengthInMeters),
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  render() {
    const isAccordionDisabled =
      !!this.state.editingGateway || !!this.state.editingBeacon || !!this.state.editingZone;

    return (
      <>
        {this.state.isLoading ? (
          <div className="loading-wrap-edit-map">
            <Loading />
          </div>
        ) : (
          <div>
            <Prompt
              message={(location) => {
                if (this.state.isDirty === true) {
                  return location.onbeforeunload
                    ? true
                    : 'You have unsaved changes. Are you sure you want to leave this page?';
                }
              }}
            />
            <Text as="h1" style={{ color: 'var(--primary)' }}>
              {this.state.map.name}
            </Text>
            {this.state.map.gateways ? (
              <div className="row">
                <div className="col-xl-4 col-lg-12 mt-2">
                  <Accordion defaultActiveKey="gateways">
                    {/* Gateways */}
                    <Card id="gateway-card">
                      <CustomAccordionToggle
                        eventKey="gateways"
                        text="Gateways"
                        disabled={isAccordionDisabled}
                      />
                      <Accordion.Collapse eventKey="gateways">
                        <Card.Body style={styles.cardBody}>
                          <GatewayList
                            gateways={this.state.map.gateways}
                            onSelect={this.changeVisibility}
                            onAdd={this.onGatewayAdd}
                            onEdit={this.onGatewayEdit}
                            exceptIds={this.state.map.gateways.map((g) => g.gatewayId)}
                            searchItems={this.state.search.gateways}
                            onSearchFilterChanged={this.getGateways}
                            editing={this.state.editingGateway}
                            onEditMap={this.onGatewayEditMap}
                            onRemove={this.onRemove}
                            onEditCompleted={this.onEditCompleted}
                            onAddCanceled={this.onAddCanceled}
                            isDirty={this.state.isDirty}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    {/* Zones */}
                    <Card id="places-card">
                      <CustomAccordionToggle
                        eventKey="places"
                        text="Places"
                        disabled={isAccordionDisabled}
                      />
                      <Accordion.Collapse eventKey="places">
                        <Card.Body style={styles.cardBody}>
                          <ZoneList
                            zones={this.state.map.zones}
                            onAdd={this.onZoneAdd}
                            onEdit={this.onZoneEdit}
                            editing={this.state.editingZone}
                            onEditMap={this.onZoneEditMap}
                            onSelect={this.changeVisibility}
                            onRemove={this.onRemove}
                            onEditCompleted={this.onEditCompleted}
                            onAddCanceled={this.onAddCanceled}
                            isDirty={this.state.isDirty}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    {/* Beacons */}
                    <Card id="beacon-card">
                      <CustomAccordionToggle
                        eventKey="beacons"
                        text="Beacons"
                        disabled={isAccordionDisabled}
                      />
                      <Accordion.Collapse eventKey="beacons">
                        <Card.Body style={styles.cardBody}>
                          <BeaconList
                            beacons={this.state.map.beacons}
                            onSelect={this.changeVisibility}
                            onAdd={this.onBeaconAdd}
                            onEdit={this.onBeaconEdit}
                            excludedSearchItems={this.state.map.beacons.map((b) => b.beaconId)}
                            searchItems={this.state.search.beacons}
                            onSearchFilterChanged={this.getBeacons}
                            editing={this.state.editingBeacon}
                            onEditMap={this.onBeaconEditMap}
                            onRemove={this.onRemove}
                            onEditCompleted={this.onEditCompleted}
                            onAddCanceled={this.onAddCanceled}
                            isDirty={this.state.isDirty}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    {/* Static Measurement */}
                    <Card id="measurement-card">
                      <CustomAccordionToggle
                        eventKey="static"
                        text="Static Measurement"
                        disabled={isAccordionDisabled}
                      />
                      <Accordion.Collapse eventKey="static">
                        <Card.Body style={styles.cardBody}>
                          <MeasurementRow
                            startMeasurement={this.onMeasurementAdd}
                            changedDistance={this.onMeasurementDistanceChange}
                            pxPerMeter={this.getPxPerMeter()}
                            initPxPerMeter={this.state.initPxPerMeter}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>

                <div className="col-xl-8 col-lg-12">
                  <Container>
                    <Row>
                      <Col className="d-flex save-delete-map-container" style={styles.editButtons}>
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              this.handleUpdate();
                              this.callZoneSaveChild();
                            }}
                            className="btn btn-primary mb-3"
                            disabled={
                              this.state.editingGateway
                                ? true
                                : this.state.editingBeacon
                                ? true
                                : this.state.editingZone
                                ? true
                                : this.state.isDirty
                                ? false
                                : true
                            }
                          >
                            Save Changes
                          </button>
                        </div>
                        {isReadOnly() === false && isUser() === false && (
                          <Button
                            variant="danger"
                            onClick={this.onClickDeleteMap}
                            className="btn btn-primary mb-3"
                          >
                            Delete Map
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Container>

                  <div className="card">
                    <div className="card-body">
                      <MapEdit
                        map={this.state.map}
                        mapURL={this.state.url}
                        updateMap={this.updateMap}
                        editGateway={this.state.maps.editGateway}
                        addGateway={this.state.maps.addGateway}
                        onGatewayChanged={this.onGatewayEditMapCompleted}
                        editZone={this.state.maps.editZone}
                        addZone={this.state.maps.addZone}
                        onZoneAdded={this.onZoneAddToMapCompleted}
                        onZoneChanged={this.onZoneEditMapCompleted}
                        editBeacon={this.state.maps.editBeacon}
                        addBeacon={this.state.maps.addBeacon}
                        onBeaconChanged={this.onBeaconEditMapCompleted}
                        addMeasure={this.state.editingMeasurement}
                        onMeasureAdded={this.onMeasurementAdded}
                        isEditingGateway={this.state.editingGateway}
                        isEditingBeacon={this.state.editingBeacon}
                        ref={(cd) => (this.child = cd)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        )}
      </>
    );
  }
}

MapEditContainer.propTypes = {
  match: PropTypes.object,
};

function CustomAccordionToggle({ text, eventKey, disabled }) {
  const currEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey);

  const style = disabled ? styles.cardHeaderDisabled : styles.cardHeader;

  return (
    <>
      <Card.Header style={style} onClick={disabled ? undefined : decoratedOnClick}>
        {text}
      </Card.Header>
    </>
  );
}

export default MapEditContainer;
