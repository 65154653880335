import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import KeepTriggers from './ReassignTriggers/KeepTriggers';
import DeleteTriggers from './ReassignTriggers/DeleteTriggers';
import ReassignTriggers from './ReassignTriggers/ReassignTriggers';

const Question = (props) => {
  const { goBack, beacon, asset, onNoTriggers } = props;
  const [selected, setSelected] = useState(null);
  const [currentScreen, setCurrentScreen] = useState('init');
  const [triggerList, setTriggerList] = useState([]);
  useEffect(() => {
    let array = [];
    asset?.beacons?.forEach((aBeacon) => {
      if (aBeacon.triggerConditions) {
        aBeacon.triggerConditions.forEach((tc) => {
          if (!array.includes(tc.triggerName)) {
            array.push(tc.triggerName);
          }
        });
      }
    });
    if (array.length) {
      setTriggerList(array);
    } else {
      onNoTriggers();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {currentScreen === 'init' ? (
        <div className="text-center">
          you have {triggerList.length} triggers associated with{' '}
          <span className="text-primary">{beacon.name}</span>. Would you like to keep, delete or
          reassign these triggers?
          <div>
            <Button
              variant={selected === 'keep' ? 'primary' : 'secondary'}
              className="my-3 w-50"
              onClick={() => setSelected('keep')}
            >
              Keep
            </Button>
          </div>
          <div>
            <Button
              variant={selected === 'delete' ? 'primary' : 'secondary'}
              className="my-3 w-50"
              onClick={() => setSelected('delete')}
            >
              Delete
            </Button>
          </div>
          <div>
            <Button
              variant={selected === 'reassign' ? 'primary' : 'secondary'}
              className="my-3 w-50"
              onClick={() => setSelected('reassign')}
            >
              Re-Assign
            </Button>{' '}
          </div>
          <div className="row mt-5">
            <div className="col">
              <Button
                variant="primary"
                className="my-3"
                onClick={() => {
                  setSelected(null);
                  goBack();
                }}
              >
                Back
              </Button>
            </div>
            <div className="col">
              <Button
                variant="primary"
                className="my-3"
                onClick={() => setCurrentScreen(selected ?? 'init')}
              >
                Next
              </Button>{' '}
            </div>
          </div>
        </div>
      ) : (
        <div>
          {Boolean(currentScreen === 'keep') && (
            <KeepTriggers beacon={beacon} asset={asset} triggerList={triggerList} />
          )}
          {Boolean(currentScreen === 'delete') && (
            <div className="text-center">
              <DeleteTriggers beacon={beacon} asset={asset} />
            </div>
          )}
          {Boolean(currentScreen === 'reassign') && (
            <div className="text-center">
              <ReassignTriggers beacon={beacon} asset={asset} />
            </div>
          )}
          <Button onClick={() => setCurrentScreen('init')}>Back</Button>
        </div>
      )}
    </>
  );
};

export default Question;
