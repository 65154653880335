import './../../../scss/comps/Navigation.scss';
import './../../../scss/variables.scss';
import { Text } from '@components';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { store } from '../../../redux';
import { isAdmin, isLegacyAuthEnabled, isSystemAdmin } from '../../../utils';

function Navigation() {
  const { navigation } = store.getState();
  const [systemAdminActive, setSystemAdminActive] = useState(false);

  const isActive = (pathname) => {
    return new RegExp(pathname).test(window.location.pathname);
  };

  const toggleSystemAdminSubMenu = () => {
    setSystemAdminActive(!systemAdminActive);
  };

  // Admin only links
  const renderAdminLinks = () => {
    if (!isAdmin()) return null;

    if (!isLegacyAuthEnabled()) return null;

    return (
      <Fragment>
        <NavLink
          iconName="users"
          text="User Management"
          to="/admin/user-management"
          isActive={isActive('/admin/user-management')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
      </Fragment>
    );
  };

  // System Admin Only Links
  const renderSystemAdminLinks = () => {
    if (!isSystemAdmin()) return null;

    if (!isLegacyAuthEnabled()) return null;

    return (
      <Fragment>
        <NavLink
          text="Admin Portal"
          iconName="user-c-frame-2"
          toggleIcon="down-arrow"
          to="/admin/app"
          isActive={isActive('/admin/app') || isActive('/admin/users')}
          togglePortalLinks={toggleSystemAdminSubMenu}
          systemAdminActive={systemAdminActive}
        />
        {systemAdminActive && (
          <>
            <NavLink
              text="Apps"
              iconName="app-store"
              to="/admin/app"
              isActive={isActive('/admin/app')}
              children={true}
            />
            <NavLink
              text="Users"
              iconName="users"
              to="/admin/users"
              isActive={isActive('/admin/users')}
              children={true}
            />
          </>
        )}
      </Fragment>
    );
  };
  // #endregion HELPER FUNCTIONS

  return (
    <nav data-testid="main-nav" className="sidenav" style={{ width: navigation.navWidth }}>
      <div className="nav-links">
        <NavLink
          iconName="dashboard"
          text="Dashboard"
          to="/dashboard"
          isActive={isActive('/dashboard')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="trigger"
          text="Triggers"
          to="/triggers"
          isActive={isActive('/triggers')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="map"
          text="Maps"
          to="/maps"
          isActive={
            isActive('/assets') && isActive('/maps') ? false : isActive('/maps') ? true : false
          }
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="asset"
          text="Assets"
          to="/assets"
          isActive={isActive('/assets')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="place"
          text="Places"
          to="/places"
          isActive={isActive('/places')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        <NavLink
          iconName="device"
          text="Device Management"
          to="/admin/device-management/Gateways"
          isActive={isActive('/admin/device-management')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        {false && (
          <NavLink
            iconName="fas fa-map-pin"
            text="Asset"
            to="/reports/assets"
            isActive={isActive('/reports/assets')}
            hideAdminPortalChildren={setSystemAdminActive}
          />
        )}
        <NavLink
          iconName="analytics"
          text="Analytics"
          to="/analytics/overview"
          isActive={isActive('/analytics')}
          hideAdminPortalChildren={setSystemAdminActive}
        />
        {/* Admin */}
        {renderAdminLinks()}
        {/* System Admin */}
        {renderSystemAdminLinks()}
      </div>
    </nav>
  );
}

// #region HELPER COMPS
function NavLinkHeader({ text }) {
  return <li className="nav-header">{text}</li>;
}

NavLinkHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

function NavLink({
  iconName,
  text,
  to,
  isAdminOnly,
  isActive,
  envRegexString,
  togglePortalLinks,
  hideAdminPortalChildren,
  toggleIcon,
  children,
  systemAdminActive,
}) {
  const history = useHistory();
  const { navigation } = store.getState();
  const [hovered, setHovered] = useState(false);

  if (!new RegExp(envRegexString).test(window._sonar_env.REACT_APP_ENV)) return null;

  if (isAdminOnly && !isAdmin()) return null;

  const linkClassName = classnames('menu no-arrow', { open: isActive });

  //Allow page refresh of the same location
  const refreshPage = () => {
    window.location.pathname == to && window.location.reload();
  };

  // Admin Portal Child Links
  if (children) {
    return (
      <Button
        className={'d-flex align-items-center justify-content-right mt-1'}
        variant={'light'}
        style={{
          width: '100%',
          paddingLeft: '0',
          paddingRight: '0',
          paddingTop: '10px',
          paddingBottom: '10px',
          float: 'right',
        }}
        onClick={() => {
          history.push(to);
        }}
      >
        <div
          className="icon-wrap"
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: isActive ? 'var(--primary)' : 'transparent',
            borderRadius: '60px',
            padding: '0.5em 0.5em',
            margin: '0 20px 0 20px',
            textAlign: 'right',
          }}
        >
          <i
            className={`sonarIcon sonarIcon-${iconName} fs-sm `}
            style={{
              color: isActive ? 'var(--white)' : 'var(--nav-text)',
              cursor: 'pointer',
              fontWeight: 'normal',
            }}
            disabled={false}
          />
        </div>
        <Text
          style={{
            color: isActive ? 'var(--primary)' : 'var(--dark)',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </Text>
      </Button>
    );
  } else if (text === 'Admin Portal') {
    return (
      <Button
        className={'d-flex align-items-center justify-content-left mt-3'}
        variant={'light'}
        style={{
          width: '100%',
          paddingLeft: '0',
          paddingRight: '0',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
        onClick={() => {
          togglePortalLinks();
          history.push(to);
        }}
        onMouseEnter={() => {
          // Add mouse enter event handler
          setHovered(true);
        }}
        onMouseLeave={() => {
          // Add mouse leave event handler
          setHovered(false);
        }}
      >
        <div
          className="icon-wrap"
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: isActive ? 'var(--primary)' : 'transparent',
            borderRadius: '60px',
            padding: '0.5em 0.5em',
            margin: '0 20px 0 20px',
            textAlign: 'right',
          }}
        >
          <i
            className={`sonarIcon sonarIcon-${iconName} fs-md `}
            style={{
              color: isActive ? 'var(--white)' : 'var(--nav-text)',
              cursor: 'pointer',
              fontWeight: 'normal',
            }}
            disabled={false}
          />
        </div>
        <Text
          style={{
            color: isActive ? 'var(--primary)' : 'var(--dark)',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </Text>
        <div
          className="icon-wrap"
          style={{
            display: 'flex',
            alignItems: 'center',

            borderRadius: '60px',
            padding: '0.5em 0.5em',
            margin: '0 20px 0 20px',
            textAlign: 'right',
          }}
        >
          <i
            className={`sonarIcon sonarIcon-${toggleIcon} fs-sm `}
            style={{
              color: systemAdminActive
                ? 'var(--primary)'
                : hovered
                ? 'var(--nav-text)'
                : 'transparent', // Adjust icon color
              cursor: 'pointer',
              fontWeight: 'normal',
              transform: systemAdminActive ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: '0.2s',
            }}
            disabled={false}
          />
        </div>
      </Button>
    );
  } else {
    return (
      <Button
        className={'d-flex align-items-center justify-content-left mt-3'}
        variant={'light'}
        style={{
          width: '100%',
          paddingLeft: '0',
          paddingRight: '0',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
        onClick={() => {
          hideAdminPortalChildren(false);
          refreshPage();
          history.push(to);
        }}
      >
        <div
          className="icon-wrap"
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: isActive ? 'var(--primary)' : 'transparent',
            borderRadius: '60px',
            padding: '0.5em 0.5em',
            margin: '0 20px 0 20px',
            textAlign: 'right',
          }}
        >
          <i
            className={`sonarIcon sonarIcon-${iconName} fs-md `}
            style={{
              color: isActive ? 'var(--white)' : 'var(--nav-text)',
              cursor: 'pointer',
              fontWeight: 'normal',
            }}
            disabled={false}
          />
        </div>
        <Text
          style={{
            color: isActive ? 'var(--primary)' : 'var(--dark)',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </Text>
      </Button>
    );
  }
}

NavLink.defaultProps = {
  isAdminOnly: false,
  isActive: false,
  isDevOnly: false,
  envRegexString: '(prod|stage|dev|test|local)',
};

NavLink.propTypes = {
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,

  // defaults
  isAdminOnly: PropTypes.bool,
  isActive: PropTypes.bool,
  isDevOnly: PropTypes.bool,
  envRegexString: PropTypes.string,
};
// #endregion HELPER COMPS

export default Navigation;
