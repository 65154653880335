export const chartColors = [
  '#0048ff',
  '#f178b6',
  '#e7bd54',
  '#008392',
  '#002ecb',
  '#ff6f00',
  '#b7f178',
  '#9454e7',
  '#922900',
  '#cb5b00',
  '#ffee00',
  '#78f1ef',
  '#e754a7',
  '#927200',
  '#cbc100',
  '#dd00ff',
  '#f19678',
  '#a7e754',
  '#500092',
  '#b300cb',
];

export const chartLines = ['solid', 'dot', 'dash', 'longdash', 'dashdot'];
