import Axios from 'axios';
import { IV2FilterPayload } from 'components/AssetFilter/functions/V2FilterPayload';
import { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { assetCSVFormat } from '../../components/grids/AgGrid/FormatCSV';
import { PagedQueryParams, ServerSideGrid } from '../../components/grids/AgGrid/ServerSideGrid';
import {
  BeaconReadingsCellRenderer,
  LinkCellRenderer,
  OnlineStatusCellRenderer,
  PlacesCellRenderer,
} from '../../components/grids/index';
import { AssetListModel } from '../../models/AssetListModel';
import { PagedModel } from '../../models/PagedModel';
import { Api } from '../../services';
import { assetColumnDefs } from './columnDefs';

export function AssetListContainer<T>() {
  const [rowData, setRowData] = useState<any>(new PagedModel<T>());
  const [csvData, setCsvData] = useState<any>(new PagedModel<T>());
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const id = 'Assets';
  const cancelToken = Api.CancelToken;
  let fetchDataCancel;
  const assetFilters = JSON.parse(localStorage.getItem('assetFilters')).concat(
    JSON.parse(localStorage.getItem('tempFilters')) || [],
  );

  const fetchData = async (payload?: IV2FilterPayload, csvExport?: boolean) => {
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    setIsLoading(true);
    const exportPayload = csvExport ? { ...payload, isExport: true } : payload;

    Api.post(`/v2/assets/filter/`, exportPayload, {
      cancelToken: new cancelToken(function executor(c) {
        fetchDataCancel = c;
      }),
    })
      .then((data) => {
        let resp = data.data;
        if (csvExport) {
          setCsvData(assetCSVFormat(resp));
        } else {
          setRowData(resp);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          setIsLoading(false);
        }
      });
  };

  return (
    <Card className="h-100" style={{ overflow: 'hidden' }}>
      <Card.Body>
        <Row className="h-100">
          <Col className="h-100">
            <ServerSideGrid<AssetListModel>
              id={id}
              initQueryParams={new PagedQueryParams()}
              assetFilters={assetFilters}
              columnDefs={assetColumnDefs}
              handleFetchData={fetchData}
              rowData={rowData}
              csvData={csvData}
              isLoading={isLoading}
              filterControl={true}
              disabledFilters={['all', 'device id', 'battery value']}
              searchControl={true}
              viewingControl={true}
              pagerControl={true}
              exportControl={true}
              gridProps={{
                frameworkComponents: {
                  LinkCellRenderer,
                  OnlineStatusCellRenderer,
                  BeaconReadingsCellRenderer,
                  PlacesCellRenderer,
                },
              }}
              csvHeaders={[
                'id',
                'name',
                'created',
                'assetType',
                'movementThreshold',
                'lastCheckIn',
                'beacons',
                'keywords',
                'location',
                'gpsLocation',
                'identifiers',
                'scans',
                'status',
                'favorite',
                'locationBeaconId',
                'preSignedImageUrl',
              ]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
