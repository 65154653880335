import { useQuery } from 'react-query';
import { Row, Col, Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Api } from '../../services';
import { assetColumnDefs } from './columnDefs';
import {
  OnlineStatusCellRenderer,
  LinkCellRenderer,
  BeaconReadingsCellRenderer,
  PlacesCellRenderer,
  toast,
} from '../../components';
import { getTableQueryParams } from '../../utils';
import { useTableState } from '../../react';
import AgGrid from '../../components/grids/AgGrid/AgGrid';

const cancelToken = Api.CancelToken;
const source = cancelToken.source();

async function fetchData({ limit, search, page = 1 }) {
  const { data } = await Api.get(
    `/assets?skip=${(page - 1) * limit}&top=${limit}&filter=${search}`,
    {
      cancelToken: source.token,
    },
  );

  return data;
}

function AssetListScreen({ history }) {
  const tableParams = getTableQueryParams();

  const { page, limit, search, setPageLink, setLimitLink, setStateLink } = useTableState({
    initSearch: tableParams.search,
    initLimit: tableParams.limit,
    initPage: tableParams.page,
    link: '/assets',
    history,
  });

  // #region HELPER FUNCTIONS
  const onCellClicked = ({ column, data }) => {
    const ignoreCols = ['readings', 'places', 'name'];
    if (ignoreCols.includes(column.colId)) return;

    history.push(`/assets/${data.id}`);
  };

  const getGridProps = () => {
    return {
      frameworkComponents: {
        LinkCellRenderer,
        OnlineStatusCellRenderer,
        BeaconReadingsCellRenderer,
        PlacesCellRenderer,
      },
      onCellClicked,
    };
  };

  const onError = (error) => {
    toast.error(`Failed to get assets. ${error.response.data.message}.`);
  };
  // #endregion HELPER FUNCTIONS

  const res = useQuery(
    ['listAssets', page, { limit, search }],
    () => fetchData({ page, limit, search }),
    { onError, keepPreviousData: true },
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <AgGrid
            rowData={res.data?.items ?? []}
            columnDefs={assetColumnDefs}
            gridProps={getGridProps()}
            isLoading={res.isFetching}
            onChangePage={setPageLink}
            onChangeLimit={setLimitLink}
            onChangeSearch={(search) => setStateLink({ search, page: 1 })}
            initSearch={tableParams.search}
            searchDebounceTimeSec={0.5}
            useQuickFilter={false}
            pagination
          />
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(AssetListScreen);
