import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { createTableQs } from '../../utils';

interface TableState {
  page: number;
  limit: number;
  search: string;
}

function useTableState({ initSearch = '', initPage = 1, initLimit = 20, link = '' } = {}) {
  const [page, setPage] = useState(initPage);
  const [limit, setLimit] = useState(initLimit);
  const [search, setSearch] = useState(initSearch);

  const history = useHistory();

  const setStateLink = useCallback(
    (state: TableState) => {
      const { page: newPage = page, limit: newLimit = limit, search: newSearch = search } = state;

      setPage(newPage);
      setLimit(newLimit);
      setSearch(newSearch);

      const qs = createTableQs({ search: newSearch, page: newPage, limit: newLimit });
      history.replace(`${link}?${qs}`);
    },
    [history, page, search, limit, link],
  );

  const setPageLink = useCallback(
    (page) => {
      setPage(page);
      const qs = createTableQs({ search, page, limit });

      history.replace(`${link}?${qs}`);
    },
    [history, link, search, limit],
  );

  const setLimitLink = useCallback(
    (limit) => {
      setLimit(limit);
      const qs = createTableQs({ search, page, limit });

      history.replace(`${link}?${qs}`);
    },
    [history, link, search, page],
  );

  const setSearchLink = useCallback(
    (search) => {
      setSearch(search);
      const qs = createTableQs({ search, page, limit });

      history.replace(`${link}?${qs}`);
    },
    [history, link, limit, page],
  );

  const setDefaults = useCallback(() => {
    setPage(1);
    setLimit(20);
    setSearch('');
  }, []);

  const qs = createTableQs({ search, page, limit });

  return {
    page,
    setPage,
    setPageLink,
    limit,
    setLimit,
    setLimitLink,
    search,
    setSearch,
    setSearchLink,
    setDefaults,
    setStateLink,
    qs,
  };
}

export default useTableState;
