import '../../scss/variables.scss';
import PropTypes from 'prop-types';

// import { ONLINE_STATUS_MAP } from '../../constants';

function OnlineStatus({ variant, size = 15, className = '' }) {
  // const onlineStatus = ONLINE_STATUS_MAP[status];
  let color = 'var()';

  switch (variant) {
    case 'success':
      color = 'var(--c-primary)';
      break;
    case 'secondary':
      color = 'var(--secondary)';
      break;
    case 'warning':
      color = 'var(--warning)';
      break;
    case 'danger':
      color = 'var(--danger)';
      break;
  }

  return (
    <span
      className={`onlineStatus onlineStatus-${variant} circle ${className}`}
      style={{ width: size, height: size, backgroundColor: color }}
    />
  );
}

OnlineStatus.propTypes = {
  variant: PropTypes.string.isRequired,

  // defaults
  size: PropTypes.number,
  className: PropTypes.string,
};

export default OnlineStatus;
