import { Text } from '@components';
import { CustomApi } from '@services';
import Axios from 'axios';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Loading, toast } from '../../components';
import CloseModal from '../../components/modals/CloseModal';

function CreateAppModal({ onClose, setTriggerUpdate }) {
  const [appName, setAppName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isValidAppName = () =>
    /^[A-Za-z0-9][A-Za-z0-9\s!@#$%^&*()-=_+[\]{}|;:'",.<>?/~`]*$/.test(appName);

  const handleAppNameChange = (e) => {
    setAppName(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      if (!isValidAppName()) {
        toast.error('Invalid application name format.');
        return;
      }

      // Make API call to create application
      await CustomApi.post('/api/applications', { name: appName });

      toast.success('Application created successfully!');
    } catch (error) {
      toast.error('Failed to create application.');
    } finally {
      onClose();
      // triggers serversidegrid debounceFetchData
      setTriggerUpdate(true);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <CloseModal close={onClose} />
          <Text as="h3" className="text-center">
            Create Application
          </Text>
          <Form className="mt-5 mb-4">
            <Form.Group as={Row} className="d-block text-center" controlId="formAppName">
              <Form.Label column sm={4}>
                Application Name:
              </Form.Label>
              <Col sm={7} className="m-auto">
                <Form.Control
                  type="text"
                  placeholder="Enter Application Name"
                  value={appName}
                  onChange={handleAppNameChange}
                />
              </Col>
            </Form.Group>
          </Form>
          <div className="text-center">
            <Button className="w-25" variant="secondary m-3" onClick={onClose}>
              Close
            </Button>
            <Button
              className="w-25"
              variant="primary"
              onClick={handleSubmit}
              disabled={!isValidAppName() || isLoading}
            >
              Create
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default CreateAppModal;
