import '../../../../scss/variables.scss';
import './FavoriteEditCellRenderer.scss';
import { Text } from '@components';
import { isAdmin, isSystemAdmin } from '@utils';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApp } from '../../../../react';
import { favoriteService } from '../../../../services';
import { Api } from '../../../../services';
import Icon from '../../../Icon/Icon';

function FavoriteEditCellRenderer(items) {
  const history = useHistory();
  const app = useApp();
  const authorized = isAdmin() || isSystemAdmin();
  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkIsFavorite = async () => {
    const { data } = await favoriteService.getFavorites(app.id);
    const isFavorite = data.find(
      (fav) => fav.itemType === 'Map' && fav.itemId === Number(items.data.id),
    );
    isFavorite ? setIsFavorite(true) : setIsFavorite(false);
    setIsLoading(false);
  };

  const _onClickEdit = () => {
    history.push(`/maps/${items.data.id}/edit`);
  };

  // #region FAVORITE
  async function favoriteMap() {
    setIsLoading(true);
    setIsFavorite(true);
    return Api.post(`/maps/${items.data.id}/favorites`);
  }

  async function unfavoriteMap() {
    setIsLoading(true);
    setIsFavorite(false);
    return Api.delete(`/maps/${items.data.id}/favorites`);
  }

  checkIsFavorite();

  return isLoading ? (
    <Text>Loading...</Text>
  ) : (
    <div className="favorite-edit">
      {authorized && (
        <Icon
          name="edit"
          className="edit align-sub"
          variant="light"
          disabled={false}
          size="lg"
          style={{ color: 'var(--primary)' }}
          onClick={_onClickEdit}
        />
      )}
      <Icon
        name={isFavorite ? 'star-fill' : 'star-empty'}
        className={isFavorite ? 'fill align-sub' : 'empty align-sub'}
        variant="light"
        disabled={false}
        size="lg"
        style={{ color: 'var(--primary)' }}
        onClick={isFavorite ? unfavoriteMap : favoriteMap}
      />
    </div>
  );
}

export default FavoriteEditCellRenderer;
