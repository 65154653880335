import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect,useState } from 'react';
import { Step,Steps, Wizard } from 'react-albus';
import { Button, Card, Col, Form, Modal,ProgressBar } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useTableState,withDock } from '../../../react';
import { Api } from '../../../services';
import { noop } from '../../../utils';
import Icon from '../../Icon/Icon';
import PaginatedSearch from '../../PaginatedSearch/PaginatedSearch';
import Text from '../../Text/Text';
import toast from '../../utils/toast';
import AddContact from '../../workflows/contacts/AddContact/AddContact';

const TRIGGERS_MAP = {
  Temperature: {
    name: 'Temperature',
    abilityId: 3,
    below: {
      helpText:
        'Receive alerts when Temperature falls below specified value. (Enter in Fahrenheit)',
    },
    above: {
      helpText:
        'Receive alerts when Temperature rises above specified value. (Enter in Fahrenheit)',
    },
  },
  Humidity: {
    name: 'Humidity',
    abilityId: 4,
    below: {},
    above: {},
  },
  Battery: {
    name: 'Battery',
    abilityId: 1,
    below: {},
    above: null,
  },
  Movement: {
    name: 'Movement',
    below: {
      placeholder: `Asset moves more than`,
      helpText: `Receive alerts when the beacon moves more than specified value.`,
    },
    units: ['m', 'ft'],
  },
  Place: {
    name: 'Place',
    below: {},
    above: {},
  },
};
const TRIGGER_TYPES = Object.keys(TRIGGERS_MAP);

const STEPS = {
  BEACON_SELECT: { id: 'BEACON_SELECT' },
  TYPE: { id: 'TYPE' },
  CONFIGURE: { id: 'CONFIGURE' },
  CONTACTS: { id: 'CONTACTS' },
  SAVE: { id: 'SAVE' },
};

const FREQUENCY_MAP = {
  M15: {
    label: '15 Minutes',
    value: '00:15:00',
  },
  M30: {
    label: '30 Minutes',
    value: '00:30:00',
  },
  M45: {
    label: '45 Minutes',
    value: '00:45:00',
  },
  H1: {
    label: '1 Hour',
    value: '1:00:00',
  },
  H2: {
    label: '2 Hours',
    value: '2:00:00',
  },
  H6: {
    label: '6 Hours',
    value: '6:00:00',
  },
  H12: {
    label: '12 Hours',
    value: '12:00:00',
  },
  D1: {
    label: '1 Day',
    value: '1.00:00:00',
  },
};

const initSavedTrigger = {
  name: '',
  desc: '',
  frequency: FREQUENCY_MAP.D1.value,
  delay: '0',
  isOneTime: false,
};

async function fetchContacts() {
  const { data } = await Api.get(`/contacts`);

  return {
    items: data,
    count: data.length,
  };
}

async function postContact(data) {
  return Api.post('/contacts', data);
}

async function postTrigger(data) {
  return Api.post('/triggers', data);
}

function CreateTriggerWizard({ places, closeDock, beacons, app, onSuccess, asset, history }) {
  const [step, setStep] = useState(STEPS.BEACON_SELECT);
  const [type, setType] = useState('');
  const [below, setBelow] = useState('');
  const [above, setAbove] = useState('');
  const [unit, setUnit] = useState('');
  const [contacts, setContacts] = useState([]);
  const [savedTrigger, setSavedTrigger] = useState(initSavedTrigger);
  const [beaconId, setBeaconId] = useState('');
  const [initPathname] = useState(history.location.pathname);
  const [showModal, setShowModal] = useState(false);

  const { search, setSearch } = useTableState();

  const beacon = beacons.find((currBeacon) => currBeacon.id + '' === beaconId);

  const contactsRes = useQuery('createTrigger-contacts', fetchContacts);

  const postContactMutation = useMutation(postContact);
  const postTriggerMutation = useMutation(postTrigger);

  // const reset = useCallback(() => {
  //   setStep(STEPS.BEACON_SELECT);
  //   setType('');
  //   setBelow('');
  //   setAbove('');
  //   setUnit('');
  //   setContacts([]);
  //   setSavedTrigger(initSavedTrigger);
  //   setBeaconId('');
  //   setSearch('');
  // }, [setSearch]);

  // listen for route changes and close dock automatically
  useEffect(() => {
    history.listen((e) => {
      if (e.pathname !== initPathname) closeDock();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddContactCb = async ({ name, email: emailAddress, phone: phoneNumber }) => {
    try {
      const res = await postContactMutation.mutateAsync({
        name,
        emailAddress,
        phoneNumber,
        applicationId: app.id,
      });

      toast.success('Created contact!');
      setContacts(contacts.concat({ name, emailAddress, id: res.data, phoneNumber }));
      contactsRes.refetch();
    } catch (error) {
      toast.error('Failed to add contact.');
    }
  };

  const callPostTriggerMutation = useCallback(async () => {
    const onError = () => {
      toast.error('Failed to create trigger.');
    };

    try {
      const onTriggerSuccess = () => {
        onSuccess();
        toast.success('Created trigger!');
        closeDock();
        // setStep(STEPS.BEACON_SELECT);
        // reset();
      };

      const trigger = TRIGGERS_MAP[type];

      const getAbilityTriggers = () => {
        if (!trigger.abilityId) return [];

        const abilityTrigger = { abilityId: trigger.abilityId };
        if (below) abilityTrigger.fallsBelow = below;
        if (above) abilityTrigger.risesAbove = above;

        return [abilityTrigger];
      };

      const getPlaceTriggers = () => {
        if (type !== 'Place') return [];

        const placeTrigger = {};
        if (below) placeTrigger.enters = below;
        if (above) placeTrigger.exits = above;

        return [placeTrigger];
      };

      const getMovementTriggers = () => {
        if (type !== 'Movement') return [];

        return [{ distance: below, unitOfMeasure: unit }];
      };

      const getRecipients = () => {
        return contacts.reduce((accum, contact) => {
          if (isEmpty(contact)) return accum;

          accum.push({
            contactId: contact.id,
            sonar: true,
            email: true,
          });

          return accum;
        }, []);
      };

      const data = {
        name: savedTrigger.name,
        description: savedTrigger.desc,
        beaconIds: [beaconId],
        abilityTriggers: getAbilityTriggers(),
        placeTriggers: getPlaceTriggers(),
        movementTriggers: getMovementTriggers(),
        proximityToGatewayTriggers: [],
        proximityToLocationTriggers: [],
        recipients: getRecipients(),
        startDate: new Date().toISOString(),
        frequency: savedTrigger.frequency,
        duration: `00:${savedTrigger.delay}:00`,
        oneTime: savedTrigger.isOneTime,
      };

      postTriggerMutation.mutateAsync(data, { onSuccess: onTriggerSuccess, onError });
    } catch (error) {
      onError();
    }
  }, [
    savedTrigger,
    above,
    below,
    type,
    contacts,
    postTriggerMutation,
    closeDock,
    // reset,
    beaconId,
    onSuccess,
    unit,
  ]);

  function selectBeaconRender() {
    return (
      <div>
        <Form.Control as="select" onChange={(e) => setBeaconId(e.target.value)} value={beaconId}>
          <option value="">Select A Beacon</option>
          {beacons.map((beacon) => {
            return (
              <option id={beacon.id} value={beacon.id}>
                {beacon.name}
              </option>
            );
          })}
        </Form.Control>

        <PrevNextBtns
          prev={{ hide: true }}
          next={{ onClick: () => setStep(STEPS.TYPE), disabled: !beaconId }}
        />
      </div>
    );
  }

  function selectTriggerRender() {
    return (
      <div>
        <Text as="h3">What type of trigger would you like to create?</Text>
        {TRIGGER_TYPES.map((currType) => (
          <Button
            variant={type === currType ? 'primary' : 'outline-primary'}
            className="m-3"
            onClick={() => {
              setType(currType);
            }}
            style={{ width: 200 }}
          >
            {currType}
          </Button>
        ))}

        <PrevNextBtns
          prev={{ onClick: () => setStep(STEPS.BEACON_SELECT) }}
          next={{ onClick: () => setStep(STEPS.CONFIGURE), disabled: !type }}
        />
      </div>
    );
  }

  function setValuesRender() {
    const trigger = TRIGGERS_MAP[type];

    // default form
    let form = (
      <div>
        <Form.Row>
          <Col>
            <Form.Control
              type="number"
              placeholder={trigger.below?.placeholder || `${type} falls below`}
              value={below}
              onChange={(e) => setBelow(e.target.value)}
            />
            <Form.Text className="text-muted">
              {trigger.below?.helpText ||
                `Receive alerts when ${type} falls below specified value.`}
            </Form.Text>
          </Col>

          {trigger.above && (
            <Col>
              <Form.Control
                type="number"
                placeholder={trigger.above?.placeholder || `${type} rises above`}
                value={above}
                onChange={(e) => setAbove(e.target.value)}
              />
              <Form.Text className="text-muted">
                {trigger.above?.helpText ||
                  `Receive alerts when ${type} rises above specified value.`}
              </Form.Text>
            </Col>
          )}

          {trigger.units && (
            <Col xs={2}>
              <Form.Control as="select" value={unit} onChange={(e) => setUnit(e.target.value)}>
                <option value="">Select Unit</option>
                {trigger.units.map((unit) => (
                  <option key={unit} value={unit}>
                    {unit}
                  </option>
                ))}
              </Form.Control>
            </Col>
          )}
        </Form.Row>
      </div>
    );

    // Place trigger requires a different form
    if (type === 'Place') {
      form = (
        <div>
          <Form.Row>
            <Col>
              <Form.Control
                as="select"
                placeholder="Enters"
                value={below}
                onChange={(e) => setBelow(e.target.value)}
              >
                <option value="">Choose Entry Place</option>
                {places.map((place) => (
                  <option key={place.id} value={place.id}>
                    {place.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Text className="text-muted">
                Receive alerts if {beacon.name} enters the specified place.
              </Form.Text>
            </Col>

            <Col>
              <Form.Control
                as="select"
                placeholder="Exits"
                value={above}
                onChange={(e) => setAbove(e.target.value)}
              >
                <option value="">Choose Exit Place</option>
                {places.map((place) => (
                  <option key={place.id} value={place.id}>
                    {place.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Text className="text-muted">
                Receive alerts if {beacon.name} exits the specified place.
              </Form.Text>
            </Col>
          </Form.Row>
        </div>
      );
    }

    const onClickPrev = () => {
      setBelow('');
      setAbove('');
      setUnit('');
      setStep(STEPS.TYPE);
    };

    const isNextDisabled = () => {
      // return !above && !below;
      if (!above && !below) return true;
      if (trigger.units && !unit) return true;

      return false;
    };

    return (
      <div>
        <Text as="h3">
          Notify me when {beacon.name} {type}:
        </Text>

        {form}

        <PrevNextBtns
          prev={{ onClick: onClickPrev }}
          next={{ onClick: () => setStep(STEPS.CONTACTS), disabled: isNextDisabled() }}
        />
      </div>
    );
  }

  function selectContactRender() {
    const contactIds = contacts.map((c) => c.id);
    const itemFilter = ({ item, search }) =>
      item.name.toLowerCase().includes(search.toLowerCase()) && !contactIds.includes(item.id);

    const onSelectItem = ({ item, index, hideItems }) => {
      const copy = contacts.concat(item);
      setContacts(copy);
      hideItems();
    };

    const onDeleteContact = (index) => {
      const copy = [...contacts];
      copy.splice(index, 1);
      setContacts(copy);
    };

    const renderContact = ({ item: contact }) => {
      return (
        <>
          <span className="fs-lg">{contact.name}</span>
          <br />
          <span>{contact.emailAddress}</span>
        </>
      );
    };

    const renderAddContact = () => (
      <Button variant="link" size="sm" onClick={() => setShowModal(true)}>
        Add A Contact
      </Button>
    );

    const helpText = contacts.length
      ? `You have selected ${contacts.length} contacts`
      : 'Use the Search Bar to select contacts';

    return (
      <div>
        <div className="d-flex flex-row justify-content-between">
          <Text as="h3">Notifications</Text>
        </div>

        <div className="my-3">
          <PaginatedSearch
            items={contactsRes.data?.items}
            search={search}
            setSearch={setSearch}
            loading={contactsRes.isLoading || contactsRes.isFetching}
            renderItem={renderContact}
            refetch={contactsRes.refetch}
            placeholder="Search Contacts"
            onSelectItem={onSelectItem}
            itemFilter={itemFilter}
            helpText={helpText}
          />
        </div>

        <div>
          {contacts.map((contact, index) => {
            return (
              <Form.Row key={contact.id} className="my-3 d-flex flex-row align-items-center">
                <Col xs={11}>
                  <Form.Control
                    type="text"
                    value={`${contact.name} - ${contact.emailAddress}`}
                    disabled
                  />
                </Col>

                <Col xs={1}>
                  <Icon
                    name="add"
                    size="lg"
                    disabled={false}
                    onClick={(e) => {
                      e.preventDefault();
                      onDeleteContact(index);
                    }}
                    className="sonarIcon-rotate-45"
                    variant="danger"
                  />
                </Col>
              </Form.Row>
            );
          })}
        </div>

        <div className="my-3">{renderAddContact()}</div>

        <PrevNextBtns
          prev={{ onClick: () => setStep(STEPS.CONFIGURE) }}
          next={{ onClick: () => setStep(STEPS.SAVE) }}
        />
      </div>
    );
  }

  function saveTriggerRender() {
    return (
      <div>
        <Text as="h3">Save Trigger</Text>

        <div>
          <Form.Group>
            <Form.Label>Trigger Name*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter trigger name"
              value={savedTrigger.name}
              onChange={(e) => setSavedTrigger({ ...savedTrigger, name: e.target.value })}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Trigger Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter trigger description"
              value={savedTrigger.desc}
              onChange={(e) => setSavedTrigger({ ...savedTrigger, desc: e.target.value })}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Notification Frequency</Form.Label>
            <Form.Control
              as="select"
              placeholder="Select Frequency"
              value={savedTrigger.frequency}
              onChange={(e) => setSavedTrigger({ ...savedTrigger, frequency: e.target.value })}
            >
              {Object.values(FREQUENCY_MAP).map((freq) => {
                return (
                  <option id={freq.label} value={freq.value}>
                    {freq.label}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Text muted>
              How often would you like to receive notifications about this trigger.
            </Form.Text>
          </Form.Group>

          {/* <Form.Group>
            <Form.Label>Notification Delay</Form.Label>
            <Form.Control
              type="number"
              placeholder="0"
              min="0"
              value={savedTrigger.delay}
              onChange={(e) => setSavedTrigger({ ...savedTrigger, delay: e.target.value })}
            />
            <Form.Text muted>
              How many minutes must the trigger be met before you are notified.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Check
              type="checkbox"
              label="One Time Trigger"
              onChange={(e) => setSavedTrigger({ ...savedTrigger, isOneTime: e.target.checked })}
            />
            <Form.Text muted>Select if this trigger should fire one time only.</Form.Text>
          </Form.Group> */}
        </div>

        <PrevNextBtns
          prev={{ onClick: () => setStep(STEPS.CONTACTS) }}
          next={{ text: 'Save', onClick: callPostTriggerMutation, disabled: !savedTrigger.name }}
        />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column p-3">
      <div className="d-flex flex-row justify-content-end">
        <Icon
          name="add"
          size="lg"
          disabled={false}
          onClick={() => {
            // reset();
            closeDock();
          }}
          className="sonarIcon-rotate-45"
          variant="dark"
        />
      </div>

      <Card>
        <Card.Header>
          <Text as="h1">
            Create Trigger - <Text variant="primary">{asset.name}</Text>
          </Text>
        </Card.Header>
        <Card.Body>
          <Wizard
            render={({ steps }) => {
              return (
                <div>
                  <ProgressBar
                    now={
                      ((steps.findIndex((currStep) => currStep.id === step.id) + 1) /
                        steps.length) *
                      100
                    }
                    className="mb-5"
                  />

                  {/* Steps */}
                  <div>
                    <Steps key={step.id} step={step}>
                      <Step id={STEPS.BEACON_SELECT.id} render={selectBeaconRender} />

                      <Step id={STEPS.TYPE.id} render={selectTriggerRender} />

                      <Step id={STEPS.CONFIGURE.id} render={setValuesRender} />

                      <Step id={STEPS.CONTACTS.id} render={selectContactRender} />

                      <Step id={STEPS.SAVE.id} render={saveTriggerRender} />
                    </Steps>
                  </div>
                </div>
              );
            }}
          />
        </Card.Body>
      </Card>

      <Modal centered show={showModal} backdrop="static" onHide={() => setShowModal(false)}>
        <Modal.Header closeButton />

        <Modal.Body>
          <AddContact onAddContact={onAddContactCb} isLoading={postContactMutation.isLoading} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

CreateTriggerWizard.defaultProps = {
  onSuccess: noop,
};

CreateTriggerWizard.propTypes = {
  places: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  closeDock: PropTypes.func.isRequired,
  beacons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  asset: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,

  // defaults
  onSuccess: PropTypes.func,
};

/**
 * prev|next - { text: string, onClick: func, hide: bool, disabled: bool }
 */
function PrevNextBtns({ prev = { hide: false }, next = { hide: false, disabled: false } }) {
  return (
    <div>
      {!prev.hide && (
        <Button variant="danger" onClick={prev.onClick} className="m-3">
          {prev.text || 'Previous'}
        </Button>
      )}

      {!next.hide && (
        <Button variant="success" onClick={next.onClick} className="m-3" disabled={next.disabled}>
          {next.text || 'Next'}
        </Button>
      )}
    </div>
  );
}

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default compose(withDock, withRouter, connect(mapStateToProps))(CreateTriggerWizard);
