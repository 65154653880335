import '../../../../../scss/icons/icons.scss';
import './BeaconConfig.scss';
import { Text } from '@components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

function BeaconConfig({
  beacon,
  asset,
  onCreateConfig,
  goBack,
  onReasignQuestion,
  onSuccess,
  existingBeacons,
}) {
  const [isPrimary, setIsPrimary] = useState(false);
  const [locOffset, setLocOffset] = useState(0);
  const [temp, setTemp] = useState(0);
  const [humidity, setHumidity] = useState(0);

  const config = {
    isPrimary,
    locOffset,
    tempOffset: temp,
    humidityOffset: humidity,
  };

  const renderWarning = () => {
    if (!beacon.assetName) return null;

    return (
      <div className="d-flex flex-column">
        <div className="sa mb-3">
          <div className="sa-warning mx-auto">
            <div className="sa-warning-body"></div>
            <div className="sa-warning-dot"></div>
          </div>
        </div>
        <Text variant="warning" className="fs-md text-center">
          Beacon {beacon.name} is already attached to Asset {beacon.assetName}. <br /> Clicking
          Confirm will add the Beacon to Asset {asset.name}
        </Text>
      </div>
    );
  };
  console.log(asset);
  return (
    <Container className="d-flex flex-column justify-content-between h-100">
      <Row>
        <Col>
          <Form>
            <Form.Group className="">
              <Form.Label className="fs-lg">Will {beacon.name} be the primary beacon?</Form.Label>
              {/* New Assets & Assets without Beacons automatically set beacon attached as primary so check will be disabled and checked */}
              <Form.Check
                type="checkbox"
                label="Yes"
                checked={
                  isPrimary ||
                  asset.isCreated ||
                  (asset.beacons && asset.beacons.length === 0) ||
                  existingBeacons?.length === 0
                }
                onChange={(e) => setIsPrimary(e.target.checked)}
                disabled={
                  asset.isCreated ||
                  (asset.beacons && asset.beacons.length === 0) ||
                  existingBeacons?.length === 0
                }
              />
            </Form.Group>

            {isPrimary ? (
              <Form.Group className="mb-5">
                <Form.Label>
                  <Text>Location Offset</Text>
                </Form.Label>
                <Form.Control
                  type="number"
                  value={locOffset}
                  onChange={(e) => setLocOffset(e.target.value)}
                />
              </Form.Group>
            ) : null}

            <Text className="fs-lg mb-3">Environmental Offset</Text>
            <Form.Group>
              <Form.Label>Temperature</Form.Label>
              <Form.Control type="number" value={temp} onChange={(e) => setTemp(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Humidity</Form.Label>
              <Form.Control
                type="number"
                value={humidity}
                onChange={(e) => setHumidity(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col>{renderWarning()}</Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <Button variant="secondary" className="my-2" style={{ width: 200 }} onClick={goBack}>
              Back
            </Button>
          </div>

          <div className="d-flex flex-row justify-content-center">
            <Button
              variant="primary"
              className="my-2"
              style={{ width: 200 }}
              onClick={
                Boolean(beacon?.assetName)
                  ? () => {
                      onReasignQuestion();
                      onCreateConfig(config);
                    }
                  : () => {
                      onCreateConfig(config);
                      onSuccess();
                    }
              }
            >
              Confirm
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

BeaconConfig.propTypes = {
  beacon: PropTypes.object.isRequired,
  onClickNext: PropTypes.func,
  onCreateConfig: PropTypes.func.isRequired,
};

export default BeaconConfig;
