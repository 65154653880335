import { useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { getNewInvitee, inviteUsers, ROLES } from './utils';
import { isSystemAdmin } from '@utils';
import AppSelector from './AppSelector';
import './userMgmt.scss';
import { Loading } from '@components';

/**
 * @desc Displays form to invite users by userName and assign
 * their role
 */
export function InviteUsersModal({ appVal, appName, setAppVal, setAppName, onCancel }) {
  const [users, setUsers] = useState<any>([getNewInvitee()]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChangeInviteeEmail = (e, index) => {
    const newUsers = JSON.parse(JSON.stringify(users));
    newUsers[index].userName = e.target.value;
    setUsers(newUsers);
  };

  const onChangePhoneNumber = (e, index) => {
    const newUsers = JSON.parse(JSON.stringify(users));
    newUsers[index].phoneNumber = e.target.value;
    setUsers(newUsers);
  };

  // Add new invitee row
  const onFocusEmail = (index) => {
    const newUsers = JSON.parse(JSON.stringify(users));
    newUsers.push(addInvitee());
    setUsers(newUsers);
  };

  // Delete invitee row
  const DeleteRow = (index) => {
    const newUsers = JSON.parse(JSON.stringify(users));
    newUsers.splice(index, 1);
    setUsers(newUsers);
  };

  // Update user role
  const onChangeInviteeRole = (e, index) => {
    const newUsers = JSON.parse(JSON.stringify(users));
    newUsers[index].role = e.target.value;
    setUsers(newUsers);
  };

  // Get new row info
  const addInvitee = () => {
    return getNewInvitee();
  };

  // Process new invites
  const sendInvite = async () => {
    setIsLoading(true);

    const newUsers = users
      .filter((invitee) => !!invitee.userName)
      .map((invitee) => ({
        userName: invitee.userName,
        phoneNumber: invitee?.phoneNumber,
        role: invitee.role,
        applicationId: appVal,
      }));
    await inviteUsers({ users: newUsers, appName: appName });
    onCancel();
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Loading />
        <span>&nbsp;Processing Users...</span>
      </div>
    );
  }

  return (
    <div>
      <AppSelector
        appVal={appVal}
        appName={appName}
        setAppVal={setAppVal}
        setAppName={setAppName}
      />

      <hr />

      {users.map((user, index) => {
        return (
          <div className="invite d-flex mb-4 justify-content-around" key={index}>
            {index === 0 ? (
              <Button
                variant="secondary"
                title="Add Email"
                className="mb-3"
                style={{ width: '15%' }}
                onClick={() => onFocusEmail(index)}
              >
                + Email
              </Button>
            ) : (
              <Button
                variant="danger"
                className="remove-user btn btn-outline-danger"
                style={{ width: '15%' }}
                title="Remove"
                onClick={() => DeleteRow(index)}
              >
                Remove
              </Button>
            )}
            <Form.Control
              type="email"
              as="input"
              placeholder="Email"
              className="col-sm-6 mr-1 mb-3 w-50"
              value={user.userName}
              onChange={(e) => onChangeInviteeEmail(e, index)}
            />
            <Form.Select
              className="role-select col-sm-3 mb-3"
              style={{ width: '26%' }}
              value={user.role}
              onChange={(e) => onChangeInviteeRole(e, index)}
            >
              {ROLES.map((role) => {
                return (
                  <option key={role} value={role}>
                    {role}
                  </option>
                );
              })}
            </Form.Select>
          </div>
        );
      })}

      <div className="invite-btn d-flex justify-content-center mb-4">
        <Button variant="primary" title="Send Invite" onClick={sendInvite} disabled={!appVal}>
          Send Invite
        </Button>
        <Button variant="secondary" title="Cancel" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default InviteUsersModal;
