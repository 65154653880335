import { UPDATE_USER } from '../types';

const user = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER:
      state = {
        ...state,
        data: action.payload,
      };
      return state;

    default:
      return state;
  }
};

export default user;
