import Api from './Api';

class FavoriteService {
  getFavorites = (appId) => {
    return Api.get(`/applications/${appId}/favorites`, {
      cancelToken: Api.CancelToken.source().token,
    });
  };

  favoriteAsset = async (assetId) => {
    return Api.post(`/assets/${assetId}/favorites`, {
      cancelToken: Api.CancelToken.source().token,
    });
  };

  unfavoriteAsset = async (assetId) => {
    return Api.delete(`/assets/${assetId}/favorites`, {
      cancelToken: Api.CancelToken.source().token,
    });
  };

  favoritePlace = async (placeId) => {
    return Api.post(`/places/${placeId}/favorites`, {
      cancelToken: Api.CancelToken.source().token,
    });
  };

  unfavoritePlace = async (placeId) => {
    return Api.delete(`/places/${placeId}/favorites`, {
      cancelToken: Api.CancelToken.source().token,
    });
  };
}

export default new FavoriteService();
export { FavoriteService };
