export const assetCSVFormat = (dataResponse) => {
  for (let i = 0; i < dataResponse.length; i++) {
    let kv = '';
    let ids = [];

    dataResponse[i].identifiers.forEach((obj) =>
      obj.key != '' && obj.value != '' ? (kv += '(' + obj.key + '+' + obj.value + ')') : '',
    );

    dataResponse[i].beacons.forEach((obj) => ids.push(obj.deviceId));

    dataResponse[i] = {
      ids: dataResponse[i].id,
      name: dataResponse[i].name,
      keywords: dataResponse[i].keywords.join('+'),
      identifiers: kv,
      beaconIds: ids.join('+'),
      movementThreshold: dataResponse[i].movementThreshold,
      created: dataResponse[i].created,
      lastCheckIn: dataResponse[i].lastCheckin,
      beacons: dataResponse[i].beacons,
      location: dataResponse[i].location,
      gpsLocation: dataResponse[i].gpsLocation,
      scans: dataResponse[i].scans,
      status: dataResponse[i].status,
      favorite: dataResponse[i].favorite,
      locationBeaconId: dataResponse[i].locationBeaconId,
      preSignedImageUrl: dataResponse[i].preSignedImageUrl,
    };
  }
  return dataResponse;
};

export const batteryCSVFormat = (dataResponse) => {
  for (let i = 0; i < dataResponse.length; i++) {
    dataResponse[i] = {
      id: dataResponse[i].id,
      assetName: dataResponse[i].assetName,
      assetId: dataResponse[i].assetId,
      deviceId: dataResponse[i].deviceId,
      placeId: dataResponse[i].placeId,
      placeName: dataResponse[i].placeName,
      mapId: dataResponse[i].mapId,
      mapName: dataResponse[i].mapName,
      batteryValue: dataResponse[i].batteryValue,
      lastChanged: dataResponse[i].lastChanged,
      lastCheckIn: dataResponse[i].lastTimestamp,
      lastTimestamp: dataResponse[i].lastTimestamp,
    };
  }
  return dataResponse;
};

export const environmentalCSVFormat = (dataResponse) => {
  for (let i = 0; i < dataResponse.length; i++) {
    dataResponse[i] = {
      assetId: dataResponse[i].assetId,
      assetName: dataResponse[i].assetName,
      beaconId: dataResponse[i].beaconId,
      beaconName: dataResponse[i].beaconName,
      deviceId: dataResponse[i].deviceId,
      timeRecorded: dataResponse[i].readingTime,
      averageTemperature:
        dataResponse[i].averageTempCelsius == null ? 'null' : dataResponse[i].averageTempCelsius,
      averageRelativeHumidity:
        dataResponse[i].averageRelativeHumidity == null
          ? 'null'
          : dataResponse[i].averageRelativeHumidity,
    };
  }
  return dataResponse;
};

export const triggerResCSVFormat = (dataResponse) => {
  for (let i = 0; i < dataResponse.length; i++) {
    dataResponse[i] = {
      beaconId: dataResponse[i].beaconId,
      applicationId: dataResponse[i].applicationId,
      triggerId: dataResponse[i].triggerId,
      assetId: dataResponse[i].assetId,
      assetName: dataResponse[i].assetName,
      triggerName: dataResponse[i].triggerName,
      triggerStatus: dataResponse[i].triggerStatus,
      criteria: dataResponse[i].criteria,
      reportedValue: dataResponse[i].reportedValue,
      recipientId: dataResponse[i].recipientId,
      comment: dataResponse[i].comment,
      triggerInstanceId: dataResponse[i].triggerInstanceId,
      recipientName: dataResponse[i].recipientName,
      compareValue: dataResponse[i].compareValue,
      action: dataResponse[i].action,
      totalRecordCount: dataResponse[i].totalRecordCount,
      notificationTime: dataResponse[i].notificationTime,
      responseTime: dataResponse[i].responseTime,
    };
  }
  return dataResponse;
};

export const beaconsCSVFormat = (dataResponse) => {
  let dataResponseItems: any = dataResponse.items.map((item: any, index: number) => {
    return {
      name: item?.name,
      deviceId: item?.deviceId,
      beaconTypeId: item?.beaconTypeId,
      lastCheckin: item?.lastCheckin,
      assetName: item?.assetName,
      firstSeen: item?.firstSeen,
      id: item?.id,
      uuid: item?.uuid,
      power: item?.power,
      assetId: item?.assetId,
      assigned: item?.assigned,
      beaconType: item?.beaconType,
      isOnline: item?.isOnline,
      keywords: item?.keywords,
      readings: item?.readings,
      location: item?.location,
      macAddress: item?.macAddress,
      isLocationBeacon: item?.isLocationBeacon,
    };
  });

  return {
    count: dataResponse.count,
    items: dataResponseItems,
  };
};
