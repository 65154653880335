import { useState } from 'react';
import { OverlayTrigger, Button, Popover } from 'react-bootstrap';
import '../../../../scss/variables.scss';

function MapThumbnailCellRenderer({ to, imgURL, img256URL }) {
  const [imgSrc, setImgSrc] = useState(imgURL);
  const [img256Src, setImg256Src] = useState(img256URL);

  const handleError = () => {
    setImgSrc('/img/imgError.png');
  };

  const handleHoverError = () => {
    setImg256Src(imgSrc);
  };

  const renderTooltip = (props) => (
    <Popover id="button-tooltip" {...props}>
      <img
        src={img256Src}
        className="card-img-top"
        style={{ height: '100%', width: '100%' }}
        onError={handleHoverError}
      />
    </Popover>
  );

  return (
    <OverlayTrigger placement="left" delay={{ show: 250, hide: 0 }} overlay={renderTooltip}>
      <Button
        variant="light"
        href={to}
        style={{ border: 'none', background: 'none', boxShadow: 'none' }}
      >
        <img
          src={imgSrc}
          className="card-img-top"
          style={{ height: '60px', width: '60px' }}
          onError={handleError}
        />
      </Button>
    </OverlayTrigger>
  );
}

export default MapThumbnailCellRenderer;
