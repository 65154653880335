import { EditDeleteCellRenderer } from '@components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from '../../components';
import { store } from '../../redux';
import { Api } from '../../services';

// Asset Detail, Associated Beacons Edit Modal Pop Up Columns

const timeValues = [
  '1 min',
  '5 mins',
  '10 mins',
  '30 mins',
  '1 hour',
  '4 hours',
  '12 hours',
  '24 hours',
];
const MySwal = withReactContent(Swal);

function convertFrequencyToSeconds(frequencyString) {
  // Split the input string into parts
  const parts = frequencyString.split(' ');

  // Extract the numeric value and unit (e.g., '1' and 'min')
  const numericValue = parseFloat(parts[0]);
  const unit = parts[1].toLowerCase(); // Convert to lowercase for case-insensitivity

  // Define conversion factors for different units
  const conversionFactors = {
    sec: 1,
    min: 60,
    mins: 60,
    hour: 3600,
    hours: 3600,
  };

  // Check if the unit is a valid conversion unit
  if (conversionFactors.hasOwnProperty(unit)) {
    // Multiply the numeric value by the conversion factor to get seconds
    return numericValue * conversionFactors[unit];
  }

  // Invalid unit, return NaN or handle the error as needed
  return NaN;
}

const BeaconAbilitiesColumnDefs = () => {
  const cancelToken = Api.CancelToken;
  const source = cancelToken.source();
  const onClickConfirmEdit = async (data, api) => {
    // Create an object with the updated ability data
    const updatedAbility = [
      {
        AbilityId: data.abilityId,
        Frequency: convertFrequencyToSeconds(data.frequency),
      },
    ];

    // Make the PATCH request to update the ability frequency
    try {
      await Api.patch(`assets/${data.assetId}/beacons/${data.beaconId}/abilities`, updatedAbility);
      toast.success('Ability Frequency updated successfully!');
    } catch (error) {
      toast.error('Failed to update Frequency');
    }
  };

  return [
    {
      headerName: 'Ability',
      field: 'ability',
      suppressMovable: true,
    },
    {
      headerName: 'Frequency',
      field: 'frequency',
      colId: 'frequency',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: timeValues,
      },
      suppressMovable: true,
      width: 150,
      editable(params) {
        const { node } = params;
        const { editDeleteCR } = store.getState().grids;
        return node.data.abilityIndex === editDeleteCR.id;
      },
    },
    {
      headerName: 'Edit/Delete',
      field: 'frequencyEdit',
      width: 115,
      cellRenderer: EditDeleteCellRenderer,
      cellRendererParams(params) {
        const { data, api, node } = params;
        const { abilityIndex } = data;
        const isFrequencyEdit = true;
        let id = abilityIndex;
        const abilities = data.abilities;

        return {
          id,
          node,
          abilityIndex,
          isFrequencyEdit,
          onClickEdit() {
            let rowIndex = 0;
            api.forEachNodeAfterFilterAndSort((node, nodeIndex) => {
              if (node.data.abilityIndex === abilityIndex) rowIndex = nodeIndex;
            });
            api.startEditingCell({
              colKey: 'frequency',
              rowIndex: rowIndex,
            });
          },
          async onClickDelete() {
            let userResponsePromise = new Promise((resolve, reject) => {
              MySwal.fire({
                allowOutsideClick: false,
                title: 'Are you sure?',
                text: `Delete ${data.ability} ability?`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
              })
                .then((submit) => {
                  resolve(submit.isConfirmed);
                })
                .catch((err) => {
                  reject(err);
                });
            });

            let userResponse;

            try {
              userResponse = await userResponsePromise;
            } catch (error) {
              userResponse = false;
              console.log(error);
            }

            if (userResponse) {
              try {
                await Api.delete(
                  `assets/${data.assetId}/beacons/${data.beaconId}/abilities/${data.abilityId}`,
                  {
                    cancelToken: source.token,
                  },
                );
                api.gridBodyCtrl.gridOptionsService.gridOptions.rowData.splice(node.rowIndex, 1);
                api.setRowData(api.gridBodyCtrl.gridOptionsService.gridOptions.rowData);
                toast.success(`Successfully deleted ${data.ability} ability!`);
              } catch (error) {
                toast.error(`Failed to delete ability.`);
              }
            }
          },
          onClickCancelEdit() {
            api.stopEditing(true);
          },
          onClickConfirmEdit() {
            onClickConfirmEdit(data, abilities, api);
          },
        };
      },
    },
  ];
};
export default BeaconAbilitiesColumnDefs;
