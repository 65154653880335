import styled from 'styled-components';

const Dropdown = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  position: absolute;
  /* leaflet-pane is set to z-index: 400. setting 500 to keep the menu on top */
  z-index: 500 !important;
  background-color: var(--white);
  border: 1px solid var(--primary);
  min-width: 8rem;
  text-align: left;
  & > div[class*='item-'] {
    color: var(--coverage-grey);
    padding: 0.4rem 1rem;
    font-weight: 200;
    font-size: 0.8rem;
    &:hover {
      color: var(--c-coverage-grey);
      background-color: var(--c-primary);
    }
  }
  & > div[data-active='true'] {
    font-weight: 600;
  }
`;

const PlaybackControls = (props) => {
  const items = props.items;
  // const [playbackSpeed, setPlaybackSpeed] = useState('Normal');

  const handleClick = (e) => {
    props.handlePlaybackSpeed(e.target.innerHTML);
  };

  return (
    <Dropdown hidden={props.hidden}>
      {items.map((currentValue, index) => {
        return (
          <div
            className={'item-' + index}
            data-active={currentValue === props.playbackSpeed ? true : false}
            onClick={handleClick}
            key={index}
          >
            {currentValue}
          </div>
        );
      })}
    </Dropdown>
  );
};

export default PlaybackControls;
