import { Api } from '@services';
import { useEffect,useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select2 from 'react-select';
import toast from '../utils/toast';

const mapStateToPropsUserSelect = ({ app }) => ({
  appId: app.app.id,
});

const cancelToken = Api.CancelToken;
let fetchDataCancel;

export default connect(mapStateToPropsUserSelect)((props) => {
  const [users, setUsers] = useState([]);
  const [inputVal, setInputVal] = useState('');
  const { value, onSingleChange, onMultiChange, className } = props;
  // const appId = props.appId; // Fix this

  const search = (q) => {
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    Api.get(`contacts/?filter=${encodeURIComponent(q)}`, {
      // cancelToken: new cancelToken(function executor(c) {
      //   fetchDataCancel = c;
      // }),
    })
      .then((results) => {
        setUsers(results.data);
      })
      .catch((e) => console.log(e));
    setInputVal(q);
  };

  useEffect(() => {
    Api.get('contacts/')
      .then((results) => {
        setUsers(results.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const singleStyle = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'var(--c-primary)' : 'unset',
      color: 'black',
      '&:hover': {
        backgroundColor: '#e0ecfc',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? 'lightgray' : 'lightgray',
      '&:hover': {
        borderColor: state.isFocused ? 'lightgray' : 'lightgray',
      },
    }),
  };

  const multiStyle = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      maxWidth: 230,
      border: 0,
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      padding: 5,
      fontSize: '.9em',
      backgroundColor: 'white',
      minWidth: '95%',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      border: 0,
      display: 'none',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'red',
    }),
    input: (provided, state) => ({
      display: 'none',
    }),
  };

  return (
    <div key={'userSelectContainer'}>
      <Select2
        className="mb-2"
        key={'userSelectSingle'}
        onChange={onSingleChange}
        value={inputVal}
        placeholder={'Add Contact'}
        onInputChange={(e) => {
          search(e);
        }}
        options={users.map((user) => {
          return {
            value: user.id,
            label: user.emailAddress,
          };
        })}
        styles={singleStyle}
        noOptionsMessage={() => (
          <Button
            className="w-100"
            variant="light"
            size="sm"
            onClick={() => {
              const re = /\S+@\S+\.\S+/;
              if (re.test(inputVal)) {
                onSingleChange({ value: null, label: inputVal });
                setInputVal(null);
              } else {
                toast.warn('Please enter a valid email.');
              }
            }}
          >
            Add Contact
          </Button>
        )}
      />

      <Select2
        key={'userSelectMulti'}
        onChange={onMultiChange}
        placeholder={''}
        isMulti={true}
        value={value || ''}
        options={users.map((user) => {
          return {
            value: user.id,
            label: user.emailAddress,
          };
        })}
        styles={multiStyle}
        className={className}
      />
    </div>
  );
});
