import { useCallback } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import { Api } from '../../services';
import BulkAssetUploadScreen from './BulkAssetUploadScreen';

const csvFile = {
  headers: [
    { label: 'New Asset Name', key: 'newAssetName' },
    { label: 'Keywords (+)', key: 'keywords' },
    { label: 'Key Value Pairs ((+))', key: 'keyValuePairs' },
    { label: 'Existing Beacon IDs (+)', key: 'existingBeaconDeviceName' },
    { label: 'Movement Threshold', key: 'movementThreshold' },
  ],
  exampleData: [
    {
      newAssetName: 'Asset 1',
      keywords: 'zoning-alert+supplies',
      keyValuePairs: '(1+2)(3+4)',
      existingBeaconDeviceName: 'AA:AA:AA:AA:AA:AA+BB:BB:BB:BB:BB:BB',
      movementThreshold: '0',
    },
  ],
  filename: 'BulkAssetUploadTemplate.csv',
};

const csvFileSuccess = {
  headers: [
    { label: 'New Asset ID', key: 'assetId' },
    { label: 'New Asset Name', key: 'newAssetName' },
    { label: 'Keywords (+)', key: 'keywords' },
    { label: 'Key Value Pairs ((+))', key: 'keyValuePairs' },
    { label: 'Existing Beacon IDs +', key: 'existingBeaconDeviceName' },
  ],
  filename: 'BulkAssetUploadSuccess.csv',
};

const csvFileError = {
  headers: [
    { label: 'New Asset Name', key: 'newAssetName' },
    { label: 'Keywords (+)', key: 'keywords' },
    { label: 'Key Value Pairs ((+))', key: 'keyValuePairs' },
    { label: 'Existing Beacon IDs +', key: 'existingBeaconDeviceName' },
    { label: 'Error Messages', key: 'errorMessages' },
  ],
  filename: 'BulkAssetUploadError.csv',
};

async function postBulkAssetUpload(data) {
  return Api.post('/assets/bulk', data);
}

function BulkAssetUpload({ app, onDone }) {
  const bulkAssetUploadMutation = useMutation(postBulkAssetUpload);

  const itemMapper = useCallback(
    (row) => {
      const item = {
        newAssetName: row[0],
        keywords: row[1],
        keyValuePairs: row[2],
        existingBeaconDeviceName: row[3],
        movementThreshold: row[4],
        applicationId: app.id,
      };

      return item;
    },
    [app],
  );

  return (
    <BulkAssetUploadScreen
      itemName="Assets"
      closeDock={onDone}
      csvFile={csvFile}
      csvFileSuccess={csvFileSuccess}
      csvFileError={csvFileError}
      itemMapper={itemMapper}
      mutation={bulkAssetUploadMutation.mutateAsync}
    />
  );
}

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default connect(mapStateToProps)(BulkAssetUpload);
