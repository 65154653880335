import Api from './Api';

class AppService {
  getApps = async (take) => {
    const { data } = await Api.get(`/applications?top=${take}`, {
      cancelToken: Api.CancelToken.source().token,
    });
    return data;
  };
}

export { AppService };
export default new AppService();
