import './ChangePassword.scss';
import '../../scss/animations/animations.scss';
import axios from 'axios';
import { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Errors, LoadingButton, toast } from '../../components';
import { isSSO } from '../../utils';
import { usePasswordValidation } from './../../hooks/usePasswordValidation';

async function updatePassword(data) {
  const onSuccess = () => {
    toast.success('Successfully updated password!');
  };

  axios.defaults.headers.Authorization = `${localStorage.getItem('token')}`;
  if (data.newPassword.firstPassword === data.oldPassword) {
    toast.error('New password must be different from the current password!');
    return false;
  }
  return axios
    .post(`${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authentication/changepassword`, {
      newPassword: data.newPassword.firstPassword,
      oldPassword: data.oldPassword,
    })
    .then(() => {
      onSuccess();
    });
}

function ChangePassword() {
  const [newPassword, setNewPassword] = useState({
    firstPassword: '',
    secondPassword: '',
  });

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] = usePasswordValidation({
    firstPassword: newPassword.firstPassword,
    secondPassword: newPassword.secondPassword,
  });
  const [oldPassword, setOldPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const setFirst = (event) => {
    setNewPassword({ ...newPassword, firstPassword: event.target.value });
  };
  const setSecond = (event) => {
    setNewPassword({ ...newPassword, secondPassword: event.target.value });
  };

  const history = useHistory();

  const onError = (err) => {
    errors.push(err ? err.response.data.message : 'there was an issue resetting your password.');
    toast.error('Failed to update password.');
  };

  const disabled = () => {
    return validLength === true &&
      hasNumber === true &&
      upperCase === true &&
      lowerCase === true &&
      match === true &&
      specialChar === true
      ? false
      : true;
  };

  const callUpdatePassword = async () => {
    try {
      if (oldPassword !== newPassword) {
        await updatePassword({
          oldPassword,
          newPassword,
        });
      } else {
        toast.error('Old password and new password cannot be same.');
      }
    } catch (error) {
      onError();
    }
  };

  if (isSSO()) {
    history.push('/dashboard');
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Errors errors={errors} setErrors={setErrors} />
          </Col>
        </Row>

        <Form className="change-password-form">
          <Form.Group>
            <Row>
              <Col>
                <h2>Change Password</h2>

                <p className="mt-4">Passwords must contain:</p>

                <div className="d-flex position-relative">
                  {validLength && <div className="primary-checkmark draw me-4"></div>}
                  <Form.Text
                    style={{
                      textDecoration: validLength ? 'line-through' : 'none',
                      color: validLength ? 'var(--secondary)' : '',
                    }}
                  >
                    At least 8 characters.
                  </Form.Text>
                </div>

                <div className="d-flex position-relative">
                  {hasNumber && <div className="primary-checkmark draw me-4"></div>}
                  <Form.Text
                    style={{
                      textDecoration: hasNumber ? 'line-through' : 'none',
                      color: hasNumber ? 'var(--secondary)' : '',
                    }}
                  >
                    At least one number.
                  </Form.Text>
                </div>

                <div className="d-flex position-relative">
                  {upperCase && <div className="primary-checkmark draw me-4"></div>}
                  <Form.Text
                    style={{
                      textDecoration: upperCase ? 'line-through' : 'none',
                      color: upperCase ? 'var(--secondary)' : '',
                    }}
                  >
                    At least one upper case letter (A-Z).
                  </Form.Text>
                </div>

                <div className="d-flex position-relative">
                  {lowerCase && <div className="primary-checkmark draw me-4"></div>}
                  <Form.Text
                    style={{
                      textDecoration: lowerCase ? 'line-through' : 'none',
                      color: lowerCase ? 'var(--secondary)' : '',
                    }}
                  >
                    At least one lower case letter (a-z).
                  </Form.Text>
                </div>

                <div className="d-flex position-relative">
                  {specialChar && <div className="primary-checkmark draw me-4"></div>}
                  <Form.Text
                    style={{
                      textDecoration: specialChar ? 'line-through' : 'none',
                      color: specialChar ? 'var(--secondary)' : '',
                    }}
                  >
                    At least one special character.
                  </Form.Text>
                </div>
              </Col>
              <Col>
                <Form.Control
                  className="mb-4"
                  placeholder="Current Password"
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />

                <div className="d-flex position-relative">
                  <Form.Control
                    className="mb-4"
                    placeholder="New Password"
                    type="password"
                    onChange={setFirst}
                  />
                  {validLength && hasNumber && upperCase && lowerCase && specialChar && (
                    <span className="d-flex flex-row mt-2 ">
                      <div className="circle-anim">
                        <div className="checkmark draw"></div>
                      </div>
                    </span>
                  )}
                </div>

                <div className="d-flex position-relative">
                  <Form.Control
                    className="mb-2"
                    placeholder="Confirm New Password"
                    type="password"
                    onChange={setSecond}
                  />
                  {newPassword.firstPassword !== '' &&
                    validLength &&
                    hasNumber &&
                    upperCase &&
                    lowerCase &&
                    specialChar &&
                    match && (
                      <span className="d-flex flex-row mt-2 ">
                        <div className="circle-anim">
                          <div className="checkmark draw"></div>
                        </div>
                      </span>
                    )}
                  {newPassword.secondPassword !== '' && !match && (
                    <span className="d-flex flex-row mt-2">
                      <div className="circle-anim danger">
                        <div className="x-mark draw"></div>
                      </div>
                    </span>
                  )}
                </div>

                <small
                  className="pw-error"
                  style={{
                    visibility: newPassword.secondPassword !== '' && !match ? '' : 'hidden',
                  }}
                >
                  Passwords do not match.
                </small>

                <LoadingButton
                  text="Change My Password"
                  isLoading={false}
                  className="my-3"
                  id="update-pw"
                  onClick={callUpdatePassword}
                  disabled={disabled()}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Container>
    </>
  );
}

export default ChangePassword;
