import { useEffect, useRef, useState } from 'react';
import { Form, Modal, Col, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Api } from '@services';
import { TriggerContact } from '../../../models/TriggerContact';
import { useApp } from '@react';
import AddContact from '../contacts/AddContact/AddContact';
import Icon from '../../Icon/Icon';
import { TriggerWorkflowStepProps } from './TriggerWorkflowStepsProps';

interface ContactsFormProps extends TriggerWorkflowStepProps {
  onAddContact: (model) => void;
}

export function ContactsForm({ trigger, onChange, onAddContact, disabled }: ContactsFormProps) {
  const [triggerContacts, setTriggerContacts] = useState<TriggerContact[]>(trigger.Contacts ?? []);
  const [contacts, setContacts] = useState<any[]>([]);
  const [newContactId, setNewContactId] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const re = new RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'i',
  );
  const app = useApp();
  const typeaheadRef = useRef(null);

  useEffect(() => {
    onChange(triggerContacts, 'Contacts');
    // eslint-disable-next-line
  }, [triggerContacts]);

  useEffect(() => {
    let isLoaded = true;
    const fetch = async () => {
      try {
        const result = await Api.get<any[]>('/contacts');
        if (isLoaded) {
          setContacts(result.data);
        }
      } catch (ex) {}
    };
    fetch();
    // eslint-disable-next-line

    return () => {
      isLoaded = false;
    };
  }, [newContactId]);

  function CreateContact(value: string) {
    let name = null;
    let email = null;
    let phone = null;
    if (re.test(value) === true) {
      // tests for email
      email = value;
    } else if (value.match(/^[\d]{10}$/g)) {
      phone = value;
    } else {
      name = value;
    }

    Api.post('/contacts', {
      ApplicationId: app.id,
      Name: name || null,
      PhoneNumber: phone || null,
      EmailAddress: email || null,
    })
      .then((data: any) => {
        const id = data.data;
        setNewContactId(id);
        setTriggerContacts([
          ...trigger.Contacts,
          new TriggerContact().Create(id, email ?? phone ?? name),
        ]);
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  function AddRemoveContact(value: number) {
    if (trigger.Contacts.find((t) => t.contactId === value)) {
      // if selected value is already in trigger state, remove it.
      setTriggerContacts(trigger.Contacts.filter((t, i) => t.contactId !== value));
    } else {
      // otherwise, we get the full contact object and add to the trigger.
      let contact = contacts.find((c) => c.id === value);
      setTriggerContacts([
        ...trigger.Contacts,
        new TriggerContact().Create(
          contact.id,
          contact.emailAddress || contact.phoneNumber || contact.name,
        ),
      ]);
    }
  }

  return (
    <>
      <Form.Group className="mb-3" as={Row}>
        <Form.Label column sm="2" htmlFor="contacts">
          Contacts
        </Form.Label>
        <Col>
          <Typeahead
            id="contacts"
            className={'w-100'}
            disabled={disabled}
            placeholder="Search..."
            newSelectionPrefix="Add New Contact: "
            onChange={(selected) => {
              let id = selected[0]?.id;
              let contact = selected[0]?.label;
              if (typeof id !== 'string') {
                id && AddRemoveContact(id);
              } else {
                CreateContact(contact);
              }
              typeaheadRef.current.clear();
            }}
            allowNew
            options={
              contacts &&
              contacts
                .filter((c) => c.emailAddress !== null)
                .map((c, i) => {
                  return {
                    id: c.id,
                    label: c.emailAddress || c.phoneNumber,
                  };
                })
            }
            ref={typeaheadRef}
          />
        </Col>
      </Form.Group>
      {trigger.Contacts?.map((contact, index) => {
        return (
          <Form.Group as={Row} key={index}>
            <Form.Label column sm={{ offset: 2 }}>
              {contact.name}
            </Form.Label>
            <Col style={{ display: 'inline-flex' }}>
              <button
                hidden
                className={'btn btn-sm'}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span className={'fa fa-envelope'} />
              </button>
              <button
                hidden
                className={'btn btn-sm'}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span className={'fa fa-comment'} />
              </button>
              <button
                hidden
                className={'btn btn-sm'}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span className={'fa fa-mobile'} />
              </button>
              <Icon
                name="add"
                variant="light"
                size="lg"
                disabled={false}
                onClick={(e) => {
                  e.preventDefault();
                  AddRemoveContact(contact.contactId);
                }}
                style={{ color: 'var(--danger)' }}
                className="sonarIcon-rotate-45 align-sub"
              />
            </Col>
          </Form.Group>
        );
      })}
      <Modal centered show={showModal} backdrop="static" onHide={() => setShowModal(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <AddContact onAddContact={onAddContact} />
        </Modal.Body>
      </Modal>
    </>
  );
}
