import './MapList.scss';
import { Api } from '@services';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import {
  FavoriteEditCellRenderer,
  Icon,
  LinkCellRenderer,
  MapThumbnailCellRenderer,
} from '../../components';
import { PagedQueryParams, ServerSideGrid } from '../../components/grids/AgGrid/ServerSideGrid';
import { PagedModel } from '../../models/PagedModel';
import { mapListColumnDefs } from './columnDefs';

export function MapList() {
  const [rowData, setRowData] = useState<any>(new PagedModel());
  const [csvData, setCsvData] = useState<any>(new PagedModel());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const FetchData = async (payload: any, isCSV: any, queryState: any) => {
    setIsLoading(true);
    Api.get(
      `/maps?top=${isCSV ? rowData.count : queryState.pageSize}&skip=${queryState.skip()}&filter=${
        queryState.filter
      }`,
    ).then((results: any) => {
      if (isCSV) {
        setCsvData(results.data);
      } else {
        setRowData(results.data);
      }
      setIsLoading(false);
    });
  };

  return (
    <Card className="map-list-card h-100">
      <Card.Body>
        <ServerSideGrid
          id={'MapListGrid'}
          columnDefs={mapListColumnDefs}
          handleFetchData={FetchData}
          rowData={rowData}
          csvData={csvData}
          isLoading={isLoading}
          disabledFilters={[]}
          filterControl={false}
          searchControl={true}
          viewingControl={true}
          pagerControl={true}
          exportControl={true}
          gridProps={{
            frameworkComponents: {
              FavoriteEditCellRenderer,
              LinkCellRenderer,
              MapThumbnailCellRenderer,
            },
            rowHeight: 80,
            suppressClickEdit: true,
            editType: 'fullRow',
          }}
          initQueryParams={new PagedQueryParams()}
          csvHeaders={[
            'Id',
            'Map Name',
            'Description',
            'Image Url',
            'PreSigned Image Url',
            'Scale',
            'Width',
            'Height',
            'Keywords',
            'Places',
            'Gateways',
            'Stationary Assets',
            'Stationary Beacons',
            'Assets',
            'PreSigned Thumbnail Url',
            'PreSigned Thumbnail 256 Url',
          ]}
        />
      </Card.Body>
    </Card>
  );
}
