import { useState } from 'react';
import { Button,Col, Row } from 'react-bootstrap';

const ReassignTriggers = (props) => {
  const { asset } = props;
  const [
    currentScreen,
    // setCurrentScreen
  ] = useState('init');
  return currentScreen === 'init' ? (
    <div className="text-center">
      <Row>
        <Col>
          <h3>Select Beacon</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <select className="form-control" placeholder="Select a beacon">
            <option default>Select Beacon Assigned to {asset.name}</option>
            {asset.beacons.map((beacon) => {
              return <option value={beacon.id}>{beacon.name}</option>;
            })}
          </select>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="mt-5">
          <Button variant="success">Complete</Button>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default ReassignTriggers;
