export function matomo() {
  if (
    window._sonar_env.SITE_ID !== '' &&
    window._sonar_env.SITE_URL !== '' &&
    window._sonar_env.ENABLE_MATOMO === 'true'
  ) {
    var _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['setDocumentTitle', document.domain + '/' + document.title]);
    _paq.push(['setUserId', localStorage.getItem('email')]);
    _paq.push(['setCustomUrl', window.location.href]);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = window._sonar_env.SITE_URL;
      var v = u.split('/')[2];
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', window._sonar_env.SITE_ID]);
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = `//cdn.matomo.cloud/${v}/matomo.js`;
      s.parentNode.insertBefore(g, s);
    })();
  }
}
