import { useEffect, useState, useRef } from 'react';
import { Form, Container, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isReadOnly } from '@utils';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Validator from 'yup';
import '../../../../scss/variables.scss';
import './CreateAsset.scss';
import { Text, Icon, Keywords, EquipmentInfo } from '@components';
import { useOnMount } from '@react';

const schema = Validator.object().shape({
  name: Validator.string().min(1).required(),
});

export interface OnCreateAssetEvent {
  name: string;
  keywords: string[];
  identifiers: string[][];
}
interface CreateAssetProps {
  onCreateAsset: (event: OnCreateAssetEvent) => void;
  onClickNext?: () => void;
  isModified?: () => void;
  goBack?: () => void;
  includeBackBtn?: boolean;
}

export function CreateAsset({
  onCreateAsset,
  onClickNext,
  includeBackBtn,
  goBack,
  isModified,
}: CreateAssetProps) {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [isAddingKeyword, setIsAddingKeyword] = useState(false);
  const [identifiers, setIdentifiers] = useState<any>([]);
  const [equipDirty, setEquipDirty] = useState<boolean>(false);
  const [isAddingIdentifier, setIsAddingIdentifier] = useState<boolean>(false);
  const renderTooltip = (tip) => <Tooltip id="button-tooltip-create-asset">{tip}</Tooltip>;

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: schema,
    initialErrors: {
      name: 'Please enter a name for your Asset',
    },
    onSubmit: (values) => {
      const asset = {
        name: values.name,
        keywords,
        identifiers: identifiers.map((row) => [row.key, row.value]),
      };

      onCreateAsset(asset);
      onClickNext();
    },
  });

  useEffect(() => {
    if (formik.dirty) {
      isModified ? isModified() : null;
    }
  }, [formik]);

  const nameInputRef = useRef(null);

  useOnMount(() => nameInputRef.current?.focus());

  return (
    <Container className="h-100 d-flex flex-column">
      <Row>
        <Col>
          <Text as="h2" className="mb-5">
            Create New Asset
          </Text>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="position-relative">
              <Form.Label>Name *</Form.Label>
              <Form.Control
                ref={nameInputRef}
                name="name"
                type="text"
                placeholder="Asset Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.name && formik.touched.name}
                onBlur={formik.handleBlur}
              />
              <Form.Control.Feedback type="invalid" className="position-absolute">
                Please enter a name for your Asset.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="mb-5 mt-4">
              <OverlayTrigger
                placement="left-start"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  `Keywords can be identifying and/or descriptive information to help group, locate, and characterize your Asset. Example “manufacturing” or “supplies”.`,
                )}
              >
                <span className="d-flex align-items-center" style={{ width: 'fit-content' }}>
                  <Text className="fs-lg">Add Keywords</Text>
                  <Icon
                    name="add"
                    size="sm"
                    variant="light"
                    disabled={false}
                    style={{ color: 'var(--primary)' }}
                    className={'sonarIcon-circle'}
                    onClick={() => setIsAddingKeyword((prev) => !prev)}
                  />
                </span>
              </OverlayTrigger>
              <Keywords
                isAddingKeyword={isAddingKeyword}
                onChange={(keywords) => setKeywords(keywords)}
              />
            </div>

            <div className="mb-5 mt-4">
              <OverlayTrigger
                placement="left-start"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  `Key-Value Pairs can be identifying and/or descriptive pairs of information to help group, locate, and characterize your Asset. Example “tool, craftsman”.`,
                )}
              >
                <span className="d-flex align-items-center" style={{ width: 'fit-content' }}>
                  <Text className="fs-lg">Key-Value Pairs</Text>
                  <Icon
                    name="add"
                    size="sm"
                    variant="light"
                    disabled={!isReadOnly() ? false : true}
                    style={{ color: 'var(--primary)' }}
                    className={'sonarIcon-circle'}
                    onClick={() => {
                      setIsAddingIdentifier(true);
                    }}
                  />
                </span>
              </OverlayTrigger>
              <EquipmentInfo
                setIsAddingIdentifier={setIsAddingIdentifier}
                isAddingIdentifier={isAddingIdentifier}
                initData={identifiers}
                setEquipDirty={setEquipDirty}
                updateIdentifiers={(identifiers: any) => {
                  isModified();
                  setIdentifiers(identifiers);
                }}
              />
            </div>

            {includeBackBtn ? (
              <div className="d-flex flex-row justify-content-center">
                <Button
                  variant="secondary"
                  className="my-2 back-btn"
                  style={{ width: 200 }}
                  onClick={goBack}
                >
                  Back
                </Button>
              </div>
            ) : (
              ''
            )}

            <div className="w-100 d-flex flex-row justify-content-center">
              <Button
                type="submit"
                variant="primary"
                className="my-2 next-btn"
                style={{ width: 200 }}
                disabled={!isEmpty(formik.errors)}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

CreateAsset.propTypes = {
  onCreateAsset: PropTypes.func.isRequired,
};

export default CreateAsset;
