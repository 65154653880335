import EventEmitter from 'eventemitter3';

export const emitter = new EventEmitter();

export const EVENTS = {
  REFETCH: 'REFETCH',
};

export const csvHeaders = {
  gateways: [
    'id',
    'name',
    'deviceId',
    'keywords',
    'lastCheckin',
    'readings',
    'isOnline',
    'place',
    'healthCheckTimestamp',
    'macAddress',
  ],
  beacons: [
    'name',
    'deviceId',
    'beaconTypeId',
    'lastCheckin',
    'assetName',
    'firstSeen',
    'id',
    'uuid',
    'power',
    'assetId',
    'assigned',
    'beaconType',
    'isOnline',
    'keywords',
    'readings',
    'location',
    'macAddress',
    'isLocationBeacon',
  ],
  triggers: [
    'id',
    'name',
    'description',
    'keywords',
    'filter',
    'severity',
    'triggerType',
    'summary',
    'lastModified',
    'instance',
  ],
  userManagement: ['userId', 'userName', 'phoneNumber', 'emailConfirmed', 'userRole', 'lockoutEnd'],
};
