import Api from './Api';

import { IPostAsset, CreateAsset } from '@types';

interface IAssetService {
  createAsset: (asset: CreateAsset) => Promise<CreateAsset & { id: number }>;
}

class AssetService implements IAssetService {
  createAsset = async (asset: CreateAsset): Promise<CreateAsset & { id: number }> => {
    const res = await Api.post<IPostAsset, number>('/assets', asset);

    return {
      id: res.data,
      ...asset,
    };
  };
}

const assetService = new AssetService();
export { AssetService, assetService };
export default assetService;
