// general utils

import moment from 'moment';

export function noop() {}

/**
 * @desc Helper to sort an array of objects.
 * @param {Array} arr array to sort
 * @param {string} key Key of object in array to sort by
 * @param {boolean} asc Sort in ascending order
 */
export function sortArrayObjsByKey(arr, key, asc = true) {
  const sortedCopy = [...arr];
  sortedCopy.sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA < valueB) return asc ? -1 : 1;
    if (valueA > valueB) return asc ? 1 : -1;
    return 0;
  });
  return sortedCopy;
}

export function getFormattedDateTime(value, invalidStr = '--') {
  const m = moment(value);
  if (!m.isValid()) return invalidStr;
  return m.format('llll');
}

export function getFormattedBatteryDateTime(value) {
  const m = moment(value);
  return value ? m.format('llll') : '--';
}

export function getFormattedBool(value, { isFalse = 'NO', isTrue = 'YES' } = {}) {
  return value ? isTrue : isFalse;
}

export function getValueOrDefault(value, theDefault = '--') {
  return value || theDefault;
}

export function getFormattedLocation(location) {
  return `(${Number(location.x).toFixed(0)}, ${Number(location.y).toFixed(0)})`;
}

export function isLmcoEnv() {
  return window._sonar_env.REACT_APP_ENV.includes('lmco');
}

export function isLegacyAuthEnabled() {
  return window._sonar_env.REACT_APP_ENABLE_LEGACY_AUTH === 'true' ? true : false;
}

export function prependString(value, string) {
  return string + value;
}

export function formatPhoneNumber(value) {
  const startsWithAlpha = /^[a-zA-Z]/.test(value);
  // If the value is null or empty string, return '--'
  if (!value || typeof value !== 'string' || value.trim().length === 0) {
    return '--';
  }

  // Remove non-digit characters
  const cleaned = value.replace(/\D/g, '');

  // Check if the value consists of only numbers
  if (/^\d+$/.test(cleaned)) {
    // Format according to length to determine country code
    if (cleaned.length === 10) {
      // Format as (XXX) XXX-XXXX for 10-digit US numbers
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    } else if (cleaned.length > 10) {
      // For longer numbers, add country code
      return `+${cleaned.slice(0, cleaned.length - 10)} (${cleaned.slice(-10, -7)}) ${cleaned.slice(
        -7,
        -4,
      )}-${cleaned.slice(-4)}`;
    } else {
      // Invalid length, return '--'
      return value;
    }
  } else if (startsWithAlpha) {
    return value;
  } else {
    // Value contains non-numeric characters, return '--'
    return '--';
  }
}
