import * as types from '../types';

const trigger = (
  state = { thingTriggers: [], applicationTriggers: [], triggerDetails: {} },
  action,
) => {
  switch (action.type) {
    case types.TRIGGER_THING_GET_COMPLETED:
      return Object.assign({}, state, {
        thingTriggers: action.triggers,
      });

    case types.TRIGGER_APPLICATION_GET_COMPLETED:
      return Object.assign({}, state, {
        applicationTriggers: action.triggers,
      });

    case types.TRIGGER_GET_COMPLETED:
      return Object.assign({}, state, {
        triggerDetails: action.trigger,
      });

    default:
      return state;
  }
};

export default trigger;
