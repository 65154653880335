import * as types from '../types';

const peripherals = (
  state = {
    isLoading: false,
    items: [],
    total: 0,
  },
  action,
) => {
  switch (action.type) {
    case types.PERIPHERALS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PERIPHERALS_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        items: action.peripherals,
        total: action.total,
      });
    default:
      return state;
  }
};

export default peripherals;
