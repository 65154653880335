import {
  BeaconReadingsCellRenderer,
  LinkCellRenderer,
  OnlineStatusCellRenderer,
  PlacesCellRenderer,
} from '@components';
import { getFormattedDateTime } from '../../utils';

export const assetColumnDefs = [
  {
    headerName: '',
    field: 'status',
    cellRenderer: OnlineStatusCellRenderer,
    valueGetter: ({ data }) => (data.status ? 1 : 0),
    width: 60,
    minWidth: 60,
    maxWidth: 60,
  },
  {
    headerName: 'Name',
    field: 'name',
    colId: 'name',
    cellRenderer: LinkCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `/assets/${data.id}`,
      text: data.name || '--',
      isBlock: false,
    }),
  },
  {
    headerName: 'Location',
    field: 'location.places',
    colId: 'places',
    cellRenderer: PlacesCellRenderer,
    valueFormatter() {
      return;
    },
  },
  {
    headerName: 'Last Check-In',
    field: 'lastCheckin',
    valueFormatter(params) {
      return getFormattedDateTime(params.value);
    },
  },
  {
    headerName: 'Readings',
    field: 'beacons',
    colId: 'readings',
    cellRenderer: BeaconReadingsCellRenderer,
    valueFormatter() {
      return;
    },
  },
];
