import * as L from 'leaflet';

const createZoneLayers = (zone) => {
  let layers = [];
  if (zone.isVisible) {
    if (zone.isHighlighted) {
      layers.push(createHighlightedZonePolygon(zone));
    } else {
      layers.push(createZonePolygon(zone));
    }
  }
  return layers;
};

const createZonePolygon = (zone) => {
  let latLngs = [];
  if (zone.boundary) {
    for (let pt of zone.boundary) {
      latLngs.push([pt.y, pt.x]);
    }

    let poly = L.polygon(latLngs, {
      color: '#ff7a67',
      weight: 2,
      fillOpacity: zone.fillOpacity || 0,
      fillColor: zone.fillColor,
    });
    poly.zoneIndex = zone.index;

    if (zone.name) poly.bindPopup(zone.name);

    return poly;
  } else {
    return null;
  }
};

const createZonesLayerGroup = (zones) => {
  return new L.FeatureGroup(createZonesLayerArray(zones));
};

const createZonesLayerArray = (zones) => {
  if (!zones) {
    return [];
  }

  const layers = [];
  for (let zone of zones) {
    const zoneLayers = createZoneLayers(zone);
    if (zoneLayers) {
      for (let layer of zoneLayers) {
        if (layer) {
          layers.push(layer);
        }
      }
    }
  }
  return layers;
};

const createHighlightedZonePolygon = (zone) => {
  let latLngs = [];
  for (let pt of zone.boundary) {
    latLngs.push([pt.y, pt.x]);
  }

  return L.polygon(latLngs, {
    color: '#ff7a67',
    weight: 4,
    fillOpacity: 0.4,
  });
};

export { createZoneLayers, createZonesLayerGroup };
