import { Api } from '@services';
import { useState } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';

export function KeywordsInput({ valid, invalid, disabled, initFilter, onFilterChange }) {
  const [cache, setCache] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [query, setQuery] = useState<string>('');

  const getResults = async (call) => {
    const resp = await Api.get<any[]>(call);
    const newItems = resp.data.map((value, index) => ({
      id: index,
      name: value,
    }));
    return { newItems };
  };

  const handleSearch = (value) => {
    setQuery(value);
    // if cached query items are available then set those as the initial options
    if (cache[value]) {
      setOptions(cache[value].items);
    } else {
      // get and cache new query items
      const initialCall = `/assets/filtered/keywords(${value})/keywords`;
      setIsLoading(true);
      getResults(initialCall).then((resp) => {
        setOptions(resp.newItems);
        setCache({
          ...cache,
          [value]: { items: resp.newItems },
        });
        setIsLoading(false);
      });
    }
  };

  const handleChange = (keyword) => {
    if (keyword.length > 0) {
      let value = keyword[0]?.label;
      onFilterChange(`keywords(${value})`);
    } else {
      onFilterChange(null);
    }
  };

  return (
    <AsyncTypeahead
      allowNew
      defaultInputValue={initFilter}
      delay={500}
      disabled={disabled}
      id="keyword"
      inputProps={{ style: { width: '100%' } }}
      isInvalid={invalid}
      isLoading={isLoading}
      isValid={valid}
      maxResults={25}
      minLength={1}
      onChange={handleChange}
      onSearch={handleSearch}
      options={
        options &&
        options.map((option) => {
          return {
            id: option.id,
            label: option.name,
          };
        })
      }
      paginate
      placeholder="Search for a Keyword..."
      renderMenuItemChildren={(option, { text }) => (
        <div title={option.label}>
          <Highlighter search={text}>{option.label}</Highlighter>
          <div>
            <small className="filter-sm-name">Keyword ID: {option.id}</small>
          </div>
        </div>
      )}
      useCache={false}
    />
  );
}
