import '../../../scss/variables.scss';
import { ThemeProvider } from '@material-ui/styles';
import { DateTimePicker } from 'material-ui-pickers';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Row, Tab, Tabs } from 'react-bootstrap';
import { DateTheme } from '../../../scss/themeOverrides/DateTheme';
import Icon from '../../Icon/Icon';

const TABS = [
  {
    key: 'calendar',
    title: (
      <i
        className="sonarIcon sonarIcon-calendar align-sub fs-lg"
        title="End Date"
        style={{ color: 'var(--primary)' }}
      />
    ),
  },
  {
    key: '- 12 hr',
    getDate: (date) => date.setHours(date.getHours() - 12),
  },
  {
    key: '- 24 hr',
    getDate: (date) => date.setHours(date.getHours() - 24),
  },
  {
    key: '- 3 days',
    getDate: (date) => date.setHours(date.getHours() - 72),
  },
  {
    key: '- 5 days',
    getDate: (date) => date.setHours(date.getHours() - 120),
  },
];

function DateTabs({ onChangeDate }) {
  const [tabs] = useState(TABS);
  const [tabKey, setTabKey] = useState(tabs[1].key);
  const [startDate, setStartDate] = useState(new Date(tabs[1].getDate(new Date())));
  const [endDate, setEndDate] = useState(new Date());
  const [calToggle, setCalToggle] = useState(false);

  useEffect(() => {
    onSelect('- 12 hr');
  }, []);

  useEffect(() => {
    onChangeDate(startDate, endDate);
  }, [startDate, endDate]);

  const onStartChange = (e) => {
    setStartDate(new Date(e));
  };

  const onEndChange = (e) => {
    setEndDate(new Date(e));
    onStartChange(tabs.find((tab) => tab.key === tabKey).getDate(new Date(e)));
  };

  function onSelect(key) {
    if (key === 'calendar') {
      setCalToggle(!calToggle);
    } else {
      setTabKey(key);
      onStartChange(tabs.find((tab) => tab.key === key).getDate(new Date(endDate)));
    }
  }

  return (
    <Row className="d-flex flex-row align-items-center me-1 ignore-click">
      <Tabs activeKey={tabKey} onSelect={onSelect} style={{ flexWrap: 'nowrap' }}>
        {calToggle === true && (
          <Tab
            key={'Date'}
            title={
              <ThemeProvider theme={DateTheme}>
                <DateTimePicker value={endDate} onChange={onEndChange} maxDate={new Date()} />
              </ThemeProvider>
            }
          />
        )}
        {tabs.map((tab) => (
          <Tab key={tab.key} eventKey={tab.key} title={tab.title || tab.key} />
        ))}
      </Tabs>
    </Row>
  );
}

DateTabs.propTypes = {
  onChangeDate: PropTypes.func.isRequired,
};

export default DateTabs;
