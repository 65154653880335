import { LinkCellRenderer } from '@components';
import { getFormattedDateTime } from '@utils';
import { ColDef } from 'ag-grid-community';
import { TriggerListModel } from '../../models/Trigger';

function NameColumn(): ColDef {
  return {
    headerName: 'Name',
    field: 'name' as keyof TriggerListModel,
    colId: 'name',
    cellRenderer: LinkCellRenderer,
    sortable: true,

    cellRendererParams: (data: any) => {
      return {
        to: `/triggers/${data.data.id}`,
        text: data?.data?.name || '--',
      };
    },
  } as ColDef;
}

function KeywordsColumn(): ColDef {
  return {
    headerName: 'Keywords',
    field: 'keywords' as keyof TriggerListModel,
    colId: 'keywords',
    sortable: false,
    valueFormatter: (params: any) => {
      return (params.data.keywords as string[])?.join(', ');
    },
  } as ColDef;
}

function LastModifiedColumn(): ColDef {
  return {
    headerName: 'Last Modified',
    field: 'lastModified' as keyof TriggerListModel,
    valueFormatter(params) {
      return getFormattedDateTime(params.value);
    },
    colId: 'lastModified',
    sortable: true,
  } as ColDef;
}

export const triggerColDefs = [NameColumn(), KeywordsColumn(), LastModifiedColumn()] as ColDef[];
