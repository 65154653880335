import * as L from 'leaflet';
import {} from 'leaflet.awesome-markers';

// Static Maps
const createPath = (locations) => {
  let lines = [];

  for (let i = 0; i < locations.length - 1; i++) {
    let line;
    line = L.polyline([
      [locations[i].location.y, locations[i].location.x],
      [locations[i + 1].location.y, locations[i + 1].location.x],
    ]).setStyle({ weight: '4', color: '#0048FF' });

    lines.push(line);
  }

  return lines;
};

// GPS Maps
const createGeoPath = (locations) => {
  let lines = [];

  for (let i = 0; i < locations.length - 1; i++) {
    let line;
    if (i === 0) {
      //Auto fill position 0 of the path array
      line = L.polyline([
        [locations[i].location.x, locations[i].location.y],
        [locations[i].location.x, locations[i].location.y],
      ]).setStyle({ weight: '8' });
    } else if (i === locations.length - 1) {
      //Last item in array need to render i,i
      line = L.polyline([
        [locations[i].location.x, locations[i].location.y],
        [locations[i].location.x, locations[i].location.y],
      ]).setStyle({ weight: '8' });
    } else if (locations[i].location.type === 1 && locations[i + 1].location.type === 1) {
      // GPS
      // PolyLine Pair Matches same Geo Map type [x=1,y=1]
      line = L.polyline([
        [locations[i].location.x, locations[i].location.y],
        [locations[i + 1].location.x, locations[i + 1].location.y],
      ]).setStyle({ weight: '8' });
    } else if (locations[i].location.type === 1 && locations[i + 1].location.type === 0) {
      // GPS
      // PolyLine Pair is Geo -> Static
      // Need to render i, i rather than i, i+1
      line = L.polyline([
        [locations[i].location.x, locations[i].location.y],
        [locations[i].location.x, locations[i].location.y],
      ]).setStyle({ weight: '8' });
    } else if (locations[i].location.type === 0 && locations[i + 1].location.type === 0) {
      // STATIC
      // PolyLine Pair Matches for Static Map
      // CHECK MAPID for a match before rendering normally, if MAPID doesn't match will need to output [i, i] if it does match output [i, i+1]
      if (locations[i].mapId === locations[i + 1].mapId) {
        // SAME MAP
        line = L.polyline([
          [locations[i].location.x, locations[i].location.y],
          [locations[i + 1].location.x, locations[i + 1].location.y],
        ]).setStyle({ weight: '8' });
      } else {
        // NOT SAME MAP
        line = L.polyline([
          [locations[i].location.x, locations[i].location.y],
          [locations[i].location.x, locations[i].location.y],
        ]).setStyle({ weight: '8' });
      }
    } else if (locations[i].location.type === 0 && locations[i + 1].location.type === 1) {
      // STATIC
      // PolyLine Pair is Static -> Geo
      // Need to render i,i rather than i,i+1
      line = L.polyline([
        [locations[i].location.x, locations[i].location.y],
        [locations[i].location.x, locations[i].location.y],
      ]).setStyle({ weight: '8' });
    }
    lines.push(line);
  }

  return lines;
};

export { createGeoPath,createPath };

// let arr = [
//     [1, 1], // draw
//     [1, 1], // d
//     [1, 0], // draw 1,1 using the same coordinates
//     [0, 0], // d
//     [0, 0],
//     [0, 1],
// ];

// x = i y = 1+1

// on map switch create dummy point thats currently selected coord for both x,y
// when leaving a map

// Are both x and y the same map type with the same map id then draw normally
// if theyre different, utilize a x,x drawing

// LOGIC:

// If we change to a new map dummy point of y,y from previous map
// the point before leaving a map would be a dummy point of x,x - might not be needed

// check mapid and type on i and i - 1 && i and i + 1

// ^^^ keeps the arrays the same size as the master array

// when drawing the lines check for map type of index
