import { useEffect, useState } from 'react';
import Select2 from 'react-select';

const AdminUserAppSelector = (props) => {
  const [selectedApp, setSelectedApp] = useState({ label: 'Select an Application', value: '' });
  const AppList = props.appList?.map((app) => ({
    value: app.id,
    label: app.name,
  }));

  const handleChange = (selectedOption) => {
    setSelectedApp(selectedOption);
    window.history.pushState({}, '', `/admin/users/${selectedOption.value}`);
  };

  useEffect(() => {
    if (selectedApp) {
      props.setAppIdSelected(selectedApp.value, selectedApp.label);
    }
  }, [selectedApp, props]);

  return (
    <Select2
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: 'var(--c-primary)',
        },
      })}
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (styles, state) => ({
          ...styles,
          backgroundColor: state.isSelected ? 'var(--c-primary)' : null,
          color: state.isSelected ? 'white' : 'black',
          '&:hover': {
            backgroundColor: state.isSelected ? 'var(--c-primary)' : '#e0ecfc',
          },
        }),
        singleValue: (base, style) => ({
          ...base,
          color: 'hsl(0, 0%, 50%)',
        }),
      }}
      options={AppList}
      placeholder="Select an Application"
      onChange={handleChange}
      value={selectedApp}
    />
  );
};

export default AdminUserAppSelector;
