import axios from 'axios';
import React from 'react';
import { Button,Card, Col, Form, Row } from 'react-bootstrap';
import { useHistory,useParams } from 'react-router-dom';
import { parseString } from 'xml2js';

export default () => {
  const history = useHistory();
  const { id } = useParams();
  const [article, setArticle] = React.useState({});
  React.useEffect(() => {
    axios
      .get(`https://thinaer.io/feed/?post_type=article&p=${id}&withoutcomments=1`)
      .then(({ data }) => {
        parseString(data, { trim: true }, (err, result) => {
          setArticle(
            result.rss.channel[0].item.map((i) => {
              i.title = i.title[0];
              // i.id = i.guid[0]._.split('p=')[1];
              return i;
            })[0],
          );
        });
      });
  }, []);

  return (
    <Row>
      <Col lg={10} className="m-auto">
        <Row>
          <Col>
            <Button onClick={() => history.push('/help')} variant="transparent" size="sm">
              <i class="fas fa-chevron-circle-left text-primary"></i> Help Articles
            </Button>
          </Col>
        </Row>
        <Col>
          <h1 className="text-primary mb-3" onClick={() => history.push('/help/' + article.id)}>
            {article.title}
          </h1>
          <Row>
            {article['content:encoded'] && (
              <Col
                dangerouslySetInnerHTML={{ __html: article['content:encoded'][0].split('|||')[0] }}
              />
            )}
          </Row>
        </Col>
      </Col>
    </Row>
  );
};
