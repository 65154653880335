import { UPDATE_SEARCH_FILTER, UPDATE_SEARCH_STRING } from '../types';

const initState = {
  searchString: '',
  searchFilter: '',
};

export function updateSearchFilterAction(searchFilter) {
  return {
    type: UPDATE_SEARCH_FILTER,
    searchFilter,
  };
}

function handleUpdateSearchFilter(state, { searchFilter }) {
  return {
    ...state,
    searchFilter,
  };
}

export function updateSearchStringAction(searchString) {
  return {
    type: UPDATE_SEARCH_STRING,
    searchString,
  };
}

function handleUpdateSearchString(state, { searchString }) {
  return {
    ...state,
    searchString,
  };
}

const actionHandler = {
  [UPDATE_SEARCH_FILTER]: handleUpdateSearchFilter,
  [UPDATE_SEARCH_STRING]: handleUpdateSearchString,
};

const reducer = (state = initState, action) => {
  const handlerFn = actionHandler[action.type];

  if (!handlerFn) return state;

  return handlerFn(state, action);
};

export default reducer;
