/* eslint-disable no-unused-vars */
import axios from 'axios';
import {
  authLogoutAction,
  authSetTokenAction,
  authSetUserAction,
  history,
  store,
  userPrefsSetAssetFiltersAction,
} from '../redux';
import { Api } from '.';

class AuthService {
  login = async ({ username, password }) => {
    const { data } = await axios.post(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authentication/login`,
      {
        username,
        password,
      },
    );

    if (!data.authenticated) throw new Error('Not authenticated');

    axios.defaults.headers.Authorization = `Bearer ${data.accessToken}`;

    const { user } = data;
    // Checking if user has default application id or automatically selects the first application id
    const defaultAppId =
      user.defaultApplication || (user.applications && user.applications.length > 0)
        ? Number(user.applications[0])
        : null;

    store.dispatch(
      authSetUserAction({
        id: user.id,
        email: user.email,
        defaultApplication: defaultAppId,
        apps: user.applications,
        role: user.role,
      }),
    );
    store.dispatch(authSetTokenAction(data.accessToken));
    store.dispatch(userPrefsSetAssetFiltersAction(user.assetFilters));

    // TODO: keeping these localStorage calls in place until move on to new api and can dump prev Axios instance
    localStorage.setItem('email', data.user.email);
    localStorage.setItem('defaultApplication', defaultAppId);
    localStorage.setItem('applications', data.user.applications);
    localStorage.setItem('token', 'Bearer ' + data.accessToken);
    localStorage.setItem('tokenExpiration', data.tokenExpiration);
    localStorage.setItem('assetFilters', JSON.stringify(data.user.assetFilters));
    localStorage.setItem('refreshTokenExpiration', data.refreshTokenExpiration);
    localStorage.setItem('refreshToken', data.refreshToken);

    return data;
  };

  sso = async () => {
    window.open(`${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authenticate?isWeb=true`, '_blank');
  };

  signup = async ({ username }) => {
    const { data } = await axios.post(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/registration/register`,
      {
        username,
        email: username,
      },
    );

    return data;
  };

  forgotPassword = async ({ username }) => {
    return axios.post(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authentication/forgotpassword`,
      {
        email: username,
      },
    );
  };

  logout = async () => {
    localStorage.removeItem('newUserConfirmation');
    history.push('/login');
    const userTheme = localStorage.getItem('theme');
    localStorage.clear();
    localStorage.setItem('theme', userTheme);
    store.dispatch(authLogoutAction());
  };

  confirmUser = async ({ userId, token }) => {
    return axios.post(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authentication/confirmRegistration/${userId}?${token}`,
    );
  };

  confirmForgot = async ({ email, password, token }) => {
    return axios.post(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authentication/confirmforgot?confirmationToken=${token}`,
      {
        email,
        password,
      },
    );
  };

  getUser = async (id) => {
    return axios.get(`${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/users/${id}`);
  };

  getMe = async () => {
    return Api.get(`/me`);
  };
}

export default new AuthService();
export { AuthService };
