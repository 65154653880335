import './editMap.scss';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { OnlineStatusCellRenderer } from '../../../../components';
import { getFormattedLocation } from '../../../../utils';
import BeaconEditRow from './beaconEditRow';

class BeaconList extends Component {
  render() {
    return (
      <div>
        <div className="btn-group btn-group-sm w-100">
          <button
            className="btn btn-primary btn-sm ms-4 me-4 mt-2 mb-2"
            type="button"
            onClick={() => {
              this.props.onAdd(), this.setState({ isNewBeacon: true });
            }}
            disabled={this.props.editing}
          >
            Add Stationary Beacon
          </button>
        </div>

        <ul className="list-group list-group-flush edit-map-device-list">
          {/* Add Stationary Beacon Process / Edit Existing Beacons */}
          {this.props.beacons.map((b, i) => {
            return (
              <>
                {this.props.editing &&
                  b.index === this.props.editing.index &&
                  b.beacon == null &&
                  this.state?.isNewBeacon && (
                    <BeaconEditRow
                      beacon={b}
                      excludedSearchItems={this.props.excludedSearchItems}
                      onSearchFilterChanged={this.props.onSearchFilterChanged}
                      searchItems={this.props.searchItems}
                      onEditCompleted={this.props.onEditCompleted}
                      onAddCanceled={this.props.onAddCanceled}
                      onEditMap={this.props.onEditMap}
                    />
                  )}
                {this.props.editing &&
                  b.index === this.props.editing.index &&
                  b.beacon !== null &&
                  !this.state?.isNewBeacon && (
                    <BeaconEditRow
                      beacon={b}
                      excludedSearchItems={this.props.excludedSearchItems}
                      onSearchFilterChanged={this.props.onSearchFilterChanged}
                      searchItems={this.props.searchItems}
                      onEditCompleted={this.props.onEditCompleted}
                      onAddCanceled={this.props.onAddCanceled}
                      onEditMap={this.props.onEditMap}
                    />
                  )}
              </>
            );
          })}

          {/* List of Beacons / Hides when Adding New Beacon or Editing Beacons */}
          {!this.props.editing && (
            <>
              {Boolean(this.props.beacons.length) && (
                <>
                  <li className="d-flex list-group-item p-1 justify-content-between align-items-center ps-4 pe-4">
                    <div className="w-50">Beacons</div>
                    <div className="w-25">
                      <span className="float-right">Coordinates</span>
                    </div>
                    <div className="w-25"></div>
                  </li>
                </>
              )}
              {this.props.beacons.map((b, i) => {
                return (
                  <>
                    {b.beacon?.name && b.location && (
                      <li
                        className="list-group-item list-group-item-action ps-4 pe-4 pt-1 pb-1"
                        key={i}
                      >
                        <div className="w-100 d-flex align-items-center justify-content-between">
                          <span
                            className="w-50"
                            onClick={() => this.props.onSelect('beacons', 'index', b.index)}
                          >
                            {
                              <OnlineStatusCellRenderer
                                value={Boolean(b.beacon?.isOnline) ? 1 : 0}
                              />
                            }{' '}
                            {b.isVisible ? (
                              <strong>{b.beacon?.name}</strong>
                            ) : (
                              <span>{b.beacon?.name}</span>
                            )}
                          </span>

                          <div className="w-25">
                            {b.location ? (
                              <span className="float-right">
                                {getFormattedLocation(b.location)}
                              </span>
                            ) : (
                              false
                            )}
                          </div>
                          <div className="w-25">
                            <div className="btn-group float-right edit-beacons-btns">
                              <span
                                className="btn flat-btn btn-default p-2 edit-properties-btn"
                                onClick={() => {
                                  this.props.onEdit(b), this.setState({ isNewBeacon: false });
                                }}
                                title="Edit Properties"
                              >
                                <i className="fas fa-edit" />
                              </span>
                              <span
                                className="btn flat-btn btn-default p-2"
                                onClick={() => this.props.onEditMap(b)}
                                title="Change Beacons Location"
                              >
                                <i className="fas fa-map-pin" />
                              </span>
                              <span
                                onClick={() => this.props.onRemove('beacons', i)}
                                className="btn flat-btn btn-default p-2"
                                title="Remove from Map"
                              >
                                <i className="fas fa-times text-danger" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </>
                );
              })}
            </>
          )}
        </ul>
      </div>
    );
  }
}

BeaconList.propTypes = {
  editing: PropTypes.object,
  beacons: PropTypes.array,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onEditMap: PropTypes.func,
  onSearchFilterChanged: PropTypes.func,
  excludedSearchItems: PropTypes.array,
  searchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
};

export default BeaconList;
