import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { Api } from '../../services';
import { Loading, Text, toast } from '../../components';

async function fetchData({ deviceId }) {
  const { data } = await Api.get<string>(`/beacons/${deviceId}/asset`);
  return data;
}

function getRelativeAssetUrl(absoluteAssetUrl: string) {
  const routeArray = absoluteAssetUrl.split('/');
  return '/' + routeArray.slice(routeArray.length - 2).join('/');
}

function BeaconIdRedirectToAsset() {
  const { deviceId } = useParams<{ deviceId: string }>();

  const onSuccess = () => toast.success('Redirecting to asset!');

  const res = useQuery<string, AxiosError>(['beaconId-redirect'], () => fetchData({ deviceId }), {
    onSuccess,
  });

  if (res.isLoading) return <Loading />;

  if (res.isError) return <Text variant="danger">{res.error.response.data}</Text>;

  const assetRoute = getRelativeAssetUrl(res.data);

  return <Redirect to={assetRoute} />;
}

export default BeaconIdRedirectToAsset;
