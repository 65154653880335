const initState = {
  // slice of state for EditDeleteCellRenderer
  editDeleteCR: {
    id: null,
    isEditing: false,
  },
};

export const GRIDS_SET_EDIT_DELETE_CR = 'GRIDS_SET_EDIT_DELETE_CR';

export function gridsSetEditDeleteCRAction({ editDeleteCR }) {
  return { type: GRIDS_SET_EDIT_DELETE_CR, editDeleteCR };
}

export function gridsResetEditDeleteCRAction() {
  return {
    type: GRIDS_SET_EDIT_DELETE_CR,
    editDeleteCR: {
      id: null,
      isEditing: false,
    },
  };
}

function handleSetEditDeleteCR(state, { editDeleteCR }) {
  return {
    ...state,
    editDeleteCR,
  };
}

const actionHandler = {
  [GRIDS_SET_EDIT_DELETE_CR]: handleSetEditDeleteCR,
};

const reducer = (state = initState, action) => {
  const handlerFn = actionHandler[action.type];

  if (!handlerFn) return state;

  return handlerFn(state, action);
};

export default reducer;
