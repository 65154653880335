import Icon from '../../../Icon/Icon';
import Text from '../../../Text/Text';
import '../../../../scss/variables.scss';

function IconValueCellRenderer({ iconName, value }) {
  return (
    <>
      <Icon
        name={iconName}
        variant="dark"
        disabled={false}
        showPointer={false}
        style={{ color: 'var(--primary)' }}
      />
      <Text>{value}</Text>
    </>
  );
}

export default IconValueCellRenderer;
