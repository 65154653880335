import { useEffect } from 'react';

import { noop } from '../../utils';

function useOnMount(cb: () => void = noop) {
  useEffect(() => {
    cb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useOnMount;
