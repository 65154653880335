const mapOptions = {
  gatewayVisibility: true,
  zoneVisibility: true,
  beaconVisibility: true,
};

const assignPropsToMap = (map, options = mapOptions) => {
  if (!map.zones) {
    map.zones = [];
  } else {
    map.zones = map.zones.map((z, i) => {
      z.zone = { ...z };
      z.index = i + 1;
      z.isHighlighted = false;
      z.isSelected = false;
      z.isVisible = options.zoneVisibility;
      return z;
    });
  }

  if (!map.gateways) {
    map.gateways = [];
  } else {
    map.gateways = map.gateways.map((z, i) => {
      z.gateway = { ...z };
      z.index = i + 1;
      z.isHighlighted = false;
      z.isSelected = false;
      z.isVisible = options.gatewayVisibility;
      return z;
    });
  }

  if (!map.beacons) {
    map.beacons = [];
  } else {
    map.beacons = map.beacons.map((b, i) => {
      b.beacon = { ...b };
      b.index = i + 1;
      b.isHighlighted = false;
      b.isSelected = false;
      b.isVisible = options.beaconVisibility;
      return b;
    });
  }

  return map;
};

const All = {
  assignPropsToMap,
};
export default All;
