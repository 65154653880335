import { createStore } from 'redux';
import { persistStore } from 'redux-persist';

import reducer from '../reducers';

function initializeStore() {
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducer,
    undefined,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  );

  const persistor = persistStore(store);

  return { store, persistor };
}

const { store, persistor } = initializeStore();

export { store, persistor };
