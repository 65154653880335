import { Api } from '@services';
import { useEffect, useState } from 'react';
import { Badge, Button, Row } from 'react-bootstrap';
import styled from 'styled-components';

const StyledFilter = styled.div<{ variant: string }>`
  padding: 0.2rem 0.8rem;
  margin: 0.8rem auto;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 50px;
  color: ${(props) => (props.variant === 'primary' ? 'var(--primary)' : 'var(--pink)')};
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > button .remove-filter-button {
    color: var(--dark);
  }
  &:hover {
    border-color: ${(props) => (props.variant === 'primary' ? 'var(--primary)' : 'var(--pink)')};
    box-shadow: 0 0 1px
      ${(props) => (props.variant === 'primary' ? 'var(--primary)' : 'var(--pink)')};
    > button .remove-filter-button {
      visibility: visible;
      opacity: 1;
      color: var(--pink);
    }
  }
`;

export const Filter = ({ variant, filter, onRemoveFilter }) => {
  const [filterType, setFilterType] = useState<string>(null);
  const [filterValue, setFilterValue] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let str = null;
    let type = null;

    if (filter) {
      const fromIndex = filter.indexOf('(');
      const toIndex = filter.indexOf(')');
      if (filter === 'active(true)') {
        type = 'Active Assets';
        str = 'Beacons Attached';
      } else if (filter === 'active(false)') {
        type = 'Inactive Assets';
        str = 'No Beacons Attached';
      } else {
        // For other cases, use substring logic
        type = filter === 'all' ? 'All' : filter?.substring(0, fromIndex);
        switch (type) {
          case 'all':
            type = 'All';
            str = 'all';
            break;
          case 'id':
            type = 'Single Asset';
            setIsLoading(true);
            getAssetName(filter.substring(fromIndex + 1, toIndex));
            break;
          case 'identifiers':
            type = 'Identifiers';
            str = filter.substring(fromIndex + 1, toIndex).split(',');
            break;
          case 'keywords':
            type = 'Keyword';
            str = filter.substring(fromIndex + 1, toIndex).split(',');
            break;
          case 'map':
            type = 'Map';
            str = filter.substring(fromIndex + 1, toIndex);
            break;
          case 'name':
            type = 'Name';
            str = filter.substring(fromIndex + 1, toIndex);
            break;
          case 'place':
            type = 'Place';
            str = filter.substring(fromIndex + 1, toIndex);
            break;
          case 'deviceId':
            type = 'Device ID';
            str = filter.substring(fromIndex + 1, toIndex);
            break;
          case 'batteryValue':
            type = 'Battery Value';
            const [comparison, value] = filter.substring(fromIndex + 1, toIndex).split(',');
            // Interpret the first number as 'Greater than' (0) or 'Less than' (1)
            const comparisonStr = comparison === '0' ? 'Greater than' : 'Less than';
            str = `${comparisonStr} ${value}`;
            break;
        }
      }
    }

    setFilterType(type);
    setFilterValue(str);
  }, [filter]);

  const getAssetName = (assetId) => {
    const fetch = async () => {
      try {
        const resp = await Api.get<any[]>(`/assets/filtered/id(${assetId})/namesAndIds`);
        setFilterValue(resp.data[0]?.name);
        setIsLoading(false);
      } catch (e) {
        console.warn(e);
      }
    };
    fetch();
  };

  return (
    <StyledFilter variant={variant}>
      <span>
        {filterType}: {filterValue}
      </span>
      <Button className="pt-0 pb-0" variant="light" onClick={onRemoveFilter}>
        <span className="remove-filter-button">&#x2715;</span>
      </Button>
    </StyledFilter>
  );
};
