const initState = {
  user: null,
  token: '',
};

// #region TYPES
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
// #endregion TYPES

// #region ACTIONS
export const authSetUserAction = (user) => ({ type: AUTH_SET_USER, user });

export const authSetTokenAction = (token) => ({ type: AUTH_SET_TOKEN, token });

export const authLogoutAction = () => ({ type: AUTH_LOGOUT });
// #endregion ACTIONS

// #region ACTION HANDLERS
const handleSetUser = (state, { user }) => ({ ...state, user });

const handleSetToken = (state, { token }) => ({ ...state, token });
// #endregion ACTION HANDLERS

const actionHandler = {
  [AUTH_SET_USER]: handleSetUser,
  [AUTH_SET_TOKEN]: handleSetToken,
};

const reducer = (state = initState, action) => {
  const handlerFn = actionHandler[action.type];

  if (!handlerFn) return state;

  return handlerFn(state, action);
};

export default reducer;
