import { useEffect, useState } from 'react';
import Axios from 'axios';
import { CreateTriggerWorkflow, LinkCellRenderer, DockContainer } from '@components';
import { Api } from '@services';
import { Row, Col, Card } from 'react-bootstrap';
import { PagedModel } from '../../models/PagedModel';
import { triggerColDefs } from './columnDefs';
import { useDock } from '@react';
import { PagedQueryParams, ServerSideGrid } from '../../components/grids/AgGrid/ServerSideGrid';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import TriggerResolutionModal from './TriggerResolutionModal';
import { TriggerSeverityLevels } from '../../enums/TriggerSeverityLevels';
import { csvHeaders } from '@screens/deviceMgmt/utils';

export function TriggerListContainer<T>() {
  //useState Hooks
  const { id, instanceId, resolvedById, appId } = useParams<any>();
  const [rowData, setRowData] = useState<any>(new PagedModel<T>());
  const [csvData, setCsvData] = useState<any>(new PagedModel<T>());
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const { renderDock, closeDock } = useDock();
  const [showModal, setShowModal] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);

  const history = useHistory();
  const cancelToken = Api.CancelToken;
  let fetchDataCancel;

  const openTrigger = (triggerId: number) => {
    renderDock({
      render: () => (
        <DockContainer>
          <CreateTriggerWorkflow
            triggerId={triggerId}
            onDone={() => {
              closeDock();
              history.push('/triggers');
            }}
            setRowSelected={setRowSelected}
          />
        </DockContainer>
      ),
    });
  };

  useEffect(() => {
    history.listen((location) => {
      const url = location.pathname.split('/');
      let triggerId = +url[url.length - 1];
      if (!isNaN(triggerId) && history.location.pathname.includes(`/triggers/${triggerId}`)) {
        openTrigger(triggerId);
      }
    });
  }, [history]);

  useEffect(() => {
    if (id) {
      openTrigger(id);
    }

    if (instanceId) {
      setShowModal(true);
    }
  }, []);

  const fetchData = async (payload?, csvExport?, queryState?) => {
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    setIsLoading(true);
    Api.get<PagedModel<T>>(
      `bulktriggers?top=${
        csvExport ? rowData.count : queryState.pageSize
      }&skip=${queryState.skip()}&filter=${queryState.filter}&orderBy=${queryState.orderBy()}`,
      {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      },
    )
      .then((data) => {
        let resp = data.data;
        if (csvExport) {
          setCsvData(resp);
        } else {
          setRowData(resp);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          setIsLoading(false);
        }
      });
  };

  return (
    <Card className="h-100" style={{ overflow: 'hidden' }}>
      <Card.Body>
        <TriggerResolutionModal
          show={showModal}
          handleClose={() => {
            setShowModal(!showModal);
            history.push('/triggers' + window.location.search);
          }}
          instanceId={instanceId}
          resolvedById={resolvedById}
          appId={appId}
        />

        <Row className="h-100">
          <Col className="h-100">
            <ServerSideGrid
              id={'Triggers'}
              columnDefs={triggerColDefs}
              initQueryParams={new PagedQueryParams(id)}
              handleFetchData={fetchData}
              rowData={rowData}
              csvData={csvData}
              isLoading={isLoading}
              disabledFilters={[]}
              filterControl={false}
              searchControl={true}
              viewingControl={true}
              pagerControl={true}
              exportControl={true}
              enumTranslation={{
                severity: TriggerSeverityLevels,
              }}
              gridProps={{
                frameworkComponents: {
                  LinkCellRenderer,
                },
              }}
              csvHeaders={csvHeaders.triggers}
              deleteRow={rowSelected}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
