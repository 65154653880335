import { useState, useCallback } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Text } from '@components';
import PropTypes from 'prop-types';

export interface OnAddContactParam {
  name: string;
  email: string;
  phone: string;
}

interface AddContactProps {
  onAddContact: (param: OnAddContactParam) => void;
}

function AddContact({ onAddContact }: AddContactProps) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const onClick = useCallback(() => {
    onAddContact({ email, name, phone });
  }, [name, email, phone, onAddContact]);

  const isDisabled = () => {
    if (!name) return true;
    if (!email && !phone) return true;

    return false;
  };

  return (
    <div className="text-left">
      <Text as="h1" className="text-center">
        Add Contact
      </Text>

      <Form>
        <Form.Group>
          <Form.Label>Name*</Form.Label>
          <Form.Control
            type="text"
            placeholder="Name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Text muted>You must enter either an email or phone number, or both.</Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="text"
            placeholder="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Group>
      </Form>

      <div className="d-flex flex-row justify-content-center">
        <Button onClick={onClick} disabled={isDisabled()}>
          Add
        </Button>
      </div>
    </div>
  );
}

AddContact.propTypes = {
  onAddContact: PropTypes.func.isRequired,
};

export default AddContact;
