import {
  BeaconReadingsCellRenderer,
  LinkCellRenderer,
  OnlineStatusCellRenderer,
  PlacesCellRenderer,
} from '@components';
import { getFormattedDateTime } from '@utils';

export const assetColumnDefs = [
  {
    headerName: '',
    field: 'status',
    cellRenderer: OnlineStatusCellRenderer,
    width: 75,
    maxWidth: 75,
    minWidth: 75,
    headerClass: 'p-0',
  },
  {
    headerName: 'Name',
    field: 'name',
    colId: 'name',
    cellRenderer: ({ data }) => {
      if (data.name) {
        return <LinkCellRenderer to={`/assets/${data.id}`} text={data.name} />;
      } else {
        return '--';
      }
    },
  },
  {
    headerName: 'Location',
    field: 'location.places',
    colId: 'places',
    cellRenderer: PlacesCellRenderer,
    sortable: false,
  },
  {
    headerName: 'Last Check-In',
    field: 'lastCheckin',
    valueFormatter(params) {
      return getFormattedDateTime(params.value);
    },
    sortable: true,
  },
  {
    headerName: 'Readings',
    field: 'beacons',
    colId: 'readings',
    cellRenderer: BeaconReadingsCellRenderer,
    valueFormatter(params) {
      return params.value;
    },
    sortable: false,
  },
];
