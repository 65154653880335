import {
  MAP_CREATED,
  MAP_DELETED,
  MAP_RECEIVED,
  MAP_REQUEST_FAILURE,
  MAP_UPDATED,
  MAP_UPLOADED,
  MAPS_CLEAR,
  MAPS_RECEIVED,
  MAPS_REQUEST_FAILURE,
  REQUEST_MAP,
  REQUEST_MAP_DELETE,
  REQUEST_MAPS,
} from '../types';

const maps = (
  state = {
    currentApplication: {
      appID: localStorage.currentApplicationID,
      name: localStorage.currentApplicationName,
    },
    isLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_MAP:
      state = { ...state, isLoading: true, currentMap: undefined };
      return state;

    case MAP_RECEIVED:
      state = { ...state, currentMap: action.map, isLoading: false };
      return state;

    case REQUEST_MAPS:
      state = { ...state, isLoading: true, maps: undefined };
      return state;

    case MAPS_RECEIVED:
      state = { ...state, maps: action.maps, isLoading: false };
      return state;

    case MAP_DELETED:
      state = { ...state, maps: undefined, currentMap: undefined, isLoading: false };
      return state;

    case MAPS_REQUEST_FAILURE:
      state = { ...state, error: action.error, isLoading: false };
      return state;

    case MAP_REQUEST_FAILURE:
      state = { ...state, error: action.error, isLoading: false };
      return state;

    case REQUEST_MAP_DELETE:
      state = { ...state, isLoading: true };
      return state;

    case MAP_UPDATED:
      state = { ...state, isLoading: false };
      return state;

    case MAP_UPLOADED:
      state = {
        ...state,
        isLoading: false,
        imageUrl: action.imageUrl,
      };
      return state;

    case MAP_CREATED:
      state = {
        ...state,
        isLoading: false,
        currentMap: action.payload,
        imageUrl: action.imageUrl,
      };
      return state;

    case MAPS_CLEAR:
      state = {
        ...state,
        imageUrl: '',
        currentMap: undefined,
      };
      return state;

    default:
      return state;
  }
};

export default maps;
