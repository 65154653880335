import moment from 'moment';
import { Component } from 'react';
import { Text } from '../../components';
import { ABILITY_MAP } from '../../constants';

class ValueDateCellRenderer extends Component {
  render() {
    const { value, timestamp, ability } = this.props.value;
    let abilityFormatter = ABILITY_MAP[ability] || ABILITY_MAP['Generic'];

    return (
      <div>
        <Text className="fs-lg">{!value ? '--' : abilityFormatter.value(value)}</Text>
        <br />
        <Text className="fs-sm">{!value ? '' : moment(timestamp).format('lll')}</Text>
      </div>
    );
  }
}

export default ValueDateCellRenderer;
