import { Card, DropdownButton, OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Text } from '../../components';
import BeaconUpload from '../../screens/bulkUpload/BeaconUpload';
import GatewayUpload from '../../screens/bulkUpload/GatewayUpload';
import { Api } from '../../services';
import { isReadOnly, isUser } from '../../utils/authUtils';

async function fetchDeviceData({ deviceType }) {
  const { data } = await Api.get(`/${deviceType}/counts`);
  return data;
}

function DeviceStats({ deviceType, activeKey, disableButton, onSelect, gridRefresh }) {
  const deviceRes = useQuery<any>([`${deviceType}-staus`], () => fetchDeviceData({ deviceType }));
  const TABS = [
    {
      key: 'Gateways',
    },
    {
      key: 'Beacons',
    },
  ];
  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <Tabs className="fs-lg fw-bold" activeKey={activeKey} onSelect={onSelect}>
          {TABS.map((tab) => (
            <Tab key={tab.key} eventKey={tab.key} title={tab.key} disabled={disableButton} />
          ))}
        </Tabs>
        {isUser() === false && isReadOnly() === false && (
          <DropdownButton id="Add Devices" className="me-3" variant="secondary" title="Add Devices">
            <BeaconUpload gridRefresh={gridRefresh} />

            <GatewayUpload gridRefresh={gridRefresh} />
          </DropdownButton>
        )}
      </Card.Header>
      {deviceRes.isLoading && (
        <Card.Body>
          <Text className="fw-bold fs-lg" style={{ color: 'var(--dark)' }}>
            ...Loading
          </Text>
        </Card.Body>
      )}
      {!deviceRes.isLoading && (
        <Card.Body className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-row justify-content-center">
            <div
              className="d-flex flex-row w-50 justify-content-center"
              style={{ whiteSpace: 'nowrap' }}
            >
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  `Total number of ${deviceType} deployed and online in this application.`,
                )}
              >
                <p className="fw-bold fs-lg" style={{ color: 'var(--c-primary)' }}>
                  Online: &nbsp;
                </p>
              </OverlayTrigger>
              <span className="fw-bold fs-lg" style={{ color: 'var(--c-primary)' }}>
                {deviceRes.data.online}
              </span>
            </div>
            <div className="d-flex flex-row w-50 justify-content-center">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  `Total number of ${deviceType} deployed and offline in this application.`,
                )}
              >
                <p className="fw-bold fs-lg" style={{ color: 'var(--danger)' }}>
                  Offline: &nbsp;
                </p>
              </OverlayTrigger>
              <span className="fw-bold fs-lg" style={{ color: 'var(--danger)' }}>
                {deviceRes.data.offline}
              </span>
            </div>
            <div className="d-flex flex-row w-50 justify-content-center">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  `Total number of ${deviceType} acquired and not yet deployed in this application.`,
                )}
              >
                <p className="fw-bold fs-lg" style={{ color: 'var(--nav-text)' }}>
                  In Inventory: &nbsp;
                </p>
              </OverlayTrigger>
              <span className="fw-bold fs-lg" style={{ color: 'var(--nav-text)' }}>
                {deviceRes.data.inInventory}
              </span>
            </div>
          </div>
          {activeKey === 'Beacons' && (
            <div className="d-flex flex-row justify-content-center">
              <div className="d-flex flex-row w-50 justify-content-center">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    `Total number of Active Beacons that have returned a reading and are not deleted.`,
                  )}
                >
                  <p className="fw-bold fs-lg" style={{ color: 'var(--primary-gray)' }}>
                    Active Deployed: &nbsp;
                  </p>
                </OverlayTrigger>
                <span className="fw-bold fs-lg" style={{ color: 'var(--primary-gray)' }}>
                  {deviceRes.data.activeDeployed}
                </span>
              </div>
              <div className="d-flex flex-row w-50 justify-content-center">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    `Total number of Passive Beacons that have returned a reading and are not deleted.`,
                  )}
                >
                  <p className="fw-bold fs-lg" style={{ color: 'var(--primary-gray)' }}>
                    Passive Deployed: &nbsp;
                  </p>
                </OverlayTrigger>
                <span className="fw-bold fs-lg" style={{ color: 'var(--primary-gray)' }}>
                  {deviceRes.data.passiveDeployed}
                </span>
              </div>
            </div>
          )}
        </Card.Body>
      )}
    </Card>
  );
}

export default DeviceStats;
