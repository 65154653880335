import { useCallback, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CsvModal, Icon, Loading, Text } from '../../components';
import OverrideModal from '../../components/modals/CsvModal/OverrideModal';
import ResultsModal from '../../components/modals/CsvModal/ResultsModal';
import { Api } from '../../services';

const MySwal = withReactContent(Swal);

function useOutsideAlerter(ref, isModified, callBack) {
  useEffect(() => {
    function handleClickOutside(event) {
      const selectedNav = event.target.closest('a');
      if (ref.current && !ref.current.contains(event.target) && !isModified) {
        callBack();
      } else if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.className.includes('swal2')
      ) {
        MySwal.fire({
          allowOutsideClick: false,
          title: 'Are you sure you want to leave?',
          text: 'Any CSV files will not be available once this window is closed.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((submit) => {
          if (submit.isConfirmed) {
            callBack();
            if (selectedNav) {
              window.location = selectedNav.attributes.href.value;
            }
          } else {
            MySwal.close();
          }
        });
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModified]);
}

function BulkAssetUploadScreen({
  closeDock,
  itemName,
  csvFile,
  csvFileSuccess,
  csvFileError,
  mutation,
  itemMapper,
}) {
  const wrapperRef = useRef(null);
  const [uploadData, setUploadData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [overrideUploads, setOverrideUploads] = useState([]);
  const [failedItems, setFailedItems] = useState([]);
  const [successItems, setSuccessItems] = useState([]);
  const [isModified, setIsModified] = useState(false);
  const [page, setPage] = useState('CsvModal');

  useEffect(() => {
    if (successItems.length !== 0 || failedItems.length !== 0) {
      setPage('ResultsModal');
    }
    if (loading === true) {
      MySwal.fire({ allowOutsideClick: false, showConfirmButton: false, html: <Loading /> });
    }
  }, [loading]);

  useOutsideAlerter(wrapperRef, isModified, closeDock);

  const cancel = () => {
    setIsModified(false);
    setPage('CsvModal');
  };

  const onUpload = useCallback(
    async (data) => {
      setLoading(true);
      setIsModified(true);
      setUploadData(data);
      setFailedItems([]);
      setSuccessItems([]);

      const items = data.map(itemMapper);
      try {
        const { data: bulkUploads } = await mutation(items);
        processData(bulkUploads);
        MySwal.close();
        setLoading(false);
      } catch {
        setLoading(false);
        MySwal.fire({
          allowOutsideClick: false,
          title: 'There was an issue with your upload!',
          text: 'Please double check your template and try again.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Okay',
        }).then((submit) => {
          if (submit.isConfirmed) {
            closeDock();
            MySwal.close();
          }
        });
      }
    },
    [mutation, itemMapper],
  );

  const forceUpload = async () => {
    setLoading(true);
    setFailedItems([]);
    setSuccessItems([]);

    const items = uploadData.map(itemMapper);
    const { data: bulkUploads } = await Api.post('/assets/bulk?force=true', items);

    processData(bulkUploads);
    MySwal.close();
    setLoading(false);
  };

  const processData = async (bulkUploads) => {
    if (bulkUploads.uploadRejected === true) {
      setOverrideUploads(bulkUploads.responseRows.filter((upload) => !upload.success));
      setPage('OverrideModal');
    } else {
      const successUploads = bulkUploads.responseRows.filter((upload) => upload.success);
      const failedUploads = bulkUploads.responseRows.filter((upload) => !upload.success);
      setSuccessItems(successUploads);
      setFailedItems(failedUploads);
    }
  };

  const text = `Download the CSV template to include the ${itemName} you would like to add. Then upload the completed CSV.`;
  const renderCSVModal = (
    <CsvModal
      title={`Add ${itemName}`}
      text={text}
      csvFile={csvFile}
      onUpload={onUpload}
      setModified={(e) => setIsModified(e)}
      itemName={itemName}
      isDock={true}
    />
  );

  const renderResultsModal = (
    <ResultsModal
      csvFileSuccess={csvFileSuccess}
      csvFileError={csvFileError}
      itemName={itemName}
      failedItems={failedItems}
      successItems={successItems}
      isDock={true}
    />
  );

  const renderOverrideModal = (
    <OverrideModal
      csvFile={csvFile}
      itemName={itemName}
      overrideItems={overrideUploads}
      forceUpload={forceUpload}
      cancel={cancel}
    />
  );

  const pageSwitcher = () => {
    switch (page) {
      case 'CsvModal':
        return renderCSVModal;
      case 'ResultsModal':
        return renderResultsModal;
      case 'OverrideModal':
        return renderOverrideModal;
    }
  };

  return (
    <div ref={wrapperRef} className="d-flex flex-column p-3 h-100">
      <div className="d-flex flex-row justify-content-between">{pageSwitcher()}</div>
    </div>
  );
}

export default BulkAssetUploadScreen;
