import '../../../../scss/_export.module.scss';
import '../Header.scss';
import { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { appToggleApplication } from '../../../../redux';
import { appService } from '../../../../services';
import Text from '../../../Text/Text';

const styles = {
  selected: {
    fontWeight: 800,
    color: 'var(--primary)',
  },
  notSelected: {
    fontWeight: 'normal',
  },
};

async function fetchApps(take) {
  let apps = await appService.getApps(take);
  return apps;
}

function ApplicationSwitcher({ currentApp, toggleApp, closeDropdown }) {
  const [take, setTake] = useState<number>(15);
  const appsRes = useQuery([`apps`], () => fetchApps(take));

  useEffect(() => {
    appsRes.refetch();
  }, [take]);

  const handleScroll = (e) => {
    let element = e.target;
    if (Math.abs(element.clientHeight - (element.scrollHeight - element.scrollTop)) <= 20) {
      setTake(take + 15);
    }
  };

  if (appsRes.isLoading) return null;

  return (
    <div onScroll={(e) => handleScroll(e)} style={{ maxHeight: '400px', overflowY: 'scroll' }}>
      <ListGroup variant="flush">
        {appsRes.data.map((app) => {
          const style = app.id === currentApp?.id ? styles.selected : styles.notSelected;

          return (
            <ListGroup.Item
              key={app.id}
              action
              style={{ backgroundColor: 'var(--dropdown)', color: 'var(--primary)' }}
              onClick={() => {
                toggleApp(app);
                if (closeDropdown) {
                  closeDropdown(); // Close the popover if provided
                }
              }}
            >
              <Link to="/">
                <Text style={style}>{app.name}</Text>
              </Link>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
}

const mapStateToProps = ({ app, auth }) => {
  return {
    currentApp: app.app,
    assignedApps: auth.user.apps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleApp: (app) => dispatch(appToggleApplication(app)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationSwitcher);
