export interface IAssetFilterModel {
  FilterType: FilterTypes;
  Filter: string;
  Keywords: string[];
  Identifiers: IdentifierFilter[];
  BeaconTypeId?: number;
  AssetId?: number;
}

export interface IIdentifierFilter {
  Key: string;
  Filter: string;
}

export class IdentifierFilter implements IIdentifierFilter {
  public Key: string;
  public Filter: string;

  constructor(key: string) {
    this.Key = key;
    this.Filter = '*';
  }
}

export class AssetFilterModel implements IAssetFilterModel {
  public FilterType: FilterTypes;
  public Filter: string;
  public Keywords: string[];
  public Identifiers: IdentifierFilter[];
  public BeaconTypeId?: number;
  public AssetId?: number;

  constructor() {
    this.Identifiers = [];
    this.Keywords = [];
  }
}

export enum FilterTypes {
  All = 'All',
  Id = 'Id',
  Name = 'Name',
  Keywords = 'Keywords',
  Identifiers = 'Identifiers',
}
