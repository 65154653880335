import { Button } from 'react-bootstrap';

/**
 * @desc Displays x icon to close swal modals
 */
export default function CloseModal({ close }) {
  return (
    <div className="d-flex justify-content-end">
      <Button variant="light" onClick={close}>
        <i className="fa fa-times" />
      </Button>
    </div>
  );
}
