import { FavoriteEditCellRenderer, LinkCellRenderer, MapThumbnailCellRenderer } from '@components';

export const mapListColumnDefs = [
  {
    headerName: '',
    colId: 'unassignBeacon',
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    cellClass: 'd-flex flex-row justify-content-center',
    cellRenderer: FavoriteEditCellRenderer,
  },
  {
    headerName: 'Map Name',
    headerClass: 'ag-theme-custom-text-right',
    field: 'name',
    colId: 'name',
    minWidth: 200,
    cellRenderer: LinkCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `/maps/${data.id}`,
      text: data.name || '--',
    }),
    headerComponentParams: {
      template: '<div><span class="maps-header">Map Name &nbsp;</span><div>',
    },
  },
  {
    headerName: 'Places',
    headerClass: 'ag-theme-custom-text-right',
    field: 'places',
    colId: 'places',
    type: 'rightAligned',
    headerComponentParams: {
      template:
        '<div><span class="maps-header">Places &nbsp;</span> <i class="sonarIcon sonarIcon-16 sonarIcon-place align-sub" /><div>',
    },
  },
  {
    headerName: 'Gateways',
    headerClass: 'ag-theme-custom-text-right',
    field: 'gateways',
    colId: 'gateways',
    type: 'rightAligned',
    headerComponentParams: {
      template:
        '<div><span class="maps-header">Gateways &nbsp;</span> <i class="sonarIcon sonarIcon-16 sonarIcon-gateway align-sub"/><div>',
    },
  },
  {
    headerName: 'Assets',
    headerClass: 'ag-theme-custom-text-right',
    field: 'assets',
    colId: 'assets',
    type: 'rightAligned',
    headerComponentParams: {
      template:
        '<div><span class="maps-header">Assets &nbsp;</span> <i class="sonarIcon sonarIcon-16 sonarIcon-asset align-sub" /><div>',
    },
  },
  {
    headerName: '',
    headerClass: 'ag-theme-custom-text-right',
    field: 'maps',
    colId: 'maps',
    type: 'rightAligned',
    cellRenderer: MapThumbnailCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `/maps/${data.id}`,
      imgURL: `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${data.id}/image`,
      img256URL: `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${data.id}/image`,
    }),
  },
];
