import './UserProfileCellRenderer.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { connect } from 'react-redux';
import { gridsSetEditDeleteCRAction } from '../../../../redux';

function UserProfileCellRenderer({
  data,
  lockOutExpireDate,
  phoneNumber,
  confirmedEmail,
  api,
  onClickEdit,
  onClickDelete,
  onClickCancelEdit,
  editDeleteCR,
  setEditDeleteCR,
}) {
  const userName = data.userName?.toLowerCase() || data.email?.toLowerCase();
  const id = data.userId;

  const [modal, setModal] = useState(false);

  const onClickUserProfile = () => {
    setModal(!modal);
  };

  const resetContext = () => {
    const ctx = { id: null, isEditing: false };
    setEditDeleteCR(ctx);
  };

  const _onClickEdit = () => {
    resetContext();
    const ctx = { id, isEditing: true };
    setEditDeleteCR(ctx);
    onClickEdit();
    setModal(!modal);
  };

  const _onClickCancelEdit = () => {
    api.stopEditing(true);
    onClickCancelEdit();
  };

  return (
    <>
      <Modal
        className="acc-mgm-user-profile-container"
        centered
        show={modal}
        onHide={() => setModal(false)}
      >
        <ModalBody>
          <div className="d-flex justify-content-end">
            <Button variant="light" onClick={() => setModal(false)}>
              <i className="fa fa-times" style={{ fontSize: '24px', fontWeight: '300' }} />
            </Button>
          </div>
          <div className="user-profile-pic">
            <span className="user-initials fs-6x">{userName.charAt(0).toUpperCase()}</span>
          </div>

          <div className="user-profile-info text-center">
            <div className="user-role-text">{data.userRole}</div>
            <div>Email: {userName}</div>
            {data.phoneNumber && <div>Phone: {phoneNumber}</div>}
            {data.lockoutEnd && <div>Lock Out Expiration: {lockOutExpireDate}</div>}
            <div>Email Confirmed: {confirmedEmail}</div>
          </div>

          <div className="mt-5 mb-3 d-flex justify-content-evenly button-container">
            <Button variant="primary" onClick={_onClickEdit}>
              Edit User Role
            </Button>
            <Button
              variant="danger"
              onClick={
                editDeleteCR?.isEditing
                  ? () => {
                      _onClickCancelEdit(), onClickDelete();
                    }
                  : () => {
                      onClickDelete();
                    }
              }
            >
              Delete User
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <button className="w-100 d-block text-dark user-text-btn" onClick={onClickUserProfile}>
        {userName}
      </button>
    </>
  );
}

UserProfileCellRenderer.propTypes = {
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickCancelEdit: PropTypes.func.isRequired,
  onClickConfirmEdit: PropTypes.func.isRequired,
  api: PropTypes.object.isRequired,
  editDeleteCR: PropTypes.object.isRequired,
  setEditDeleteCR: PropTypes.func.isRequired,
};

const mapStateToProps = ({ grids }) => ({
  editDeleteCR: grids.editDeleteCR,
});

const mapDispatchToProps = (dispatch) => ({
  setEditDeleteCR: (editDeleteCR) => dispatch(gridsSetEditDeleteCRAction({ editDeleteCR })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileCellRenderer);
