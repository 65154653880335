import {
  BeaconReadingsCellRenderer,
  IconValueCellRenderer,
  LinkCellRenderer,
  OnlineStatusCellRenderer,
} from '@components';
import { ABILITY_MAP } from '../../constants';
import { getFormattedDateTime } from '../../utils';

export const placeListColumnDefs = [
  {
    headerName: 'Place',
    field: 'name',
    colId: 'name',
    cellRenderer: LinkCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `/places/${data.id}`,
      text: data.name,
    }),
  },
  {
    headerName: 'Map',
    field: 'mapName',
  },
  {
    headerName: 'Assets',
    field: 'assets',
    cellRenderer: IconValueCellRenderer,
    cellRendererParams: { iconName: 'asset' },
  },
  {
    headerName: 'Stationary Beacons',
    field: 'stationaryBeacons',
    cellRenderer: IconValueCellRenderer,
    cellRendererParams: { iconName: 'stationary-beacon' },
  },
];

export const stationaryBeaconsColumnDefs = [
  {
    headerName: 'Stationary Beacon',
    field: 'name',
    sortable: false,
  },
  {
    headerName: 'Device ID',
    field: 'deviceId',
    sortable: false,
  },
  {
    headerName: 'Battery',
    sortable: false,
    valueGetter(params) {
      const reading = params.data.readings?.find((reading) => reading.ability === 'Battery');
      return reading ? ABILITY_MAP[reading.ability].value(reading) : '--';
    },
  },
  {
    headerName: 'Temperature',
    sortable: false,
    valueGetter(params) {
      const reading = params.data.readings?.find((reading) => reading.ability === 'Temperature');
      return reading ? ABILITY_MAP[reading.ability].value(reading) : '--';
    },
  },
  {
    headerName: 'Humidity',
    sortable: false,
    valueGetter(params) {
      const reading = params.data.readings?.find((reading) => reading.ability === 'Humidity');
      return reading ? ABILITY_MAP[reading.ability].value(reading) : '--';
    },
  },
  {
    headerName: 'Vibration',
    sortable: false,
    valueGetter(params) {
      const reading = params.data.readings?.find((reading) => reading.ability === 'Vibration');
      return reading ? ABILITY_MAP[reading.ability].value(reading) : '--';
    },
  },
  {
    headerName: 'Pressure',
    sortable: false,
    valueGetter(params) {
      const regex = RegExp('(pressure)', 'gi');
      const reading = params.data.readings?.find((reading) => regex.test(reading.ability));
      return reading ? ABILITY_MAP[reading.ability].value(reading) : '--';
    },
  },
];

export const gatewaysColumnDefs = [
  {
    headerName: 'Gateway',
    field: 'name',
    sortable: false,
  },
  {
    headerName: 'Online',
    field: 'isOnline',
    sortable: false,
    cellRenderer: OnlineStatusCellRenderer,
    valueGetter: ({ data }) => (data.isOnline ? 1 : 0),
  },
  {
    headerName: 'MAC ID',
    field: 'deviceId',
    sortable: false,
  },
];

export const assetColumnDefs = [
  {
    headerName: '',
    cellRenderer: OnlineStatusCellRenderer,
    valueGetter: ({ data }) => (data.status ? 1 : 0),
    width: 60,
    minWidth: 60,
    maxWidth: 60,
  },
  {
    headerName: 'Name',
    field: 'name',
    colId: 'name',
    cellRenderer: LinkCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `/assets/${data.id}`,
      text: data.name || '--',
    }),
  },
  {
    headerName: 'Last Check-In',
    field: 'lastCheckin',
    valueFormatter(params) {
      return getFormattedDateTime(params.value);
    },
  },
  {
    headerName: 'Readings',
    field: 'beacons',
    colId: 'readings',
    cellRenderer: BeaconReadingsCellRenderer,
    valueFormatter() {
      return;
    },
  },
];
