import { Col,Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router-dom';
import { IconValueCellRenderer, LinkCellRenderer, Loading } from '../../components';
import AgGrid from '../../components/grids/AgGrid/AgGrid';
import { useTableState } from '../../react';
import { Api } from '../../services';
import { getTableQueryParams } from '../../utils';
import { placeListColumnDefs } from './columnDefs';


async function fetchData({ limit, search, page = 1 }) {
  const { data } = await Api.get(
    `/places?skip=${(page - 1) * limit}&top=${limit}&filter=${search}`,
  );

  return data;
}

function PlaceListScreen({ history }) {
  const tableParams = getTableQueryParams();

  const { page, limit, search, setPageLink, setLimitLink, setSearchLink } = useTableState({
    initSearch: tableParams.search,
    initLimit: tableParams.limit,
    initPage: tableParams.page,
    link: '/places',
    history,
  });

  const onCellClicked = ({ data, column }) => {
    const ignoreCols = ['name'];
    if (ignoreCols.includes(column.colId)) return;

    history.push(`/places/${data.id}`);
  };

  const getGridProps = () => {
    return {
      frameworkComponents: {
        LinkCellRenderer: LinkCellRenderer,
        IconValueCellRenderer: IconValueCellRenderer,
      },
      onCellClicked: onCellClicked,
    };
  };

  const res = useQuery(
    ['listPlaces', page, { limit, search }],
    () => fetchData({ page, limit, search }),
    { keepPreviousData: true },
  );

  if (res.isLoading) return <Loading />;

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col className="h-90">
          <AgGrid
            rowData={res.data.items}
            columnDefs={placeListColumnDefs}
            gridProps={getGridProps()}
            isLoading={res.isFetching}
            onChangePage={setPageLink}
            onChangeLimit={setLimitLink}
            onChangeSearch={setSearchLink}
            initSearch={tableParams.search}
            searchDebounceTimeSec={0.5}
            useQuickFilter={false}
            pagination
          />
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(PlaceListScreen);
