import { createTheme } from '@material-ui/core';

export const DateTheme = createTheme({
  palette: {
    primary: {
      main: '#0048FF',
    },
  },
  overrides: {
    MuiDialogActions: {
      root: {
        '& button': {
          textTransform: 'unset',
        },
        '& button:last-child': {
          color: 'white',
          backgroundColor: 'var(--c-primary)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'var(--c-primary)',
        },
        '&:hover $notchedOutline': {
          borderColor: 'var(--c-primary)',
        },
        '&$focused $notchedOutline': {
          borderColor: 'var(--c-primary)',
        },
      },
    },
    MuiTabs: {
      root: {
        '& .MuiTabs-indicator': {
          backgroundColor: 'white',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: '(var--c-primary)',
      },
    },
    MuiFormLabel: {
      root: {
        color: '(var--dark)',
      },
    },
    MuiIconButton: {
      root: {
        color: '(var--dark)',
      },
    },
  },
});
