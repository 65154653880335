import { useEffect, useState } from 'react';
import { Button,Col, Row } from 'react-bootstrap';

const DeleteTriggers = (props) => {
  const { asset } = props;
  const [triggerList, setTriggerList] = useState([]);
  useEffect(() => {
    let array = [];
    asset?.beacons?.forEach((beacon) => {
      beacon.triggerConditions.forEach((tc) => {
        if (!array.includes(tc.triggerName)) {
          array.push(tc.triggerName);
        }
      });
    });
    setTriggerList(array);

    // DELETE TRIGGERS
    // eslint-disable-next-line
  }, []);
  return (
    <div className="text-center">
      <Row>
        <Col>{Boolean(triggerList.length) && <h4 className="mb-4">Success!</h4>}</Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h5 className="mb-4">Triggers have been deleted.</h5>
        </Col>
      </Row>
      <Row>
        <Col className="text-left col-8 m-auto">
          <ul>
            {triggerList?.map((trigger) => (
              <li>{trigger}</li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="success">Done</Button>
        </Col>
      </Row>
    </div>
  );
};

export default DeleteTriggers;
