import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import * as loadingAnim from '../../animations/loading.json';
function Loading({ height = 250, width = 250 }) {
  return (
    <div
      className="loading-wrapper"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Lottie
        animationData={loadingAnim.default}
        loop
        play
        style={{ width: width, height: height }}
      />
    </div>
  );
}

Loading.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Loading;
