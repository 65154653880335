import './UploadScreen.scss';
import { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CsvModal, Loading, toast } from '../../components';
import ResultsModal from '../../components/modals/CsvModal/ResultsModal';

const MySwal = withReactContent(Swal);

function UploadScreen({
  csvFile,
  csvFileSuccess,
  csvFileError,
  mutation,
  itemName,
  itemMapper,
  refresh,
}) {
  //useState Hooks
  const [loading, setLoading] = useState(false);
  const [failedItems, setFailedItems] = useState([]);
  const [successItems, setSuccessItems] = useState([]);
  const [modified, setModified] = useState(false);

  //useEffect Hooks
  useEffect(() => {
    if (successItems.length !== 0 || failedItems.length !== 0) {
      openResModal();
    }
    if (loading === true) {
      MySwal.fire({ html: <Loading />, showConfirmButton: false });
    }
  }, [loading]);

  const onUpload = useCallback(
    async (data) => {
      try {
        setLoading(true);
        setFailedItems([]);
        setSuccessItems([]);
        const items = data.map(itemMapper);
        const { data: bulkUploads } = await mutation(items);

        toast.success(`Attempting to upload ${items.length} ${itemName}`);

        const successUploads = bulkUploads.filter((upload) => upload.success);
        const failedUploads = bulkUploads.filter((upload) => !upload.success);

        setSuccessItems(successUploads);
        setFailedItems(failedUploads);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        MySwal.fire({
          allowOutsideClick: false,
          title: 'There was an issue with your upload!',
          text: 'Please double check your template and try again.',
          icon: 'error',
        }).then((submit) => {
          if (submit.isConfirmed) {
            MySwal.close();
          }
        });
      }
    },
    [mutation, itemMapper],
  );

  const text = `Download the CSV template to include the ${itemName} you would like to add then upload the completed CSV.`;
  const Modal = (
    <CsvModal
      title={`Add ${itemName}`}
      text={text}
      csvFile={csvFile}
      onUpload={onUpload}
      setModified={setModified}
      itemName={itemName}
      failedItems={failedItems}
      successItems={successItems}
      isLoading={loading}
    />
  );

  const openCSVModal = async () => {
    await MySwal.fire({ html: Modal, showConfirmButton: false });
  };

  const ResModal = (
    <ResultsModal
      csvFileSuccess={csvFileSuccess}
      csvFileError={csvFileError}
      itemName={itemName}
      failedItems={failedItems}
      successItems={successItems}
    />
  );

  const openResModal = async () => {
    await MySwal.fire({
      className: 'uploadItems',
      html: ResModal,
      allowOutsideClick: false,
    }).then(() => {
      refresh();
    });
  };

  if (loading) return <Loading />;

  return (
    <Dropdown.Item
      as="button"
      onClick={(e) => {
        openCSVModal();
      }}
    >
      {`Upload ${itemName}`}
    </Dropdown.Item>
  );
}

export default UploadScreen;
