import '../../../../scss/_export.module.scss';
import { useDock } from '@react';
import About from '@screens/about/About';
import UserSettingsContainer from '@screens/userProfile/UserSettingsContainer';
import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { DockContainer, Text } from '../../../../components';
import { authService } from '../../../../services';
import { isLegacyAuthEnabled, isSSO } from '../../../../utils';

const Item = styled(ListGroup.Item)`
  background-color: var(--dropdown);
  color: var(--dark);
`;

export function SettingsDropdown({ closeDropdown, setShowTourModal }) {
  const { renderDock, closeDock } = useDock();
  const renderChangePassword = () => {
    if (!isLegacyAuthEnabled()) return null;

    return !isSSO() ? (
      <Item action onClick={closeDropdown} href="/change-password">
        <Text>Change Password</Text>
      </Item>
    ) : null;
  };

  const renderAbout = () => {
    closeDropdown();

    renderDock({
      render: () => (
        <DockContainer>
          <About onDone={closeDock} setShowTourModal={setShowTourModal} />
        </DockContainer>
      ),
    });
  };

  const renderUserPreferences = () => {
    closeDropdown();

    renderDock({
      render: () => (
        <DockContainer>
          <UserSettingsContainer onDone={closeDock} />
        </DockContainer>
      ),
    });
  };

  return (
    <ListGroup variant="flush">
      <Item action onClick={renderAbout}>
        <Text>About</Text>
      </Item>

      <Item action onClick={renderUserPreferences}>
        <Text>User Preferences</Text>
      </Item>

      {renderChangePassword()}

      <Item action onClick={closeDropdown} href="/dashboard-edit">
        <Text>Edit Dashboard</Text>
      </Item>

      <Item
        action
        onClick={() => {
          authService.logout();
          closeDropdown();
        }}
      >
        <Text>Logout</Text>
      </Item>
    </ListGroup>
  );
}
