import './AdvancedAsset.scss';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AddBeaconToAsset, ASSET_TYPE, Icon, Loading, Text } from '../../components';
import AdvancedCharting from '../../components/AdvancedAsset/AdvancedCharting';
import { toast } from '../../components/utils';
import { withDock } from '../../react';
import { Api } from '../../services';

const AdvancedAsset = ({ match, renderDock }) => {
  const [lastReadings, setLastReadings] = useState([]);
  const [assetName, setAssetName] = useState(null);
  const [onAssetAdvanced, setOnAssetAdvanced] = useState(true);
  const [isBeaconsAvail, setIsBeaconsAvail] = useState(true);
  const [beacons, setBeacons] = useState([]);
  const [asset, setAsset] = useState({});

  const cancelToken = Api.CancelToken;
  const source = cancelToken.source();
  const { assetId } = match.params;

  const getData = async () => {
    try {
      const { data: apiAsset, status } = await Api.get(`v2/assets/${assetId}`, {
        cancelToken: source.token,
      });
      Api.get(`v2/assets/${assetId}/readings`, {
        cancelToken: source.token,
      }).then(({ data }) => {
        setTimeout(() => {
          data.length ? setIsBeaconsAvail(true) : setIsBeaconsAvail(false);
          setBeacons(data);
        }, 1000);
        setLastReadings(data);
      });

      setAsset(apiAsset);
      setAssetName(apiAsset.name);
    } catch (error) {}
  };

  const refetch = () => {
    getData({ useLoading: false });
  };

  useEffect(() => {
    getData();
  }, []);

  function openCreateBeaconFormDock() {
    renderDock({
      render: () => (
        <AddBeaconToAsset
          initAssetType={ASSET_TYPE.EXISTING}
          initAsset={asset}
          onAssetDetailsPage={true}
          onSuccess={() => {
            refetch();
            toast.success('Successfully added associated beacon!');
          }}
        />
      ),
    });
  }

  const NoAttachedBeacons = () => {
    return (
      <Card className="beacon-list-card h-100">
        <Card.Body>
          <div className="beacon-list-empty">
            <Icon
              name="beacon"
              variant="dark"
              size="8x"
              className="mb-3"
              disabled={false}
              showPointer={false}
            />
            <Text className="mb-3" as="h5">
              No Beacons Attached to Asset {assetName}!
            </Text>
            <Text className="mb-4" as="h5">
              Click Below to Add Beacons.
            </Text>
            <Button variant="primary" onClick={openCreateBeaconFormDock}>
              Add Beacons&nbsp;
              <i className="sonarIcon sonarIcon-add fs-sm" />
            </Button>
            <Link to={`/assets/${assetId}`}>
              <Button className="mt-2 ps-5 pe-5" variant="secondary">
                Back
              </Button>
            </Link>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {isBeaconsAvail ? (
        <Container fluid>
          {!lastReadings.length ? (
            <div style={{ paddingTop: '250px' }}>
              <Loading />
            </div>
          ) : (
            <Row className="advanced-charting">
              <Col>
                <AdvancedCharting
                  lastReadings={lastReadings}
                  assetName={assetName}
                  asset={asset}
                  beacons={beacons}
                />
              </Col>
            </Row>
          )}
        </Container>
      ) : (
        <NoAttachedBeacons />
      )}
    </>
  );
};

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default withDock(connect(mapStateToProps)(withRouter(AdvancedAsset)));
