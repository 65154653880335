import { TimeUnit } from '../enums/TimeUnit';
import { TriggerSeverityLevels } from '../enums/TriggerSeverityLevels';
import { TriggerTypes } from '../enums/TriggerTypes';
import { TriggerContact } from './TriggerContact';

export class TriggerListModel {
  id: number;
  name: string;
  description: string;
  keywords: string[];
  filter: string;
  severity: TriggerSeverityLevels;
  triggerType: TriggerTypes;
  summary: string;
  lastModified: Date;
}

export class Trigger {
  Id: number;
  Name: string;
  Description: string;
  Keywords: string[];
  Filter: string;
  Severity: TriggerSeverityLevels;
  TriggerType: TriggerTypes;
  Contacts: TriggerContact[];
  ReminderFrequencyValue: number;
  ReminderFrequencyUnit: TimeUnit;
  MetDurationValue: number;
  MetDurationUnit: TimeUnit;
  AutoResolve: boolean;
  ResolveDurationValue: number;
  ResolveDurationUnit: TimeUnit;
  Webhooks: string[];

  constructor() {
    this.Id = 0;
    this.Name = '';
    this.Description = '';
    this.Keywords = [];
    this.Filter = '*';
    this.TriggerType = null;
    this.Contacts = [];
    this.Severity = TriggerSeverityLevels.Moderate;
    this.ReminderFrequencyValue = 0;
    this.ReminderFrequencyUnit = 1;
    this.MetDurationValue = 0;
    this.MetDurationUnit = 1;
    this.AutoResolve = false;
    this.ResolveDurationValue = 0;
    this.ResolveDurationUnit = 1;
    this.Webhooks = [];
  }

  AbilityId?: number;
  FallsBelow?: number;
  RisesAbove?: number;
  CloserThan?: number;
  Distance?: number;
  Enters: boolean;
  Exits: boolean;
  FartherThan?: number;
  Lat?: number;
  Lng?: number;
  PlaceId?: number;
  Units: string;
  GatewayId?: number;
  MapId?: number;
}
