import { useRef } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import Swal from 'sweetalert2';
import Text from '../../Text/Text';
import CloseModal from '../CloseModal';

function ResultsModal({
  csvFileSuccess,
  csvFileError,
  itemName,
  failedItems,
  successItems,
  isDock,
}) {
  const csvLinkRef = useRef();

  return (
    <Container>
      <Row>
        <Col>{!isDock && <CloseModal close={() => Swal.close()} />}</Col>
      </Row>
      <Row>
        <Col>
          <h1>Results</h1>
        </Col>
      </Row>
      {successItems.length !== 0 && (
        <Row className="mt-5 mb-5">
          <Col>
            <Text as="h1" className="fs-lg fw-bold">
              {`The following ${itemName} uploaded successfully:`}
            </Text>
            <ListGroup>
              {successItems.map((item, index) => (
                <ListGroup.Item key={index}>
                  <Text>
                    {item.model.hasOwnProperty('name') ? item.model.name : item.model.newAssetName}
                  </Text>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      )}
      {failedItems.length !== 0 && (
        <Row className="mt-5 mb-5">
          <Col xs={12}>
            <Text as="h1" className="fs-lg fw-bold">
              {`The following ${itemName} failed to upload:`}
            </Text>
            <ListGroup>
              {failedItems.map((item, index) => (
                <ListGroup.Item key={index}>
                  <Text>{item.msg}</Text>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      )}
      <Col xs={12} className="d-flex flex-row justify-content-between">
        {failedItems.length !== 0 && (
          <CSVLink
            ref={csvLinkRef}
            filename={`Failed-${itemName}`}
            headers={csvFileError.headers}
            disabled={failedItems.length === 0}
            data={failedItems.map((item) => {
              if (item.model.hasOwnProperty('name')) {
                return {
                  name: item.model.name,
                  deviceId: item.model.deviceId,
                  errorMessages: item.msg,
                };
              } else {
                return {
                  newAssetName: item.model.newAssetName,
                  keywords: item.model.keywords,
                  keyValuePairs: item.model.keyValuePairs,
                  existingBeaconDeviceName: item.model.existingBeaconIds,
                  errorMessages: item.model.errorMessages,
                };
              }
            })}
            style={{ color: 'var(--primary)' }}
          >
            Download CSV with failed {itemName}.
          </CSVLink>
        )}
        {successItems.length !== 0 && (
          <CSVLink
            ref={csvLinkRef}
            filename={`Success-${itemName}`}
            headers={csvFileSuccess.headers}
            disabled={successItems.length === 0}
            data={successItems.map((item) => {
              if (item.model.hasOwnProperty('name')) {
                return {
                  name: item.model.name,
                  deviceId: item.model.deviceId,
                };
              } else {
                return {
                  assetId: item.model.assetId,
                  newAssetName: item.model.newAssetName,
                  keywords: item.model.keywords,
                  keyValuePairs: item.model.keyValuePairs,
                  existingBeaconDeviceName: item.model.existingBeaconIds,
                };
              }
            })}
            style={{ color: 'var(--primary)' }}
          >
            Download CSV with successful {itemName}.
          </CSVLink>
        )}
      </Col>
    </Container>
  );
}

export default ResultsModal;
