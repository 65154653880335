import { Link } from 'react-router-dom';

function AssetNameCellRenderer({ data }) {
  if (data.assetId === 'N/A' || data.assetId === null || data.assetId === undefined)
    return <span>--</span>;

  return (
    <Link to={`/assets/${data.assetId}`} className="btn-link font-weight-light">
      {data.assetName || '--'}
    </Link>
  );
}

export default AssetNameCellRenderer;
