import * as types from '../types';

const readings = (
  state = {
    isLoading: false,
    reading: {},
    readings: {},
  },
  action,
) => {
  switch (action.type) {
    case types.READINGS_CLEAR:
      return Object.assign({}, state, {
        isLoading: false,
        reading: {},
        readings: {},
      });
    case types.READINGS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.READINGS_RECEIVED:
      state.readings[action.ability] = action.data;

      return Object.assign({}, state, {
        isLoading: false,
        reading: action,
      });
    default:
      return state;
  }
};

export default readings;
