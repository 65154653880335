import './About.scss';
import { useEffect, useRef } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Text } from '../../components';

const pjson = require('../../../package.json');

function useOutsideAlerter(ref, callBack) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (!ref.current.contains(event.target)) {
        callBack();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
}

const About = ({ onDone, setShowTourModal }) => {
  const history = useHistory();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onDone);

  const dashboardTourTrigger = () => {
    onDone();
    const isDashboard = window.location.pathname === '/dashboard';
    if (!isDashboard) {
      // Redirect the user to /dashboard
      history.push('/dashboard');
    }
    // Display the modal after a delay
    const showTourModalTimeout = setTimeout(() => {
      setShowTourModal(true);
    }, 500);

    return () => clearTimeout(showTourModalTimeout);
  };

  return (
    <div ref={wrapperRef} className="about-container">
      {/* -- Version and Release Notes -- */}
      <ListGroup variant="flush">
        <ListGroup.Item>
          <div className="d-flex flex-column ms-3 mb-2">
            <Text className="fs-lg fw-bold">Current Version</Text>
            <Text className="fs-md">{pjson.version}</Text>
            <Text className="fs-md">Release Date: June 2024</Text>
            <Text className="fs-md">
              See what's new in our{' '}
              <a
                className="fw-bold"
                href="https://thinaer.io/help-center/release-notes/"
                target="_blank"
                rel="noreferrer"
              >
                Release Notes
              </a>
            </Text>
          </div>
        </ListGroup.Item>

        {/* -- Mobile Links -- */}
        <ListGroup.Item>
          <div className="d-flex flex-column ms-3 mt-2 mb-2">
            <div className="d-flex align-items-center justify-content-center">
              <Text className="fs-lg fw-bold mb-3">
                Take Sonar wherever you go! Download the mobile app:
              </Text>
            </div>
            <div className="d-flex align-items-center justify-content-around me-3 ms-3 mt-3 mb-3">
              <img src={'/img/app-store.jpg'} className="card-img-top" />
              <Text className="fs-md ms-3 me-3 mobile-msg">
                Use the mobile app to manage your Assets virtually anywhere. Configure Gateways, add
                new Beacons, and create new Assets from your iOS device.
              </Text>
              <div className="app-icons vertical">
                <a
                  href="https://apps.apple.com/us/app/sonar-mobile/id1398130124?itsct=apps_box_badge&amp;itscg=30200"
                  className="apple-href"
                >
                  <img
                    className="apple"
                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1532304000"
                    alt="Download on the App Store"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.thinaer.sonar&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    className="android"
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </ListGroup.Item>

        {/* -- Help Center -- */}
        <ListGroup.Item>
          <div className="d-flex flex-column align-items-center justify-content-center mt-2 mb-2">
            <Text className="fs-lg fw-bold mb-3">Want to get the most out of Sonar?</Text>
            <Text
              className="fs-md mb-3 fw-bold"
              style={{ color: 'var(--primary)', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={dashboardTourTrigger}
            >
              Take a Tour
            </Text>
            <Text className="fs-md">
              Learn more about our key features and <br /> watch our tutorials in the{' '}
              <a
                className="fw-bold"
                href="https://thinaer.io/help-center/"
                target="_blank"
                rel="noreferrer"
              >
                Help Center
              </a>
              !{' '}
            </Text>
          </div>
        </ListGroup.Item>

        {/* -- Newsletter -- */}
        {/* <Card.Header>
        <div className="d-flex flex-row, text-align-center align-items-center justify-content-around mt-4 mb-4">
          <Text
            className="fs-lg fw-bold text-align-middle me-5"
            style={{
              display: 'flex',
              whiteSpace: 'nowrap',
              width: '50%',
              color: 'var(--dark)',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            Subscribe to our newsletter
          </Text>
          <InputGroup>
            <FormControl aria-describedby="email" placeholder="Email" aria-label="Email" />
            <InputGroup.Append>
              <Button type="submit" onClick={(e) => console.log(e)}>
                Subcribe
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </Card.Header> */}

        {/* -- Footer Items -- */}
        <ListGroup.Item className="footer-items">
          <div className="footer-container d-flex flex-column align-items-center justify-content-around">
            <div
              className="d-flex align-items-center justify-content-around"
              style={{ width: '100%' }}
            >
              <div
                className="d-flex flex-column align-items-center justify-content-center"
                style={{ width: '65%' }}
              >
                <div
                  className="d-flex align-items-center justify-content-around"
                  style={{ width: '100%' }}
                >
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ textAlign: 'center' }}
                  >
                    <a
                      className="fw-bold"
                      href="https://thinaer.io/privacy-policy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </div>
                  <a
                    className="fw-bold"
                    href="https://thinaer.io/contact/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact
                  </a>
                </div>
                <Text className="mt-2 mb-2">
                  Thinaer's seamlessly integrated platform creates a digital twin of your operations
                  at any scale. Improve your bottom line, maximize productivity and deploy IoT
                  digital transformation out-of-the-box.
                </Text>
                <div className="d-flex align-items-center justify-content-around w-100 hipaa-eula-container">
                  <a href="https://thinaer.io/hipaa/" target="_blank" rel="noreferrer">
                    HIPAA
                  </a>
                  <a href="https://thinaer.io/eula/" target="_blank" rel="noreferrer">
                    EULA
                  </a>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column align-items-center justify-content-center blue-social-box">
                  <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                    <a
                      href="https://www.linkedin.com/company/thinaer/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className={`sonarIcon sonarIcon-logo-linkedin fs-2x me-3`} />
                    </a>
                    <a href="https://www.youtube.com/@thinaer" target="_blank" rel="noreferrer">
                      <i className={`sonarIcon sonarIcon-logo-youtube fs-2x`} />
                    </a>
                  </div>
                  <Text>info@thinaer.io</Text>
                </div>
              </div>
            </div>
          </div>
        </ListGroup.Item>
        <ListGroupItem>
          <div className="d-flex flex-column justify-content-around align-items-center mt-4 mb-4">
            <Text>&#169; {new Date().getFullYear()} Thinaer. All rights reserved.</Text>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default About;
