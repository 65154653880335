export const csvHeaders = {
  battery: [
    'id',
    'assetName',
    'assetId',
    'deviceId',
    'placeId',
    'placeName',
    'mapId',
    'mapName',
    'batteryValue',
    'lastChanged',
    'lastCheckIn',
    'lastTimestamp',
  ],
  environmental: [
    'assetId',
    'assetName',
    'beaconId',
    'beaconName',
    'deviceId',
    'timeRecorded',
    'averageTemperature',
    'averageRelativeHumidity',
  ],
  // humidity: [
  //   'assetId',
  //   'assetName',
  //   'readingTime',
  //   'beaconId',
  //   'beaconName',
  //   'deviceID',
  //   'averageTemperature',
  //   'averageRelativeHumidity',
  //   'averageCelsiusAndFahrenheit',
  //   'averageTempCelsius',
  //   'totalRowCount'
  // ],
  // temperature: [
  //   'assetId',
  //   'assetName',
  //   'readingTime',
  //   'beaconId',
  //   'beaconName',
  //   'deviceID',
  //   'averageTemperature',
  //   'averageRelativeHumidity',
  //   'averageCelsiusAndFahrenheit',
  //   'averageTempCelsius',
  //   'totalRowCount'
  // ],
  trigger: [
    'beaconId',
    'applicationId',
    'triggerId',
    'assetId',
    'assetName',
    'triggerName',
    'triggerStatus',
    'criteria',
    'reportedValue',
    'recipientId',
    'comment',
    'triggerInstanceId',
    'recipientName',
    'compareValue',
    'action',
    'totalRecordCount',
    'notificationTime',
    'responseTime',
  ],
};
