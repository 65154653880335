import { Api } from '@services';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { PlaceForm } from './PlaceForm';
import { useFormValidation } from './trigger.hooks';

export const PlaceFormContainer = ({
  trigger,
  isSubmitted,
  mapId,
  placeId,
  triggerEnters,
  triggerExits,
  onChange,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [maps, setMaps] = useState<any[]>([]);
  const [places, setPlaces] = useState<any[]>([]);
  const [selectedPlace, setSelectedPlace] = useState<any[]>([]);
  const [selectedMap, setSelectedMap] = useState<any[]>([]);
  const [mapIsNull, setMapIsNull] = useState<boolean>(true);
  const [
    conditionError,
    hasName,
    hasType,
    hasCondition,
    hasRisesAbove,
    hasFallsBelow,
    hasEntryExit,
    hasMap,
    hasPlace,
    hasFilter,
    isValid,
  ] = useFormValidation({ trigger });

  useEffect(() => {
    let isLoaded = true;
    setIsLoading(true);
    const fetch = async () => {
      try {
        const result = await Api.get<any>(`/maps`);
        if (isLoaded) {
          setMaps(result.data.items);
          setIsLoading(false);
        }
      } catch (ex) {
        console.warn(ex);
      }
    };
    fetch();

    return () => {
      isLoaded = false;
    };
  }, []);

  useEffect(() => {
    let isLoaded = true;
    const fetch = async () => {
      try {
        const result = await Api.get<any>(`/maps/${mapId}/places`);
        if (isLoaded) {
          setPlaces(result.data.items);
        }
      } catch (ex) {
        console.warn(ex);
      }
    };
    fetch();

    mapId === null ? setMapIsNull(true) : setMapIsNull(false);

    return () => {
      isLoaded = false;
    };
  }, [mapId]);

  useEffect(() => {
    if (mapId !== undefined && mapId !== null && maps.length > 0) {
      let currentMap = maps.find((map) => map.id === parseInt(mapId));
      setSelectedMap([{ id: currentMap.id, name: currentMap.name }]);
    }
    if (placeId !== undefined && places.length > 0) {
      let currentPlace = places.find((place) => place.id === parseInt(placeId));
      setSelectedPlace([{ id: currentPlace.id.toString(), name: currentPlace.name }]);
    }
  }, [maps]);

  const onPlaceFormChange = (value: any, type: any) => {
    onChange(value, type);
  };

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Entry/Exit
          <span className="text-danger"> *</span>
        </Form.Label>
        <Col style={{ display: 'inline-flex', alignItems: 'center' }}>
          <Form.Check
            inline
            isInvalid={!hasEntryExit && isSubmitted}
            isValid={hasEntryExit && isSubmitted}
            className="is-invalid"
            label="Enters"
            name="entersExits"
            type="radio"
            disabled={disabled}
            defaultChecked={triggerEnters}
            onClick={() => {
              onChange(true, 'Enters', { Exits: false });
            }}
          />
          <Form.Check
            inline
            isInvalid={!hasEntryExit && isSubmitted}
            isValid={hasEntryExit && isSubmitted}
            className="is-invalid"
            label="Exits"
            name="entersExits"
            type="radio"
            disabled={disabled}
            defaultChecked={triggerExits}
            onClick={() => {
              onChange(false, 'Enters', { Exits: true });
            }}
          />
          {!hasEntryExit && isSubmitted ? (
            <div className="invalid-feedback">* Please select In or Not In</div>
          ) : null}
        </Col>
      </Form.Group>
      {isLoading === false && (
        <PlaceForm
          isSubmitted={isSubmitted}
          selectedMap={selectedMap}
          selectedPlace={selectedPlace}
          hasMap={hasMap}
          hasPlace={hasPlace}
          onPlaceFormChange={onPlaceFormChange}
          mapOptions={maps}
          placesOptions={places}
          disabled={disabled}
          mapIsNull={mapIsNull}
        />
      )}
    </>
  );
};
