import { noop } from '../../../../utils';
import Icon from '../../../Icon/Icon';

function IconCellRenderer({
  iconName,
  variant = 'primary',
  disabled = true,
  onClick = noop,
  className = '',
  style,
  size = 'lg',
}) {
  return (
    <Icon
      name={iconName}
      className={className}
      variant={variant}
      disabled={disabled}
      style={style}
      onClick={onClick}
      size={size}
    />
  );
}

export default IconCellRenderer;
