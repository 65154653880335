import { Loading } from '@components';
import axios from 'axios';
import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { parseString } from 'xml2js';
import StoreContext from '../../services/Store';

const CancelToken = axios.CancelToken;
let fetchDataCancel;

const Equipment = () => {
  const history = useHistory();
  const q = new URLSearchParams(window.location.search);
  const { cart, setCart } = React.useContext(StoreContext);

  const [products, setProducts] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(q.get('search') || '');
  const [tag, setTag] = React.useState(q.get('tag') || '');
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(q.get('page') || 1);

  React.useEffect(() => {
    setLoading(true);
    setProducts([]);

    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }

    axios
      .get(
        'https://thinaer.io/feed/?post_type=equipment&s=' +
          searchTerm +
          '&paged=' +
          page +
          '&Tags=' +
          tag,
        {
          cancelToken: new CancelToken(function executor(c) {
            fetchDataCancel = c;
          }),
        },
      )
      .then(({ data }) => {
        parseString(data, { trim: true }, (err, result) => {
          if (result?.rss?.channel[0]?.item) {
            setProducts(
              result.rss.channel[0].item.map((i) => {
                i.title = i.title[0];
                i.id = i.guid[0]._.split('p=')[1];
                return i;
              }),
            );
          }
          setLoading(false);
        });
      });
  }, [page]);

  React.useEffect(() => {
    setLoading(true);
    setProducts([]);
    setPage(1);

    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    axios
      .get(
        'https://thinaer.io/feed/?post_type=equipment&s=' +
          searchTerm +
          '&paged=1' +
          '&Tags=' +
          tag,
        {
          cancelToken: new CancelToken(function executor(c) {
            fetchDataCancel = c;
          }),
        },
      )
      .then(({ data }) => {
        parseString(data, { trim: true }, (err, result) => {
          if (result?.rss?.channel[0]?.item) {
            setProducts(
              result.rss.channel[0].item.map((i) => {
                i.title = i.title[0];
                i.id = i.guid[0]._.split('p=')[1];
                return i;
              }),
            );
          }
          setLoading(false);
        });
      });
  }, [searchTerm, tag]);

  React.useEffect(() => {
    let search = '?';
    search = Boolean(searchTerm.trim().length) ? search + '&search=' + searchTerm : search;
    search = Boolean(page > 1) ? search + '&page=' + page : search;
    search = Boolean(tag.trim().length > 1) ? search + '&tag=' + tag : search;
    search = search.replace('?&', '?');
    history.push(window.location.pathname.split('?')[0] + search);
  }, [searchTerm, page, tag]);

  const handlePage = (opperation) => {
    if (page >= 1) {
      if (opperation === 'previous') {
        if (page !== 1) {
          setPage(Number(page) - 1);
        }
      } else {
        if (products.length >= 12) {
          setPage(Number(page) + 1);
        }
      }
    }
  };

  const Pagination = () => {
    return (
      <Row className="mt-3">
        <Col>&nbsp;</Col>
        <Col lg={3} className="text-right">
          <Row>
            <Col>
              <Button variant="transparent" size="sm" onClick={() => handlePage('previous')}>
                <i
                  class={`fas fa-arrow-circle-left h5 text-${page > 1 ? 'primary' : 'secondary'}`}
                ></i>
              </Button>
            </Col>
            <Col xs={3}>
              <Button variant="transparent" size="sm" onClick={() => handlePage('next')}>
                <i
                  class={`fas fa-arrow-circle-right h5 text-${
                    products.length >= 12 ? 'primary' : 'secondary'
                  }`}
                ></i>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Col lg={10} className="m-auto">
      <h3>
        <i class="fas fa-tablet text-primary"></i> Equipment
      </h3>
      {Boolean(tag.trim().length) && (
        <Row>
          <Col className="mt-3">
            Currently showing results for the tag{' '}
            <b className="text-primary">"{q.get('tag')?.replace(/-/g, ' ')}"</b>{' '}
            <i class="btn p-0 h6 text-danger mt-1 far fa-times-circle" onClick={() => setTag('')} />
          </Col>
        </Row>
      )}
      <hr />
      <Row>
        <Col>
          <Form.Control
            value={searchTerm}
            placeholder="Search products..."
            onInput={({ target }) => setSearchTerm(target.value)}
          />
        </Col>
      </Row>
      <Pagination />
      {!loading ? (
        <Row className="mt-3">
          <Col>
            <Row className="mt-3">
              {products.length ? (
                products?.map((article) => {
                  const cf = JSON.parse(article['content:encoded'][0].split('|||')[1]);
                  const tags = JSON.parse(article['content:encoded'][0].split('|||')[2]) ?? [];
                  return (
                    <Col lg={4}>
                      <Card className="pe-3 ps-3 pt-3 pb-0 mb-5">
                        <Row className="h-25">
                          <Col className="text-center">
                            <Button
                              variant="transparent"
                              className="text-primary p-0"
                              onClick={() => history.push('/equipment/' + article.id)}
                            >
                              <h5 style={{ height: 75 }}>{article.title}</h5>
                              <Row>
                                <Col
                                  style={{ height: window.innerHeight * 0.3, overflowY: 'hidden' }}
                                >
                                  <img className="w-75 m-auto" src={cf.images[0].url} />
                                </Col>
                              </Row>
                              <Row className="mt-3 mb-3">
                                <Col>
                                  <Button
                                    size="sm"
                                    onClick={() => history.push('/equipment/' + article.id)}
                                  >
                                    View Unit
                                  </Button>
                                </Col>
                              </Row>
                            </Button>
                            <Row className="bg-light" style={{ height: 100 }}>
                              <Col>
                                {tags &&
                                  tags?.map((tag) => {
                                    return (
                                      <Button
                                        onClick={() => setTag(tag.slug)}
                                        size="sm"
                                        variant="light"
                                        className="mx-1"
                                      >
                                        {tag.name}
                                      </Button>
                                    );
                                  })}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <h4>No results found.</h4>
              )}
            </Row>
            <Pagination />
          </Col>
        </Row>
      ) : (
        <Loading />
      )}
    </Col>
  );
};

export default Equipment;
