import './BeaconSuccess.scss';
import '../../../../../scss/animations/animations.scss';
import { Loading, Text } from '@components';
import { useOnMount } from '@react';
import { Api } from '@services';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { connect } from 'react-redux';

async function createAsset(data) {
  return Api.post('/assets', data);
}

async function assignBeaconToAsset({ assetId, beaconId, isLocationBeacon }) {
  let data = {
    isLocationBeacon: isLocationBeacon,
  };

  let update = Api.post(`/assets/${assetId}/beacons/${beaconId}`, data);

  return update;
}

function BeaconSuccess({
  asset,
  onClickDone,
  beacon = null,
  assetConfig = null,
  app,
  existingBeacons,
}) {
  const [assetId, setAssetId] = useState(asset.id);

  const createAssetMutation = useMutation(createAsset);
  const assignBeaconMutation = useMutation(assignBeaconToAsset);

  async function callCreateAsset() {
    if (assetId) return assetId; // asset has already been created or is an existing one

    try {
      const assetData = {
        applicationId: app.id,
        name: asset.name,
        movementThreshold: assetConfig?.locOffset,
        keywords: asset.keywords,
        identifiers: asset.identifiers,
      };

      const res = await createAssetMutation.mutateAsync(assetData);
      setAssetId(res.data);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  async function callAssignBeacon() {
    const _assetId = await callCreateAsset();

    try {
      if (!beacon) return; // just created an asset only

      await assignBeaconMutation.mutateAsync({
        assetId: _assetId,
        beaconId: beacon.id,
        isLocationBeacon: Boolean(assetConfig?.isPrimary),
      });
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    callAssignBeacon();
  }, []);

  // New Assets & Assets without Beacons automatically set beacon attached as primary
  const primaryStr =
    Boolean(assetConfig?.isPrimary) ||
    asset.isCreated ||
    (asset.beacons && asset.beacons.length === 0) ||
    existingBeacons?.length === 0
      ? ' as the primary location beacon'
      : '';

  // handle loading
  if (createAssetMutation.isLoading || assignBeaconMutation.isLoading) return <Loading />;

  // handle errors
  if (createAssetMutation.isError || assignBeaconMutation.isError) {
    return (
      <Container>
        <Row>
          <Col>
            <Text as="h2">Failed request. Please try again.</Text>
            <Button onClick={callAssignBeacon}>Try Again</Button>
          </Col>
        </Row>
      </Container>
    );
  }

  // success
  return (
    <Container className="add-beacon-success d-flex flex-column justify-content-between h-100 text-center">
      <Row>
        <Col xs={12}>
          <Text className="fs-2x success">Success!</Text>
        </Col>

        <Col className="my-5" xs={12}></Col>

        <Col>
          <Text className="fs-lg">
            <div className="mb-5" style={{ visibility: asset.isCreated ? '' : 'hidden' }}>
              <div className="circle-anim m-auto">
                <div className="checkmark draw"></div>
              </div>
              <div className="mt-3">
                New Asset Created:{' '}
                <a
                  href={`/assets/${assetId}`}
                  style={{ textDecoration: 'none', color: 'var(--primary)' }}
                >
                  {asset.name}
                </a>
              </div>
            </div>
          </Text>

          <Text className="fs-lg">
            <div className="mb-5" style={{ visibility: beacon ? '' : 'hidden' }}>
              <div className="circle-anim m-auto">
                <div className="checkmark draw"></div>
              </div>
              <div className="mt-3">
                Beacon: {beacon.name} has successfully been associated with Asset:{' '}
                <a
                  href={`/assets/${assetId ? assetId : beacon.assetId}`}
                  style={{ textDecoration: 'none', color: 'var(--primary)' }}
                >
                  {asset.name}
                </a>
                <div className="fs-xs">{primaryStr}</div>
              </div>
            </div>
          </Text>
        </Col>
      </Row>
      <Row className="">
        <Col>
          <Button variant="primary" className="my-3" style={{ width: 200 }} onClick={onClickDone}>
            Done
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

BeaconSuccess.propTypes = {
  asset: PropTypes.object.isRequired,
  onClickDone: PropTypes.func.isRequired,

  // defaults
  beacon: PropTypes.object,
  assetConfig: PropTypes.object,
};

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default connect(mapStateToProps)(BeaconSuccess);
