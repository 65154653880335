import './BeaconReadingsPager.scss';
import '../../scss/variables.scss';
import { cloneDeep, get } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { ABILITY_MAP, IGNORED_ABILITIES } from '../../constants';
import DynamicPager from '../DynamicPager/DynamicPager';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

function BeaconReadingsPager({ beacons, assetId, mapName, placeName, assetScreen }) {
  const [beaconPage, setBeaconPage] = useState(1);

  // AbilityId's that currently don't have Icons
  const abilityIdArray = [
    7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 31, 32, 34,
    40, 41, 42, 76, 77,
  ];

  // Creating a clone of Beacons
  const _beacons = beacons.length !== 0 ? cloneDeep(beacons) : beacons;

  if (_beacons.length !== 0) {
    _beacons.unshift({
      readings: [
        {
          ability: 'Asset ID',
          icon: 'fa fa-passport',
          textOnly: true,
          mean: assetId || '--',
        },
        {
          ability: 'Map',
          mean: mapName || '--',
        },
        {
          ability: 'Place',
          mean: placeName || '--',
        },
      ],
    });
  }

  const beacon = _beacons[beaconPage - 1];

  const cardTitle =
    beaconPage === 1 && _beacons.length !== 0
      ? 'Location Details'
      : `${get(beacon, 'name')} (${beaconPage - 1} of ${_beacons.length - 1})`;

  const renderBeaconReadings = () => {
    const beaconReadings = beacon.readings.filter(
      (reading) => !IGNORED_ABILITIES.includes(reading.ability),
    );

    if (!beaconReadings.length) {
      return (
        <div className="w-100 d-flex flex-row">
          <Text className="text-center w-100 fs-lg">No Readings</Text>
        </div>
      );
    }

    return beaconReadings.map((reading) => {
      let abilityFormatter = ABILITY_MAP[reading.ability] || ABILITY_MAP['Generic'];

      // Set icon depending if in array/ has reading.icon
      const setIcon = () => {
        // Needed this to have Hours use the History Icon
        readingAbility === 'Hours' ? (readingAbility = 'History') : readingAbility;

        if (abilityIdArray.includes(reading.abilityId)) {
          return (
            <Icon name={'analytics'} variant="dark" size="3x" style={{ color: 'var(--primary)' }} />
          );
        } else if (reading.icon) {
          return (
            <i
              className={reading.icon + ` fs-4x px-1 sonarIcon-primary me-auto`}
              style={{ color: 'var(--primary)' }}
            ></i>
          );
        } else {
          return (
            <Icon
              name={readingAbility.toLowerCase()}
              variant="dark"
              size="3x"
              style={{ color: 'var(--primary)' }}
              disabled={false}
              showPointer={false}
            />
          );
        }
      };

      //Removing spaces from reading.ability for Icons
      let readingAbility = reading.ability.replace(/\s/g, '');
      return (
        <div key={reading.ability} className="d-flex mt-3 mb-3 justify-content-center">
          {setIcon()}
          <div className="d-flex flex-column align-items-center me-auto">
            <Text as="h6">{reading.ability}</Text>
            <Text>{abilityFormatter.value(reading)}</Text>
          </div>
        </div>
      );
    });
  };

  // Asset Details Page - If asset contains no beacons, continue displaying cards
  if (!beacon) {
    return (
      <div className="d-flex flex-row flex-wrap justify-content-between w-100 px-5">
        <div key="Asset ID" className="d-flex mt-3 mb-3 justify-content-center">
          <i
            className={'fa fa-passport fs-3x px-1 sonarIcon-primary me-auto'}
            style={{ color: 'var(--primary)' }}
          ></i>
          <div className="d-flex flex-column align-items-center me-auto">
            <Text as="h6">Asset Id</Text>
            <Text>{assetId || '--'}</Text>
          </div>
        </div>
        <div key="Map Name" className="d-flex mt-3 mb-3 justify-content-center">
          <Icon
            name="map"
            variant="dark"
            size="3x"
            disabled={false}
            showPointer={false}
            style={{ color: 'var(--primary)' }}
          />
          <div className="d-flex flex-column align-items-center me-auto">
            <Text as="h6">Map</Text>
            <Text>{mapName || '--'}</Text>
          </div>
        </div>
        <div key="Place Name" className="d-flex mt-3 mb-3 justify-content-center">
          <Icon
            name="place"
            variant="dark"
            size="3x"
            disabled={false}
            showPointer={false}
            style={{ color: 'var(--primary)' }}
          />
          <div className="d-flex flex-column align-items-center me-auto">
            <Text as="h6">Place</Text>
            <Text>{placeName || '--'}</Text>
          </div>
        </div>
      </div>
    );
  }

  if (assetScreen) {
    return (
      <div className="d-flex flex-row flex-wrap justify-content-between w-100 px-5">
        {renderBeaconReadings()}
      </div>
    );
  }

  // Show Multiple Reading Popup for Assets
  return (
    <div className="p-3">
      {!assetScreen && <Card.Title>{cardTitle}</Card.Title>}
      <Card.Body className="p-3">
        <DynamicPager onChangePage={(page) => setBeaconPage(page)} pageCount={_beacons.length}>
          <div className="w-100 px-2 asset-content-wrap">{renderBeaconReadings()}</div>
        </DynamicPager>
      </Card.Body>
    </div>
  );
}

BeaconReadingsPager.propTypes = {
  beacons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      deviceId: PropTypes.string.isRequired,
      readings: PropTypes.arrayOf(
        PropTypes.shape({
          mean: PropTypes.number.isRequired,
          value: PropTypes.string.isRequired,
          timestamp: PropTypes.string.isRequired,
          ability: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  assetId: PropTypes.string,
  mapName: PropTypes.string,
  placeName: PropTypes.string,
  assetScreen: PropTypes.bool,
};

export default BeaconReadingsPager;
