import { MultiUserSelect } from '@components';
import StoreContext from '@services/Store';
import React, { useState } from 'react';
import { Button,Col, Form, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const Dialog = (p) => (
  <div className="bg-white col-lg-9 px-1 mt-lg-5 ms-auto me-auto rounded">{p.children}</div>
);
export default (props) => {
  const [storeIsOn, setStoreIsOn] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem('sonar-cart')) ?? {
      products: [],
    },
  );

  const submit = () => {
    const data = {
      products: cart.products.map((p) => ({
        id: p.sonar_id,
        title: p.title,
        image: p.images[0].url,
        qty: p.qty,
      })),
      subscribers,
    };
    setSuccess(true);
    const nCart = { ...cart };
    nCart.products = [];
    setCart(nCart);
  };

  React.useEffect(() => {
    localStorage.setItem('sonar-cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <StoreContext.Provider value={{ cart, setCart, storeIsOn, showCart, setShowCart, setSuccess }}>
      <Modal show={showCart} onHide={() => setShowCart(!showCart)} dialogAs={Dialog}>
        <Modal.Header>
          <h5>
            <i class="fas fa-check-double h4 text-primary"></i> Quote Request Form
          </h5>
        </Modal.Header>
        <Row>
          <Col>
            <Modal.Body>
              {cart.products?.length && !success ? (
                cart.products.map((product, i) => {
                  return (
                    <>
                      <Row>
                        <Col>
                          <Button
                            className="p-0"
                            variant="transparent"
                            onClick={() => {
                              setShowCart(false);
                              history.push('/equipment/' + product.id);
                            }}
                          >
                            <Row>
                              <Col lg={3}>
                                <img src={product.images[0].url} className="w-100" />
                              </Col>
                              <Col className="text-left">
                                <Row>
                                  <Col>
                                    <small className="text-primary">{product.title}</small>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <small>Sonar #:{product.sonar_id}</small>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Button>
                        </Col>
                        <Col className="pt-3" xs={4}>
                          <Row>
                            <Form.Control
                              className={'col-6'}
                              value={product.qty}
                              type={'number'}
                              onChange={({ target }) => {
                                const nCart = { ...cart };
                                nCart.products[i].qty = target.value;
                                setCart(nCart);
                              }}
                            />
                            <Col
                              xs={1}
                              onClick={() => {
                                const nCart = { ...cart };
                                nCart.products = nCart.products.filter(
                                  (p) => p.sonar_id !== product.sonar_id,
                                );
                                setCart(nCart);
                              }}
                            >
                              <i
                                class="far fa-times-circle text-danger pe-5"
                                style={{ cursor: 'pointer' }}
                              ></i>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  );
                })
              ) : success ? (
                <div className="text-center pt-5 pb-5">
                  <h1>Quote Request Sent</h1>
                  <i class="far fa-check-circle h1 text-success"></i>
                  <p>
                    Thank you for your interest in our products. <br />
                    You will be contacted by the sonar purchasing department soon.
                  </p>
                </div>
              ) : (
                <p className="p-5 text-center">No equipment in quote</p>
              )}
            </Modal.Body>
          </Col>
          {Boolean(cart.products?.length && !success) && (
            <Col lg={4}>
              <Modal.Body>
                <p>Add contacts you would like to keep in the loop for this quote:</p>
                <MultiUserSelect
                  placeholder="Add Contact"
                  onSingleChange={(e) => {
                    if (!subscribers.map((s) => s.label).includes(e.label) || e.value === null) {
                      if (e.value === null) {
                        e.value = makeid(5);
                      }
                      setSubscribers([...subscribers, ...[e]]);
                    }
                  }}
                  onMultiChange={(e) => {
                    setSubscribers(e);
                  }}
                  value={subscribers}
                />
                <Button className="w-100" onClick={submit}>
                  Request Quote
                </Button>
              </Modal.Body>
            </Col>
          )}
        </Row>
      </Modal>
      {props.children}
    </StoreContext.Provider>
  );
};
