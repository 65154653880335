import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Card, Button } from 'react-bootstrap';
import { SearchInput } from '../../components/inputs';
import { Link } from 'react-router-dom';
import { Api } from '../../services';
import { Icon, Text, Loading } from '../../components';
import { capitalize, isReadOnly, isUser } from '../../utils';
import { MapImage } from '../../components/images/MapImage';
import './Map.scss';

const limit = 100;

async function fetchMaps({ page = 1 }) {
  const { data } = await Api.get(`/maps?top=${limit}&skip=${(page - 1) * limit}`);
  return data;
}

function MapList() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  useEffect(() => {
    let searchHistory = JSON.parse(sessionStorage.getItem('searchHistory'));
    let key = 'ServerSideGrid-MapList';

    if (!searchHistory) {
      sessionStorage.setItem('searchHistory', JSON.stringify({}));
      setSearch('');
    } else if (!key || !searchHistory[key]) {
      return;
    } else {
      setSearch(searchHistory[key]);
    }
  }, []);

  const res = useQuery(['listMaps', page], () => fetchMaps({ page }), { keepPreviousData: true });

  if (res.isLoading) return <Loading />;

  const pageCount = Math.ceil(res.data.count / limit);

  const mapCounts = ['places', 'gateways', 'assets', 'stationaryAssets', 'stationaryBeacons'];
  return (
    <>
      {isReadOnly() === false && isUser() === false && (
        <div className="d-flex flex-row justify-content-end px-5">
          <Link to="/maps/create">
            <Button>Create Map</Button>
          </Link>
        </div>
      )}

      <div className="col-11 mt-5 map-input">
        <SearchInput
          id={'ServerSideGrid-MapList'}
          placeholder="Search for maps..."
          onChange={setSearch}
        />
      </div>

      <div className="d-flex flex-row flex-wrap">
        {res.data.items
          .filter((map) =>
            search.trim().length ? map.name.toLowerCase().includes(search.toLowerCase()) : true,
          )
          .map((map) => {
            return (
              <Card key={map.id} className="m-5" style={{ width: '24rem' }}>
                {/* Map name */}
                <Card.Header>
                  <Text as="h1" className="fs-lg">
                    {map.name}
                  </Text>
                </Card.Header>

                {/* Map image */}
                <MapImage mapId={map.id} size={'card'} />

                <Card.Body>
                  {/* Map item counts */}
                  <div className="d-flex flex-row flex-wrap">
                    {mapCounts.map((mapCount) => {
                      return (
                        <div key={mapCount} className="text-center m-3">
                          <Text className="fs-lg">{map[mapCount]}</Text>
                          <br />
                          <Text>
                            {mapCount
                              .split(/(?=[A-Z])/)
                              .map(capitalize)
                              .join(' ')}
                          </Text>
                        </div>
                      );
                    })}
                  </div>
                </Card.Body>

                <Card.Footer className="d-flex flex-row justify-content-between">
                  {isReadOnly() === false && isUser() === false && (
                    <Link to={`/maps/${map.id}/edit`} className="btn-link font-weight-light">
                      <Icon name="edit" size="1x" className="cursor-pointer" disabled={false} />
                      Edit
                    </Link>
                  )}
                  <Link to={`/maps/${map.id}`} className="btn-link font-weight-light">
                    <Icon name="map" size="1x" className="cursor-pointer" disabled={false} />
                    View Locations
                  </Link>
                </Card.Footer>
              </Card>
            );
          })}
      </div>

      <div className="d-flex flex-row justify-content-end">
        {page < pageCount && (
          <Button variant="outline-primary" onClick={() => setPage(page + 1)}>
            Load More
          </Button>
        )}
      </div>
    </>
  );
}

export default MapList;
