import classnames from 'classnames';
import { Component, createContext, createRef } from 'react';
import { withDock } from '../../../react';

export const DockContext = createContext(null);

const defaultDock = {
  width: 0,
  render: () => null,
};

class Dock extends Component {
  constructor() {
    super();
    this.ref = createRef(null);
  }

  render() {
    const { width, minWidth, render } = this.props?.dock || defaultDock;
    const className = classnames('dock', { 'dock-close': width === 0 });

    return (
      <DockContext.Provider value={this.ref}>
        <div className={className} style={{ width, minWidth }} ref={this.ref}>
          {render()}
        </div>
      </DockContext.Provider>
    );
  }
}

export default withDock(Dock);
