import * as types from '../types';

const people = (
  state = {
    isLoading: false,
    items: [],
    pages: 0,
    total: 0,
    currentPage: 1,
    currentOrder: 'asc',
    currentPerson: {},
    currentDevice: {},
    devices: [],
  },
  action,
) => {
  switch (action.type) {
    case types.PERSON_DELETE_STARTED:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PERSON_DELETE_COMPLETED:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case types.PERSON_DEVICES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        devices: [],
      });
    case types.PERSON_DEVICES_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        devices: action.devices,
      });

    case types.PERSON_DEVICE_POST_STARTED: // get single person request
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PERSON_DEVICE_POST_COMPLETED:
      return {
        ...state,
        isLoading: false,
        currentPerson: action.person,
      };
    case types.PERSON_DEVICE_DELETE_STARTED: // get single person request
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PERSON_DEVICE_DELETE_COMPLETED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.PERSON_REQUEST: // get single person request
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.PERSON_RECEIVED: // single person request completed
      return {
        ...state,
        isLoading: false,
        currentPerson: action.person,
      };

    case types.PERSON_DEVICE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });

    case types.PERSON_DEVICE_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        currentDevice: action.device,
      });
    case types.PEOPLE_REQUEST:
      return Object.assign({}, state, {
        //items: [],
        isLoading: true,
        currentPerson: {},
        currentDevice: {},
      });
    case types.PEOPLE_RECEIVED:
      return Object.assign({}, state, {
        isLoading: false,
        items: action.people,
        pages: action.pages,
        total: action.total,
        currentPage: action.currentPage,
        currentOrder: action.currentOrder,
        currentDevice: {},
        currentPerson: {},
        devices: [],
      });
    case types.PEOPLE_REQUEST_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
};

export default people;
