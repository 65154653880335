import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import StoreContext from '@services/Store';
import axios from 'axios';
import React, { Component } from 'react';
import { Button, Card, Col, Form, OverlayTrigger, Popover,Row } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import { useHistory,useParams } from 'react-router-dom';
import { parseString } from 'xml2js';

export default () => {
  const { storeIsOn, cart, setCart, showCart, setShowCart, setSuccess } =
    React.useContext(StoreContext);
  const history = useHistory();
  const { id } = useParams();
  const [article, setArticle] = React.useState(null);
  const [description, setDescription] = React.useState('');
  const [details, setDetails] = React.useState(null);
  const [tags, setTags] = React.useState(null);
  const [price, setPrice] = React.useState(null);
  const [qty, setQty] = React.useState(1);
  React.useEffect(() => {
    axios
      .get(`https://thinaer.io/feed/?post_type=equipment&p=${id}&withoutcomments=1`)
      .then(({ data }) => {
        parseString(data, { trim: true }, (err, result) => {
          setArticle(
            result.rss.channel[0].item.map((i) => {
              i.title = i.title[0];
              i.id = i.guid[0]._.split('p=')[1];
              return i;
            })[0],
          );
        });
      });
  }, [id]);

  React.useEffect(() => {
    if (article !== null) {
      setDescription(article['content:encoded'][0].split('|||')[0]);
      setDetails(JSON.parse(article['content:encoded'][0].split('|||')[1]));
      setTags(JSON.parse(article['content:encoded'][0].split('|||')[2]));
    }
  }, [article]);
  React.useEffect(() => {
    if (article !== null) {
      setPrice(details.price);
    }
  }, [details]);

  const updateCart = () => {
    setSuccess(false);
    const nCart = { ...cart };
    const index = cart.products.findIndex((p) => p.sonar_id === details.sonar_id);
    if (qty <= 0) {
      return false;
    }
    if (index > -1) {
      nCart.products[index].qty = Number(nCart.products[index].qty) + Number(qty);
    } else {
      nCart.products = [
        ...nCart.products,
        ...[
          {
            ...details,
            ...{
              title: article.title,
              qty: Number(qty),
              id: article.id,
            },
          },
        ],
      ];
    }
    setCart(nCart);
    setShowCart(true);
  };

  return (
    <Row>
      {article && (
        <Col lg={10} className="m-auto">
          <Col>
            <Row>
              <Col md={5}>
                <Button onClick={() => history.push('/equipment')} variant="transparent" size="sm">
                  <i class="fas fa-chevron-circle-left text-primary"></i> Equipment List
                </Button>
              </Col>
              <Col className="text-left">
                <h3 className="text-primary mb-3">{article.title}</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={5}>
                <Carousel>
                  {details?.images?.map((image) => (
                    <div>
                      <img src={image.url} />
                      {/*<p className="legend">{image.caption}</p>*/}
                    </div>
                  ))}
                </Carousel>
              </Col>
              <Col>
                {details &&
                  Object.keys(details)
                    .filter(
                      (k) => ['number', 'string'].includes(typeof details[k]) && k !== 'price',
                    )
                    .map((k) => {
                      return (
                        <Row className="mt-3">
                          <Col>
                            <strong>{k.replace(/_/, ' ')}:</strong>
                          </Col>
                          <Col>{details[k]}</Col>
                        </Row>
                      );
                    })}
                <hr />
                <h6>Dimensions And Weight</h6>
                {details &&
                  Object.keys(details.dimensions_and_weight)
                    .filter((k) =>
                      ['number', 'string'].includes(typeof details.dimensions_and_weight[k]),
                    )
                    .map((k) => {
                      return (
                        <Row className="mt-3">
                          <Col>
                            <strong>{k.replace(/_/, ' ')}:</strong>
                          </Col>
                          <Col>{details.dimensions_and_weight[k]}</Col>
                        </Row>
                      );
                    })}
                <hr />
                <Row className="bg-light mb-3">
                  <Col>
                    {tags &&
                      tags?.map((tag, i) => {
                        return Boolean(tag?.description?.trim()) ? (
                          <OverlayTrigger
                            rootClose={true}
                            trigger="click"
                            placement="bottom"
                            overlay={
                              <Popover
                                style={{
                                  width: 'unset',
                                  minWidth: '35%',
                                  minHeight: 180,
                                  maxWidth: 'unset',
                                }}
                                target={'tag' + i}
                                id="popover-basic"
                              >
                                <Row>
                                  <h6 className="ps-4 pt-4 text-primary">{tag.name}</h6>
                                </Row>
                                <Row>
                                  <Col
                                    className="p-4"
                                    dangerouslySetInnerHTML={{ __html: tag.description }}
                                  />
                                </Row>
                                <Row>
                                  <Col className="text-right">
                                    <Button
                                      onClick={() => history.push(`/equipment?tag=${tag.name}`)}
                                      size="sm"
                                      variant="transparent"
                                      className="text-primary"
                                    >
                                      <u>See more {tag.name} units</u>
                                    </Button>
                                  </Col>
                                </Row>
                              </Popover>
                            }
                          >
                            <Button id={'tag' + i} size="sm" variant="light" className="mx-1">
                              {tag.name}
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <Button id={'tag' + i} size="sm" variant="light" className="mx-1">
                            {tag.name}
                          </Button>
                        );
                      })}
                  </Col>
                </Row>
                {storeIsOn && (
                  <Row className="mt-5">
                    <Col xs={5}>
                      <Row>
                        <Col xs={1}>qty:</Col>
                        <Col>
                          <Row>
                            <Col>
                              <Form.Control
                                min={0}
                                step={5}
                                max={1000}
                                value={qty}
                                type="range"
                                onInput={({ target }) => setQty(target.value)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Control
                                value={qty}
                                type={'number'}
                                onInput={({ target }) => setQty(target.value)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Button className="col-5" onClick={updateCart}>
                      Add to Quote
                    </Button>
                  </Row>
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              {article['content:encoded'] && (
                <Col style={{ minHeight: 500 }} dangerouslySetInnerHTML={{ __html: description }} />
              )}
            </Row>
          </Col>
        </Col>
      )}
    </Row>
  );
};
