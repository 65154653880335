import { Trigger } from '../models/Trigger';
import Api from './Api';

export class TriggerService {
  createTrigger({ trigger }: { trigger: Trigger }) {
    if (trigger.Id > 0) {
      return Api.put('/bulktriggers/' + trigger.Id, trigger, {
        cancelToken: Api.CancelToken.source().token,
      });
    }

    return Api.post('/bulktriggers/', trigger, {
      cancelToken: Api.CancelToken.source().token,
    });
  }

  deleteTrigger({ trigger }: { trigger: Trigger }) {
    if (trigger.Id > 0) {
      return Api.delete(`/bulktriggers/${trigger.Id}`, {
        cancelToken: Api.CancelToken.source().token,
      });
    }
  }

  updateTrigger({ id, trigger }: any) {
    return Api.post('/triggers/' + id, trigger, {
      cancelToken: Api.CancelToken.source().token,
    });
  }

  resetTrigger = ({ id }) => {
    return Api.post(
      `/triggers/${id}/reset`,
      {},
      {
        cancelToken: Api.CancelToken.source().token,
      },
    );
  };

  resolveTrigger = ({ id, comment = '' }) => {
    return Api.post(
      `/triggers/${id}/resolve`,
      { comment },
      {
        cancelToken: Api.CancelToken.source().token,
      },
    );
  };
}

const triggerService = new TriggerService();

export { triggerService };

export default TriggerService;
