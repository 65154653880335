import { useDock } from '@react';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Trigger } from '../../../models/Trigger';
import { TriggerService } from './../../../services';

export const useCreateTrigger = ({ triggerService }: { triggerService: TriggerService }) => {
  const { closeDock } = useDock();
  const initiateCreateTrigger = useCallback(
    async ({ trigger }: { trigger: Trigger | null }) => {
      try {
        const response = await triggerService.createTrigger({ trigger });

        return {
          id: response.data,
          ...trigger,
        };
      } catch (error) {
        console.error(error);
      } finally {
        return closeDock;
      }
    },
    [triggerService],
  );

  const initiateCreateTriggerMutation = useMutation(initiateCreateTrigger);

  const isLoading = initiateCreateTriggerMutation.isLoading;
  const isError = initiateCreateTriggerMutation.isError;
  const isSuccess = initiateCreateTriggerMutation.isSuccess;

  return {
    initiateCreateTriggerMutation,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useDeleteTrigger = ({ triggerService }: { triggerService: TriggerService }) => {
  const { closeDock } = useDock();
  const initiateDeleteTrigger = useCallback(
    async ({ trigger }: { trigger: Trigger | null }) => {
      try {
        const response = await triggerService.deleteTrigger({ trigger });

        return {
          status: response.status,
          trigger,
        };
      } catch (error) {
        console.error(error);
      } finally {
        closeDock();
      }
    },
    [triggerService],
  );

  const initiateDeleteTriggerMutation = useMutation(initiateDeleteTrigger);

  const isLoading = initiateDeleteTriggerMutation.isLoading;
  const isError = initiateDeleteTriggerMutation.isError;
  const isSuccess = initiateDeleteTriggerMutation.isSuccess;

  return {
    initiateDeleteTriggerMutation,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useFormValidation = ({ trigger }: { trigger: Trigger }) => {
  const [conditionError, setConditionError] = useState<string>('');
  const [hasName, setHasName] = useState<boolean>(false);
  const [hasType, setHasType] = useState<boolean>(false);
  const [hasCondition, setHasCondition] = useState<boolean>(false);
  const [hasRisesAbove, setHasRisesAbove] = useState<boolean>(false);
  const [hasFallsBelow, setHasFallsBelow] = useState<boolean>(false);
  const [hasEntryExit, setHasEntryExit] = useState<boolean>(false);
  const [hasMap, setHasMap] = useState<boolean>(false);
  const [hasPlace, setHasPlace] = useState<boolean>(false);
  const [hasAssetFilter, setHasAssetFilter] = useState<boolean>(false);
  const [hasFilter, setHasFilter] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  function checkAboveBelow(value): boolean {
    switch (trigger.AbilityId) {
      case 1:
      case 4: {
        // 0 - 100
        const regex = new RegExp('^[0-9][0-9]?$|^100$');
        if (regex.test(value) === false) {
          setConditionError('* Please enter a whole number between 0 & 100');
        }
        return regex.test(value);
      }
      case 3:
      case 5:
      case 6:
      case 7:
      case 8:
      case 19: {
        // All Numbers
        const regex = new RegExp('^[+-]?[0-9]+(?:.[0-9]+)?$');
        if (regex.test(value) === false) {
          setConditionError('* Please enter a positive or negative value');
        }
        return regex.test(value);
      }
      case 17: {
        // Positive Integers
        const regex = new RegExp('^[0-9]+(.[0-9]*)?$');
        if (regex.test(value) === false) {
          setConditionError('* Please enter a positive value');
        }
        return regex.test(value);
      }
      case 18:
      case 25:
      case 23: {
        // No Input
        return true;
      }
      case 24: {
        //TODO: Radians
        return true;
      }
      case 42: {
        // No Input
        return true;
      }
      case 43: {
        // No Input
        return true;
      }
      case 44: {
        // No Input
        return true;
      }
      default: {
        setConditionError('* Error');
        return false;
      }
    }
  }

  useEffect(() => {
    // Checks Name
    trigger.Name.trim().length > 0 ? setHasName(true) : setHasName(false);

    // Checks Trigger Type
    trigger.TriggerType !== null ? setHasType(true) : setHasType(false);

    switch (trigger.TriggerType) {
      // Ability
      case 0: {
        //Reset values if Ability changed
        setHasEntryExit(false);
        setHasMap(false);
        setHasPlace(false);

        // Checks Condition
        trigger.AbilityId !== undefined ? setHasCondition(true) : setHasCondition(false);

        // Checks Rises Above/Falls Below
        if (trigger.RisesAbove != null && trigger.FallsBelow == null) {
          setHasRisesAbove(checkAboveBelow(trigger.RisesAbove));
          setHasFallsBelow(true);
        } else if (trigger.RisesAbove == null && trigger.FallsBelow != null) {
          setHasFallsBelow(checkAboveBelow(trigger.FallsBelow));
          setHasRisesAbove(true);
        } else {
          setHasRisesAbove(checkAboveBelow(trigger.RisesAbove));
          setHasFallsBelow(checkAboveBelow(trigger.FallsBelow));
        }
        break;
      }
      // Entry/Exit
      case 3: {
        //Reset values if Ability changed
        setHasCondition(false);
        setHasRisesAbove(false);
        setHasFallsBelow(false);

        // Checks Entry/Exit
        trigger.Enters !== undefined && trigger.Exits !== undefined
          ? setHasEntryExit(true)
          : setHasEntryExit(false);

        // Checks Map
        trigger.MapId !== undefined ? setHasMap(true) : setHasMap(false);

        // Checks Place
        trigger.PlaceId !== undefined ? setHasPlace(true) : setHasPlace(false);

        break;
      }
    }

    // Checks Asset Filter
    switch (trigger.Filter) {
      case null: {
        setHasAssetFilter(true);
        setHasFilter(false);
        break;
      }
      case '*': {
        setHasAssetFilter(false);
        setHasFilter(false);
        break;
      }
      case 'all': {
        setHasAssetFilter(true);
        setHasFilter(true);
        break;
      }
      case 'identifiers()': {
        setHasAssetFilter(true);
        setHasFilter(false);
        break;
      }
      case 'id(undefined)': {
        setHasAssetFilter(true);
        setHasFilter(false);
        break;
      }
      case 'name()': {
        setHasAssetFilter(true);
        setHasFilter(false);
        break;
      }
      case 'keywords()': {
        setHasAssetFilter(true);
        setHasFilter(false);
        break;
      }
      default: {
        setHasAssetFilter(true);
        setHasFilter(true);
      }
    }
  }, [
    trigger.Name,
    trigger.TriggerType,
    trigger.AbilityId,
    trigger.RisesAbove,
    trigger.FallsBelow,
    trigger.Enters,
    trigger.Exits,
    trigger.MapId,
    trigger.PlaceId,
    trigger.Filter,
  ]);

  useEffect(() => {
    // Check if Triggers is valid for submission
    const abilityArr = [hasName, hasType, hasCondition, hasRisesAbove, hasFallsBelow, hasFilter];
    const entryExitArr = [hasName, hasType, hasEntryExit, hasMap, hasPlace, hasFilter];
    if (abilityArr.every((item) => item === true) || entryExitArr.every((item) => item === true)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [
    conditionError,
    hasName,
    hasType,
    hasCondition,
    hasRisesAbove,
    hasFallsBelow,
    hasEntryExit,
    hasMap,
    hasPlace,
    hasAssetFilter,
    hasFilter,
  ]);

  return [
    conditionError,
    hasName,
    hasType,
    hasCondition,
    hasRisesAbove,
    hasFallsBelow,
    hasEntryExit,
    hasMap,
    hasPlace,
    hasAssetFilter,
    hasFilter,
    isValid,
  ];
};
