import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import * as loadingAnim from '../../../../animations/loading.json';
import BeaconAbilitiesColumnDefs from '../../../../screens/assets/BeaconAbilitiesColumnDefs';
import { Api } from '../../../../services';
import { isAdmin, isSystemAdmin } from '../../../../utils';
import { Loading, toast } from '../../..';
import { EditDeleteCellRenderer, IconCellRenderer } from '../../..';
import Icon from '../../../Icon/Icon';

function BeaconEditAbilitiesPopUp({
  data,
  iconName,
  variant = 'primary',
  disabled = true,
  className = '',
  style,
  size = 'lg',
}) {
  const authorized = isAdmin() || isSystemAdmin();
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const gridRef = useRef(null);

  // Truncate data.name if it's longer than 20 characters
  const truncatedName = data.name.length > 21 ? data.name.slice(0, 21) + '...' : data.name;

  const onClickEditBeacon = async () => {
    setModal(!modal);
    setIsLoading(true);
    try {
      const res = await Api.get(`v2/assets/${data.assetId}`);
      const beacons = res.data.beacons;

      beacons.forEach((beacon) => {
        // If the beacon id is the same as the beacon id from the new call being made
        if (beacon.id === data.id) {
          // The Newest beacon abilities will be used/loaded when opening the edit frequency pop-up
          data.abilities = beacon.abilities;
        }
      });
    } catch (error) {
      // Handle any errors that might occur during the API call
      console.error('Error fetching data:', error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const handleGridReady = (event: GridReadyEvent) => {
      const gridApi = event.api;
      gridApi.sizeColumnsToFit();
    };

    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.addEventListener('gridReady', handleGridReady);
    }

    return () => {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.removeEventListener('gridReady', handleGridReady);
      }
    };
  }, []);

  const gridProps = {
    frameworkComponents: {
      IconCellRenderer,
      EditDeleteCellRenderer,
    },
    rowHeight: 0,
    columnDefs: BeaconAbilitiesColumnDefs(),
    rowData: data.abilities
      .map((ability, abilityIndex) => {
        let frequencyDisplay = '';

        if (ability.frequency >= 3600) {
          const hours = Math.floor(ability.frequency / 3600);
          const minutes = Math.floor((ability.frequency % 3600) / 60);

          if (hours > 0) {
            frequencyDisplay += `${hours} hour${hours !== 1 ? 's' : ''}`;
          }

          if (minutes > 0) {
            frequencyDisplay += `${hours > 0 ? ' ' : ''}${minutes} min${minutes !== 1 ? 's' : ''}`;
          }
        } else if (ability.frequency >= 60) {
          const minutes = Math.floor(ability.frequency / 60);
          frequencyDisplay = `${minutes} min${minutes !== 1 ? 's' : ''}`;
        } else {
          frequencyDisplay = `${ability.frequency} sec${ability.frequency !== 1 ? 's' : ''}`;
        }

        return {
          isLocationBeacon: data.isLocationBeacon,
          abilities: data.abilities,
          assetId: data.assetId,
          beaconId: data.id,
          abilityId: ability.id,
          abilityIndex: abilityIndex,
          ability: ability.name,
          frequency: frequencyDisplay,
        };
      })
      .reverse(),
    defaultColDef: { sortable: false, filter: false },
  };
  return (
    <>
      {authorized && (
        <>
          <Modal centered show={modal} onHide={() => setModal(false)}>
            <ModalBody style={{ height: '330px', width: '100%' }}>
              <div
                className="d-flex justify-content-end position-absolute"
                style={{ top: '10px', right: '20px' }}
              >
                <Button variant="light" onClick={() => setModal(false)}>
                  <i className="fa fa-times" style={{ fontSize: '24px', fontWeight: '300' }} />
                </Button>
              </div>
              <h5 className="text-center">{truncatedName} Beacon Abilities</h5>
              {isLoading ? (
                <Loading
                  animationData={loadingAnim.default}
                  width={200}
                  height={200}
                  style={{ paddingBottom: '35px' }}
                />
              ) : (
                <div className="ag-theme-material" style={{ height: '250px', width: '102%' }}>
                  <AgGridReact {...gridProps} ref={gridRef} />
                </div>
              )}
            </ModalBody>
          </Modal>
          <Icon
            name={iconName}
            className={className}
            variant={variant}
            disabled={disabled}
            style={style}
            onClick={onClickEditBeacon}
            size={size}
          />
        </>
      )}
    </>
  );
}

export default BeaconEditAbilitiesPopUp;
