import { Api } from '@services';
import { useState } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';

export function SingleAssetInput({ valid, invalid, disabled, initFilter, onFilterChange }) {
  const [cache, setCache] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [query, setQuery] = useState<string>('');

  const getResults = async (call) => {
    const resp = await Api.get<any[]>(call);
    const newItems = resp.data['items'].map((i) => ({
      id: i.id,
      name: i.name,
    }));
    const next = resp.data['next'];
    const total = resp.data['count'];
    return { newItems, next, total };
  };

  const handleSearch = (value) => {
    onFilterChange(null);
    setQuery(value);
    // if cached query items are available then set those as the initial options
    if (cache[value]) {
      setOptions(cache[value].items);
    } else {
      // get and cache new query items
      const initialCall = `/assets?top=27&skip=0&filter=${value}&orderBy=name`;
      setIsLoading(true);
      getResults(initialCall).then((resp) => {
        setOptions(resp.newItems);
        setCache({
          ...cache,
          [value]: { items: resp.newItems, next: resp.next, total: resp.total },
        });
        setIsLoading(false);
      });
    }
  };

  const handlePagination = () => {
    // if query cache length is less than total
    // continue to fetch new items on pagination using cached next api call
    if (cache[query].items.length !== cache[query].total) {
      setIsLoading(true);
      getResults(cache[query].next).then((resp) => {
        const items = cache[query].items.concat(resp.newItems);
        setOptions(items);
        setCache({ ...cache, [query]: { items: items, next: resp.next, total: resp.total } });
        setIsLoading(false);
      });
    }
  };

  const handleChange = (selected) => {
    if (selected.length > 0) {
      let value = selected[0]?.id;
      onFilterChange(`id(${value})`);
    } else {
      onFilterChange(null);
    }
  };

  return (
    <AsyncTypeahead
      defaultInputValue={initFilter}
      delay={500}
      disabled={disabled}
      id="single-asset"
      inputProps={{ style: { width: '100%' } }}
      isInvalid={invalid}
      isLoading={isLoading}
      isValid={valid}
      maxResults={25}
      minLength={1}
      onChange={handleChange}
      onPaginate={handlePagination}
      onSearch={handleSearch}
      options={
        options &&
        options.map((option) => {
          return {
            id: option.id,
            label: option.name,
          };
        })
      }
      paginate
      placeholder="Search for an Asset..."
      renderMenuItemChildren={(option, { text }) => (
        <div title={option.label}>
          <Highlighter search={text}>{option.label}</Highlighter>
          <div>
            <small className="filter-sm-name">Asset ID: {option.id}</small>
          </div>
        </div>
      )}
      useCache={false}
    />
  );
}
