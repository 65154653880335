import { toast } from 'react-toastify';

const Toast = {
  warn(msg) {
    return toast.warn(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-warn',
      autoClose: 5000,
    });
  },
  error(msg = '') {
    return toast.error(msg || 'Error.', {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-failure',
      autoClose: 5000,
    });
  },
  success(msg = '') {
    return toast.success(msg || 'Success!', {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-success',
      autoClose: 5000,
    });
  },
  info(msg) {
    return toast.info(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: 'toast-info',
      autoClose: 5000,
    });
  },
};

export default Toast;
