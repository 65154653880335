interface ITriggerContact {
  contactId: number;
  name: string;
  Email: boolean;
  Sms: boolean;
  Mobile: boolean;
}

export class TriggerContact implements ITriggerContact {
  contactId: number;
  name: string;
  Email: boolean;
  Sms: boolean;
  Mobile: boolean;

  public Create(id: number, name: string): TriggerContact {
    this.contactId = id;
    this.name = name;
    this.Email = true;
    this.Sms = true;
    this.Mobile = true;
    return this;
  }
}
