import { Popover, Tooltip, OverlayTrigger } from 'react-bootstrap';

function HelpTooltip({ title, content }) {
  const renderOverlay = () => (
    <Tooltip>
      <Popover style={{ width: 200 }}>
        <Popover.Header as="h3" style={{ color: 'black' }}>
          {title}
        </Popover.Header>
        <Popover.Body>{content}</Popover.Body>
      </Popover>
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="bottom" overlay={renderOverlay()}>
      <i className="fa fa-question-circle fa-xs me-1 pointer" />
    </OverlayTrigger>
  );
}

export default HelpTooltip;
