import { Component } from 'react';
import { Button } from 'react-bootstrap';
import Lottie from 'react-lottie-player';
import * as errorAnim from '../../animations/errorCloud.json';
import { authService } from '../../services';
import Text from '../Text/Text';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error({ error, errorInfo });
  }

  onLogout = () => {
    this.setState({ hasError: false }, () => {
      authService.logout();
    });
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="d-flex flex-column justify-content-center align-items-center p-5">
          <Text as="h1" className="fs-2x fw-bold">
            Something went wrong.
          </Text>
          <Lottie animationData={errorAnim.default} />
          <p className="fs-lg">Try to refresh the page.</p>
          <p>If an error keeps occurring, you may need to Logout and Login again.</p>
          <Button variant="primary" onClick={this.onLogout}>
            Logout
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
