import './PlaceScreen.scss';
import '../../scss/variables.scss';
import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  BeaconReadingsCellRenderer,
  DetailOptionsOverlay,
  Icon,
  LinkCellRenderer,
  Loading,
  OnlineStatusCellRenderer,
  Text,
  toast,
} from '../../components';
import { PagedQueryParams, ServerSideGrid } from '../../components/grids/AgGrid/ServerSideGrid';
import { ABILITIES, ABILITY_MAP } from '../../constants';
import LocationMap from '../../modules/location/components/locationMap';
import { useApp } from '../../react';
import { Api } from '../../services';
import { favoriteService } from '../../services';
import { isReadOnly } from './../../utils';
import { assetColumnDefs, gatewaysColumnDefs, stationaryBeaconsColumnDefs } from './columnDefs';

const MySwal = withReactContent(Swal);

const TAB_KEYS_MAP = {
  ASSETS: 'Assets',
  STATIONARY_BEACONS: 'Stationary Beacons',
  GATEWAYS: 'Gateways',
};

const TAB_KEYS = Object.values(TAB_KEYS_MAP);

function PlaceScreen(props) {
  const [tabKey, setTabKey] = useState(TAB_KEYS_MAP.ASSETS);
  const [abilityTabKey, setAbilityTabKey] = useState(ABILITY_MAP.Temperature.name);
  const [mapLayers, setMapLayers] = useState([]);
  const [isFavorite, setIsFavorite] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [place, setPlace] = useState(null);
  const [map, setMap] = useState(null);
  const [gateways, setGateways] = useState(null);
  const [stationaryBeacons, setStationaryBeacons] = useState(null);
  const [assets, setAssets] = useState(null);
  const [assetLocations, setAssetLocations] = useState(null);
  const [gatewayLocations, setGatewayLocations] = useState(null);
  const [stationaryBeaconLocations, setStationaryBeaconLocations] = useState(null);

  const app = useApp();
  const placeId = props.match.params.placeId;
  const defaultAssetFilter = [{ field: 'placeId', type: 'equal', filter: placeId }];
  const assetFilters = JSON.parse(localStorage.getItem('assetFilters')).concat(
    JSON.parse(localStorage.getItem('tempFilters')) || [],
  );

  useEffect(() => {
    getPlaceData();
    checkIsFavorite();
  }, []);

  useEffect(() => {
    fetchMapLocations();
  }, [map, assets]);

  // Getting Place & map data for place detail page
  const getPlaceData = async () => {
    setIsLoading(true);

    try {
      // Fetch place
      const { data: placeInfo } = await Api.get(`/v2/places/${placeId}`);
      // Set place data
      setPlace(placeInfo);
      // Get Place map Image
      getPlaceMap(placeInfo);
      // Setting base data for Assets, stationary Beacon and Gateways
      const [stationaryBeaconsResponse, gatewaysResponse] = await Promise.all([
        Api.get(`v2/places/${placeId}/stationary-beacons`),
        Api.get(`v2/places/${placeId}/gateways`),
      ]);
      const stationaryBeaconsData = stationaryBeaconsResponse.data;
      const gatewaysData = gatewaysResponse.data;
      setStationaryBeacons({
        items: stationaryBeaconsData,
        count: stationaryBeaconsData?.length,
      });
      setGateways({ items: gatewaysData, count: gatewaysData?.length });
    } catch (error) {
      console.error('Error fetching base data:', error);
    }
  };

  const getPlaceMap = async (place) => {
    const { data: mapData } = await Api.get(`/maps/${place.mapId}/layout?trackView=false`);
    setMap(mapData);
  };

  // Filtering for assets, beacons & gateways on places detail page
  const onFilterChange = async (payload) => {
    let formattedTabKey = tabKey.toLowerCase().replace(/\s+/g, '-');
    setIsLoading(true);

    if (tabKey === 'Assets') {
      Api.post(`/v2/${formattedTabKey}/filter/`, payload).then((data) => {
        const assets = data.data;
        setAssets(assets);
        setAssetLocations(getAssetLocations(assets, map));
        setIsLoading(false);
      });
    } else if (tabKey === 'Stationary Beacons') {
      Api.post(`/v2/${formattedTabKey}/filtered/`, payload).then((data) => {
        const beacons = data.data;
        setStationaryBeacons(beacons);
        setStationaryBeaconLocations(getStationaryBeaconLocations(beacons, map));
        setIsLoading(false);
      });
    } else if (tabKey === 'Gateways') {
      //Filter endpoint hasnt been created yet filtering is off for gateways
      // const { data: gateways } = await Api.post(`v2/places/${formattedTabKey}/filtered`, payload);
      // setGateways({ items: gateways, count: gateways?.length });
      setIsLoading(false);
    }
  };

  const getAssetLocations = (assets, map) => {
    return assets.items.map((asset) => {
      return {
        location: asset.location
          ? {
              x: asset.location.coordinates.x,
              y: asset.location.coordinates.y,
              type: 0,
            }
          : asset.coordinates
          ? {
              x: asset.coordinates.x,
              y: asset.coordinates.y,
              type: 0,
            }
          : null,
        isVisible: true,
        mapId: map?.id,
        name: asset.name,
        onmouseover: (e) => e.marker.openPopup(),
        color: asset.status === 1 ? 'var(--c-primary)' : 'var(--danger)',
        fillColor: asset.status === 1 ? 'var(--c-primary)' : 'var(--danger)',
        asset,
      };
    });
  };

  const getStationaryBeaconLocations = (stationaryBeacons) => {
    return stationaryBeacons.items.map((currBeacon, index) => {
      const location = {
        type: 0,
        x: currBeacon.coordinates.x,
        y: currBeacon.coordinates.y,
      };
      const beacon = { ...currBeacon, Location: location };
      currBeacon.readings.forEach((reading) => {
        beacon[reading.ability] = reading;
      });

      return {
        beacon,
        index,
        isVisible: true,
        location,
        autoPopup: true,
      };
    });
  };

  const getGatewayLocations = (gateways) => {
    return gateways.items.map((gateway, index) => {
      return {
        gateway,
        isVisible: true,
        location: {
          type: 0,
          x: gateway.coordinates.x,
          y: gateway.coordinates.y,
        },
        index,
        autoPopup: true,
      };
    });
  };

  // set locations for assets, beacons, & gateways on the map AFTER map has been loaded
  const fetchMapLocations = () => {
    if (map && assets) {
      setAssetLocations(getAssetLocations(assets, map));
    }
    if (map && stationaryBeacons) {
      setStationaryBeaconLocations(getStationaryBeaconLocations(stationaryBeacons, map));
    }
    if (map && gateways) {
      setGatewayLocations(getGatewayLocations(gateways));
    }
  };

  // #region FAVORITE
  const favoritePlace = async () => {
    await favoriteService.favoritePlace(placeId);
    checkIsFavorite();
  };

  const unfavoritePlace = async () => {
    await favoriteService.unfavoritePlace(placeId);
    checkIsFavorite();
  };

  const checkIsFavorite = async () => {
    const { data } = await favoriteService.getFavorites(app.id);
    const favorite = data.find((fav) => fav.itemType === 'Place' && fav.itemId === Number(placeId));
    setIsFavorite(!!favorite);
  };
  // #endregion FAVORITE

  const deletePlace = async () => {
    const { placeId } = props.match.params;

    let userResponsePromise = new Promise((resolve, reject) => {
      MySwal.fire({
        title: `Are you sure?`,
        text: 'This place will be deleted?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
      })
        .then((submit) => {
          resolve(submit.isConfirmed);
        })
        .catch((err) => {
          reject(err);
        });
    });

    let userResponse;

    try {
      userResponse = await userResponsePromise;
    } catch (error) {
      userResponse = false;
      console.log(error);
    }

    if (userResponse) {
      try {
        await Api.delete(`/places/${placeId}`);
        props.history.push('/places');
        toast.success('Place was successfully deleted!');
      } catch (error) {
        toast.error('Failed to delete the place.');
      }
    }
  };

  const onAssetClicked = useCallback(
    ({ node }) => {
      const mapLayer = mapLayers?.find((layer) => layer.data.asset?.id === node.data?.id);
      mapLayer
        ?.bindPopup(
          `<br/>
          <div class='d-flex flex-row align-items-center'>
            <strong class='col-10'>${mapLayer.data.asset.name}</strong> 
            <a
              class='btn-light d-flex justify-content-left align-items-center' href='#'
              onclick="CBCopy('${mapLayer.data.asset.name}')"
            > 
              <i class="col-1 far fa-lg fa-copy" ></i> 
            <a>
          </div>
          <hr/>
          <div class="row">
            <div class="col text-center" style="text-align:center;">
              <span class="btn btn-primary btn-sm" type="button" onclick={window.markers.locations?.[${mapLayer.data.asset?.id}]()}>Go to Asset</span>
            </div>
          </div>`,
          { autoPan: false },
        )
        .openPopup();
    },
    [mapLayers],
  );

  const onStationaryClicked = useCallback(
    ({ node }) => {
      const mapLayer = mapLayers?.find((layer) => layer.data.beacon?.id === node.data?.id);
      mapLayer
        ?.bindPopup(
          `<br/>
          <div class='d-flex flex-row align-items-center'>
            <strong class='col-10'>Id: ${mapLayer.data.beacon.id}</strong> 
            <a
              class='btn-light d-flex justify-content-left align-items-center' href='#'
              onclick="CBCopy('${mapLayer.data.beacon.id}')"
            > 
              <i class="col-1 far fa-lg fa-copy" ></i> 
            <a>
          </div>`,
          { autoPan: false },
        )
        .openPopup();
    },
    [mapLayers],
  );

  const onGatewayClicked = useCallback(
    ({ node }) => {
      const mapLayer = mapLayers?.find((layer) => layer.data.gateway?.id === node.data?.id);
      mapLayer
        ?.bindPopup(
          `<br/>
          <div class='d-flex flex-row align-items-center'>
            <strong class='col-10'>Id: ${mapLayer.data.gateway.id}</strong> 
            <a
              class='btn-light d-flex justify-content-left align-items-center' href='#'
              onclick="CBCopy('${mapLayer.data.gateway.id}')"
            > 
              <i class="col-1 far fa-lg fa-copy" ></i> 
            <a>
          </div>`,
          { autoPan: false },
        )
        .openPopup();
    },
    [mapLayers],
  );

  function renderTabs() {
    return (
      <Tabs activeKey={tabKey} onSelect={(key) => setTabKey(key)}>
        {TAB_KEYS.map((currTabKey) => {
          return <Tab key={currTabKey} eventKey={currTabKey} title={currTabKey} />;
        })}
      </Tabs>
    );
  }

  const renderAssetsGrid = () => {
    return (
      <ServerSideGrid
        id={'assetGrid'}
        initQueryParams={new PagedQueryParams()}
        assetFilters={assetFilters}
        isLoading={isLoading}
        columnDefs={assetColumnDefs}
        handleFetchData={(payload, isCSV) => {
          onFilterChange(
            payload,
            defaultAssetFilter.map((item) => payload.filters.push(item)),
          );
        }}
        rowData={assets || []}
        ignoreFetch={false}
        disabledFilters={['all', 'map', 'place', 'device id', 'battery value']}
        filterControl={true}
        searchControl={true}
        viewingControl={true}
        pagerControl={true}
        exportControl={false}
        gridProps={{
          frameworkComponents: {
            OnlineStatusCellRenderer,
            LinkCellRenderer,
            BeaconReadingsCellRenderer,
          },
          onCellClicked: onAssetClicked,
          getRowNodeId: (data) => data.id,
        }}
      />
    );
  };

  function renderStationaryBeaconsGrid() {
    return (
      <ServerSideGrid
        id={'stationaryBeaconsGrid'}
        initQueryParams={new PagedQueryParams()}
        assetFilters={assetFilters}
        isLoading={isLoading}
        columnDefs={stationaryBeaconsColumnDefs}
        handleFetchData={(payload, isCSV) => {
          onFilterChange(
            payload,
            defaultAssetFilter.map((item) => payload.filters.push(item)),
          );
        }}
        rowData={stationaryBeacons ? stationaryBeacons : []}
        ignoreFetch={false}
        disabledFilters={['all', 'map', 'place', 'active', 'single asset', 'keyword', 'identifier']}
        filterControl={true}
        searchControl={true}
        viewingControl={true}
        pagerControl={true}
        exportControl={false}
        gridProps={{
          onCellClicked: onStationaryClicked,
          getRowNodeId: (data) => data.id,
        }}
      />
    );
  }

  function renderGatewaysGrid() {
    return (
      <ServerSideGrid
        id={'GatewaysGrid'}
        initQueryParams={new PagedQueryParams()}
        isLoading={isLoading}
        columnDefs={gatewaysColumnDefs}
        rowData={gateways}
        ignoreFetch={true}
        filterControl={false}
        searchControl={false}
        viewingControl={false}
        pagerControl={false}
        exportControl={false}
        gridProps={{
          onCellClicked: onGatewayClicked,
          getRowNodeId: (data) => data.id,
        }}
      />
    );
  }

  // render grid based off of selected tab
  function renderGrid() {
    switch (tabKey) {
      case TAB_KEYS_MAP.ASSETS:
        return renderAssetsGrid();
      case TAB_KEYS_MAP.STATIONARY_BEACONS:
        return renderStationaryBeaconsGrid();
      case TAB_KEYS_MAP.GATEWAYS:
        return renderGatewaysGrid();
      default:
        return null;
    }
  }
  // #endregion GRID RENDER FUNCTIONS

  // #region MAP RENDER FUNCTIONS
  function renderAssetsMap() {
    if (assetLocations === null) return null;

    return (
      <LocationMap
        id="assetsMap"
        key="assetsMap"
        map={map}
        locations={assetLocations}
        height="100%"
        onSetLocationLayers={(e) => setMapLayers(e)}
      />
    );
  }

  function renderStationaryBeaconsMap() {
    return (
      <div className="h-100">
        <Tabs activeKey={abilityTabKey} onSelect={(key) => setAbilityTabKey(key)}>
          {ABILITIES.filter((ability) =>
            ['Temperature', 'Humidity', 'Location', 'Gateway Health Check'].includes(ability),
          ).map((ability) => {
            return (
              <Tab
                key={ability}
                eventKey={ability}
                title={
                  <Icon
                    variant="dark"
                    name={ability.toLowerCase()}
                    disabled={false}
                    title={ability}
                    style={{ color: 'var(--primary)' }}
                  />
                }
              />
            );
          })}
        </Tabs>

        <LocationMap
          id="stationaryBeaconsMap"
          key="stationaryBeaconsMap"
          map={map}
          beacons={stationaryBeaconLocations}
          reading={abilityTabKey}
          height="85%"
          onSetLayers={(e) => setMapLayers(e)}
        />
      </div>
    );
  }

  function renderGatewaysMap() {
    return (
      <LocationMap
        id="gatewaysMap"
        key="gatewaysMap"
        map={map}
        gateways={gatewayLocations}
        height="100%"
        onSetLayers={(e) => setMapLayers(e)}
      />
    );
  }

  // render map based off selected tab
  function renderMap() {
    switch (tabKey) {
      case TAB_KEYS_MAP.ASSETS:
        return renderAssetsMap();
      case TAB_KEYS_MAP.GATEWAYS:
        return renderGatewaysMap();
      case TAB_KEYS_MAP.STATIONARY_BEACONS:
        return renderStationaryBeaconsMap();
      default:
        return null;
    }
  }
  // #endregion MAP RENDER FUNCTIONS

  if (place === null) {
    return <Loading />;
  }

  return (
    <Card className="h-100" style={{ overflow: 'hidden' }}>
      <Card.Header>
        <Card.Title>
          {/* Favorite and Options */}
          <Row>
            <Col className="d-flex flex-row justify-content-between">
              {/* Heading and Place ID */}
              <div className="d-flex flex-column">
                <Text className="fs-md mb-1">{place?.name}</Text>
                <Text className="fs-sm fw-light">Place ID: {placeId}</Text>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-end">
                <Icon
                  name={isFavorite ? 'star-fill' : 'star-empty'}
                  size="lg"
                  variant="light"
                  className="font-weight-bold"
                  disabled={false}
                  onClick={isFavorite ? unfavoritePlace : favoritePlace}
                  style={{ color: 'var(--primary)' }}
                />
                {isReadOnly() === false && (
                  <DetailOptionsOverlay onClickDelete={deletePlace} showEditBtn={false} />
                )}
              </div>
            </Col>
          </Row>
        </Card.Title>

        {/* Tabs */}
        <Row>
          <Col>{renderTabs()}</Col>
        </Row>
      </Card.Header>
      <Card.Body className="h-100">
        {/* Grid & Map */}
        <Row className="h-100">
          {/* Grid */}
          <Col className="place-grid" xxl={12}>
            {renderGrid()}
          </Col>

          {/* Map */}
          <Col className="place-map" xxl={12}>
            <div style={{ height: '90%' }}>{renderMap()}</div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default PlaceScreen;
