import PropTypes from 'prop-types';
import '../../scss/_export.module.scss';

function Text({ as = 'span', style = {}, children, className = '', ...restProps }) {
  const Comp = as;

  return (
    <Comp {...restProps} className={`text ${className}`} style={style}>
      {children}
    </Comp>
  );
}

Text.propTypes = {
  children: PropTypes.any.isRequired,

  // defaults
  as: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
};

export default Text;
