import * as types from '../types';

const reporting = (
  state = {
    isLoading: false,
    items: [],
    assetTypes: [],
    pages: 0,
    total: 0,
    currentPage: 1,
  },
  action,
) => {
  switch (action.type) {
    case types.LOCATION_REQUEST: // get all request started
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.LOCATION_RECEIVED: // things request completed
      return Object.assign({}, state, {
        isLoading: false,
        items: action.items,
        pages: action.pages,
        total: action.total,
      });

    case types.ASSET_TYPES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });

    case types.ASSET_TYPES_RECEIVED: // things request completed
      return Object.assign({}, state, {
        isLoading: false,
        assetTypes: action.items,
      });

    case types.TEMP_HUMIDITY_REQUEST: // get all request started
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.TEMP_HUMIDITY_RECEIVED: // things request completed
      return Object.assign({}, state, {
        isLoading: false,
        items: action.items,
        pages: action.pages,
        total: action.total,
      });
    case types.REPORT_REQUEST_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
      });

    default:
      return state;
  }
};

export default reporting;
