import './StartEndDatePicker.scss';
import MomentUtils from '@date-io/moment';
import { createTheme } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#0048FF',
    },
  },
  overrides: {
    MuiDialogActions: {
      root: {
        boxShadow: '0 0 0 1px var(--c-primary)',
        '& button': {
          textTransform: 'unset',
        },
        '& button:last-child': {
          color: '#fff',
          backgroundColor: '#0048FF',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'rgb(206, 212, 218)',
          top: '-3px !important',
          bottom: '-3px !important',
        },
        '&:hover $notchedOutline': {
          borderColor: 'rgb(206, 212, 218)',
        },
        '&$focused $notchedOutline': {
          borderColor: 'var(--c-primary)',
        },
      },
      adornedEnd: {
        paddingRight: '5px',
      },
    },
    MuiFormControl: {
      root: {
        minWidth: 'auto',
        width: '100%',
      },
    },
    MuiInputBase: {
      root: {
        color: '(var--dark)',
      },
    },
    MuiFormLabel: {
      root: {
        color: '(var--dark)',
        top: '-10px!important',
        margin: '0!important',
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(14px, -2px) scale(0.75) !important',
        zIndex: '1!important',
        backgroundColor: 'var(--light) !important',
        padding: '3px !important',
      },
      outlined: { zIndex: '0' },
    },
    MuiIconButton: {
      root: {
        color: '(var--dark)',
        padding: '0',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: 'var(--c-primary)',
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: 'white!important',
      },
    },
    MuiFormHelperText: {
      root: {
        color: 'var(--danger)!important',
      },
    },
  },
});

const DateTimeWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: baseline;
  justify-items: center;
  margin-top: 3px;
`;

const SingleDateTimeWrapper = styled.div`
  width: 100%;
  justify-items: center;
  margin-top: 3px;
`;

// Single Version Start-End Date Picker for Analytics Overview Page

const AdvancedAssetAddRunPicker = (props) => {
  const clearDate = null;
  return (
    <Form>
      <SingleDateTimeWrapper>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              variant="inline"
              inputVariant="outlined"
              label={'Run Start Date'}
              value={props.addRun === undefined ? clearDate : props.addRun}
              format="MM/DD/YYYY hh:mm A"
              placeholder="Run Start Date"
              onChange={(date) => {
                props.handleAddRunChange(date);
              }}
              disableFuture
              className="me-3"
              error={false}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </SingleDateTimeWrapper>
    </Form>
  );
};

export default AdvancedAssetAddRunPicker;
