import { getFormattedBatteryDateTime, getFormattedDateTime } from '@utils';
import { LinkCellRenderer } from '../../components';

export const environmentalColumns = [
  {
    headerName: 'Asset Name',
    field: 'assetName',
    cellRenderer: ({ data }) => {
      if (data.assetId) {
        return <LinkCellRenderer to={`/assets/${data.assetId}`} text={data.assetName} />;
      } else {
        return '--';
      }
    },
  },
  {
    headerName: 'Beacon Name',
    field: 'beaconName',
  },
  {
    headerName: 'Device ID',
    field: 'deviceId',
  },
  {
    headerName: 'Time Recorded',
    field: 'readingTime',
    valueFormatter(params) {
      return getFormattedDateTime(params.value);
    },
  },
  {
    headerName: 'Temperature',
    field: 'averageCelsiusAndFahrenheit',
    valueFormatter(params) {
      return params.value === 'F/C' ? '--' : params.value;
    },
  },
  {
    headerName: 'Humidity',
    field: 'averageRelativeHumidity',
    valueFormatter(params) {
      return params.value ? params.value : '--';
    },
  },
];

export const assetColumns = [
  {
    headerName: 'Asset Name',
    field: '',
  },
  {
    headerName: 'Last Location',
    field: '',
  },
  {
    headerName: 'Asset Manufacturer',
    field: '',
  },
  {
    headerName: 'Alt. ID / Crest',
    field: '',
  },
  {
    headerName: 'Last Check-In',
    field: '',
  },
  {
    headerName: 'Last Maintenance',
    field: '',
  },
];

export const triggerResolutionColumns = [
  {
    headerName: 'Asset Name',
    field: 'assetName',
    cellRenderer: ({ data }) => {
      if (data.assetId) {
        return <LinkCellRenderer to={`/assets/${data.assetId}`} text={data.assetName} />;
      } else {
        return '--';
      }
    },
  },
  {
    headerName: 'Trigger Name',
    field: 'triggerName',
  },
  {
    headerName: 'Time Triggered',
    field: 'notificationTime',
    valueFormatter(params) {
      return getFormattedDateTime(params.value);
    },
  },
  {
    headerName: 'Recipient',
    field: 'recipientName',
  },
  {
    headerName: 'Response Time',
    field: 'responseTime',
    valueFormatter(params) {
      return getFormattedDateTime(params.value);
    },
  },
  {
    headerName: 'Triggered Value',
    field: 'reportedValue',
  },
  {
    headerName: 'Expected Value',
    field: 'compareValue',
  },
  {
    headerName: 'Comment',
    field: 'comment',
  },
];

export const batteryColumns = [
  {
    headerName: 'Asset Name',
    field: 'assetName',
    cellRenderer: ({ data }) => {
      if (data.assetId) {
        return <LinkCellRenderer to={`/assets/${data.assetId}`} text={data.assetName} />;
      } else {
        return '--';
      }
    },
  },
  {
    headerName: 'Asset ID',
    field: 'assetId',
  },
  {
    headerName: 'Device ID',
    field: 'deviceId',
  },
  {
    headerName: 'Map',
    field: 'mapName',
  },
  {
    headerName: 'Place',
    field: 'placeName',
  },
  {
    headerName: 'Battery Value',
    field: 'batteryValue',
  },
  {
    headerName: 'Last Check-In',
    field: 'lastTimestamp',
    valueFormatter(params) {
      return getFormattedDateTime(params.value);
    },
  },
  {
    headerName: 'Battery Last Changed',
    field: 'lastChanged',
    valueFormatter(params) {
      return getFormattedBatteryDateTime(params.value);
    },
  },
];
