export function V2FilterPayload(
  assetFilters: any[],
  condition: string,
  skip: number,
  sorts: any,
  take: number,
  defaultFilter?: any,
  search?: string,
  startDate?: string,
  endDate?: any,
) {
  let str = null;
  let type = null;
  let arr = [];
  if (defaultFilter !== undefined) {
    defaultFilter.map((item: any) => arr.push(item));
  }

  // Filter out duplicate 'active(false)' and 'active(true)' entries
  const uniqueFilters = assetFilters.filter((value, index, self) => {
    if (value.includes('active')) {
      const isActiveTrue = value === 'active(true)';
      const isActiveFalse = value === 'active(false)';
      return !self.some((entry) => {
        if (isActiveTrue) return entry === 'active(false)';
        if (isActiveFalse) return entry === 'active(true)';
        return false;
      });
    }
    return true;
  });

  uniqueFilters.forEach((filter: any) => {
    const fromIndex = filter.indexOf('(');
    const toIndex = filter.indexOf(')');
    let comparisonStr;

    if (filter === 'active(true)') {
      type = 'active';
      str = 'true';
    } else if (filter === 'active(false)') {
      type = 'active';
      str = 'false';
    } else {
      type = filter === 'all' ? 'all' : filter?.substring(0, fromIndex);
      switch (type) {
        case 'all':
          type = 'All';
          str = '*';
          break;
        case 'id':
          type = 'Id';
          str = filter.substring(fromIndex + 1, toIndex);
          break;
        case 'identifiers':
          type = 'Identifiers';
          str = filter.substring(fromIndex + 1, toIndex).split(',')[0];
          break;
        case 'keywords':
          type = 'Keywords';
          str = filter.substring(fromIndex + 1, toIndex).split(',')[0];
          break;
        case 'map':
          type = 'Maps';
          str = filter.substring(fromIndex + 1, toIndex);
          break;
        case 'name':
          type = 'Name';
          str = filter.substring(fromIndex + 1, toIndex);
          break;
        case 'place':
          type = 'Places';
          str = filter.substring(fromIndex + 1, toIndex);
          break;
        case 'deviceId':
          type = 'deviceId';
          str = filter.substring(fromIndex + 1, toIndex);
          break;
        case 'batteryValue':
          const [comparison, value] = filter.substring(fromIndex + 1, toIndex).split(',');
          comparisonStr = comparison === '0' ? '>' : '<';

          type = `batteryvalue`;
          str = parseFloat(value.trim());
          break;
      }
    }
    arr.push({
      field: type.toLowerCase(),
      type:
        type === 'Id' || type === 'active'
          ? 'equals'
          : type.startsWith('battery')
          ? comparisonStr
          : 'contains',

      filter: str,
    });
  });

  const filters: any[] = arr;

  if (startDate && endDate) {
    const formattedStartDate = new Date(startDate).toISOString();
    const formattedEndDate = new Date(endDate).toISOString();

    filters.push(
      {
        field: 'startdate',
        filter: formattedStartDate,
      },
      {
        field: 'enddate',
        filter: formattedEndDate,
      },
    );
  }

  let payload = {
    skip: skip,
    take: take,
    filters: filters,
    sorts: sorts,
    fields: [],
    search: decodeURI(search),
    condition: condition,
  };

  return payload;
}

/**
 * Interface of V3FilterPayload
 * @param assetFilter - The different type of filters to apply
 * @param condition - THe condition AND or OR
 * @param skip - Allows yo skip items return alomost like it
 * @param sorts - Controls sort direction ASC or DESC
 * @param take - Allows you only take certain amount of items
 * @param defaultFilter - The default filter
 * @param search - The alphanun text type into the search bar
 * @param startDate - The start date
 * @param endDate - The end date
 */
export interface IV2FilterPayload {
  assetFilters: any[];
  condition: string;
  skip: number;
  sorts: any;
  take: number;
  defaultFilter?: any;
  search?: string;
  startDate?: string;
  endDate?: any;
}
