/* eslint-disable react-hooks/exhaustive-deps */
import { ColDefUtil, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import { get, isArray, isUndefined } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { FilterContainer } from '../../../containers/FilterContainer';
import { SearchInput } from '../../inputs';
import Pager from '../../Pager/Pager';

// temporary solution for dynamic resizing of rows counter input
const RowCounter = styled.input`
  min-width: 4rem;
  width: ${(props) => props.value.toString().length + 0.5 + 'rem'} !important;
  text-align: center;
`;

const COLUMNS_SIZES = {
  TRUNCATE: 'truncate',
  NOTRUNCATE: 'notruncate',
};

interface Props {
  id: string;
  columnDefs: any[];
  rowData: any[];
  isLoading: boolean;
  initSearch: string;
  searchDebounceTimeSec: number;
  gridProps: any;
  useDomLayout: boolean;
  type: 'assetGrid' | 'mapDetail' | undefined;
  hasSearch: boolean;
  columnSize: 'truncate' | 'notruncate';
  pagination: boolean;
  onChangeSearch?: (args: any) => any;
  onChangeLimit?: (args: any) => any;
  onFilterChange?: (args: any) => any;
  onChangePage?: (args: any) => any;
  onGridReady?: (args: any) => any;
  onCellKeyPress?: (args: any) => any;
  onCellClick?: (args: any) => any;
  useQuickFilter: boolean;
}

const AgGrid: React.FC<Props> = ({
  id,
  columnDefs,
  rowData,
  isLoading = false,
  initSearch = '',
  searchDebounceTimeSec = 0,
  gridProps = {},
  useDomLayout = true,
  type,
  hasSearch = true,
  columnSize = 'truncate',
  useQuickFilter = true,
  pagination = false,
  onChangeSearch,
  onChangeLimit,
  onFilterChange,
  onChangePage,
  onGridReady,
  onCellKeyPress,
  onCellClick,
}) => {
  const agGridRef = useRef<AgGridReactType>();
  const [search, setSearch] = useState(initSearch);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(rowData.length < 20 ? rowData.length : 20);

  useEffect(() => {
    window.addEventListener('resize', getColumnSizeFn);
    return () => {
      window.removeEventListener('resize', getColumnSizeFn);
    };
  }, []);

  useEffect(() => {
    setLimit(rowData.length < 20 ? rowData.length : 20);
    agGridRef.current?.api?.setRowData(rowData);
  }, [rowData]);

  const autoSizeColumns = (skipHeader = false) => {
    let allColumnIds = [];
    if (isArray(agGridRef.current?.columnApi.getAllColumns())) {
      agGridRef.current?.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });
      agGridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  };

  const sizeColumnsToFit = () => {
    //if (!agGridRef.current) return;
    agGridRef?.current.api.sizeColumnsToFit();
  };

  const getColumnSizeFn = () => {
    if (columnSize === 'truncate') sizeColumnsToFit();
    return () => autoSizeColumns(); // avoid passing event as first arg to autoSizeColumns
  };

  const _onChangeSearch = (search) => {
    setSearch(search);
    if (onChangeSearch) onChangeSearch(search);
  };

  const _onChangeLimit = (e) => {
    setLimit(parseInt(e.target.value));
    if (onChangeLimit) onChangeLimit(parseInt(e.target.value));
  };

  const _onCellKeyPress = (e) => {
    let keyPressed = e.event.key;
    switch (keyPressed) {
      case '-':
        sizeColumnsToFit();
        break;
      case '+':
        autoSizeColumns();
        break;
      default:
        break;
    }

    if (onCellKeyPress) onCellKeyPress(e);
  };

  const _onCellClicked = (e) => {
    const cellClick = onCellClick || null;
    if (cellClick) {
      onCellClick(e);
    }
  };

  const _onGridReady = (params: GridReadyEvent) => {
    if (isLoading) agGridRef.current?.api.showLoadingOverlay();
    setTimeout(getColumnSizeFn, 500);

    if (onGridReady) onGridReady(params);
  };

  const getDefaultColDef = () => {
    return {
      sortable: true,
      filter: false,
      resizable: true,
      singleClickEdit: true,
      filterParams: {
        resetButton: true,
      },
    };
  };

  const onPaginationChanged = useCallback((page) => {
    agGridRef.current?.api.paginationGoToPage(page - 1);
    setPage(page);
    if (onChangePage) onChangePage(page);
  }, []);

  const renderHeader = () => {
    return (
      <div className="justify-content-between form-inline ps-4 pe-4 m-2">
        {hasSearch && (
          <InputGroup>
            <SearchInput
              id={id}
              initSearch={initSearch}
              placeholder="Quick Search"
              onChange={_onChangeSearch}
              debounceTimeSec={searchDebounceTimeSec}
            />
            {type === 'assetGrid' || type === 'mapDetail' ? (
              <FilterContainer
                onChange={onFilterChange}
                disabledFilters={['all', 'map(', 'place(']}
              />
            ) : null}
          </InputGroup>
        )}
        {pagination && (
          <>
            <div>
              <span>Viewing </span>
              <RowCounter
                type="number"
                max={rowData.length ?? 0}
                className="form-control form-control-sm"
                value={limit}
                onChange={_onChangeLimit}
                disabled={isUndefined(onChangeLimit)}
              />
              <span> of {rowData.length} items.</span>
            </div>
            <Pager
              page={page}
              pageCount={get(agGridRef, 'current')?.api.paginationGetTotalPages() || 1}
              onChange={onPaginationChanged}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="h-100">
      {renderHeader()}
      <div className="ag-theme-material h-100 w-100">
        <AgGridReact
          ref={agGridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          quickFilterText={useQuickFilter ? search : undefined}
          onGridReady={_onGridReady}
          onCellKeyPress={_onCellKeyPress}
          onCellClicked={_onCellClicked}
          defaultColDef={getDefaultColDef()}
          domLayout={useDomLayout ? 'autoHeight' : undefined}
          scrollbarWidth={10}
          animateRows
          suppressDragLeaveHidesColumns
          suppressPaginationPanel={pagination}
          pagination={pagination}
          paginationPageSize={limit}
          // pass other ag-grid props in a `gridProps` object unless it will widely be used by all grids to keep api clean
          {...gridProps}
        />
      </div>
    </div>
  );
};

export default AgGrid;
