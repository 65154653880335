import { Icon } from '@components';
import { useEffect, useState } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import Select2 from 'react-select';
import { Api } from '../../services';

function AssetChartEdit({
  index,
  curAsset,
  assetId,
  beaconId,
  readingId,
  isValid,
  setAssetId,
  setBeaconId,
  setReadingId,
  remove,
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cache, setCache] = useState({});
  const [assetOptions, setAssetOptions] = useState<any[]>([]);
  const [beaconOptions, setBeaconOptions] = useState<any[]>([]);
  const [readingOptions, setReadingOptions] = useState<any[]>([]);
  const [beaconValue, setBeaconValue] = useState<string>('');
  const [readingValue, setReadingValue] = useState<string>('');
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    setBeaconOptions(
      curAsset
        ? curAsset.data.beacons.map((beacon) => ({ value: beacon.id, label: beacon.name }))
        : [],
    );
    setReadingOptions(
      curAsset?.data?.beacons
        ?.filter((beacon) => {
          return beacon.id === beaconId;
        })[0]
        ?.readings.map((ability) => ({
          value: ability.abilityId,
          label: ability.ability,
        })),
    );
  }, [curAsset, beaconId]);

  const handleSearch = (value) => {
    setQuery(value);
    // if cached query items are available then set those as the initial options
    if (cache[value]) {
      setAssetOptions(cache[value].items);
    } else {
      // get and cache new query items
      const initialCall = `/assets?top=25&skip=0&filter=${value}&orderBy=name`;
      setIsLoading(true);
      getResults(initialCall).then((resp) => {
        setAssetOptions(resp.newItems);
        setCache({
          ...cache,
          [value]: { items: resp.newItems, next: resp.next, total: resp.total },
        });
        setIsLoading(false);
      });
    }
  };

  const handlePagination = () => {
    // if query cache length is less than total
    // continue to fetch new items on pagination using cached next api call
    if (cache[query].items.length !== cache[query].total) {
      setIsLoading(true);
      getResults(cache[query].next).then((resp) => {
        const items = cache[query].items.concat(resp.newItems);
        setAssetOptions(items);
        setCache({ ...cache, [query]: { items: items, next: resp.next, total: resp.total } });
        setIsLoading(false);
      });
    }
  };

  const handleChange = (selected) => {
    setBeaconId(null);
    setReadingId(null);
    setBeaconValue('');
    setReadingValue('');
    if (selected.length > 0) {
      setAssetId(selected[0]?.id);
    } else {
      setAssetId(null);
    }
  };

  const getResults = async (call) => {
    const resp = await Api.get<any[]>(call);
    const newItems = resp.data['items'].map((i) => ({
      id: i.id,
      name: i.name,
    }));
    const next = resp.data['next'];
    const total = resp.data['count'];
    return { newItems, next, total };
  };

  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  return (
    <div>
      <Card.Header className="fs-md fw-bold d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-between">
          <div
            className="icon-wrap"
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'var(--primary)',
              borderRadius: '60px',
              padding: '0.5em 0.5em',
              textAlign: 'right',
            }}
          >
            <i
              className={`sonarIcon sonarIcon-analytics`}
              style={{ color: 'var(--white)', cursor: 'pointer', fontWeight: 'normal' }}
            />
          </div>
          &nbsp; {curAsset?.data?.name ? curAsset?.data?.name : `New Asset Chart`}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          {!isValid && (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                'Please make selections in the following feilds or remove the widget in order to save this layout.',
              )}
            >
              <i
                className="ignore-click sonarIcon sonarIcon-warning align-sub fs-lg"
                style={{ color: 'var(--warning)' }}
              />
            </OverlayTrigger>
          )}
          <Button
            className="delete-icon ignore-click"
            variant="light"
            onClick={() => {
              remove(index);
            }}
          >
            <i
              className="ignore-click sonarIcon sonarIcon-add sonarIcon-rotate-45 align-sub fs-lg"
              style={{ color: 'var(--danger)' }}
            />
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        <AsyncTypeahead
          className="mt-4 mb-5 ignore-click"
          delay={500}
          id="single-asset"
          inputProps={{ style: { width: '100%' } }}
          isLoading={isLoading}
          autoFocus={!isValid}
          maxResults={20}
          minLength={1}
          onChange={handleChange}
          onPaginate={handlePagination}
          onSearch={handleSearch}
          options={
            assetOptions &&
            assetOptions.map((option) => {
              return {
                id: option.id,
                label: option.name,
              };
            })
          }
          paginate
          placeholder="Search for an Asset..."
          renderMenuItemChildren={(option, { text }) => (
            <div title={option.label}>
              <Highlighter search={text}>{option.label}</Highlighter>
              <div>
                <small className="filter-sm-name">Asset ID: {option.id}</small>
              </div>
            </div>
          )}
          useCache={false}
        />
        <Select2
          className="select-2 select-beacons mb-5 ignore-click"
          isDisabled={!assetId}
          autoFocus={!isValid && assetId}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'var(--c-primary)',
            },
          })}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
            option: (styles, state) => ({
              ...styles,
              backgroundColor: state.isSelected ? 'var(--c-primary)' : null,
              color: state.isSelected ? 'white' : 'black',
              '&:hover': {
                backgroundColor: state.isSelected ? 'var(--c-primary)' : '#e0ecfc',
              },
            }),
            singleValue: (base, style) => ({
              ...base,
              color: 'hsl(0, 0%, 50%)',
            }),
          }}
          value={beaconValue}
          options={beaconOptions}
          placeholder="Select a Beacon"
          onChange={(beacon) => {
            setBeaconId(beacon.value);
            setBeaconValue(beacon.name);
          }}
        />
        <Select2
          className="select-2  mb-5 ignore-click"
          isDisabled={!beaconId}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'var(--c-primary)',
            },
          })}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
            option: (styles, state) => ({
              ...styles,
              backgroundColor: state.isSelected ? 'var(--c-primary)' : null,
              color: state.isSelected ? 'white' : 'black',
              '&:hover': {
                backgroundColor: state.isSelected ? 'var(--c-primary)' : '#e0ecfc',
              },
            }),
            singleValue: (base, style) => ({
              ...base,
              color: 'hsl(0, 0%, 50%)',
            }),
          }}
          value={readingValue}
          options={readingOptions}
          placeholder="Select an Ability"
          onChange={(reading) => {
            setReadingId(reading.value);
            setReadingValue(reading.name);
          }}
        />
      </Card.Body>
    </div>
  );
}

export default AssetChartEdit;
