import { TriggerWorkflowStepProps } from './TriggerWorkflowStepsProps';
import Form from 'react-bootstrap/Form';

export function MovementForm({ trigger, onChange, disabled }: TriggerWorkflowStepProps) {
  return (
    <Form.Group className={'w-100 d-flex flex-row'}>
      <Form.Label className={'w-25'}>Movement</Form.Label>
      <Form.Control
        disabled={disabled}
        name="distance"
        type="number"
        min="0"
        style={{ width: 88 }}
        defaultValue={0}
        placeholder=""
        value={trigger.Distance || 0}
        onChange={(e) => onChange(parseFloat(e.target.value), 'Distance')}
      />
      &nbsp;
      <Form.Control
        disabled={disabled}
        name="selectedUnits"
        as="select"
        style={{ width: 120 }}
        defaultValue={'ft'}
        value={trigger.Units || 'ft'}
        onChange={(e) => onChange(e.target.value, 'Units')}
      >
        <option id="ft" value="ft">
          Feet (ft)
        </option>
        <option id="m" value="m">
          Meters (m)
        </option>
        <option id="mi" value="mi">
          Miles (mi)
        </option>
        <option id="km" value="km">
          Kilometers (km)
        </option>
      </Form.Control>
    </Form.Group>
  );
}
