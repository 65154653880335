import moment from 'moment';

import {
  CONTACT_TRACING_SET_END_DATE,
  CONTACT_TRACING_SET_PAGE,
  CONTACT_TRACING_SET_SEARCH,
  CONTACT_TRACING_SET_START_DATE,
} from '../types';

const initState = {
  startDate: moment().subtract(1, 'd'),
  endDate: moment(),
  search: '',
  page: 1,
};

export function contactTracingSetStartDateAction(startDate) {
  return {
    type: CONTACT_TRACING_SET_START_DATE,
    startDate,
  };
}

function handleSetStartDate(state, { startDate }) {
  return {
    ...state,
    startDate,
  };
}

export function contactTracingSetEndDateAction(endDate) {
  return {
    type: CONTACT_TRACING_SET_END_DATE,
    endDate,
  };
}

function handleSetEndDate(state, { endDate }) {
  return {
    ...state,
    endDate,
  };
}

export function contactTracingSetSearchAction(search) {
  return {
    type: CONTACT_TRACING_SET_SEARCH,
    search,
  };
}

function handleSetSearch(state, { search }) {
  return {
    ...state,
    search,
  };
}

export function contactTracingSetPage(page) {
  return {
    type: CONTACT_TRACING_SET_PAGE,
    page,
  };
}

function handleSetPage(state, { page }) {
  return {
    ...state,
    page,
  };
}

const actionHandler = {
  [CONTACT_TRACING_SET_START_DATE]: handleSetStartDate,
  [CONTACT_TRACING_SET_END_DATE]: handleSetEndDate,
  [CONTACT_TRACING_SET_SEARCH]: handleSetSearch,
  [CONTACT_TRACING_SET_PAGE]: handleSetPage,
};

const reducer = (state = initState, action) => {
  const handlerFn = actionHandler[action.type];

  if (!handlerFn) return state;

  return handlerFn(state, action);
};

export default reducer;
