import { Button, Card, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { Text } from '../../components';
import './Favorites.scss';
import '../../scss/variables.scss';

export function FavoriteTab({
  eventKey,
  favorites,
  onClickItem,
  tabName,
  color,
  onDone,
  handleRemove,
}) {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header
        style={{
          color: 'var(--dark)',
          userSelect: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
        className="fs-lg fw-bold btn-light"
      >
        <div
          className="icon-wrap"
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: color,
            borderRadius: '60px',
            padding: '0.5em 0.5em',
            textAlign: 'right',
          }}
        >
          <i
            className={`sonarIcon sonarIcon-${tabName.toLowerCase()}`}
            style={{ color: 'var(--white)', cursor: 'pointer', fontWeight: 'normal' }}
          />
        </div>
        &emsp; Favorite {tabName}s
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          <Col>
            <Card.Body>
              <div className="d-flex flex-column">
                {!favorites.length && (
                  <p className="fs-md" style={{ color: 'var(--dark)' }}>
                    Click the Star icon on a Place, Asset, or Map to save Favorites!
                  </p>
                )}

                {favorites.map((fav, index) => {
                  return (
                    <div
                      key={index}
                      className="favorite-tab d-flex flex-row justify-content-left align-items-center"
                    >
                      <i
                        className={`remove-favorite sonarIcon sonarIcon-rotate-45 sonarIcon-add fs-1x`}
                        style={{ color: 'var(--nav-text)', cursor: 'pointer' }}
                        onClick={() => handleRemove(fav)}
                      />
                      &emsp;
                      <Button
                        className="d-flex flex-row justify-content-left mb-1"
                        variant="light"
                        onClick={() => {
                          onClickItem(fav);
                          onDone();
                        }}
                      >
                        <Text
                          title={fav.name}
                          style={{
                            color: 'var(--dark)',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          &nbsp; {fav.name}
                        </Text>
                      </Button>
                    </div>
                  );
                })}
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}
