/* Utils for different events like onClick, onKeyPress, etc */

import { noop } from './utils';

/**
 * @desc Compare event key to desired key and run
 * optional function if they match.
 * @param {Object} event Event object
 * @param {string|string[]} keys Keyboard key that is being checked
 * @param {Function} fn An optional function to run if key is true
 * @returns {boolean} Boolean If keys match
 */
export function isKeyboardKey(event, keys = '', fn = noop) {
  const eventKey = event?.key ?? '';
  if (!eventKey) return false;

  let _keys = typeof keys === 'string' ? [keys] : keys;

  if (!_keys.includes(eventKey)) return false;

  fn();
  return true;
}
