import { Api } from '@services';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import styled from 'styled-components';

const StyledWrapper = styled.div<{ direction: string }>`
  display: grid;
  grid-template-columns: ${(props) => (props.direction === 'row' ? '2fr 2fr' : 'none')};
  grid-template-rows: ${(props) => (props.direction === 'column' ? 'auto' : 'none')};
  grid-gap: ${(props) => (props.direction === 'column' ? '.8rem' : '1rem')};
  padding: ${(props) => (props.direction === 'column' ? '0 0 .4rem 0' : '0')};
`;

export function IdentifiersInput({
  direction,
  valid,
  invalid,
  disabled,
  initFilter,
  onFilterChange,
}) {
  const [cache, setCache] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (key !== '' && value !== '') {
      const Identifier = `identifiers(${key + '=' + value})`;
      onFilterChange(Identifier);
    }
  }, [key, value]);

  const getResults = async (call) => {
    const resp = await Api.get<any[]>(call);
    const newItems = resp.data.map((value, index) => ({
      id: index,
      name: value,
    }));
    return { newItems };
  };

  const handleSearch = (value) => {
    // if cached query items are available then set those as the initial options
    if (cache[value]) {
      setOptions(cache[value].items);
    } else {
      // get and cache new query items
      const initialCall = `/assets/filtered/identifiers(${value})/identifiers`;
      setIsLoading(true);
      getResults(initialCall).then((resp) => {
        setOptions(resp.newItems);
        setCache({
          ...cache,
          [value]: { items: resp.newItems /*, next: resp.next, total: resp.total*/ },
        });
        setIsLoading(false);
      });
    }
  };

  const handleKeyChange = (key) => {
    if (key.length > 0) {
      setKey(key[0].label);
    } else {
      setKey('');
      onFilterChange(null);
    }
  };

  const handleValueChange = (e) => {
    if (e.target.value !== '') {
      setValue(e.target.value);
    } else {
      setValue('');
      onFilterChange(null);
    }
  };

  return (
    <StyledWrapper direction={direction}>
      <AsyncTypeahead
        className={'w-100'}
        defaultInputValue={initFilter.length > 0 ? initFilter.split('=')[0] : ''}
        delay={500}
        disabled={disabled}
        id="identifier"
        inputProps={{ style: { width: '100%' } }}
        isInvalid={invalid}
        isLoading={isLoading}
        isValid={valid}
        maxResults={25}
        minLength={1}
        onChange={handleKeyChange}
        onSearch={handleSearch}
        options={
          options &&
          options.map((option) => {
            return {
              id: option.id,
              label: option.name,
            };
          })
        }
        paginate
        placeholder="Search for a key..."
        renderMenuItemChildren={(option, { text }) => (
          <div title={option.label}>
            <Highlighter search={text}>{option.label}</Highlighter>
            <div>
              <small className="filter-sm-name">Identifier ID: {option.id}</small>
            </div>
          </div>
        )}
        useCache={false}
      />
      <Form.Control
        defaultValue={initFilter.length > 0 ? initFilter.split('=')[1] : ''}
        disabled={disabled}
        isInvalid={invalid}
        isValid={valid}
        onChange={handleValueChange}
        placeholder="enter a value"
        type="text"
      />
    </StyledWrapper>
  );
}
