import { Component } from 'react';
import Swal from 'sweetalert2';
import { Api } from '../../../services';
import toast from './../../../components/utils/toast';
// import mapService from './../../../services/mapService';

/**
 * @desc Displays content to allow Admin to invite Users to App via CSV
 */
export default class AddUsersModal extends Component {
  onDeleteMap = async (event) => {
    event.preventDefault();

    try {
      await Api.delete(`/maps/${this.props.mapId}`);
      Swal.close();
      window.location.assign('/maps');
    } catch (error) {
      toast.error('Failed to delete map.');
    }
    // mapService.remove(this.props.mapId).then(() => {
    //   toast.success();
    // });
  };

  render() {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginLeft: '3vh',
            marginRight: '3vh',
          }}
        >
          <p>
            Are you sure you would like
            <br /> to delete map{' '}
            <span style={{ fontWeight: '600' }}>
              {this.props.mapName ? this.props.mapName : ''}
            </span>{' '}
            ?
          </p>
        </div>
        <div className="d-flex flex-row justify-content-around mt-4">
          <button
            type="button"
            className="btn btn-outline-primary"
            title="Cancel"
            onClick={() => Swal.close()}
          >
            Continue Editing
          </button>
          <button
            type="button"
            className="btn btn-primary"
            title="Delete Map"
            onClick={(e) => this.onDeleteMap(e)}
          >
            Delete Map
          </button>
        </div>
      </div>
    );
  }
}
