import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import { Api } from '../../services';
import UploadScreen from './UploadScreen';

async function postBulkGatewayUpload(data) {
  return Api.post('/gateways/upload', data);
}

function GatewayUpload({ app, gridRefresh }) {
  const csvFile = {
    headers: [
      { label: 'Name', key: 'name' },
      { label: 'Device ID', key: 'deviceId' },
    ],
    exampleData: [{ name: 'Gateway 1', deviceId: 'AA:AA:AA:AA:AA:AA' }],
    filename: 'GatewaysUpload.csv',
  };

  const csvFileSuccess = {
    headers: [
      { label: 'Name', key: 'name' },
      { label: 'Device ID', key: 'deviceId' },
    ],
    filename: 'GatewaysUploadSuccess.csv',
  };

  const csvFileError = {
    headers: [
      { label: 'Name', key: 'name' },
      { label: 'Device ID', key: 'deviceId' },
      { label: 'Error Message', key: 'errorMessages' },
    ],
    filename: 'GatewaysUploadError.csv',
  };

  const gatewayUploadMutation = useMutation(postBulkGatewayUpload);

  const itemMapper = useCallback(
    (row) => {
      const item = {
        name: row[0],
        deviceId: row[1],
        applicationId: app.id,
      };

      return item;
    },
    [app],
  );

  return (
    <UploadScreen
      csvFile={csvFile}
      csvFileSuccess={csvFileSuccess}
      csvFileError={csvFileError}
      mutation={gatewayUploadMutation.mutateAsync}
      itemName="Gateways"
      itemMapper={itemMapper}
      refresh={gridRefresh}
    />
  );
}

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default connect(mapStateToProps)(GatewayUpload);
