const SET_FAVORITES = 'SET_FAVORITES';

const initState = {
  favorites: {},
};

export function setFavoritesAction(favorites) {
  return {
    type: SET_FAVORITES,
    favorites,
  };
}

function handleSetFavorites(state, { favorites }) {
  return {
    ...state,
    favorites,
  };
}

const actionHandler = {
  [SET_FAVORITES]: handleSetFavorites,
};

const reducer = (state = initState, action) => {
  const handlerFn = actionHandler[action.type];

  if (!handlerFn) return state;

  return handlerFn(state, action);
};

export default reducer;
